
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_1wzqe_dbyjy_143",
  "search-field": "awsui_search-field_1wzqe_dbyjy_178",
  "input-wrapper": "awsui_input-wrapper_1wzqe_dbyjy_184",
  "add-token": "awsui_add-token_1wzqe_dbyjy_188",
  "tokens": "awsui_tokens_1wzqe_dbyjy_195",
  "token-operator": "awsui_token-operator_1wzqe_dbyjy_200",
  "property-editor": "awsui_property-editor_1wzqe_dbyjy_204",
  "token-editor": "awsui_token-editor_1wzqe_dbyjy_205",
  "property-editor-form": "awsui_property-editor-form_1wzqe_dbyjy_210",
  "token-editor-form": "awsui_token-editor-form_1wzqe_dbyjy_211",
  "property-editor-field-property": "awsui_property-editor-field-property_1wzqe_dbyjy_214",
  "token-editor-field-property": "awsui_token-editor-field-property_1wzqe_dbyjy_215",
  "property-editor-field-operator": "awsui_property-editor-field-operator_1wzqe_dbyjy_218",
  "token-editor-field-operator": "awsui_token-editor-field-operator_1wzqe_dbyjy_219",
  "property-editor-field-value": "awsui_property-editor-field-value_1wzqe_dbyjy_222",
  "token-editor-field-value": "awsui_token-editor-field-value_1wzqe_dbyjy_223",
  "property-editor-cancel": "awsui_property-editor-cancel_1wzqe_dbyjy_226",
  "token-editor-cancel": "awsui_token-editor-cancel_1wzqe_dbyjy_227",
  "property-editor-submit": "awsui_property-editor-submit_1wzqe_dbyjy_230",
  "token-editor-submit": "awsui_token-editor-submit_1wzqe_dbyjy_231",
  "property-editor-actions": "awsui_property-editor-actions_1wzqe_dbyjy_234",
  "token-editor-actions": "awsui_token-editor-actions_1wzqe_dbyjy_235",
  "custom-content-wrapper": "awsui_custom-content-wrapper_1wzqe_dbyjy_253",
  "custom-control": "awsui_custom-control_1wzqe_dbyjy_257",
  "input": "awsui_input_1wzqe_dbyjy_184",
  "results": "awsui_results_1wzqe_dbyjy_265",
  "token-trigger": "awsui_token-trigger_1wzqe_dbyjy_270",
  "remove-all": "awsui_remove-all_1wzqe_dbyjy_275",
  "token-label": "awsui_token-label_1wzqe_dbyjy_276",
  "join-operation": "awsui_join-operation_1wzqe_dbyjy_277",
  "custom-filter-actions": "awsui_custom-filter-actions_1wzqe_dbyjy_278"
};
  