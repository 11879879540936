import {
  PropertyFilterOption,
  PropertyFilterProperty,
  PropertyFilterQuery,
  PropertyFilterToken,
} from '@cloudscape-design/collection-hooks';
import {
  ButtonDropdown,
  Container,
} from '@cloudscape-design/components-themed';
import { useNotifications } from '@risksmart-app/components/Notifications/notification-context';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CustomisableRibbonForm from '@/components/CustomisableRibbon/CustomisableRibbonForm';
import {
  DEFAULT_FILTERS,
  Filter,
} from '@/components/CustomisableRibbon/defaultFilters';
import { ModalForm } from '@/components/Form';
import { SaveAction } from '@/components/Form/Form/types';
import { FilterPropertyDashboardItem } from '@/components/RegisterDashboard/FilterPropertyDashboardItem';
import {
  namedOperations,
  Parent_Type_Enum,
  useGetRibbonItemsByParentTypeQuery,
  useInsertRibbonItemsByParentTypeMutation,
  useUpdateRibbonItemsByParentTypeMutation,
} from '@/generated/graphql';
import { evictField } from '@/utils/graphqlUtils';

import { sanitizeTokens } from '../../pages/dashboards/UniversalWidget/sanitizeSettings';
import { IssueRegisterFields } from '../../pages/issues/types';
import { useHasPermission } from '../../rbac/Permission';
import {
  CustomisableRibbonFormSchema,
  CustomisableRibbonModalFields,
  defaultValues,
} from '../../schemas/customisableRibbonModalSchema';
import styles from './style.module.scss';

export interface Props {
  items: readonly IssueRegisterFields[] | undefined;
  propertyFilterQuery: PropertyFilterQuery;
  onFilterQueryChanged: (query: PropertyFilterQuery) => void;
  filteringProperties: readonly PropertyFilterProperty[];
  filteringOptions: readonly PropertyFilterOption[] | undefined;
  parentType: Parent_Type_Enum;
}

// For more information see the docs at: docs/customisable-ribbon.md
const CustomisableRibbon: FC<Props> = ({
  items,
  propertyFilterQuery,
  onFilterQueryChanged,
  filteringProperties,
  filteringOptions,
  parentType,
}) => {
  const { t } = useTranslation(['common'], {
    keyPrefix: 'customisableRibbons',
  });
  const [showEditRibbonModal, setShowEditRibbonModal] = useState(false);
  const { addNotification } = useNotifications();

  const [insertRibbonItemsByParentType] =
    useInsertRibbonItemsByParentTypeMutation({
      update: (cache) => {
        evictField(cache, 'custom_ribbon');
      },
      refetchQueries: [namedOperations.Query.getRibbonItemsByParentType],
    });

  const [updateRibbonItemsByParentType] =
    useUpdateRibbonItemsByParentTypeMutation({
      update: (cache) => {
        evictField(cache, 'custom_ribbon');
      },
      refetchQueries: [namedOperations.Query.getRibbonItemsByParentType],
    });

  const {
    data: responseData,
    loading,
    error,
  } = useGetRibbonItemsByParentTypeQuery({
    variables: {
      parentType,
    },
  });

  if (error) {
    throw error;
  }

  const userCanEdit = useHasPermission('update:custom_ribbon', undefined);
  const userCanCreate = useHasPermission('insert:custom_ribbon', undefined);

  const filterResult = responseData?.custom_ribbon[0]?.Filters as Filter[];
  const filters = useMemo(() => {
    if (!filterResult?.length) {
      return DEFAULT_FILTERS;
    }

    return filterResult.map((filter) => {
      return {
        ...filter,
        itemFilterQuery: {
          ...filter.itemFilterQuery,
          tokens: sanitizeTokens(
            filter.itemFilterQuery.tokens as PropertyFilterToken[]
          ),
        },
      };
    });
  }, [filterResult]);

  const onSave: SaveAction<CustomisableRibbonModalFields> = async (
    dataToSave
  ) => {
    const { Filters } = dataToSave;

    if (filterResult?.length) {
      const result = await updateRibbonItemsByParentType({
        variables: {
          id: responseData?.custom_ribbon[0]?.Id,
          parentType: Parent_Type_Enum.Issue,
          filters: Filters,
          originalTimestamp:
            responseData?.custom_ribbon[0]?.ModifiedAtTimestamp ||
            'no valid timestamp',
        },
      });

      if (result.data?.update_custom_ribbon?.affected_rows !== 1) {
        addNotification({
          type: 'error',
          content: <>{t('record_updated_by_another_user')}</>,
        });

        throw new Error(
          'Records not updated. Record may have been updated by another user'
        );
      }
    } else {
      await insertRibbonItemsByParentType({
        variables: {
          parentType: Parent_Type_Enum.Issue,
          filters: Filters,
        },
      });
    }
  };

  return (
    <Container data-testid="customisable-ribbon" disableContentPaddings={false}>
      <div className="flex flex-row">
        <div className={styles.container}>
          {filters.map((filter, index) => (
            <div key={index} className={styles.item}>
              <FilterPropertyDashboardItem
                title={filter.title}
                tableFilterQuery={propertyFilterQuery}
                itemFilterQuery={{
                  tokens: [...filter.itemFilterQuery.tokens],
                  operation: filter.itemFilterQuery.operation,
                }}
                items={items ?? []}
                filteringProperties={filteringProperties}
                onClick={onFilterQueryChanged}
              />
              {index !== filters.length - 1 ? (
                <div className={styles.divider} />
              ) : null}
            </div>
          ))}
        </div>
        {userCanEdit && userCanCreate && (
          <ButtonDropdown
            data-testid="edit-ribbon-button"
            expandToViewport
            ariaLabel="Edit Ribbon"
            items={[
              {
                text: t('editRibbon'),
                id: 'edit',
                disabled: false,
              },
            ]}
            variant="icon"
            onItemClick={() => setShowEditRibbonModal(true)}
          />
        )}
      </div>
      {showEditRibbonModal && !loading && (
        <div className={styles.modal}>
          <ModalForm<CustomisableRibbonModalFields>
            formId={'edit-ribbon-form'}
            header={t('editRibbon')}
            size={'max'}
            onDismiss={() => setShowEditRibbonModal(false)}
            onSave={onSave}
            visible={true}
            i18n={{
              edit_modal_title: t('editRibbon'),
              entity_name: 'Ribbon',
            }}
            schema={CustomisableRibbonFormSchema}
            defaultValues={defaultValues}
            values={{ Filters: filters }}
          >
            <CustomisableRibbonForm
              filteringProperties={filteringProperties}
              filteringOptions={filteringOptions}
              items={items}
            />
          </ModalForm>
        </div>
      )}
    </Container>
  );
};

export default CustomisableRibbon;
