import { useEffect, useState } from 'react';

import {
  GetDocumentFileQuery,
  useGetDocumentFileQuery,
  Version_Status_Enum,
} from '@/generated/graphql';

import { useFileDownload } from '../../data/rest/useFileDownload';

const escapeFileVersion = (version?: string) =>
  version ? version.replaceAll(/\./g, '_') : '';

export type PublicDocumentData = (
  | {
      type: 'html';
      content: string;
    }
  | {
      type: 'file';
      fileName: string;
      blob?: Blob;
    }
  | {
      type: 'link';
      link: string;
    }
) & {
  documentFile: GetDocumentFileQuery['document_file'][0];
};

type UsePublicDocumentResult =
  | {
      loading: true;
      error: false;
      data?: undefined;
    }
  | {
      loading: false;
      error: true;
      data?: undefined;
    }
  | {
      loading: false;
      error: false;
      data: PublicDocumentData;
    };

export const usePublicDocument = (
  documentId: string,
  fileId: 'latest' | string
): UsePublicDocumentResult => {
  const [blob, setBlob] = useState<Blob>();
  const downloadFile = useFileDownload();

  const { data, loading, error } = useGetDocumentFileQuery({
    variables: {
      where: {
        ParentDocumentId: { _eq: documentId },
        ...(fileId && fileId !== 'latest' ? { Id: { _eq: fileId } } : {}),
        Status: { _eq: Version_Status_Enum.Published },
      },
    },
  });

  const documentFile = data?.document_file[0];
  const title = documentFile?.parent?.Title;
  const version = documentFile?.Version;
  const fileName = `${title}_${escapeFileVersion(version)}`;

  useEffect(() => {
    (async () => {
      if (documentFile && documentFile.FileId && version && !blob) {
        setBlob(
          await downloadFile(
            {
              fileId: documentFile.FileId,
              fileName: `${title}_${escapeFileVersion(version)}`,
            },
            false
          )
        );
      }
    })();
  }, [documentFile, downloadFile, title, version, blob]);

  if (error && !loading) return { loading, error: !!error };
  if (!error && loading) return { loading, error: !!error };
  if (!documentFile)
    throw new Error('Document file is null but no error thrown from hasura?');

  switch (documentFile.Type) {
    case 'html':
      return {
        loading: false,
        error: false,
        data: {
          documentFile,
          type: 'html',
          content: documentFile.Content ?? '',
        },
      };
    case 'link':
      return {
        loading: false,
        error: false,
        data: {
          documentFile,
          type: 'link',
          link: documentFile.Link ?? '',
        },
      };
    case 'file':
      return blob || documentFile
        ? {
            loading: false,
            error: false,
            data: {
              documentFile,
              type: 'file',
              fileName: fileName,
              blob: blob,
            },
          }
        : {
            loading: true,
            error: false,
          };
    default:
      return { loading: false, error: true };
  }
};
