import { FC } from 'react';
import { ObjectWithContributors, useHasPermission } from 'src/rbac/Permission';

import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';

import ComplianceObligationRatingRegister from './ComplianceObligationRatingRegister';
import InternalAuditObligationRatingRegister from './InternalAuditObligationRatingRegister';
import ObligationRatingRegister from './ObligationRatingRegister';

interface Props {
  parent: ObjectWithContributors;
}

const Tab: FC<Props> = ({ parent }) => {
  const complianceMonitoringEnabled = useIsFeatureVisibleToOrg(
    'compliance_monitoring'
  );
  const internalAuditEnabled = useIsFeatureVisibleToOrg('internal_audit');
  const canViewCompliance = useHasPermission(
    'read:compliance_monitoring_assessment',
    parent
  );
  const canViewInternalAudit = useHasPermission(
    'read:internal_audit_report',
    parent
  );
  return (
    <>
      <ObligationRatingRegister parent={parent} />
      {complianceMonitoringEnabled && canViewCompliance && (
        <ComplianceObligationRatingRegister parent={parent} />
      )}
      {internalAuditEnabled && canViewInternalAudit && (
        <InternalAuditObligationRatingRegister parent={parent} />
      )}
    </>
  );
};

export default Tab;
