import { ParseKeys } from 'i18next';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Parent_Type_Enum } from '@/generated/graphql';
import {
  acceptanceDetailUrl,
  actionDetailsUrl,
  appetiteDetailsUrl,
  assessmentDetailsUrl,
  complianceMonitoringAssessmentDetailsUrl,
  controlDetailsUrl,
  controlGroupDetailsUrl,
  impactDetailsUrl,
  indicatorDetailsUrl,
  internalAuditDetailsUrl,
  internalAuditReportDetailsUrl,
  issueAssessmentDetailsUrl,
  issueDetailsUrl,
  obligationDetailsUrl,
  policyDetailsUrl,
  riskDetailsUrl,
} from '@/utils/urls';

export type EntityInfo = {
  translationKey: ParseKeys<'taxonomy'>;
  singular: string;
  plural: string;
  url: (id: string) => string;
};

export const useEntityInfo = () => {
  const { t } = useTranslation('taxonomy');
  const lookup: { [key in Parent_Type_Enum]?: EntityInfo } = useMemo(
    () => ({
      [Parent_Type_Enum.Action]: {
        translationKey: 'action',
        singular: t('action', { count: 1 }),
        plural: t('action', { count: 3 }),
        url: actionDetailsUrl,
      },
      [Parent_Type_Enum.Acceptance]: {
        translationKey: 'acceptance',
        singular: t('acceptance', { count: 1 }),
        plural: t('acceptance', { count: 3 }),
        url: acceptanceDetailUrl,
      },
      [Parent_Type_Enum.Appetite]: {
        translationKey: 'appetite',
        singular: t('appetite', { count: 1 }),
        plural: t('appetite', { count: 3 }),
        url: appetiteDetailsUrl,
      },
      [Parent_Type_Enum.Assessment]: {
        translationKey: 'assessment',
        singular: t('assessment', { count: 1 }),
        plural: t('assessment', { count: 3 }),
        url: assessmentDetailsUrl,
      },
      [Parent_Type_Enum.InternalAuditReport]: {
        translationKey: 'internal_audit_report',
        singular: t('internal_audit_report', { count: 1 }),
        plural: t('internal_audit_report', { count: 3 }),
        url: internalAuditReportDetailsUrl,
      },
      [Parent_Type_Enum.InternalAuditEntity]: {
        translationKey: 'internal_audit',
        singular: t('internal_audit', { count: 1 }),
        plural: t('internal_audit', { count: 3 }),
        url: internalAuditDetailsUrl,
      },
      [Parent_Type_Enum.ComplianceMonitoringAssessment]: {
        translationKey: 'compliance_monitoring_assessment',
        singular: t('compliance_monitoring_assessment', { count: 1 }),
        plural: t('compliance_monitoring_assessment', { count: 3 }),
        url: complianceMonitoringAssessmentDetailsUrl,
      },
      [Parent_Type_Enum.Indicator]: {
        translationKey: 'indicator',
        singular: t('indicator', { count: 1 }),
        plural: t('indicator', { count: 3 }),
        url: indicatorDetailsUrl,
      },
      [Parent_Type_Enum.Impact]: {
        translationKey: 'impact',
        singular: t('impact', { count: 1 }),
        plural: t('impact', { count: 3 }),
        url: impactDetailsUrl,
      },
      [Parent_Type_Enum.Control]: {
        translationKey: 'control',
        singular: t('control', { count: 1 }),
        plural: t('control', { count: 3 }),
        url: controlDetailsUrl,
      },
      [Parent_Type_Enum.ControlGroup]: {
        translationKey: 'control_group',
        singular: t('control_group', { count: 1 }),
        plural: t('control_group', { count: 3 }),
        url: controlGroupDetailsUrl,
      },
      [Parent_Type_Enum.Document]: {
        translationKey: 'document',
        singular: t('document', { count: 1 }),
        plural: t('document', { count: 3 }),
        url: policyDetailsUrl,
      },
      [Parent_Type_Enum.Obligation]: {
        translationKey: 'obligation',
        singular: t('obligation', { count: 1 }),
        plural: t('obligation', { count: 3 }),
        url: obligationDetailsUrl,
      },
      [Parent_Type_Enum.Risk]: {
        translationKey: 'risk',
        singular: t('risk', { count: 1 }),
        plural: t('risk', { count: 3 }),
        url: riskDetailsUrl,
      },
      [Parent_Type_Enum.Issue]: {
        translationKey: 'issue',
        singular: t('issue', { count: 1 }),
        plural: t('issue', { count: 3 }),
        url: issueDetailsUrl,
      },
      [Parent_Type_Enum.IssueAssessment]: {
        translationKey: 'issue_assessment',
        singular: t('issue_assessment', { count: 1 }),
        plural: t('issue_assessment', { count: 3 }),
        url: issueAssessmentDetailsUrl,
      },
    }),
    [t]
  );

  const getEntityInfo = useCallback(
    (type: Parent_Type_Enum) => {
      const result = lookup[type];
      if (!result) {
        throw new Error(`${type} not implemented`);
      }
      return result;
    },
    [lookup]
  );
  return getEntityInfo;
};

export default useEntityInfo;
