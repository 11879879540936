import _ from 'lodash';
import { ReactNode } from 'react';
import { FieldValues } from 'react-hook-form';

import { Filter } from '@/components/UserSearchPreferences/useGroupAndUserOptions';
import { GetUserGroupsQuery, GetUsersQuery } from '@/generated/graphql';

import UserSearchPreferences from '../../UserSearchPreferences/UserSearchPreferences';
import { ControlledBaseProps } from '..';
import ControlledMultiselect from '../ControlledMultiselect';
import { HidableOption } from '../ControlledMultiselect/types';
import styles from './style.module.scss';

interface Props<T extends FieldValues> extends ControlledBaseProps<T> {
  disabled?: boolean;
  constraintText?: React.ReactNode;
  testId?: string;
  includeGroups: boolean;
  userFilter?: Filter<GetUsersQuery['user'][number]>;
  groupFilter?: Filter<GetUserGroupsQuery['user_group'][number]>;
  customTokenRender?: (
    options: HidableOption[],
    actions: { removeToken: (value: string) => void },
    users: GetUsersQuery | undefined,
    userGroups: GetUserGroupsQuery | undefined
  ) => ReactNode;
  disabledValues?: Set<string>;
}

export const ControlledGroupAndUserMultiSelect = <T extends FieldValues>({
  includeGroups,
  userFilter,
  groupFilter,
  disabledValues,
  ...props
}: Props<T>) => {
  return (
    <UserSearchPreferences
      includeGroups={includeGroups}
      userFilter={userFilter}
      groupFilter={groupFilter}
      disabledValues={disabledValues}
    >
      {({
        onBlur,
        onChange,
        statusType,
        options,
        userGroups,
        users,
        preferencesButton,
      }) => (
        <ControlledMultiselect
          filteringType="auto"
          className={styles.root}
          statusType={statusType}
          hideTokens={true}
          options={options}
          renderTokens={true}
          {...props}
          onChange={onChange}
          customTokenRender={
            props.customTokenRender
              ? (...args) => {
                  return props?.customTokenRender?.(...args, users, userGroups);
                }
              : undefined
          }
          onBlur={onBlur}
          sideControl={preferencesButton}
          previewChangesFormatter={(
            val: [{ UserId?: string; value?: string }] | undefined | null
          ) => {
            if (Array.isArray(val)) {
              return val
                .map((v) => v.UserId ?? v.value)
                .map(
                  (v) =>
                    users?.user.find((u) => u.Id === v)?.FriendlyName ??
                    userGroups?.user_group.find((ug) => ug.Id === v)?.Name
                )
                .join(', ');
            }

            return '-';
          }}
          hasFieldChanged={(
            val:
              | {
                  from: { UserId?: string; value?: string }[];
                  to: { UserId?: string; value?: string }[];
                }
              | undefined
              | null
          ) => {
            if (val === undefined || val === null) {
              return false;
            }
            const from = val.from?.map((v) => v.UserId ?? v.value).sort();
            const to = val.to?.map((v) => v.UserId ?? v.value).sort();
            return !_.isEqual(from, to);
          }}
        />
      )}
    </UserSearchPreferences>
  );
};
