import groupBy from 'lodash/groupBy';
import { useEffect } from 'react';

import {
  Approval_Status_Enum,
  ChangeRequestPartsFragment,
  useGetChangeRequestByParentIdSubscription,
  useGetLivePendingChangeRequestsSubscription,
  useGetPendingChangeRequestsQuery,
} from '@/generated/graphql';

import useRisksmartUser from './useRisksmartUser';

export type ObjectWithApprovals = {
  Id: string;
};

type UseChangeRequestResult = {
  pendingChangeRequests: ChangeRequestPartsFragment[];
  pendingDeleteRequests: ChangeRequestPartsFragment[];
  isActiveApprover: (
    changeRequest: ChangeRequestPartsFragment,
    ownerIds?: string[]
  ) => boolean;
  activeLevelId: (changeRequest: ChangeRequestPartsFragment) => string | null;
  canAmendChangeRequest: (changeRequest: ChangeRequestPartsFragment) => boolean;
  changeRequests: ChangeRequestPartsFragment[];
  loading: boolean;
};

export const useChangeRequests = (
  objectWithApprovals?: ObjectWithApprovals
): UseChangeRequestResult => {
  const { user, isLoading } = useRisksmartUser();
  const userId = user?.userId;
  const { data, refetch } = useGetPendingChangeRequestsQuery({
    variables: {
      ParentId: objectWithApprovals?.Id ?? '',
    },
    skip: !objectWithApprovals?.Id,
    fetchPolicy: 'network-only',
  });
  const { data: liveData, loading: liveLoading } =
    useGetLivePendingChangeRequestsSubscription({
      variables: {
        ParentId: objectWithApprovals?.Id ?? '',
      },
      skip: (data?.change_request?.length ?? 0) < 1,
    });
  const { data: allChangeRequests, loading } =
    useGetChangeRequestByParentIdSubscription({
      variables: {
        Id: objectWithApprovals?.Id ?? '',
      },
      skip: !objectWithApprovals?.Id,
    });

  useEffect(() => {
    if (
      liveData?.change_request &&
      liveData.change_request.length < 1 &&
      (data?.change_request.length ?? 0) > 0
    ) {
      refetch();
    }
  }, [liveData, data, refetch]);

  const activeLevelId = (changeRequest: ChangeRequestPartsFragment) => {
    // group approver responses by levels
    const levels = groupBy(
      changeRequest.responses,
      (response) => response.approver.level?.Id ?? 'NO-LEVEL'
    );
    let rejected = false;

    const levelResults: boolean[] = [];

    Object.values(levels).forEach((level) => {
      // If there are no approvers at this level, the level is considered approved
      if (level.length === 0) levelResults.push(true);

      const responded = level.filter(
        (response) => response.Approved !== null
      ).length;
      const approvers = level.filter(
        (response) => response.Approved === true
      ).length;

      // Approval rule decisions
      switch (level[0].approver.level?.ApprovalRuleType) {
        case 'all_approve':
          // If the number of approvers is equal to the number of responses, then approve.
          levelResults.push(approvers === level.length);
          // If the number of responses is more than the number of approvers, then reject
          // since someone must have rejected it.
          if (responded > approvers) {
            rejected = true;
          }
          break;
        case 'any_one_approve':
          // If there is at least one approver, then approve
          levelResults.push(approvers > 0);
          // If everyone has responded and there are no approvers, then reject.
          if (responded === level.length && approvers === 0) {
            rejected = true;
          }
          break;
        case 'majority_approve':
          // If the number of approvers is more than half, then approve.
          levelResults.push(approvers > level.length / 2);
          // If atleast half of the approvers have rejected, then reject since
          // it will be impossible to make a majority.
          if (responded - approvers >= level.length / 2) {
            rejected = true;
          }
          break;
      }
    });

    const firstNonRejectedLevelIndex = levelResults.findIndex(
      (result) => !result
    );
    const activeLevelIndex =
      rejected || firstNonRejectedLevelIndex === -1
        ? null
        : firstNonRejectedLevelIndex;
    const activeLevelId =
      activeLevelIndex !== null ? Object.keys(levels)[activeLevelIndex] : null;

    return activeLevelId;
  };

  const isActiveApprover = (
    changeRequest: ChangeRequestPartsFragment,
    ownerIds?: string[]
  ) => {
    const levelId = activeLevelId(changeRequest);
    const responses = changeRequest.responses.filter(
      (response) => response.approver.level?.Id === levelId
    );

    const isReferencedActiveApprover = responses.some(
      (response) =>
        (response.approver.user?.Id === userId ||
          response.approver.group?.users.some(
            (user) => user?.UserId === userId
          )) &&
        response.Approved === null
    );
    const ownerApprover = responses.some(
      (response) =>
        response.approver.OwnerApprover && response.Approved === null
    );
    const isOwner = ownerIds?.includes(userId!) ?? false;

    return (
      ((ownerApprover && isOwner) || isReferencedActiveApprover) &&
      changeRequest.ChangeRequestStatus === Approval_Status_Enum.Pending
    );
  };

  const canAmendChangeRequest = (changeRequest: ChangeRequestPartsFragment) => {
    const levelId = activeLevelId(changeRequest);
    const level = changeRequest.responses.find(
      (response) => response.approver.level?.Id === levelId
    )?.approver.level;
    const rule = level?.approval?.InFlightEditRule;

    switch (rule) {
      case 'everyone':
        return true;
      case 'approvers':
        return isActiveApprover(
          changeRequest,
          changeRequest.parentOwners?.map((owner) => owner.UserId ?? '')
        );
      case 'noone':
        return false;
      default:
        return false;
    }
  };

  return {
    loading: loading || liveLoading || isLoading,
    isActiveApprover,
    activeLevelId,
    canAmendChangeRequest,
    pendingDeleteRequests:
      liveData?.change_request.filter((cr) => cr.Type === 'delete') ??
      data?.change_request.filter((cr) => cr.Type === 'delete') ??
      [],
    pendingChangeRequests:
      liveData?.change_request.filter((cr) => cr.Type === 'update') ??
      data?.change_request.filter((cr) => cr.Type === 'update') ??
      [],
    changeRequests: allChangeRequests?.change_request ?? [],
  };
};
