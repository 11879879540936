import { isApolloError } from '@apollo/client';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/notification-context';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ObjectWithContributors } from 'src/rbac/Permission';

import { ConfirmChangeRequestModal } from '@/components/ChangeRequestsPreview/ConfirmChangeRequestModal';
import PolicyDocumentStatusBadge from '@/components/PolicyDocumentStatusBadge/PolicyDocumentStatusBadge';
import {
  UpdateDocumentVersionMutationVariables,
  useGetDocumentFileByIdQuery,
  useUpdateDocumentVersionMutation,
  Version_Status_Enum,
} from '@/generated/graphql';
import { useChangeRequests } from '@/hooks/useChangeRequests';
import { mutationResultNotification } from '@/hooks/useMutationResultNotification';
import { toLocalDate } from '@/utils/dateUtils';
import { evictField } from '@/utils/graphqlUtils';
import { policyFileUrl } from '@/utils/urls';

type Props = {
  document: ObjectWithContributors;
  documentFileId: string;
};

function DocumentVersionPreview({ document, documentFileId }: Props) {
  const { data } = useGetDocumentFileByIdQuery({
    variables: {
      id: documentFileId,
    },
  });

  const { t } = useTranslation(['common'], {
    keyPrefix: 'documentFiles.versionPreview',
  });
  const navigate = useNavigate();

  const [update] = useUpdateDocumentVersionMutation({
    update: (cache) => {
      evictField(cache, 'document_file_by_pk');
      evictField(cache, 'document_file');
      evictField(cache, 'document');
    },
  });

  const documentVersion = data?.document_file[0];
  const documentIsDraft = documentVersion?.Status === Version_Status_Enum.Draft;
  const objectWithApprovals = documentVersion
    ? {
        ...documentVersion,
        owners: documentVersion.parent?.owners ?? [],
        ownerGroups: documentVersion.parent?.ownerGroups ?? [],
      }
    : undefined;

  const [showConfirmChangeRequest, setShowConfirmChangeRequest] =
    useState(false);
  const { pendingChangeRequests } = useChangeRequests(objectWithApprovals);
  const { addNotification } = useNotifications();

  const handlePublish = async (confirm?: boolean) => {
    if (!documentVersion) return;
    await mutationResultNotification({
      addNotification,
      successMessageKey: 'update_success_message',
      entityName: 'Document Version',
      asyncAction: async () => {
        try {
          await update({
            variables: {
              ...documentVersion,
              Status: Version_Status_Enum.Published,
              LatestModifiedAtTimestamp: documentVersion.ModifiedAtTimestamp,
            } as unknown as UpdateDocumentVersionMutationVariables,
            context: {
              headers: {
                'x-confirm-change-request': confirm ? 'true' : 'false',
              },
            },
          });
        } catch (error) {
          const e = error as Error;
          if (
            isApolloError(e) &&
            e.graphQLErrors.some((er) =>
              er.message.includes('You need to create a change request')
            )
          ) {
            setShowConfirmChangeRequest(true);
            return false;
          }
        }
        return true;
      },
    })({});
  };

  const handleOpen = useCallback(() => {
    if (!documentVersion) return;
    if (documentVersion.Link) {
      window.open(documentVersion.Link, '_blank');
    } else if (documentVersion.FileId) {
      navigate(policyFileUrl(document.Id, documentVersion.Id));
    }
  }, [document.Id, documentVersion, navigate]);

  if (!documentVersion) return null;

  return (
    <>
      {showConfirmChangeRequest && (
        <ConfirmChangeRequestModal
          onDismiss={() => setShowConfirmChangeRequest(false)}
          onConfirm={() => handlePublish(true)}
        />
      )}
      <div
        className={`p-5 bg-off_white rounded-md flex flex-col gap-4 justify-items-start`}
      >
        <h3 className="m-0">{t('title')}</h3>
        <div className="p-4 bg-white border-grey150 border-solid border-2 rounded-md flex flex-col gap-2">
          <div className="flex justify-between">
            <h3 className="m-0">
              {t('version', { version: documentVersion.Version })}
            </h3>
            <PolicyDocumentStatusBadge
              item={documentVersion}
              changeRequests={documentVersion.changeRequests}
            />
          </div>
          <p className="text-grey600 m-0 mb-3">
            <strong>{t('created')}:</strong>{' '}
            {toLocalDate(documentVersion.CreatedAtTimestamp)}
          </p>
          <div className="flex gap-2">
            <Button
              formAction="none"
              onClick={() => navigate(`files/update/${documentVersion.Id}`)}
              variant="primary"
            >
              {t('view')}
            </Button>
            {documentVersion.Link || documentVersion.FileId ? (
              <Button formAction="none" onClick={handleOpen}>
                {t('openFile')}
              </Button>
            ) : null}
          </div>
        </div>
        {documentIsDraft && pendingChangeRequests.length < 1 && (
          <Button onClick={() => handlePublish()}>Publish</Button>
        )}
      </div>
    </>
  );
}

export default DocumentVersionPreview;
