
    import './styles.scoped.css';
    export default {
  "layout": "awsui_layout_5gtk3_28e15_149",
  "is-visual-refresh": "awsui_is-visual-refresh_5gtk3_28e15_149",
  "background": "awsui_background_5gtk3_28e15_152",
  "header": "awsui_header_5gtk3_28e15_155",
  "is-overlap-disabled": "awsui_is-overlap-disabled_5gtk3_28e15_171",
  "content": "awsui_content_5gtk3_28e15_179",
  "has-header": "awsui_has-header_5gtk3_28e15_183"
};
  