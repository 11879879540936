import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { FormContext } from '@/components/Form/Form/FormContext';
import { FormContextProps } from '@/components/Form/Form/types';
import { Parent_Type_Enum } from '@/generated/graphql';

import AssessmentActivityFormFields from './AssessmentActivityFormFields';
import {
  AssessmentActivityFormDataFields,
  BaseAssessmentActivitySchema,
  defaultValues,
} from './assessmentActivitySchema';

type Props = Omit<
  FormContextProps<AssessmentActivityFormDataFields>,
  'schema' | 'formId' | 'parentType' | 'defaultValues' | 'i18n'
>;

const AssessmentActivityForm: FC<Props> = (props) => {
  const { t } = useTranslation(['common']);
  return (
    <FormContext
      {...props}
      header={t('details')}
      schema={BaseAssessmentActivitySchema}
      defaultValues={defaultValues}
      i18n={t('assessmentActivities')}
      formId={'assessment-activity-form'}
      parentType={Parent_Type_Enum.AssessmentActivity}
      renderTemplate={props.renderTemplate}
    >
      <AssessmentActivityFormFields readOnly={props.readOnly} />
    </FormContext>
  );
};

export default AssessmentActivityForm;
