import { SideNavigationProps } from '@cloudscape-design/components-themed';

import { CountRenderer } from './Count';
import { Item } from './Item';
import Link from './Link';
import { Icon } from './Navigation';

export const LinkGroup = ({
  item,
  renderCount,
}: {
  item: SideNavigationProps.LinkGroup & Icon;
  renderCount?: CountRenderer;
}) => {
  return (
    <Item>
      <Link item={item} renderCount={renderCount} />
    </Item>
  );
};
