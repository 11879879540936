import { useKnockFeed } from '@knocklabs/react-notification-feed';
import { FC, ReactElement, useEffect } from 'react';

export const MessagesRequester: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const { feedClient } = useKnockFeed();
  useEffect(() => {
    feedClient.fetch();
  }, [feedClient]);
  return children;
};
