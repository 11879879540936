import { useDashboardFilter } from 'src/context/useDashboardFilter';
import { merge } from 'ts-deepmerge';

import { useDashboardWidgetSettings } from '../../../../context/useDashboardWidgetSettings';
import { GigawidgetSettings } from '../../UniversalWidget/util';
import {
  DataSourceVariables,
  DateFilterOptions,
  WidgetDataSource,
} from '../types';

const applyFilter = <T, K>(filterFunc?: (value: T) => K, value?: T) => {
  if (Array.isArray(value) && value.length === 0) return {};
  if (value == null) return {};
  if (!filterFunc) return {};

  return filterFunc(value);
};

export const useDataSourceFilter = <T extends WidgetDataSource>(
  dataSource: T,
  dateFilterOptions?: DateFilterOptions<T>
) => {
  const { filters } = useDashboardFilter();
  const [settings] = useDashboardWidgetSettings<GigawidgetSettings>();

  const departmentFilter = applyFilter(
    dataSource.dashboardFilterConfig.departmentsFilter,
    filters.departments
  );
  const tagFilter = applyFilter(
    dataSource.dashboardFilterConfig.tagsFilter,
    filters.tags
  );
  const dateFilter = applyFilter(
    (dateRange) =>
      dateFilterOptions?.dateFilter?.(
        dateRange,
        dateFilterOptions?.precision ?? 'day'
      ) ?? {},
    filters.dateRange
  );

  return merge(
    dataSource.defaultVariables,
    departmentFilter,
    tagFilter,
    settings?.ignoreDashboardDateFilter ? {} : dateFilter
  ) as DataSourceVariables<T>;
};
