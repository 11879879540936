import {
  Link as DefaultLink,
  LinkProps,
} from '@cloudscape-design/components-themed';
import { FC } from 'react';

import useLink from '../hooks/use-link';

interface Props extends LinkProps {}

const Link: FC<Props> = ({ onFollow, ...props }) => {
  const { handleFollow } = useLink();
  return (
    <DefaultLink
      onFollow={
        onFollow
          ? (e) => {
              e.preventDefault();
              onFollow(e);
            }
          : handleFollow
      }
      {...props}
    />
  );
};

export default Link;
