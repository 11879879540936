import { SpaceBetween } from '@cloudscape-design/components-themed';
import Button from '@risksmart-app/components/Button';
import { PageNotFound } from '@risksmart-app/components/errors/errors';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  GetAssessmentByIdDocument,
  GetAssessmentsDocument,
  Parent_Type_Enum,
  useDeleteAssessmentsMutation,
  useGetAssessmentByIdQuery,
} from 'src/generated/graphql';
import { PageLayout } from 'src/layouts';
import { Permission } from 'src/rbac/Permission';
import {
  useGetDetailParentPath,
  useGetDetailPath,
} from 'src/routes/useGetDetailParentPath';

import ControlledTabs from '@/components/ControlledTabs';
import DeleteModal from '@/components/DeleteModal/DeleteModal';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { getFriendlyId } from '@/utils/friendlyId';
import { evictField } from '@/utils/graphqlUtils';

import useExporter from './useExporter';
import { useTabs } from './useTabs';

type Props = {
  activeTabId:
    | 'details'
    | 'activities'
    | 'findings'
    | 'impacts'
    | 'linkedItems';
  activityMode?: 'add' | 'update' | 'list' | undefined;
};

const Page: FC<Props> = ({ activeTabId, activityMode = undefined }) => {
  const { t } = useTranslation(['common']);
  const assessmentId = useGetGuidParam('assessmentId');
  const [exportItem, { loading: exporting }] = useExporter(assessmentId);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const navigate = useNavigate();
  const parentUrl = useGetDetailParentPath(assessmentId);
  const detailsPath = useGetDetailPath(assessmentId);
  const [mutate, deleteResult] = useDeleteAssessmentsMutation({
    update: (cache) => {
      evictField(cache, 'assessment');
      evictField(cache, 'assessment_aggregate');
    },
    refetchQueries: [GetAssessmentByIdDocument, GetAssessmentsDocument],
  });

  const { t: st } = useTranslation(['common'], { keyPrefix: 'assessments' });
  const { data, loading, error } = useGetAssessmentByIdQuery({
    variables: {
      Id: assessmentId,
    },
  });
  if (error) {
    throw error;
  }

  const assessment = data?.assessment[0];
  const tabs = useTabs(detailsPath, 'rating', assessment, activityMode);
  const onDelete = useDeleteResultNotification({
    entityName: st('entity'),
    asyncAction: async () => {
      if (!assessment) return false;
      await mutate({
        variables: {
          Ids: [assessment.Id],
        },
      });
      navigate(parentUrl);
      return true;
    },
  });

  if (!loading && !assessment?.Id) {
    throw new PageNotFound(`Assessment with id ${assessmentId} not found`);
  }

  const counter =
    assessment &&
    `(${getFriendlyId(Parent_Type_Enum.Assessment, assessment.SequentialId)})`;
  const fallbackTitle = st('fallback_title');
  return (
    <PageLayout
      actions={
        <Permission permission={'delete:assessment'} parentObject={assessment}>
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              iconName="download"
              disabled={exporting}
              onClick={exportItem}
            >
              {t('export')}
            </Button>
            <Button
              variant="normal"
              formAction="none"
              onClick={() => {
                setIsDeleteModalVisible(true);
              }}
            >
              {st('delete_button')}
            </Button>
          </SpaceBetween>
        </Permission>
      }
      meta={{
        title: fallbackTitle,
      }}
      title={assessment?.Title}
      counter={counter}
    >
      <ControlledTabs
        tabs={tabs}
        activeTabId={activeTabId}
        variant="container"
      />

      <DeleteModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        header={st('delete_modal_title')}
        onDelete={onDelete}
        onDismiss={() => setIsDeleteModalVisible(false)}
      >
        {st('confirm_delete_message')}
      </DeleteModal>
    </PageLayout>
  );
};

export default Page;
