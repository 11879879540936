import i18n from 'src/i18n';

import { auditItemSearch } from '@/utils/urls';

import { GetItem } from './types';

export const getItem: GetItem = (item, lookupData) => {
  const riskId = item.data?.objectId;
  const risk = lookupData.risks?.[riskId];
  return {
    message: i18n.t('notifications.messages.riskDelete', {
      title: risk?.Title ?? i18n.t('notifications.unknown'),
    }),
    url: risk ? auditItemSearch(risk.Id) : null,
  };
};
