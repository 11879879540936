import { PropertyFilterToken } from '@cloudscape-design/collection-hooks';
import {
  PropertyFilterQuery,
  SortingState,
} from '@cloudscape-design/collection-hooks/cjs/interfaces';
import {
  queryStringToTableOptions,
  TableOptions,
  tableOptionsToQueryString,
  TypedPropertyFilterQuery,
} from '@risksmart-app/components/Table/tableUtils';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { TableRecord } from '../types';

/**
 * Store and retrieve filters and sorting in the url after the hash
 *
 * @returns
 */
export const useFiltersFromUrlHash = <T extends TableRecord>() => {
  const { hash } = useLocation();
  const [propertyFilter, setPropertyFilter] = useState<PropertyFilterQuery>();
  const [sortingState, setSortingState] = useState<SortingState<T>>();
  const navigate = useNavigate();

  const updateUrlHash = useCallback(
    (options: TableOptions<T>) => {
      const queryString = tableOptionsToQueryString<T>({ ...options });

      navigate({ hash: queryString }, { replace: true });
    },
    [navigate]
  );

  useEffect(() => {
    const tableOptions = queryStringToTableOptions(
      hash.substring(1, hash.length)
    );
    if (tableOptions.filtering) {
      const sanitisedFilteringTokens: readonly PropertyFilterToken[] =
        tableOptions.filtering.tokens.map((token) => {
          try {
            const parsedValue = JSON.parse(token.value);

            return {
              ...token,
              value:
                typeof parsedValue === 'object' ? parsedValue : token.value,
            };
          } catch (e) {
            return token;
          }
        });

      setPropertyFilter({
        ...tableOptions.filtering,
        tokens: sanitisedFilteringTokens,
      } as PropertyFilterQuery);
    }
    if (tableOptions.sorting) {
      setSortingState(tableOptions.sorting);
    }
  }, [hash]);

  const setUrlSortingState = (sortingState: SortingState<T>) => {
    updateUrlHash({
      sorting: sortingState,
      filtering: propertyFilter as TypedPropertyFilterQuery<T>,
    });
    setSortingState(sortingState);
  };

  const setUrlPropertyFilter = useCallback(
    (propertyFilter: PropertyFilterQuery) => {
      updateUrlHash({
        sorting: sortingState,
        filtering: propertyFilter as TypedPropertyFilterQuery<T>,
      });
      setPropertyFilter(propertyFilter);
    },
    [sortingState, updateUrlHash]
  );

  return {
    sortingState,
    propertyFilter,
    setSortingState: setUrlSortingState,
    setPropertyFilter: setUrlPropertyFilter,
    hash,
  };
};
