import { FC, ReactNode } from 'react';

import { getColorStyles } from '@/utils/colours';
import { hasColor } from '@/utils/utils';

import styles from './style.module.scss';

interface Props {
  rating?: { color?: string; label: string; tooltip?: string };
  children?: ReactNode | string;
}

const SimpleRatingBadge: FC<Props> = ({ rating, children }) => {
  if (!rating) return '-';
  const title = rating.tooltip;
  const _color = hasColor(rating) ? rating.color : undefined;
  const className = styles.badge;
  const style = getColorStyles(_color);
  return (
    <span {...{ className }} {...{ style }} title={title}>
      {children || rating?.label}
    </span>
  );
};

export default SimpleRatingBadge;
