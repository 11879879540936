import { FC } from 'react';

type Props = {
  title?: string;
  content: string | { title: string; content: string }[];
};

const HelpSection: FC<Props> = ({ title, content }) => {
  return (
    <>
      {title && <h3>{title}</h3>}
      {typeof content === 'string' ? (
        <p>{content}</p>
      ) : (
        content.map((c, i) => (
          <div key={i}>
            <h4>{c.title}</h4>
            <p>{c.content}</p>
          </div>
        ))
      )}
    </>
  );
};

export default HelpSection;
