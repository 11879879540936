import { ButtonDropdown } from '@cloudscape-design/components-themed';
import { FC, useState } from 'react';
import { useHasPermission } from 'src/rbac/Permission';

import { Parent_Type_Enum } from '@/generated/graphql';

import { EditFieldModal } from '../EditFieldModal/EditFieldModal';
import { useRiskSmartForm } from './CustomisableForm/RiskSmartFormContext';

interface FormMenuProps {
  parentType: Parent_Type_Enum;
}
export const FormMenu: FC<FormMenuProps> = ({ parentType }) => {
  const hasAccess = useHasPermission('update:custom_attribute_schema');
  const [showCustomFieldModal, setShowCustomFieldModal] = useState(false);
  const { toggleEditMode } = useRiskSmartForm();

  const onAddAction = () => {
    setShowCustomFieldModal(true);
  };
  const onEditAction = () => {
    toggleEditMode();
  };
  const onDismissCustomFieldModal = () => {
    setShowCustomFieldModal(false);
  };
  if (!hasAccess) {
    return null;
  }
  return (
    <>
      <ButtonDropdown
        data-testid="form-settings-button"
        expandToViewport
        ariaLabel="Form Settings"
        items={[
          {
            // TODO: translation
            text: 'Add custom field',
            id: 'add',
            disabled: false,
          },
          {
            // TODO: translation
            text: 'Edit form',
            id: 'edit',
            disabled: false,
          },
        ]}
        variant="icon"
        onItemClick={(e) => {
          if (e.detail.id === 'add') {
            onAddAction();
          }
          if (e.detail.id === 'edit') {
            onEditAction();
          }
        }}
      >
        ...
      </ButtonDropdown>
      {showCustomFieldModal && (
        <EditFieldModal
          customField={true}
          onDismiss={onDismissCustomFieldModal}
          parentType={parentType}
        />
      )}
    </>
  );
};
