import { RouteObject } from 'react-router-dom';
import i18n from 'src/i18n';

import { ControlsUpdateBreadcrumb, ControlsUpdatePage } from '../pages';
import { actionRoute } from './actionRoutes.config';
import { indicatorRoute } from './indicatorRoutes.config';
import { issueRoute } from './issueRoutes.config';

export const controlRoute: RouteObject = {
  path: ':controlId',
  handle: {
    title: () => i18n.format(i18n.t('control_one'), 'capitalize'),
    breadcrumb: ControlsUpdateBreadcrumb,
  },
  children: [
    {
      path: '',
      element: (
        <ControlsUpdatePage activeTabId="details" showDeleteButton={true} />
      ),
    },
    {
      path: 'performance',
      element: <ControlsUpdatePage activeTabId="performance" />,
      handle: {
        title: () => i18n.format(i18n.t('performance'), 'capitalize'),
      },
    },
    {
      path: 'actions',
      handle: {
        title: () => i18n.format(i18n.t('action_other'), 'capitalize'),
      },
      children: [
        {
          path: '',
          element: <ControlsUpdatePage activeTabId="actions" />,
        },
        actionRoute,
      ],
    },
    {
      path: 'issues',
      handle: {
        title: () => i18n.t('issues.tab_title'),
      },

      children: [
        {
          path: '',
          element: <ControlsUpdatePage activeTabId="issues" />,
        },
        issueRoute,
      ],
    },
    {
      path: 'indicators',
      handle: {
        title: () => i18n.t('indicators.tab_title'),
      },

      children: [
        {
          path: '',
          element: <ControlsUpdatePage activeTabId="indicators" />,
        },
        indicatorRoute,
      ],
    },
    {
      path: 'approvals',
      handle: {
        title: () => i18n.t('approvals.tab_title'),
      },
      element: <ControlsUpdatePage activeTabId="approvals" />,
    },
    {
      path: 'linked-items',
      handle: {
        title: () => i18n.t('linkedItems.tab_title'),
      },

      children: [
        {
          path: '',
          element: <ControlsUpdatePage activeTabId="linkedItems" />,
        },
      ],
    },
  ],
};
