import _ from 'lodash';
import { z } from 'zod';

import { DEFAULT_FILTERS } from '@/components/CustomisableRibbon/defaultFilters';

export const CustomisableRibbonModalSchema = z
  .object({
    id: z.string(),
    title: z.string().min(1, { message: 'Required' }),
    itemFilterQuery: z.object({
      operation: z.enum(['and', 'or']),
      tokens: z
        .object({
          propertyKey: z.string().or(z.undefined()),
          operator: z.string(),
          value: z.string().or(
            z
              .object({
                key: z.string().or(z.undefined()),
                type: z.enum(['relative', 'absolute']),
                unit: z.enum(['day', 'week', 'month', 'year']),
                amount: z.number().int().or(z.undefined()),
              })
              .or(
                z.object({
                  type: z.enum(['relative', 'absolute']),
                  startDate: z.string().or(z.null()),
                  endDate: z.string().or(z.null()),
                })
              )
              .or(z.null())
          ),
        })
        .array()
        .readonly(),
    }),
    itemOrder: z.number(),
  })
  .array();

export const CustomisableRibbonFormSchema = z
  .object({
    Filters: CustomisableRibbonModalSchema,
  })
  .superRefine((val, ctx) => {
    if (val.Filters.length === 0) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['global'],
        message: 'At least one filter is required',
      });
    }

    if (val.Filters.length > 1) {
      val.Filters.forEach((filter, index) => {
        if (filter.itemFilterQuery.tokens.length > 0) {
          filter.itemFilterQuery.tokens.forEach((token) => {
            if (token.value === null) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ['global'],
                message: 'One or more filters have an invalid value',
              });
            }
          });
        }

        if (
          val.Filters.slice(index + 1).some((f) => {
            const sortedFilterTokens = _.sortBy(filter.itemFilterQuery.tokens, [
              'propertyKey',
              'operator',
              'value',
            ]);

            const sortedComparisonFilterTokens = _.sortBy(
              f.itemFilterQuery.tokens,
              ['propertyKey', 'operator', 'value']
            );

            const isSameFilterTitle =
              f.title.toLowerCase() === filter.title.toLowerCase();
            const isSameFilterOperation =
              f.itemFilterQuery.operation === filter.itemFilterQuery.operation;
            const isSameFilterQuery = _.isEqual(
              sortedComparisonFilterTokens,
              sortedFilterTokens
            );

            return (
              isSameFilterTitle || (isSameFilterOperation && isSameFilterQuery)
            );
          })
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['global'],
            message:
              'Two or more filters have the same title or filtering options',
          });
        }
      });
    }
  });

export type CustomisableRibbonModalFields = z.infer<
  typeof CustomisableRibbonFormSchema
>;

export const defaultValues: CustomisableRibbonModalFields = {
  Filters: DEFAULT_FILTERS,
};
