import { useNotifications } from '@risksmart-app/components/Notifications/notification-context';
import Table from '@risksmart-app/components/Table';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PageLayout } from 'src/layouts';

import { useGetPublicDocumentFilesQuery } from '@/generated/graphql';
import { publicPolicyFileUrl } from '@/utils/urls';

import { useGetCollectionTableProps } from './PublicPoliciesConfig';

export const PublicPoliciesPage = () => {
  const { t } = useTranslation(['common'], { keyPrefix: 'publicPolicies' });
  const { addNotification } = useNotifications();
  const { data, loading } = useGetPublicDocumentFilesQuery({
    fetchPolicy: 'no-cache',
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });

  const navigate = useNavigate();

  const handleEditFile = (id: string) => {
    navigate(publicPolicyFileUrl(id));
  };

  const collectionProps = useGetCollectionTableProps(
    data?.document_file,
    handleEditFile
  );
  return (
    <PageLayout title={t('register_title')} actions={<></>}>
      <Table {...collectionProps} loading={loading} />
    </PageLayout>
  );
};
