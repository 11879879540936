import dayjs from 'dayjs';

import { Attestation_Record_Status_Enum } from '@/generated/graphql';

import { AttestationFlatField, AttestationRegisterFields } from './types';

export const useLabelledFields = (
  records: AttestationFlatField[] | undefined
): AttestationRegisterFields[] => {
  return (
    records?.map((record) => ({
      ...record,
      User: record.user?.FriendlyName ?? record.UserId,
      Document: `${record.node.documentFile?.parent?.Title} (${record.node.documentFile?.Version})`,
      AttestationStatus:
        dayjs(record.ExpiresAt).isBefore() &&
        record.AttestationStatus !== Attestation_Record_Status_Enum.Attested &&
        record.AttestationStatus !== Attestation_Record_Status_Enum.NotRequired
          ? 'expired'
          : record.AttestationStatus,
    })) ?? []
  );
};
