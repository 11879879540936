import { FC } from 'react';

import { useGetObligationByIdQuery } from '@/generated/graphql';

type Props = {
  obligationId: string | undefined;
};

const Breadcrumb: FC<Props> = ({ obligationId }) => {
  const { data } = useGetObligationByIdQuery({
    variables: {
      _eq: obligationId!,
    },
    skip: !obligationId,
  });
  return <>{data?.obligation?.[0]?.Title || ''}</>;
};

export default Breadcrumb;
