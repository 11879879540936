import _ from 'lodash';
import { useMemo } from 'react';
import { FieldValues } from 'react-hook-form';

import Tokens from '@/components/Tokens';
import {
  GetControlsBasicQuery,
  Parent_Type_Enum,
  useGetControlsBasicQuery,
} from '@/generated/graphql';
import { getFriendlyId } from '@/utils/friendlyId';
import { controlDetailsUrl } from '@/utils/urls';

import { ControlledBaseProps } from '..';
import ControlledMultiselect from '../ControlledMultiselect';

interface Props<T extends FieldValues> extends ControlledBaseProps<T> {
  disabled?: boolean;
  excludedIds?: string[];
  renderTokens?: boolean;
}

export const getOptions = (
  allControls: GetControlsBasicQuery | undefined,
  defaultValues: { value: string }[]
): { value: string; label: string; tags: string[] }[] => {
  const ids = defaultValues.map((d) => d.value);
  const controls = allControls?.node.filter(
    (n) => n.control || ids.includes(n.Id)
  );

  return (
    controls?.map((o) => ({
      value: o.Id,
      tags: o.control
        ? [getFriendlyId(Parent_Type_Enum.Control, o.SequentialId)]
        : [],
      label:
        o.control?.Title ??
        getFriendlyId(Parent_Type_Enum.Control, o.SequentialId),
    })) ?? []
  );
};

export const ControlledControlMultiSelect = <T extends FieldValues>({
  excludedIds,
  ...props
}: Props<T>) => {
  const { data: controls, loading } = useGetControlsBasicQuery({});

  const orderedOptions = useMemo(() => {
    const defaultValues: { value: string; label: string }[] =
      props.control._defaultValues[props.name] ?? [];
    return _.sortBy(
      getOptions(controls, defaultValues).filter(
        (control) => !excludedIds?.includes(control.value)
      ),
      'label'
    );
  }, [controls, excludedIds, props.control._defaultValues, props.name]);
  return (
    <ControlledMultiselect
      statusType={loading ? 'loading' : 'finished'}
      {...props}
      hideTokens={true}
      renderTokens={true}
      filteringType="auto"
      options={orderedOptions}
      customTokenRender={(options, actions) => (
        <Tokens
          onRemove={actions.removeToken}
          disabled={props.disabled}
          tokens={options.map((o) => ({
            value: o.value!,
            url: controlDetailsUrl(o.value!),
            label: o.label!,
          }))}
        />
      )}
    />
  );
};
