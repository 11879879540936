import { FC } from 'react';

import { useGetActionByIdQuery } from '@/generated/graphql';

type Props = {
  actionId: string | undefined;
};

const Breadcrumb: FC<Props> = ({ actionId }) => {
  const { data } = useGetActionByIdQuery({
    variables: {
      _eq: actionId!,
    },
    skip: !actionId,
  });
  return <>{data?.action?.[0]?.Title}</>;
};

export default Breadcrumb;
