import useRisksmartUser from '@/hooks/useRisksmartUser';
import { handleError } from '@/utils/errorUtils';

export default function Page() {
  const { isLoading, loginWithRedirect } = useRisksmartUser();

  if (isLoading) {
    return <h1>Please wait...</h1>;
  }

  //Handle the switching of organizations
  const queryParams = new URLSearchParams(window.location.search);
  const organization = queryParams.get('organization');
  const invitation = queryParams.get('invitation');

  if (invitation && organization) {
    // eslint-disable-next-line no-console
    console.log('Organization and Invitation found');
    loginWithRedirect({
      authorizationParams: {
        invitation: invitation,
        organization: organization,
        redirect_uri: window.location.origin + '?organization=' + organization,
      },
    }).catch((error) => {
      handleError(error);
    });
  } else if (organization) {
    console.warn('Organization found');

    loginWithRedirect({
      authorizationParams: {
        organization: organization,
        redirect_uri: window.location.origin + '?organization=' + organization,
      },
    }).catch((error) => {
      handleError(error);
    });
  } else {
    loginWithRedirect({
      authorizationParams: {
        redirect_uri: window.location.origin,
      },
    }).catch((error) => {
      handleError(error);
    });
  }

  return <></>;
}
