
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_1i0s3_rhsol_143",
  "header": "awsui_header_1i0s3_rhsol_178",
  "full-page": "awsui_full-page_1i0s3_rhsol_178",
  "content": "awsui_content_1i0s3_rhsol_182",
  "error": "awsui_error_1i0s3_rhsol_186",
  "footer": "awsui_footer_1i0s3_rhsol_190",
  "actions-section": "awsui_actions-section_1i0s3_rhsol_194",
  "secondary-actions": "awsui_secondary-actions_1i0s3_rhsol_203",
  "actions": "awsui_actions_1i0s3_rhsol_194"
};
  