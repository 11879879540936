
    import './styles.scoped.css';
    export default {
  "calendar": "awsui_calendar_1ykar_1a35s_151",
  "awsui-motion-fade-in-0": "awsui_awsui-motion-fade-in-0_1ykar_1a35s_1",
  "root": "awsui_root_1ykar_1a35s_178",
  "calendar-inner": "awsui_calendar-inner_1ykar_1a35s_219",
  "calendar-header": "awsui_calendar-header_1ykar_1a35s_223",
  "calendar-header-title": "awsui_calendar-header-title_1ykar_1a35s_228",
  "calendar-next-btn": "awsui_calendar-next-btn_1ykar_1a35s_236",
  "calendar-prev-btn": "awsui_calendar-prev-btn_1ykar_1a35s_239",
  "calendar-grid": "awsui_calendar-grid_1ykar_1a35s_242",
  "calendar-grid-dense": "awsui_calendar-grid-dense_1ykar_1a35s_246",
  "calendar-grid-cell": "awsui_calendar-grid-cell_1ykar_1a35s_254",
  "calendar-date-header": "awsui_calendar-date-header_1ykar_1a35s_259",
  "calendar-date": "awsui_calendar-date_1ykar_1a35s_259",
  "calendar-date-enabled": "awsui_calendar-date-enabled_1ykar_1a35s_279",
  "calendar-date-current-page": "awsui_calendar-date-current-page_1ykar_1a35s_289",
  "calendar-date-selected": "awsui_calendar-date-selected_1ykar_1a35s_300",
  "calendar-date-current": "awsui_calendar-date-current_1ykar_1a35s_289",
  "calendar-date-dense": "awsui_calendar-date-dense_1ykar_1a35s_321",
  "date-inner": "awsui_date-inner_1ykar_1a35s_329",
  "calendar-row": "awsui_calendar-row_1ykar_1a35s_404"
};
  