import { SpaceBetween } from '@cloudscape-design/components-themed';
import Table from '@risksmart-app/components/Table';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import TabHeader from '@/components/TabHeader';

import { useGetAuditTableProps } from './config';

const AuditTab: FC = () => {
  useI18NSummaryHelpContent('auditLog.help');
  const tableProps = useGetAuditTableProps();
  const { t } = useTranslation(['common'], { keyPrefix: 'auditLog' });

  return (
    <>
      <Table
        {...tableProps}
        header={
          <SpaceBetween size="m">
            <TabHeader>{t('auditTableTitle')}</TabHeader>
          </SpaceBetween>
        }
        variant="embedded"
      />
    </>
  );
};

export default AuditTab;
