import { ContentTable } from 'pdfmake/interfaces';
import { useTranslation } from 'react-i18next';

import { useGetActionsByParentIdLazyQuery } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { toLocalDate } from '@/utils/dateUtils';
import { getOwnerValue } from '@/utils/pdf/ownerValue';
import { createTable, tableHeaders } from '@/utils/pdf/table';
import { getTagsValue } from '@/utils/pdf/tagsValue';

const useActionExportTable = (
  parentId: string
): [() => Promise<ContentTable>, boolean] => {
  const [getActions, getActionsResult] = useGetActionsByParentIdLazyQuery({
    variables: {
      ParentId: parentId,
    },
  });
  const { getLabel: getPriorityLabel } = useRating('priority');
  const { getLabel: getStatusLabel } = useRating('action_status');

  const { t: columns } = useTranslation(['common'], {
    keyPrefix: 'columns',
  });
  const { t: actionColumns } = useTranslation(['common'], {
    keyPrefix: 'actions.columns',
  });
  const createExportTable = async () => {
    const { data: actionData } = await getActions();
    const actionTableData = (actionData?.action ?? []).map((i) => [
      i.Title,
      getOwnerValue(i),
      toLocalDate(i.DateRaised),
      toLocalDate(i.DateDue),
      getStatusLabel(i.Status),
      getPriorityLabel(i.Priority),
      getTagsValue(i),
    ]);

    return createTable({
      widths: ['*', 50, 50, 70, 70, 50, 70],
      body: [
        tableHeaders([
          actionColumns('title'),
          columns('owner'),
          actionColumns('date_raised'),
          actionColumns('due_date'),
          actionColumns('status'),
          actionColumns('priority'),
          columns('tags'),
        ]),
        ...actionTableData,
      ],
    });
  };
  return [createExportTable, getActionsResult.loading];
};

export default useActionExportTable;
