import { PropertyFilterQuery } from '@cloudscape-design/collection-hooks';
import { defaultPropertyFilterI18nStrings } from '@risksmart-app/components/Table/propertyFilterI18nStrings';

import PropertyFilterPanel from '@/components/PropertyFilterPanel';

import { useGetWidgetData } from '../../Gigawidget/hooks/useGetWidgetData';
import { WidgetDataSource } from '../../Gigawidget/types';

type Props<TDataSource extends WidgetDataSource> = {
  value: PropertyFilterQuery;
  onChange: (value: PropertyFilterQuery) => void;
  dataSource: TDataSource;
};

export const WidgetPropertyFilter = <TDataSource extends WidgetDataSource>({
  dataSource,
  value,
  onChange,
}: Props<TDataSource>) => {
  const {
    tableProps: { propertyFilterProps },
  } = useGetWidgetData({ dataSource, disableDashboardFilters: true });

  return (
    <PropertyFilterPanel
      i18nStrings={{
        ...defaultPropertyFilterI18nStrings,
      }}
      query={value}
      onChange={(e) => onChange({ ...e.detail, operation: 'and' })}
      filteringProperties={propertyFilterProps.filteringProperties}
      filteringOptions={propertyFilterProps.filteringOptions}
      hideOperations={true}
    />
  );
};
