import dayjs from 'dayjs';

import {
  dateRangeFilterOperators,
  defaultDateFilterOperators,
} from '@/components/DateTimeFilter/dateFilterOperator';
import { getCustomAttributeCollections } from '@/components/Form/CustomAttributes/CustomAttributeCollections';
import { CustomAttributeFields } from '@/components/Form/CustomAttributes/CustomAttributeSchema';
import { FieldType } from '@/components/Form/CustomAttributes/EditFields/NewFieldSchema';
import Link from '@/components/Link';
import { Parent_Type_Enum } from '@/generated/graphql';
import { JSONObject } from '@/types/types';

import { EMPTY_CELL } from '../../collectionUtils';
import { toLocalDate } from '../../dateUtils';
import { FieldConfig, TableRecord } from '../types';

export const getCustomAttributeDataForRecord = <T extends TableRecord>(
  fields: Record<keyof T, FieldConfig<T>>,
  record: T
) => {
  return Object.entries(fields).reduce((acc, [key, field]) => {
    if (field.custom) {
      return {
        ...acc,
        [key]: field.customFieldValue(record),
      };
    }
    return acc;
  }, {});
};

function convertSchemaToFieldConfig<
  T extends {
    CustomAttributeData: JSONObject;
  },
>(
  schemas: CustomAttributeFields | undefined,
  parentType?: string
): { [key: string]: FieldConfig<T> } {
  const config: { [key: string]: FieldConfig<T> } = {};
  if (!schemas?.Schema || !schemas?.UiSchema) {
    return config;
  }
  // render column collections from schemas.
  const collections = getCustomAttributeCollections(
    schemas?.Schema || {},
    schemas?.UiSchema || {}
  );
  // map collections to config using type.
  collections.forEach(({ label, path, type }) => {
    const matchToField = (data: JSONObject | null) =>
      data ? (data[path] as string) || '' : '';
    const matchToArrayField = (data: JSONObject | null) =>
      data ? (data[path] as string[]) || [] : [];
    // Initial config for all types
    const basicConfig: FieldConfig<T> = {
      header: label,
      custom: true,
      customFieldValue: (item) => {
        return matchToField(item.CustomAttributeData);
      },
      exportVal: (item) => {
        return matchToField(item.CustomAttributeData);
      },
    };

    // Add specific config for each type
    switch (type) {
      case FieldType.Text:
      case FieldType.Textarea:
      case FieldType.Select:
        config[path] = {
          ...basicConfig,
          cell: (item) => matchToField(item.CustomAttributeData) || EMPTY_CELL,
        };
        break;
      case FieldType.MultiSelect:
        config[path] = {
          ...basicConfig,
          cell: (item) => {
            const result =
              matchToArrayField(item.CustomAttributeData).join(', ') ||
              EMPTY_CELL;
            return result;
          },
        };
        break;

      case FieldType.Date:
        config[path] = {
          ...basicConfig,
          cell: (item) =>
            toLocalDate(matchToField(item.CustomAttributeData)) || EMPTY_CELL,
          filterOptions: {
            filteringProperties: {
              operators:
                // TODO: remove this when we migrate all registers to the new date format
                // TODO: Also remove `_typename` from `packages/web/src/utils/table/types.ts`
                parentType && parentType !== Parent_Type_Enum.Issue
                  ? defaultDateFilterOperators
                  : dateRangeFilterOperators,
            },
          },
          exportVal: (item) =>
            matchToField(item.CustomAttributeData)
              ? dayjs(String(matchToField(item.CustomAttributeData))).format(
                  'DD/MM/YYYY HH:mm'
                )
              : '',
        };
        break;
      case FieldType.Link:
        config[path] = {
          ...basicConfig,
          cell: (item) =>
            (
              <Link
                href={matchToField(item.CustomAttributeData)}
                target="_blank"
              >
                {matchToField(item.CustomAttributeData)}
              </Link>
            ) || EMPTY_CELL,
        };
        break;
    }
  });
  return config;
}

export const convertSchemasToFieldConfigs = (
  customAttributeSchemas: CustomAttributeFields[],
  parentType?: Parent_Type_Enum
): ReturnType<typeof convertSchemaToFieldConfig> => {
  return customAttributeSchemas.reduce((configs, schema) => {
    return {
      ...configs,
      ...convertSchemaToFieldConfig(schema, parentType),
    };
  }, {});
};
