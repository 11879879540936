import { FormField } from '@cloudscape-design/components-themed';
import { capitalize } from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Select from '@/components/Form/Select';
import { Parent_Type_Enum } from '@/generated/graphql';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';

import {
  ObjectWithContributors,
  useHasPermission,
} from '../../../rbac/Permission';
import { FormType } from './types';

interface Props {
  readOnly: boolean;
  onChange?: (val: FormType) => void;
  parent?: ObjectWithContributors;
  value?: FormType;
  testId?: string;
}

const FindingTypeSelector: FC<Props> = ({
  readOnly,
  onChange,
  parent,
  value,
  testId,
}) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'assessmentResults.fields',
  });
  const { t: tc } = useTranslation('common');
  const { t: tt } = useTranslation('taxonomy');

  const policyModuleEnabled = useIsFeatureVisibleToOrg('policy');
  const complianceModuleEnabled = useIsFeatureVisibleToOrg('compliance');

  const canCreateDocumentAssessmentResult = useHasPermission(
    'insert:document_assessment_result',
    parent
  );
  const canCreateObligationAssessmentResult = useHasPermission(
    'insert:obligation_assessment_result',
    parent
  );
  const canCreateRiskAssessmentResult = useHasPermission(
    'insert:risk_assessment_result',
    parent
  );
  const canCreateControlTestResult = useHasPermission(
    'insert:test_result',
    parent
  );

  const canCreateRating =
    (canCreateDocumentAssessmentResult && policyModuleEnabled) ||
    canCreateRiskAssessmentResult ||
    (canCreateObligationAssessmentResult && complianceModuleEnabled) ||
    canCreateControlTestResult;
  const canCreateAction = useHasPermission('insert:action', parent);

  const canCreateIssue = useHasPermission('insert:issue', parent);

  const options = [];

  if (canCreateRating) {
    options.push({
      value: 'rating',
      label: capitalize(tt('rating_one')),
    });
  }

  if (canCreateAction) {
    options.push({
      value: Parent_Type_Enum.Action,
      label: capitalize(tt('action_one')),
    });
  }

  if (canCreateIssue) {
    options.push({
      value: Parent_Type_Enum.Issue,
      label: capitalize(tt('issue_one')),
    });
  }

  return (
    <FormField label={t('Type')} data-testid={`form-field-${testId}`}>
      <Select
        disabled={readOnly || options.length === 1}
        onChange={(e) => onChange?.(e.detail.selectedOption.value as FormType)}
        options={options}
        selectedOption={options.find((o) => o.value === value) || null}
        placeholder={t('Type_placeholder')}
        empty={tc('noMatchedFound')}
      />
    </FormField>
  );
};

export default FindingTypeSelector;
