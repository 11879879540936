import _ from 'lodash';

import { IndicatorFlatFields } from './types';

export const latestResultValueFromData = (
  data: Pick<IndicatorFlatFields, 'latestResults'>
) => {
  const [{ TargetValueNum = null, TargetValueTxt = null } = {}] =
    data.latestResults || [];
  let latestResultValue = '';
  if (!_.isNil(TargetValueTxt)) {
    latestResultValue = TargetValueTxt;
  } else if (!_.isNil(TargetValueNum)) {
    latestResultValue = TargetValueNum.toString();
  }
  return latestResultValue;
};
