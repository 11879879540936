
    import './styles.scoped.css';
    export default {
  "alert": "awsui_alert_mx3cw_11uur_191",
  "awsui-motion-fade-in": "awsui_awsui-motion-fade-in_mx3cw_11uur_1",
  "root": "awsui_root_mx3cw_11uur_213",
  "hidden": "awsui_hidden_mx3cw_11uur_251",
  "with-dismiss": "awsui_with-dismiss_mx3cw_11uur_301",
  "with-action": "awsui_with-action_mx3cw_11uur_301",
  "breakpoint-default": "awsui_breakpoint-default_mx3cw_11uur_307",
  "header": "awsui_header_mx3cw_11uur_314",
  "action": "awsui_action_mx3cw_11uur_318",
  "action-slot": "awsui_action-slot_mx3cw_11uur_323",
  "action-button": "awsui_action-button_mx3cw_11uur_324",
  "alert-focus-wrapper": "awsui_alert-focus-wrapper_mx3cw_11uur_328",
  "text": "awsui_text_mx3cw_11uur_357",
  "icon": "awsui_icon_mx3cw_11uur_364",
  "message": "awsui_message_mx3cw_11uur_367",
  "icon-size-medium": "awsui_icon-size-medium_mx3cw_11uur_375",
  "icon-size-big": "awsui_icon-size-big_mx3cw_11uur_378",
  "icon-size-normal": "awsui_icon-size-normal_mx3cw_11uur_381",
  "content": "awsui_content_mx3cw_11uur_385",
  "dismiss": "awsui_dismiss_mx3cw_11uur_389",
  "dismiss-button": "awsui_dismiss-button_mx3cw_11uur_394",
  "type-error": "awsui_type-error_mx3cw_11uur_398",
  "type-warning": "awsui_type-warning_mx3cw_11uur_406",
  "type-success": "awsui_type-success_mx3cw_11uur_414",
  "type-info": "awsui_type-info_mx3cw_11uur_422"
};
  