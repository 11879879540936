import {
  Cards,
  CardsProps,
  Container,
  Header,
  SpaceBetween,
} from '@cloudscape-design/components-themed';
import { NonCancelableEventHandler } from '@cloudscape-design/components-themed/internal/events';
import Button from '@risksmart-app/components/Button';
import useLink from '@risksmart-app/components/hooks/use-link';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Permission } from 'src/rbac/Permission';

import Link from '@/components/Link';
import { Parent_Type_Enum } from '@/generated/graphql';
import { getFriendlyId } from '@/utils/friendlyId';
import { riskDetailsUrl } from '@/utils/urls';

import { RiskRegisterFields } from '../risks/types';
import SelectedRiskAttribute from './SelectedRiskAttribute';
import styles from './style.module.scss';
import { CardType, DashboardState, RiskAttribute } from './types';

export interface Props {
  dashboardState: DashboardState;
  setDashboardState: (state: DashboardState) => void;
  tier: 1 | 2 | 3;
  tierRisks: readonly RiskRegisterFields[];
  selectedRiskAttribute: RiskAttribute;
}

const Tier: FC<Props> = ({
  tier = 1,
  dashboardState,
  setDashboardState,
  tierRisks,
  selectedRiskAttribute,
}) => {
  const { handleFollow } = useLink({
    state: {
      from: 'risk-dashboard',
    },
  });

  const selectedRiskId = dashboardState.get(tier);
  const parentRiskId = dashboardState?.get(tier - 1);

  const { t } = useTranslation(['common']);

  const tiers = t('tiers', { returnObjects: true });

  const tierLabel = tiers[String(tier) as keyof typeof tiers];

  const risks: CardType[] = [
    ...(tierRisks ?? []),
    {
      Id: 'unlinked',
      // TODO: translation
      Title: 'Unlinked Risks',
      Tier: tier,
      unlinked: true,
    },
  ];

  const risksInTier = findRisksInTier(
    tier,
    risks,
    dashboardState,
    parentRiskId
  );

  // TODO: translation
  const empty = (
    <>{parentRiskId && risksInTier?.length === 0 ? 'No items found' : ``}</>
  );

  const onSelectionChange: NonCancelableEventHandler<
    CardsProps.SelectionChangeDetail<CardType>
  > = ({ detail }) => {
    const newState: DashboardState = new Map();
    dashboardState.forEach((value, key) => {
      if (key === tier) {
        newState.set(key, detail.selectedItems[0].Id);
      } else if (key > tier) {
        newState.set(key, undefined); // Clear all lower tiers
      } else {
        newState.set(key, value);
      }
    });
    setDashboardState(newState);
  };

  const selectedRisk = risks.find((r) => r.Id === selectedRiskId);
  const selectedItems = selectedRisk ? [selectedRisk] : [];
  return (
    <div className={styles.tier} data-testid={`tier-${tier}`}>
      <Container fitHeight variant="stacked">
        <SpaceBetween direction="vertical" size="m">
          <Header
            variant="h2"
            actions={
              <Permission
                permission="insert:risk"
                canHaveAccessAsContributor={tier > 1}
              >
                <Button
                  iconName="add-plus"
                  variant="primary"
                  href={`/risks/add?tier=${tier}`}
                  onFollow={handleFollow}
                >
                  Add
                </Button>
              </Permission>
            }
          >
            {tierLabel}
          </Header>
          <Cards<CardType>
            ariaLabels={{
              // tod: translation
              itemSelectionLabel: (e, n) => `select ${n.Title}`,
              // tod: translation
              selectionGroupLabel: 'Item selection',
            }}
            entireCardClickable={true}
            cardDefinition={{
              sections: [
                {
                  id: 'title',
                  content: (item) => (
                    <div className="inline-block">
                      <Link
                        variant="secondary"
                        href={
                          !item.unlinked ? riskDetailsUrl(item.Id) : undefined
                        }
                      >
                        <Header variant="h3" data-unlinked={item.unlinked}>
                          <span className="text-base">{item.Title}</span>
                        </Header>
                      </Link>
                    </div>
                  ),
                },
              ],
              header: (item) => (
                <div className="flex">
                  {!item.unlinked && (
                    <div className="text-grey text-sm flex-grow">
                      {getFriendlyId(Parent_Type_Enum.Risk, item.SequentialId)}
                    </div>
                  )}

                  <SelectedRiskAttribute
                    data={item}
                    selectedRiskAttribute={selectedRiskAttribute}
                  />
                </div>
              ),
            }}
            cardsPerRow={[{ cards: 1 }]}
            items={risksInTier}
            empty={empty}
            // TODO: translation
            loadingText="Loading"
            visibleSections={['title']}
            selectionType="single"
            selectedItems={selectedItems}
            onSelectionChange={onSelectionChange}
            trackBy={(item) => item.Id}
          />
        </SpaceBetween>
      </Container>
    </div>
  );
};

function findRisksInTier(
  tier: 1 | 2 | 3,
  risks: CardType[],
  dashboardState: DashboardState,
  parentRiskId?: string
): CardType[] {
  const isTopTier = tier === 1;

  const risksInCurrentTier = risks
    .filter((risk) => risk.Tier === tier)
    .filter((risk) => !risk.unlinked);

  const riskIdsInParentTier = !isTopTier
    ? risks
        .filter((risk) => risk.Tier === tier - 1)
        .filter((risk) => !risk.unlinked)
        .map((risk) => risk.Id)
    : [];

  const risksInChildTier = risks
    .filter((risk) => risk.Tier === tier + 1)
    .filter((risk) => !risk.unlinked);

  const hasUnlinkedRisksInChildTier = Boolean(
    risksInChildTier.filter(
      (risk) =>
        !risk.unlinked &&
        !risksInCurrentTier
          .map((risk) => risk.Id)
          .includes(risk.ParentRiskId || '')
    ).length > 0
  );

  const noRiskSelected = Boolean(dashboardState.get(tier - 1)?.length === 0);
  if (!isTopTier && noRiskSelected) return [];

  return risks
    .filter((risk) => risk.Tier === tier)
    .filter((risk) => {
      // Show all risks in tier 1
      if (!risk.unlinked && isTopTier) return true;

      // Show unlinked risks
      if (
        !risk.unlinked &&
        parentRiskId === 'unlinked' &&
        !riskIdsInParentTier.includes(risk.ParentRiskId || '')
      )
        return true;

      // Show risks with parentRiskId
      if (!risk.unlinked && risk.ParentRiskId === parentRiskId) return true;
      // Show "unlinked" button if there are unlinked risks in child tier
      if (risk.unlinked && hasUnlinkedRisksInChildTier) return true;

      return false;
    });
}

export default Tier;
