
    import './styles.scoped.css';
    export default {
  "resize-active": "awsui_resize-active_lm6vo_mp7fr_152",
  "resize-side": "awsui_resize-side_lm6vo_mp7fr_158",
  "resize-bottom": "awsui_resize-bottom_lm6vo_mp7fr_161",
  "with-motion": "awsui_with-motion_lm6vo_mp7fr_166",
  "root": "awsui_root_lm6vo_mp7fr_181",
  "root-no-scroll": "awsui_root-no-scroll_lm6vo_mp7fr_188",
  "layout": "awsui_layout_lm6vo_mp7fr_193",
  "layout-no-scroll": "awsui_layout-no-scroll_lm6vo_mp7fr_199",
  "layout-main": "awsui_layout-main_lm6vo_mp7fr_203",
  "layout-main-scrollable": "awsui_layout-main-scrollable_lm6vo_mp7fr_209",
  "unfocusable": "awsui_unfocusable_lm6vo_mp7fr_214",
  "breadcrumbs-desktop": "awsui_breadcrumbs-desktop_lm6vo_mp7fr_218",
  "content-header-wrapper": "awsui_content-header-wrapper_lm6vo_mp7fr_223",
  "content-wrapper": "awsui_content-wrapper_lm6vo_mp7fr_227",
  "content-overlapped": "awsui_content-overlapped_lm6vo_mp7fr_231",
  "content-extra-top-padding": "awsui_content-extra-top-padding_lm6vo_mp7fr_235"
};
  