import {
  BoardProps,
  ItemsPaletteProps,
} from '@cloudscape-design/board-components';
import {
  Alert,
  HelpPanel,
  SpaceBetween,
} from '@cloudscape-design/components-themed';
import Button from '@risksmart-app/components/Button';
import { useTools } from '@risksmart-app/components/Tools/ToolsProvider';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { defaultDashboardFilter } from 'src/context/dashboard-filter';
import { DashboardFilter } from 'src/context/DashboardFilter';
import { processWidgets } from 'src/context/processWidgets';
import { shouldDisableClickThrough } from 'src/context/shouldDisableClickThrough';
import { DashboardPreferences } from 'src/context/types';
import { useDashboardFilter } from 'src/context/useDashboardFilter';
import { PageLayout } from 'src/layouts';
import { useHasPermissionLazy } from 'src/rbac/Permission';

import {
  GetDashboardByIdQuery,
  namedOperations,
  useGetDashboardByIdQuery,
  useUpdateDashboardMutation,
} from '@/generated/graphql';
import { useUpdateResultNotification } from '@/hooks/useMutationResultNotification';
import { handleError } from '@/utils/errorUtils';
import { useIsFeatureVisibleToOrgLazy } from '@/utils/featureFlags';

import ActionsButton from './ActionButton/ActionsButton';
import { DashboardSelector } from './DashboardSelector';
import Filters from './Filters';
import ItemPalette from './ItemPalette';
import LayoutBoard from './LayoutBoard';
import { SaveDashboardModal } from './SaveDashboardModal';
import styles from './style.module.scss';
import { StoredWidgetDefinition, WidgetDefinition } from './types';
import {
  filterWidgetsByUserPermissions,
  getDefaultLayout,
  getPaletteItems,
  widgets as widgetList,
} from './widgets';

interface Props {}

export type Dashboard = GetDashboardByIdQuery['dashboard_by_pk'];

const Page: FC<Props> = () => {
  const hasPermission = useHasPermissionLazy();
  const isFeatureVisibleToOrg = useIsFeatureVisibleToOrgLazy();
  const { t } = useTranslation(['common'], { keyPrefix: 'dashboard' });
  const [, setToolsContent] = useTools();
  const [openSaveDashboard, setOpenSaveDashboard] = useState(false);
  const [editingDashboard, setEditingDashboard] = useState(false);
  const [updateDashboard] = useUpdateDashboardMutation({
    refetchQueries: [namedOperations.Query.getDashboards],
  });
  const {
    id: selectedDashboardId,
    filters,
    setFilters,
    widgets,
    setWidgets,
    setDashboardPreferences,
  } = useDashboardFilter();

  const { data: currentDashboardData } = useGetDashboardByIdQuery({
    variables: { Id: selectedDashboardId! },
    skip: !selectedDashboardId,
  });
  const currentDashboard = currentDashboardData?.dashboard_by_pk;

  const filtersEnabledCount = useMemo(() => {
    let count = 0;
    count += filters.departments.length;
    count += filters.tags.length;
    if (filters.dateRange) count++;
    return count;
  }, [filters]);

  const clickThroughDisabled = shouldDisableClickThrough(filters);

  const resetFilters = () => setFilters(defaultDashboardFilter);

  const [items, setItems] = useState<BoardProps.Item<StoredWidgetDefinition>[]>(
    () => {
      const definitions: BoardProps.Item<StoredWidgetDefinition>[] = [];
      for (const widget of filterWidgetsByUserPermissions(
        widgets,
        hasPermission,
        isFeatureVisibleToOrg
      )) {
        if (!widgetList[widget.widgetType]) {
          handleError(`Unable to find widget with type ${widget.widgetType}`);
          continue;
        }
        const definition = {
          ...widget,
          data: {
            widgetType: widget.widgetType,
            ...widgetList[widget.widgetType],
          },
          definition: widgetList[widget.widgetType].definition,
        };
        definitions.push(definition);
      }
      return definitions;
    }
  );

  const [paletteItems, setPaletteItems] = useState<
    ItemsPaletteProps.Item<WidgetDefinition>[]
  >(() => getPaletteItems(items, hasPermission, isFeatureVisibleToOrg));

  const onFilterChange = (newFilters: DashboardFilter) => {
    setFilters(newFilters);
  };

  const changeDashboard = (dashboardToLoad?: DashboardPreferences) => {
    const newItems = dashboardToLoad
      ? filterWidgetsByUserPermissions(
          processWidgets(dashboardToLoad.widgets),
          hasPermission,
          isFeatureVisibleToOrg
        )
      : getDefaultLayout(hasPermission, isFeatureVisibleToOrg);

    setDashboardPreferences({
      id: dashboardToLoad?.id,
      filters: dashboardToLoad
        ? dashboardToLoad.filters
        : defaultDashboardFilter,
      widgets: newItems,
    });

    setItems(
      newItems.map((l) => ({
        ...l,
        data: {
          widgetType: l.widgetType,
          ...widgetList[l.widgetType],
        },
        definition: widgetList[l.widgetType].definition,
      }))
    );

    setPaletteItems(
      getPaletteItems(
        newItems.map((ni) => ({
          ...ni,
          data: { widgetType: ni.widgetType, ...widgetList[ni.widgetType] },
          definition: widgetList[ni.widgetType].definition,
        })),
        hasPermission,
        isFeatureVisibleToOrg
      )
    );
  };

  const exportDashboard = () => {
    // Hide widgets side-panel before 'printing' and give Cloudscape time to redraw the screen.
    // Ultimately we should raise a feature request with them to add an event we can subscribe to.
    setToolsContent(undefined);
    setTimeout(() => window.print(), 50);
  };

  const saveDashboard = useUpdateResultNotification({
    entityName: 'Dashboard',
    asyncAction: async () => {
      if (!currentDashboard) {
        console.error('missing dashboard ID when trying to update dashboard');
        return false;
      }
      await updateDashboard({
        variables: {
          ...currentDashboard,
          Content: {
            filters,
            widgets,
          },
          ContributorUserIds: currentDashboard.contributors.map(
            (c) => c.UserId
          ),
          ContributorGroupIds: currentDashboard.contributorGroups.map(
            (c) => c.UserGroupId
          ),
        },
      });
      return true;
    },
  });

  return (
    <PageLayout
      helpTranslationKey="dashboard.help"
      title={t('page_title')}
      secondary={
        <form className="grid place-items-stretch grid-cols-1 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 print:hidden">
          <DashboardSelector
            onChange={changeDashboard}
            selected={selectedDashboardId}
          />

          <Filters onChange={onFilterChange} />
        </form>
      }
      actions={
        <SpaceBetween size="s" direction="horizontal">
          <div className={'flex gap-3'}>
            {filtersEnabledCount > 0 && (
              <Alert
                type={clickThroughDisabled ? 'warning' : 'info'}
                className={styles.clearFilterAlert}
                header={
                  <div className={'flex gap-3 items-center'}>
                    {t(
                      clickThroughDisabled
                        ? 'filters_alert_disabled_click_through'
                        : 'filters_alert',
                      { count: filtersEnabledCount }
                    )}
                    .
                    <Button onClick={resetFilters} variant={'inline-link'}>
                      Clear
                    </Button>
                  </div>
                }
              />
            )}
            <ActionsButton
              selectedDashboard={currentDashboard}
              onSave={async () => {
                if (selectedDashboardId) {
                  await saveDashboard({});
                } else {
                  setOpenSaveDashboard(true);
                }
              }}
              onClear={() =>
                changeDashboard({
                  id: currentDashboard?.Id,
                  filters: {
                    tags: [],
                    departments: [],
                    dateRange: null,
                  },
                  widgets: [],
                })
              }
              onReset={changeDashboard}
              onExport={exportDashboard}
              onEdit={async () => {
                setOpenSaveDashboard(true);
                setEditingDashboard(true);
              }}
              onAddWidget={async () => {
                setToolsContent('page-content');
              }}
              onSaveAs={async () => {
                setOpenSaveDashboard(true);
              }}
            />
          </div>
        </SpaceBetween>
      }
      panelContent={
        <HelpPanel header={t('side_panel_title')}>
          <ItemPalette items={paletteItems} />
        </HelpPanel>
      }
    >
      {openSaveDashboard && (
        <SaveDashboardModal
          onDismiss={() => {
            setOpenSaveDashboard(false);
            setEditingDashboard(false);
          }}
          dashboardContent={{ widgets, filters }}
          isEditing={editingDashboard}
          onDelete={changeDashboard}
        />
      )}
      <LayoutBoard
        items={items}
        onAddWidgetClick={() => setToolsContent('page-content')}
        onItemsChanged={(items) => {
          setWidgets(
            items.map((item) => {
              const widget = widgets.find((w) => item.id === w.id);
              return {
                id: item.id,
                widgetType: item.data.widgetType,
                ...widget,
                rowSpan: item.rowSpan,
                columnSpan: item.columnSpan,
                columnOffset: item.columnOffset,
              };
            })
          );
          setItems(items);
          setPaletteItems(
            getPaletteItems(items, hasPermission, isFeatureVisibleToOrg)
          );
        }}
      />
    </PageLayout>
  );
};

export default Page;
