
    import './styles.scoped.css';
    export default {
  "button-trigger": "awsui_button-trigger_18eso_ong8y_159",
  "arrow": "awsui_arrow_18eso_ong8y_159",
  "has-caret": "awsui_has-caret_18eso_ong8y_222",
  "placeholder": "awsui_placeholder_18eso_ong8y_225",
  "pressed": "awsui_pressed_18eso_ong8y_238",
  "disabled": "awsui_disabled_18eso_ong8y_241",
  "in-filtering-token": "awsui_in-filtering-token_18eso_ong8y_251",
  "read-only": "awsui_read-only_18eso_ong8y_257",
  "invalid": "awsui_invalid_18eso_ong8y_279",
  "warning": "awsui_warning_18eso_ong8y_288",
  "inline-tokens": "awsui_inline-tokens_18eso_ong8y_324"
};
  