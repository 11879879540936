import { useCallback } from 'react';

import { sanitizeSettings } from '../pages/dashboards/UniversalWidget/sanitizeSettings';
import { GigawidgetSettings } from '../pages/dashboards/UniversalWidget/util';
import { useWidgetContext } from '../pages/dashboards/WidgetContext/WidgetContext';
import { useDashboardFilter } from './useDashboardFilter';

export const useDashboardWidgetSettings = <T = unknown,>(): [
  T | null,
  (settings: T) => void,
] => {
  const widgetData = useWidgetContext();
  const { widgets, setWidgets } = useDashboardFilter();

  const widget = widgetData
    ? widgets.find((w) => w.id === widgetData.widgetId)
    : undefined;

  const sanitizedSettings = widget?.settings
    ? sanitizeSettings(widget?.settings as GigawidgetSettings)
    : undefined;

  // Note, widget can be null in previous mode as not yet on the dashboard
  const setWidgetSettings = useCallback(
    (settings: T) => {
      if (!widgetData) return;
      const { widgetId } = widgetData;

      const widget = widgets.find((w) => w.id === widgetId);
      if (widget) {
        widget.settings = settings;
      }
      setWidgets(widgets);
    },
    [setWidgets, widgets, widgetData]
  );

  return [sanitizedSettings as T, setWidgetSettings];
};
