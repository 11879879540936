import { useCollection } from '@cloudscape-design/collection-hooks';
import { SpaceBetween } from '@cloudscape-design/components-themed';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/notification-context';
import Table from '@risksmart-app/components/Table';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ControlFormFieldData } from 'src/pages/controls/update/forms/controlSchema';
import {
  ObjectWithContributors,
  Permission,
  useHasPermission,
} from 'src/rbac/Permission';

import EmptyEntityCollection from '@/components/EmptyCollection/EmptyEntityCollection';
import { ownerAndContributorIds } from '@/components/Form';
import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import TabHeader from '@/components/TabHeader';
import {
  Query_Root,
  useDeleteControlsMutation,
  useGetControlsByParentIdQuery,
  useInsertChildControlMutation,
} from '@/generated/graphql';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';
import { evictField } from '@/utils/graphqlUtils';

import ControlCreateModal from '../modals/ControlCreateModal';
import { ControlsTableFields, useControlColumnDefinitions } from './config';
import DeleteControlModal from './DeleteModal/DeleteControlModal';

interface Props {
  parent: ObjectWithContributors;
  deleteCacheInvalidationFields?: (keyof Query_Root)[];
}

const Tab: FC<Props> = ({ deleteCacheInvalidationFields = [], parent }) => {
  useI18NSummaryHelpContent('controls.tabHelp');
  const linkedItemsEnabled = useIsFeatureVisibleToOrg('linked-items');
  const canViewLinkedItems = useHasPermission('read:linked_item', parent);
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], { keyPrefix: 'controls' });
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const canDeleteControls = useHasPermission('delete:control', parent);
  const [selectedControls, setSelectedControls] = useState<
    ControlsTableFields[]
  >([]);
  const { addNotification } = useNotifications();

  const [mutate] = useInsertChildControlMutation({
    update: (cache) => {
      evictField(cache, 'risk');
      evictField(cache, 'control');
      evictField(cache, 'control_aggregate');
      evictField(cache, 'obligation');
      evictField(cache, 'linked_item');
      evictField(cache, 'risk_score');
    },
  });
  const saveControl = async (data: ControlFormFieldData) => {
    await mutate({
      variables: {
        ...data,
        Type: data.Type,
        TestFrequency: data.TestFrequency,
        ParentId: parent.Id,
        CustomAttributeData: data.CustomAttributeData || undefined,
        ...ownerAndContributorIds(data),
      },
    });
  };

  const { data, loading } = useGetControlsByParentIdQuery({
    variables: {
      ParentId: parent.Id,
    },
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });
  const [deleteControls, deleteResult] = useDeleteControlsMutation({
    update: (cache) => {
      const fields = [
        'control',
        'linked_item',
        ...deleteCacheInvalidationFields,
      ];
      fields.forEach((field) => evictField(cache, field as keyof Query_Root));
    },
  });
  const handleControlCreateOpen = () => {
    setIsCreateOpen(true);
  };
  const handleControlCreateClose = () => {
    setIsCreateOpen(false);
  };

  const onDelete = useDeleteResultNotification({
    asyncAction: async () => {
      await deleteControls({
        variables: { Ids: selectedControls.map((s) => s.Id) },
      });
      setSelectedControls([]);
      setIsDeleteModalVisible(false);
      return true;
    },
    failureAction: () => {
      setIsDeleteModalVisible(false);
    },
    entityName: st('entity_name'),
  });

  const labelledFields = useMemo<ControlsTableFields[]>(
    () =>
      data?.control.map((c) => ({
        ...c,
        OverallEffectiveness:
          c.testResults.length > 0
            ? c.testResults[0].OverallEffectiveness ?? null
            : null,
      })) || [],
    [data?.control]
  );

  const columnDefinitions = useControlColumnDefinitions();

  const { items, collectionProps } = useCollection(labelledFields, {
    propertyFiltering: {
      filteringProperties: [],
      empty: (
        <EmptyEntityCollection
          entityLabel={st('entity_name')}
          action={
            <Permission permission="insert:control" parentObject={parent}>
              <Button formAction="none" onClick={handleControlCreateOpen}>
                {st('create_button')}
              </Button>
            </Permission>
          }
        />
      ),
    },
    sorting: {
      defaultState: {
        sortingColumn: columnDefinitions[0],
      },
    },
  });

  return (
    <>
      <Table
        {...collectionProps}
        selectionType={canDeleteControls ? 'multi' : undefined}
        selectedItems={selectedControls}
        trackBy="Id"
        onSelectionChange={({ detail }) => {
          setSelectedControls(detail.selectedItems);
        }}
        resizableColumns={true}
        header={
          <SpaceBetween size="m">
            <TabHeader
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Permission permission="delete:control" parentObject={parent}>
                    <Button
                      formAction="none"
                      variant="normal"
                      disabled={!selectedControls.length}
                      onClick={() => setIsDeleteModalVisible(true)}
                    >
                      {t('delete')}
                    </Button>
                  </Permission>
                  <Permission permission="insert:control" parentObject={parent}>
                    <Button
                      variant="primary"
                      formAction="none"
                      onClick={handleControlCreateOpen}
                    >
                      {st('add_button')}
                    </Button>
                  </Permission>
                </SpaceBetween>
              }
            >
              {st('tab_title')}
            </TabHeader>
          </SpaceBetween>
        }
        variant="embedded"
        loading={loading}
        columnDefinitions={columnDefinitions}
        items={items}
        loadingText={st('loading_message') ?? ''}
        sortingDisabled={false}
      />

      {isCreateOpen && (
        <ControlCreateModal
          onSave={saveControl}
          onDismiss={handleControlCreateClose}
        />
      )}

      <DeleteControlModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        onDelete={onDelete}
        onDismiss={() => setIsDeleteModalVisible(false)}
        showUnlink={!!linkedItemsEnabled && !!canViewLinkedItems}
      />
    </>
  );
};

export default Tab;
