import { FC } from 'react';
import { AuthenticatedAppLayout } from 'src/layouts/AuthenticatedAppLayout';

import { ErrorPage } from '..';

interface Props {}

const Page: FC<Props> = () => {
  return (
    <AuthenticatedAppLayout>
      <ErrorPage />
    </AuthenticatedAppLayout>
  );
};

export default Page;
