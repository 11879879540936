import i18n from 'i18next';
import { IndicatorTableFields } from 'src/pages/indicators/types';

import { GetWidgetIndicatorsDocument } from '@/generated/graphql.typed';
import { indicatorRegisterUrl } from '@/utils/urls';

import { useGetIndicatorSmartWidgetTableProps } from '../../../indicators/config';
import { UNRATED } from '../../Gigawidget/types';
import {
  dateRangeFilter,
  departmentsFilter,
  tagsFilter,
} from '../../Gigawidget/util/filterHelpers';
import { createDataSource } from '../createDataSource';
import {
  dashboardDateClickthroughFilter,
  dateClickthroughFilter,
  defaultClickthroughFilter,
  tagAndDepartmentCategoryGetters,
} from '../dataSourceHelpers';

export default createDataSource({
  hasAccess: () => true,
  documentNode: GetWidgetIndicatorsDocument,
  defaultVariables: { where: {} },
  useTablePropsHook: (data, options) =>
    useGetIndicatorSmartWidgetTableProps(
      data?.indicator,
      data?.form_configuration[0]?.customAttributeSchema ?? null,
      options
    ),
  customAttributeSchema: (data) =>
    data?.form_configuration[0]?.customAttributeSchema,
  entityNamePlural: 'indicator_other',
  entityNameSingular: 'indicator_one',
  fields: 'indicators.fields',
  dashboardFilterConfig: {
    tagsFilter: (tags) => ({ where: { tags: tagsFilter(tags) } }),
    departmentsFilter: (departments) => ({
      where: { departments: departmentsFilter(departments) },
    }),
    dateFilter: (dateRange, precision) => ({
      where: { CreatedAtTimestamp: dateRangeFilter(dateRange, precision) },
    }),
    dateClickthroughFilter:
      dashboardDateClickthroughFilter('CreatedAtTimestamp'),
  },
  clickThroughUrl: (filter) => indicatorRegisterUrl({ filtering: filter }),
  categoryGetters: [
    ...tagAndDepartmentCategoryGetters<IndicatorTableFields>(),
    {
      id: 'frequency',
      name: () => i18n.t('indicators.columns.test_frequency'),
      categoryGetter: (item) => item.TestFrequencyLabelled ?? UNRATED,
      clickthroughFilter: defaultClickthroughFilter('TestFrequencyLabelled'),
    },
    {
      id: 'conformance',
      name: () => i18n.t('indicators.columns.conformance'),
      categoryGetter: (item) => ({
        key: item.Conformance ?? UNRATED,
        label: String(item.ConformanceLabelled) ?? UNRATED,
      }),
      ratingColourKey: 'indicator_conformance_status',
      clickthroughFilter: defaultClickthroughFilter('ConformanceLabelled'),
    },
    {
      id: 'conformanceTrend',
      name: () => i18n.t('indicators.columns.conformance_trend'),
      categoryGetter: (item) => ({
        key:
          item.ConformanceTrend === '-'
            ? UNRATED
            : String(item.ConformanceTrend),
        label:
          item.ConformanceTrend === '-'
            ? UNRATED
            : String(item.ConformanceTrend),
      }),
      ratingColourKey: 'indicator_conformance_trend',
      clickthroughFilter: defaultClickthroughFilter('ConformanceTrend'),
    },
    {
      id: 'latestResult',
      name: () => i18n.t('indicators.columns.latest_result'),
      categoryGetter: (item) => item.LatestResultLabelled ?? null,
      clickthroughFilter: defaultClickthroughFilter('LatestResultLabelled'),
    },
    {
      id: 'latestResultDate',
      name: () => i18n.t('indicators.columns.latest_result_date'),
      categoryGetter: (item) => item.latestResults[0]?.ResultDate ?? null,
      date: true,
      dateFilter: (dateRange, precision) => ({
        where: {
          results: {
            ResultDate: dateRangeFilter(dateRange, precision),
          },
        },
      }),
      clickthroughFilter: dateClickthroughFilter('LatestResultDateLabelled'),
    },
    {
      id: 'unit',
      name: () => i18n.t('indicators.columns.unit'),
      categoryGetter: (item) => item.Unit ?? null,
      clickthroughFilter: defaultClickthroughFilter('Unit'),
    },
    {
      id: 'owner',
      name: () => i18n.t('columns.owner'),
      categoryGetter: (item) =>
        item.allOwners.map((owner) => ({ key: owner.id, label: owner.label })),
      clickthroughFilter: (category) => [
        {
          propertyKey: 'allOwners',
          operator: '=',
          value: category.key,
        },
      ],
    },
  ],
});
