// Context: This is a temporary solution to sanitize the settings
// There were no good solutions to ensure backwards compatibility
// of the widget date filters, so we sanitize the settings to slowly migrate
// dates to the new format as users save their dashboards
import { PropertyFilterToken } from '@cloudscape-design/collection-hooks';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { Parent_Type_Enum } from '@/generated/graphql';

import { GigawidgetSettings } from './util';

dayjs.extend(customParseFormat);

export const sanitizeTokens = (
  tokens: PropertyFilterToken[]
): PropertyFilterToken[] => {
  return tokens.map((token) => {
    if (dayjs(token?.value, 'YYYY-MM-DDTHH:mm:ss', true).isValid()) {
      const startOfTokenDate = dayjs(token.value).startOf('day');
      let startDate = null;
      let endDate = null;

      if (token.operator === '>=') {
        startDate = startOfTokenDate.format('YYYY-MM-DD');
        endDate = startOfTokenDate.add(10, 'year').format('YYYY-MM-DD');
      }

      if (token.operator === '>') {
        startDate = startOfTokenDate.add(1, 'day').format('YYYY-MM-DD');
        endDate = startOfTokenDate.add(10, 'year').format('YYYY-MM-DD');
      }

      if (token.operator === '<=') {
        startDate = startOfTokenDate.subtract(10, 'year').format('YYYY-MM-DD');
        endDate = startOfTokenDate.format('YYYY-MM-DD');
      }

      if (token.operator === '<') {
        startDate = startOfTokenDate.subtract(10, 'year').format('YYYY-MM-DD');
        endDate = startOfTokenDate.subtract(1, 'day').format('YYYY-MM-DD');
      }

      if (token.operator === '=') {
        startDate = startOfTokenDate.format('YYYY-MM-DD');
        endDate = dayjs(token.value).endOf('day').format('YYYY-MM-DD');
      }

      const newTokenValue = {
        type: 'absolute',
        startDate,
        endDate,
      };

      return {
        propertyKey: token.propertyKey as PropertyFilterToken['propertyKey'],
        operator: '=',
        value: newTokenValue,
      };
    }

    return token;
  });
};

export const sanitizeSettings = (
  settings: GigawidgetSettings
): GigawidgetSettings => {
  // Context: This is here as only issues are the only dashboards currently using the new date format
  // TODO: remove this guard once we migrate all registers to the new date format
  if (settings?.dataSource !== Parent_Type_Enum.Issue) {
    return settings;
  }

  if (settings?.filtering?.tokens?.length ?? 0 > 0) {
    return {
      ...settings,
      filtering: {
        ...settings.filtering,
        tokens: sanitizeTokens(
          settings?.filtering?.tokens as PropertyFilterToken[]
        ),
      },
    } as GigawidgetSettings;
  }

  return settings;
};
