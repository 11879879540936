import {
  Button as DefaultButton,
  ButtonProps,
} from '@cloudscape-design/components-themed';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { isInternal } from '../routes/routes.utils';
import styles from './style.module.scss';

interface Props extends ButtonProps {}

const Button: FC<Props> = ({ onClick, ...rest }) => {
  const navigate = useNavigate();
  if (rest.href && isInternal(rest?.href)) {
    onClick = (e) => {
      e.preventDefault();
      navigate(String(rest.href));
    };
  }

  return (
    <span className={styles.button}>
      <DefaultButton {...rest} onClick={onClick} />
    </span>
  );
};

export default Button;
