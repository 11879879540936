
    import './styles.scoped.css';
    export default {
  "drawer": "awsui_drawer_1r9lg_hh7b0_293",
  "refresh": "awsui_refresh_1r9lg_hh7b0_293",
  "animating": "awsui_animating_1r9lg_hh7b0_307",
  "preference-icon--svg": "awsui_preference-icon--svg_1r9lg_hh7b0_464",
  "preference-icon--layout-border": "awsui_preference-icon--layout-border_1r9lg_hh7b0_467",
  "preference-icon--layout-background": "awsui_preference-icon--layout-background_1r9lg_hh7b0_471",
  "preference-icon--layout-header": "awsui_preference-icon--layout-header_1r9lg_hh7b0_474",
  "preference-icon--border": "awsui_preference-icon--border_1r9lg_hh7b0_477",
  "preference-icon--primary-button": "awsui_preference-icon--primary-button_1r9lg_hh7b0_481",
  "preference-icon--secondary": "awsui_preference-icon--secondary_1r9lg_hh7b0_484",
  "preference-icon--disabled-element": "awsui_preference-icon--disabled-element_1r9lg_hh7b0_487",
  "preference-icon--separator": "awsui_preference-icon--separator_1r9lg_hh7b0_490",
  "preference-icon--focus-text": "awsui_preference-icon--focus-text_1r9lg_hh7b0_493",
  "preference-icon-refresh--svg": "awsui_preference-icon-refresh--svg_1r9lg_hh7b0_497",
  "preference-icon-refresh--layout-top": "awsui_preference-icon-refresh--layout-top_1r9lg_hh7b0_500",
  "preference-icon-refresh--layout-main": "awsui_preference-icon-refresh--layout-main_1r9lg_hh7b0_503",
  "preference-icon-refresh--primary": "awsui_preference-icon-refresh--primary_1r9lg_hh7b0_506",
  "preference-icon-refresh--disabled": "awsui_preference-icon-refresh--disabled_1r9lg_hh7b0_509",
  "preference-icon-refresh--column-header": "awsui_preference-icon-refresh--column-header_1r9lg_hh7b0_512",
  "preference-icon-refresh--window": "awsui_preference-icon-refresh--window_1r9lg_hh7b0_515",
  "preference-icon-refresh--secondary": "awsui_preference-icon-refresh--secondary_1r9lg_hh7b0_519",
  "preference-icon-refresh--default": "awsui_preference-icon-refresh--default_1r9lg_hh7b0_522",
  "preference-icon-refresh--separator": "awsui_preference-icon-refresh--separator_1r9lg_hh7b0_525",
  "preference-icon-refresh--input-default": "awsui_preference-icon-refresh--input-default_1r9lg_hh7b0_528",
  "preference-icon-refresh--heading": "awsui_preference-icon-refresh--heading_1r9lg_hh7b0_531",
  "root": "awsui_root_1r9lg_hh7b0_535",
  "preferences-button": "awsui_preferences-button_1r9lg_hh7b0_570",
  "close-button": "awsui_close-button_1r9lg_hh7b0_574",
  "drawer-closed": "awsui_drawer-closed_1r9lg_hh7b0_586",
  "drawer-content-side": "awsui_drawer-content-side_1r9lg_hh7b0_591",
  "drawer-content-bottom": "awsui_drawer-content-bottom_1r9lg_hh7b0_607",
  "position-bottom": "awsui_position-bottom_1r9lg_hh7b0_614",
  "position-side": "awsui_position-side_1r9lg_hh7b0_641",
  "slider": "awsui_slider_1r9lg_hh7b0_647",
  "slider-side": "awsui_slider-side_1r9lg_hh7b0_682",
  "slider-wrapper-bottom": "awsui_slider-wrapper-bottom_1r9lg_hh7b0_689",
  "slider-wrapper-side": "awsui_slider-wrapper-side_1r9lg_hh7b0_700",
  "open-button": "awsui_open-button_1r9lg_hh7b0_711",
  "open-button-side": "awsui_open-button-side_1r9lg_hh7b0_715",
  "slider-icon": "awsui_slider-icon_1r9lg_hh7b0_724",
  "slider-icon-bottom": "awsui_slider-icon-bottom_1r9lg_hh7b0_730",
  "slider-icon-side": "awsui_slider-icon-side_1r9lg_hh7b0_734",
  "pane-header-wrapper-bottom": "awsui_pane-header-wrapper-bottom_1r9lg_hh7b0_738",
  "drawer-mobile": "awsui_drawer-mobile_1r9lg_hh7b0_749",
  "drawer-disable-content-paddings": "awsui_drawer-disable-content-paddings_1r9lg_hh7b0_753",
  "content-bottom": "awsui_content-bottom_1r9lg_hh7b0_762",
  "pane-bottom-center-align": "awsui_pane-bottom-center-align_1r9lg_hh7b0_778",
  "pane-bottom-content-nav-padding": "awsui_pane-bottom-content-nav-padding_1r9lg_hh7b0_783",
  "pane-bottom-content-tools-padding": "awsui_pane-bottom-content-tools-padding_1r9lg_hh7b0_787",
  "content-bottom-max-width": "awsui_content-bottom-max-width_1r9lg_hh7b0_791",
  "content-side": "awsui_content-side_1r9lg_hh7b0_796",
  "pane-header-wrapper-side": "awsui_pane-header-wrapper-side_1r9lg_hh7b0_806",
  "pane-content-wrapper-side": "awsui_pane-content-wrapper-side_1r9lg_hh7b0_812",
  "header": "awsui_header_1r9lg_hh7b0_819",
  "header-text": "awsui_header-text_1r9lg_hh7b0_829",
  "header-actions": "awsui_header-actions_1r9lg_hh7b0_842",
  "divider": "awsui_divider_1r9lg_hh7b0_850"
};
  