import { TabsProps } from '@cloudscape-design/components-themed';
import { useTranslation } from 'react-i18next';
import ActionsTab from 'src/pages/actions/ActionsTab';
import IssueTab from 'src/pages/issues/IssueTab';
import LinkedItemsTab from 'src/pages/linked-items/LinkedItemsTab';
import { useHasPermission } from 'src/rbac/Permission';

import { GetDocumentByIdQuery, Parent_Type_Enum } from '@/generated/graphql';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';

import { ApprovalsTab } from '../../settings/tabs/approvals/Tab';
import AssessmentTab from './tabs/assessment/Tab';
import CreateTab from './tabs/create/Tab';
import FilesTab from './tabs/files/Tab';
import UpdateTab from './tabs/update/Tab';

export const useTabs = (
  mode: 'update' | 'create',
  detailsPath: string,
  document: GetDocumentByIdQuery['document'][number] | undefined
) => {
  const { t, i18n } = useTranslation(['common']);
  const canViewActions = useHasPermission('read:action', document);
  const canViewIssues = useHasPermission('read:issue', document);
  const canViewDocumentAssessmentResult = useHasPermission(
    'read:document_assessment_result',
    document
  );
  const canViewAssessment = useHasPermission('read:assessment', document);
  const canViewDocumentAssessments =
    canViewDocumentAssessmentResult && canViewAssessment;
  const canViewDocumentFiles = useHasPermission('read:document_file', document);
  const linkedItemsEnabled = useIsFeatureVisibleToOrg('linked-items');
  const canViewLinkedItems = useHasPermission('read:linked_item', document);
  const approvalsEnabled = useIsFeatureVisibleToOrg('approvers');

  const tabs: TabsProps.Tab[] = [
    {
      label: t('details'),
      id: 'details',
      content: mode === 'update' ? <UpdateTab /> : <CreateTab />,
      href: detailsPath,
    },
  ];

  if (canViewDocumentFiles) {
    tabs.push({
      label: i18n.format(t('versions'), 'capitalize'),
      id: 'files',
      content: document && <FilesTab parent={document} />,
      href: `${detailsPath}/files`,
      disabled: mode === 'create',
    });
  }

  if (canViewDocumentAssessments) {
    tabs.push({
      label: i18n.format(t('rating_other'), 'capitalize'),
      id: 'ratings',
      content: document && <AssessmentTab parent={document} />,
      href: `${detailsPath}/ratings`,
      disabled: mode === 'create',
    });
  }

  if (canViewIssues) {
    tabs.push({
      label: i18n.format(t('issue_other'), 'capitalize'),
      id: 'issues',
      content: document && <IssueTab parent={document} />,
      href: `${detailsPath}/issues`,
      disabled: mode === 'create',
    });
  }

  if (canViewActions) {
    tabs.push({
      label: i18n.format(t('action_other'), 'capitalize'),
      id: 'actions',
      content: document && <ActionsTab parent={document} />,
      href: `${detailsPath}/actions`,
      disabled: mode === 'create',
    });
  }

  if (approvalsEnabled) {
    tabs.push({
      label: t('approvals.tab_title'),
      id: 'approvals',
      content: document && (
        <ApprovalsTab parent={document} approvalType={'document'} />
      ),
      href: `${detailsPath}/approvals`,
      disabled: mode === 'create',
    });
  }

  if (linkedItemsEnabled && canViewLinkedItems) {
    tabs.push({
      label: t('linkedItems.tab_title'),
      id: 'linkedItems',
      content: document && (
        <LinkedItemsTab
          parent={document}
          parentType={Parent_Type_Enum.Document}
        />
      ),
      href: `${detailsPath}/linked-items`,
      disabled: mode === 'create',
    });
  }

  return tabs;
};
