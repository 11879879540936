
    import './styles.scoped.css';
    export default {
  "series": "awsui_series_1yz9c_2lu0k_187",
  "series--bar": "awsui_series--bar_1yz9c_2lu0k_188",
  "series__rect": "awsui_series__rect_1yz9c_2lu0k_188",
  "exiting": "awsui_exiting_1yz9c_2lu0k_205",
  "awsui-motion-fade-out-0": "awsui_awsui-motion-fade-out-0_1yz9c_2lu0k_1",
  "root": "awsui_root_1yz9c_2lu0k_227",
  "series--highlighted": "awsui_series--highlighted_1yz9c_2lu0k_240",
  "series__bar--highlighted": "awsui_series__bar--highlighted_1yz9c_2lu0k_241",
  "series--dimmed": "awsui_series--dimmed_1yz9c_2lu0k_245",
  "series--threshold": "awsui_series--threshold_1yz9c_2lu0k_249",
  "bar-group": "awsui_bar-group_1yz9c_2lu0k_254"
};
  