
    import './styles.scoped.css';
    export default {
  "link": "awsui_link_1kosq_1c5yx_143",
  "breadcrumb": "awsui_breadcrumb_1kosq_1c5yx_147",
  "icon": "awsui_icon_1kosq_1c5yx_150",
  "anchor": "awsui_anchor_1kosq_1c5yx_155",
  "last": "awsui_last_1kosq_1c5yx_201",
  "compressed": "awsui_compressed_1kosq_1c5yx_211",
  "text": "awsui_text_1kosq_1c5yx_215",
  "virtual-item": "awsui_virtual-item_1kosq_1c5yx_222"
};
  