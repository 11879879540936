
    import './styles.scoped.css';
    export default {
  "link": "awsui_link_4c84z_101c9_143",
  "variant-secondary": "awsui_variant-secondary_4c84z_101c9_212",
  "variant-primary": "awsui_variant-primary_4c84z_101c9_247",
  "variant-info": "awsui_variant-info_4c84z_101c9_280",
  "variant-value-large": "awsui_variant-value-large_4c84z_101c9_315",
  "variant-top-navigation": "awsui_variant-top-navigation_4c84z_101c9_347",
  "variant-recovery": "awsui_variant-recovery_4c84z_101c9_380",
  "button": "awsui_button_4c84z_101c9_413",
  "color-inverted": "awsui_color-inverted_4c84z_101c9_448",
  "font-size-body-s": "awsui_font-size-body-s_4c84z_101c9_469",
  "font-size-body-m": "awsui_font-size-body-m_4c84z_101c9_476",
  "font-size-heading-xs": "awsui_font-size-heading-xs_4c84z_101c9_482",
  "font-size-heading-s": "awsui_font-size-heading-s_4c84z_101c9_488",
  "font-size-heading-m": "awsui_font-size-heading-m_4c84z_101c9_495",
  "font-size-heading-l": "awsui_font-size-heading-l_4c84z_101c9_502",
  "font-size-heading-xl": "awsui_font-size-heading-xl_4c84z_101c9_509",
  "font-size-display-l": "awsui_font-size-display-l_4c84z_101c9_516",
  "font-size-inherit": "awsui_font-size-inherit_4c84z_101c9_523",
  "icon-wrapper": "awsui_icon-wrapper_4c84z_101c9_531",
  "icon": "awsui_icon_4c84z_101c9_531"
};
  