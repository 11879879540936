// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import { PropertyFilterOperatorForm } from '@cloudscape-design/collection-hooks';
import { Calendar, FormField } from '@cloudscape-design/components-themed';
import { useEffect, useState } from 'react';

import SimpleDateInput from '../Form/SimpleDateInput';

export const DateTimeForm: PropertyFilterOperatorForm<string | null> = ({
  filter,
  operator,
  value,
  onChange,
}) => {
  // Using the most reasonable default time per operator.
  const defaultTime =
    operator === '<' || operator === '>=' ? undefined : '23:59:59';
  const [{ dateValue, timeValue }, setState] = useState(
    parseValue(value ?? '', defaultTime)
  );

  const getDateTime = (date: string, time: string) =>
    date.trim() ? `${date}T${time || '00:00:00'}` : null;

  const onChangeDate = (dateValue: string): void => {
    setState((state) => ({ ...state, dateValue }));
    onChange(getDateTime(dateValue, timeValue));
  };

  // Parse value from filter text when it changes.
  useEffect(() => {
    filter && setState(parseDateTimeFilter(filter.trim()));
  }, [filter]);

  return (
    <div className="date-time-form">
      <FormField description="Date">
        <SimpleDateInput value={dateValue} onChange={onChangeDate} />
      </FormField>

      <Calendar
        value={dateValue}
        locale="en-EN"
        i18nStrings={{
          previousMonthAriaLabel: 'Previous month',
          nextMonthAriaLabel: 'Next month',
          todayAriaLabel: 'Today',
        }}
        onChange={(event) => onChangeDate(event.detail.value)}
      />
    </div>
  );
};

function parseDateTimeFilter(filter: string): {
  dateValue: string;
  timeValue: string;
} {
  const regexDate = /^(\d\d\d\d(-|\/\d\d)?(-|\/\d\d)?)(T\d\d(:\d\d)?(:\d\d)?)?/;
  const dateTime = filter.match(regexDate)?.[0] || '';

  let [dateValue, timeValue = ''] = dateTime.split('T');
  const [year, month = '01', day = '01'] = dateValue.split(/-|\//);
  const [hours = '00', minutes = '00', seconds = '00'] = timeValue.split(':');
  dateValue = year.length === 4 ? `${year}-${month}-${day}` : '';
  timeValue = timeValue ? `${hours}:${minutes}:${seconds}` : '';

  const value = !timeValue ? dateValue : dateValue + 'T' + timeValue;
  return isValidIsoDate(value)
    ? { dateValue, timeValue }
    : { dateValue: '', timeValue: '' };
}

function isValidIsoDate(isoDate: string | number | Date): boolean {
  return !isNaN(new Date(isoDate).getTime());
}

function parseValue(
  value: string,
  defaultTime = ''
): { dateValue: string; timeValue: string } {
  const [datePart = '', timePart = ''] = (value ?? '').split('T');
  return { dateValue: datePart, timeValue: timePart || defaultTime };
}
