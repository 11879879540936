import {
  Button as DefaultButton,
  ButtonProps,
} from '@cloudscape-design/components-themed';
import { FC } from 'react';

import styles from './style.module.scss';

const DeleteButton: FC<Omit<ButtonProps, 'variant' | 'formAction'>> = (
  props
) => {
  return (
    <DefaultButton
      variant="primary"
      formAction="none"
      {...props}
      {...{ className: styles.button }}
    />
  );
};

export default DeleteButton;
