import {
  Select as CSelect,
  SelectProps,
} from '@cloudscape-design/components-themed';
import { forwardRef } from 'react';

import styles from './style.module.scss';

const Select = forwardRef<SelectProps.Ref, SelectProps>((props, ref) => {
  return (
    <div className={styles.select}>
      <CSelect {...props} ref={ref} />
    </div>
  );
});
Select.displayName = 'Select';

export default Select;
