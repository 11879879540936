/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, PropsWithChildren, useContext, useState } from 'react';
import { ZodSchema } from 'zod';

import { Parent_Type_Enum } from '@/generated/graphql';

type CustomisableFormContextState = {
  parentType: Parent_Type_Enum | null;
  editMode: boolean;
  toggleEditMode: () => void;
  onSave?: () => Promise<void>;
  setOnSave: React.Dispatch<
    React.SetStateAction<(() => Promise<void>) | undefined>
  >;
  setCustomFormValidation: React.Dispatch<
    React.SetStateAction<(schema: ZodSchema) => ZodSchema>
  >;
  defaultRequiredFields: string[];
  addDefaultRequiredField: (fieldId: string) => void;
  removeDefaultRequiredField: (fieldId: string) => void;
  forcedRequiredFields: string[];
  addForcedRequiredField: (fieldId: string) => void;
  removeForcedRequiredField: (fieldId: string) => void;
  allFieldIds: string[];
  addFieldId: (name: string) => void;
  removeFieldId: (name: string) => void;
  previewChanges: Record<string, { from: unknown; to: unknown }> | null;
  readOnly?: boolean;
  beforeSaveHooks: (() => Promise<boolean>)[];
  setBeforeSaveHooks: React.Dispatch<
    React.SetStateAction<(() => Promise<boolean>)[]>
  >;
  defaultOnSave: () => Promise<void>;
};

const RiskSmartFormContext = createContext<CustomisableFormContextState>({
  parentType: null,
  editMode: false,
  toggleEditMode: () => null,
  setOnSave: () => null,
  setCustomFormValidation: () => null,
  defaultRequiredFields: [],
  addDefaultRequiredField: () => null,
  removeDefaultRequiredField: () => null,
  forcedRequiredFields: [],
  addForcedRequiredField: () => null,
  removeForcedRequiredField: () => null,
  allFieldIds: [],
  addFieldId: () => null,
  removeFieldId: () => null,
  previewChanges: null,
  readOnly: false,
  beforeSaveHooks: [],
  setBeforeSaveHooks: () => null,
  defaultOnSave: async () => {
    null;
  },
});

type Props = {
  onSave?: () => Promise<void>;
  parentType?: Parent_Type_Enum;
  setOnSave: React.Dispatch<
    React.SetStateAction<(() => Promise<void>) | undefined>
  >;
  setCustomFormValidation: React.Dispatch<
    React.SetStateAction<(schema: ZodSchema) => ZodSchema>
  >;
  previewChanges: Record<string, { from: unknown; to: unknown }> | null;
  readOnly?: boolean;
  defaultOnSave: () => Promise<void>;
  beforeSaveHooks: (() => Promise<boolean>)[];
  setBeforeSaveHooks: React.Dispatch<
    React.SetStateAction<(() => Promise<boolean>)[]>
  >;
};

export const RiskSmartFormProvider = ({
  onSave,
  setOnSave,
  setCustomFormValidation,
  parentType,
  previewChanges,
  readOnly,
  children,
  beforeSaveHooks,
  setBeforeSaveHooks,
  defaultOnSave,
}: PropsWithChildren<Props>) => {
  const [editMode, setEditMode] = useState(false);
  const [allFieldIds, setAllFieldIds] = useState<string[]>([]);
  const [defaultRequiredFields, setDefaultRequiredFields] = useState<string[]>(
    []
  );
  const [forcedRequiredFields, setForcedRequiredFields] = useState<string[]>(
    []
  );

  const addFieldId = (name: string) =>
    setAllFieldIds((fields) => Array.from(new Set([...fields, name])));

  const removeFieldId = (name: string) =>
    setAllFieldIds((allFields) => allFields.filter((f) => f !== name));

  const toggleEditMode = () => setEditMode((prev) => !prev);

  const addDefaultRequiredField = (fieldId: string) => {
    setDefaultRequiredFields((prev) => Array.from(new Set([...prev, fieldId])));
  };

  const removeDefaultRequiredField = (fieldId: string) => {
    setDefaultRequiredFields((prev) =>
      prev.filter((requiredField) => requiredField !== fieldId)
    );
  };

  const addForcedRequiredField = (fieldId: string) => {
    setForcedRequiredFields((prev) => Array.from(new Set([...prev, fieldId])));
  };

  const removeForcedRequiredField = (fieldId: string) => {
    setForcedRequiredFields((prev) =>
      prev.filter((requiredField) => requiredField !== fieldId)
    );
  };

  return (
    <RiskSmartFormContext.Provider
      value={{
        parentType: parentType ?? null,
        editMode,
        onSave,
        toggleEditMode,
        setOnSave,
        setCustomFormValidation,
        defaultRequiredFields,
        forcedRequiredFields,
        addForcedRequiredField,
        removeForcedRequiredField,
        addDefaultRequiredField,
        removeDefaultRequiredField,
        allFieldIds,
        addFieldId,
        removeFieldId,
        previewChanges,
        readOnly,
        beforeSaveHooks,
        setBeforeSaveHooks,
        defaultOnSave,
      }}
    >
      {children}
    </RiskSmartFormContext.Provider>
  );
};

export const useRiskSmartForm = () => {
  return useContext(RiskSmartFormContext);
};
