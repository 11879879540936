import dayjs from 'dayjs';

import {
  dateRangeFilterOperators,
  defaultDateFilterOperators,
} from '@/components/DateTimeFilter/dateFilterOperator';
import Link from '@/components/Link';

import { EMPTY_CELL, toLocalDate, toLocalDateTime } from '../..';
import { FieldConfig, TableRecord } from '../types';

// TODO: Change args to an object to prevent args being passed in the wrong order
// TODO: Update all usages of dateColumn to use the new args object
export function dateColumn<T extends TableRecord>(
  label: string,
  dateField: Extract<keyof T, string>,
  onClick?: (record: T) => void,
  includeTime?: boolean,
  useDateRangeFilter?: boolean
): FieldConfig<T> {
  return {
    fieldType: 'date',
    header: label,
    cell: (item) => {
      const fieldVal = item[dateField] as string;
      const dateTime =
        (includeTime ? toLocalDateTime(fieldVal) : toLocalDate(fieldVal)) ||
        EMPTY_CELL;
      if (!onClick) {
        return dateTime;
      }
      return <Link onFollow={() => onClick(item)}>{dateTime}</Link>;
    },
    filterOptions: {
      filteringProperties: {
        operators: useDateRangeFilter
          ? dateRangeFilterOperators
          : defaultDateFilterOperators,
      },
    },
    exportVal: (item) =>
      item[dateField]
        ? dayjs(String(item[dateField])).format('DD/MM/YYYY HH:mm')
        : '',
  };
}
