import dayjs from 'dayjs';
import _ from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getAllContributorsCellValue,
  getAllOwnersCellValue,
} from 'src/rbac/contributorHelper';

import { Cost_Type_Enum } from '@/generated/graphql';
import useEntityInfo from '@/hooks/getEntityInfo';
import { useRating } from '@/hooks/use-rating';

import { UNRATED } from '../controls/lookupData';
import { IssueFlatField, IssueRegisterFields } from './types';
import { calculateCostTotal } from './update/tabs/consequences/utils';

export const useLabelledFields = (
  records: IssueFlatField[] | undefined
): IssueRegisterFields[] | undefined => {
  const { t } = useTranslation(['common']);
  const issueTypes = useMemo(
    () =>
      t('issueTypes', {
        returnObjects: true,
      }),
    [t]
  );

  const status = useRating('issue_assessment_status');
  const severity = useRating('severity');

  const getEntityInfo = useEntityInfo();
  return useMemo<IssueRegisterFields[] | undefined>(() => {
    return records?.map((d) => {
      let parentTitle: string | null = null;
      let parentId: string | null = null;
      let parentType: string | null = null;
      // @TODO: we should handle multiple parents
      if (d.parents.length > 0) {
        const parent = d.parents[0];
        parentType = parent?.parent?.ObjectType
          ? getEntityInfo(parent?.parent?.ObjectType).singular
          : null;
        parentId = parent?.parent?.Id ?? null;

        if (parent.control) {
          parentTitle = parent.control.Title;
        } else if (parent.obligation) {
          parentTitle = parent.obligation.Title ?? null;
        } else if (parent.document) {
          parentTitle = parent.document.Title ?? null;
        }
      }
      return {
        ...d.assessment!,
        ...d,
        IssueTypeLabelled: d.assessment?.IssueType
          ? issueTypes[d.assessment.IssueType as keyof typeof issueTypes] //TODO get IssueType as enum
          : '-',
        SeverityLabelled:
          severity.getLabel(d.assessment?.Severity ?? null) || UNRATED.label,
        StatusLabelled: status.getLabel(d.assessment?.Status ?? null),
        ParentTitle: parentTitle,
        ParentId: parentId,
        ParentType: parentType,
        OpenActions: d.actions_aggregate.aggregate?.count ?? null,
        RegulationsBreached:
          d.parents
            ?.filter((parent) => parent.obligation)
            .map((parent) => parent.obligation?.Title)
            .join(', ') ?? '-',
        ModifiedByUserName: d.modifiedByUser?.FriendlyName || '-',
        CreatedByUserName: d.createdByUser?.FriendlyName || '-',
        AssessmentCreatedBy: d.assessment?.createdByUser?.FriendlyName || '-',
        AssessmentModifiedBy: d.assessment?.modifiedByUser?.FriendlyName || '-',
        Severity: d.assessment?.Severity ?? null,
        SequentialIdLabel: d.SequentialId ? `I-${d.SequentialId}` : '',
        AssessmentDepartments: d.assessment?.departments ?? null,
        CustomAttributeData: {
          ...(d.CustomAttributeData || {}),
          ...(d.assessment?.CustomAttributeData || {}),
        },
        allOwners: getAllOwnersCellValue(d),
        allContributors: getAllContributorsCellValue(d),
        Cost: calculateCostTotal(d.consequences ?? [], Cost_Type_Enum.Pounds),
        Hours: calculateCostTotal(d.consequences ?? [], Cost_Type_Enum.Hours),
        CustomersImpacted: calculateCostTotal(
          d.consequences ?? [],
          Cost_Type_Enum.CustomersImpacted
        ),
        CertifiedIndividual:
          d.assessment?.certifiedIndividual?.FriendlyName || '-',
        TimeToResolve:
          d.assessment?.ActualCloseDate && d.CreatedAtTimestamp
            ? dayjs(d.assessment?.ActualCloseDate).diff(
                dayjs(d.CreatedAtTimestamp),
                'days'
              )
            : null,
        TimeToReport: dayjs(d.CreatedAtTimestamp).diff(
          d.DateIdentified,
          'days'
        ),
        TimeToIdentify: dayjs(d.DateIdentified).diff(d.DateOccurred, 'days'),
        TimeSinceCreated: dayjs().diff(dayjs(d.CreatedAtTimestamp), 'days'),
      };
    });
  }, [records, issueTypes, severity, status, getEntityInfo]);
};
