import { SideNavigationProps } from '@cloudscape-design/components-themed';

import { CountRenderer } from './Count';
import { Divider } from './Divider';
import { ExpandableLinkGroup } from './ExpandableLinkGroup';
import ItemCollapsed from './ItemCollapsed';
import Link from './Link';
import { LinkGroup } from './LinkGroup';
import NestedLink from './NestedLink';
import { Section } from './Section';
import { SectionGroup } from './SectionGroup';

export const navItemTypeReducer = ({
  item,
  isChild,
  collapsed,
  renderCount,
  altTheme,
}: {
  item: SideNavigationProps.Item;
  isChild?: boolean;
  collapsed?: boolean;
  renderCount?: CountRenderer;
  altTheme?: boolean;
}) => {
  switch (item.type) {
    case 'section':
      if (collapsed)
        return (
          <ItemCollapsed
            item={item}
            renderCount={renderCount}
            altTheme={altTheme}
          />
        );
      return (
        <Section item={item} renderCount={renderCount} altTheme={altTheme} />
      );
    case 'divider':
      return <Divider />;
    case 'expandable-link-group':
      return <ExpandableLinkGroup item={item} renderCount={renderCount} />;
    case 'link':
      if (collapsed)
        return (
          <ItemCollapsed
            item={item}
            renderCount={renderCount}
            altTheme={altTheme}
          />
        );
      return isChild ? (
        <NestedLink item={item} renderCount={renderCount} />
      ) : (
        <Link item={item} renderCount={renderCount} />
      );
    case 'link-group':
      return <LinkGroup item={item} renderCount={renderCount} />;
    case 'section-group':
      return <SectionGroup item={item} />;
    default:
      return <></>;
  }
};
