
    import './styles.scoped.css';
    export default {
  "header": "awsui_header_16mm3_1qa9u_143",
  "disabled": "awsui_disabled_16mm3_1qa9u_158",
  "expandable-header": "awsui_expandable-header_16mm3_1qa9u_162",
  "rolled-down": "awsui_rolled-down_16mm3_1qa9u_173",
  "highlighted": "awsui_highlighted_16mm3_1qa9u_176",
  "is-focused": "awsui_is-focused_16mm3_1qa9u_194",
  "category": "awsui_category_16mm3_1qa9u_199",
  "expandable": "awsui_expandable_16mm3_1qa9u_162",
  "variant-navigation": "awsui_variant-navigation_16mm3_1qa9u_214",
  "expand-icon": "awsui_expand-icon_16mm3_1qa9u_221",
  "expand-icon-up": "awsui_expand-icon-up_16mm3_1qa9u_228",
  "expand-icon-right": "awsui_expand-icon-right_16mm3_1qa9u_231",
  "items-list-container": "awsui_items-list-container_16mm3_1qa9u_248",
  "in-dropdown": "awsui_in-dropdown_16mm3_1qa9u_257"
};
  