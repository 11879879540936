
    import './styles.scoped.css';
    export default {
  "visible-content": "awsui_visible-content_tc96w_h30ng_285",
  "visible-content-toggle": "awsui_visible-content-toggle_tc96w_h30ng_286",
  "visible-content-groups": "awsui_visible-content-groups_tc96w_h30ng_287",
  "visible-content-group": "awsui_visible-content-group_tc96w_h30ng_287",
  "visible-content-title": "awsui_visible-content-title_tc96w_h30ng_292",
  "visible-content-group-label": "awsui_visible-content-group-label_tc96w_h30ng_302",
  "visible-content-option": "awsui_visible-content-option_tc96w_h30ng_308",
  "visible-content-option-label": "awsui_visible-content-option-label_tc96w_h30ng_321",
  "content-display-option-toggle": "awsui_content-display-option-toggle_tc96w_h30ng_612",
  "content-display-option-content": "awsui_content-display-option-content_tc96w_h30ng_616",
  "content-display-option": "awsui_content-display-option_tc96w_h30ng_612",
  "placeholder": "awsui_placeholder_tc96w_h30ng_666",
  "sorting": "awsui_sorting_tc96w_h30ng_666",
  "content-display-option-label": "awsui_content-display-option-label_tc96w_h30ng_693",
  "drag-overlay": "awsui_drag-overlay_tc96w_h30ng_700",
  "content-display": "awsui_content-display_tc96w_h30ng_612",
  "content-display-title": "awsui_content-display-title_tc96w_h30ng_733",
  "content-display-description": "awsui_content-display-description_tc96w_h30ng_742",
  "content-display-option-list": "awsui_content-display-option-list_tc96w_h30ng_750",
  "root": "awsui_root_tc96w_h30ng_757",
  "modal-root": "awsui_modal-root_tc96w_h30ng_758",
  "trigger-button": "awsui_trigger-button_tc96w_h30ng_759",
  "cancel-button": "awsui_cancel-button_tc96w_h30ng_760",
  "confirm-button": "awsui_confirm-button_tc96w_h30ng_761",
  "custom": "awsui_custom_tc96w_h30ng_762",
  "second-column-small": "awsui_second-column-small_tc96w_h30ng_766",
  "wrap-lines": "awsui_wrap-lines_tc96w_h30ng_770",
  "striped-rows": "awsui_striped-rows_tc96w_h30ng_771",
  "content-density": "awsui_content-density_tc96w_h30ng_772",
  "page-size": "awsui_page-size_tc96w_h30ng_773",
  "page-size-form-field": "awsui_page-size-form-field_tc96w_h30ng_774",
  "page-size-radio-group": "awsui_page-size-radio-group_tc96w_h30ng_775",
  "sticky-columns": "awsui_sticky-columns_tc96w_h30ng_776",
  "sticky-columns-form-field": "awsui_sticky-columns-form-field_tc96w_h30ng_777",
  "sticky-columns-radio-group": "awsui_sticky-columns-radio-group_tc96w_h30ng_778",
  "sticky-columns-first": "awsui_sticky-columns-first_tc96w_h30ng_779",
  "sticky-columns-last": "awsui_sticky-columns-last_tc96w_h30ng_780"
};
  