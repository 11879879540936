
    import './styles.scoped.css';
    export default {
  "block-body-scroll": "awsui_block-body-scroll_19d8l_1otq8_151",
  "mobile-bar": "awsui_mobile-bar_19d8l_1otq8_155",
  "mobile-bar-breadcrumbs": "awsui_mobile-bar-breadcrumbs_19d8l_1otq8_168",
  "mobile-toggle": "awsui_mobile-toggle_19d8l_1otq8_175",
  "mobile-toggle-type-navigation": "awsui_mobile-toggle-type-navigation_19d8l_1otq8_184",
  "mobile-toggle-type-tools": "awsui_mobile-toggle-type-tools_19d8l_1otq8_187",
  "mobile-toggle-type-drawer": "awsui_mobile-toggle-type-drawer_19d8l_1otq8_187",
  "drawers-container": "awsui_drawers-container_19d8l_1otq8_194"
};
  