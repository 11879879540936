import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useFileUpdate } from 'src/data/rest/useFileUpdate';
import IndicatorsDetailsForm from 'src/pages/indicators/forms/IndicatorDetailsForm';
import {
  defaultValues,
  IndicatorFormDataFields,
} from 'src/pages/indicators/forms/indicatorSchema';
import { getContributors, getOwners } from 'src/rbac/contributorHelper';
import { useHasPermission } from 'src/rbac/Permission';

import {
  contributorUpdateFields,
  departmentInsertInputBuilder,
  ownerUpdateFields,
  tagInsertInputBuilder,
} from '@/components/Form';
import { PageWrapper } from '@/components/Form/Form/PageWrapper';
import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import {
  GetIndicatorByIdQuery,
  Parent_Type_Enum,
  useUpdateIndicatorMutation,
} from '@/generated/graphql';
import { evictField } from '@/utils/graphqlUtils';

type Props = {
  indicator: GetIndicatorByIdQuery['indicator'][0];
};

const Tab: FC<Props> = ({ indicator }) => {
  useI18NSummaryHelpContent('indicators.detailsHelp');
  const { updateFiles } = useFileUpdate();
  const canUpdateIndicator = useHasPermission('update:indicator', indicator);
  const { t } = useTranslation(['common']);
  const navigate = useNavigate();
  const [updateIndicator] = useUpdateIndicatorMutation({
    update: (cache) => evictField(cache, 'indicator'),
  });
  const onSave = async (data: IndicatorFormDataFields) => {
    if (!indicator) {
      throw new Error('Missing indicator data');
    }
    const { files, newFiles, ...rest } = data;
    await updateIndicator({
      variables: {
        ...rest,
        Id: indicator?.Id || '',
        CustomAttributeData: data.CustomAttributeData || undefined,
        ...contributorUpdateFields(data, indicator.Id),
        ...ownerUpdateFields(data, indicator.Id),
        tags: tagInsertInputBuilder(data.TagTypeIds, indicator.Id),
        departments: departmentInsertInputBuilder(
          data.DepartmentTypeIds,
          indicator.Id
        ),
      },
    });

    await updateFiles({
      parentType: Parent_Type_Enum.Indicator,
      parentId: indicator?.Id,
      newFiles: newFiles,
      originalFiles: indicator?.files,
      selectedFiles: files,
    });
  };

  const onDismiss = () => navigate(-1);
  const indicatorValue: IndicatorFormDataFields = {
    ...defaultValues,
    ...indicator,
    Owners: getOwners(indicator),
    Contributors: getContributors(indicator),
    ancestorContributors: indicator?.ancestorContributors ?? [],
  } as IndicatorFormDataFields;
  return (
    <IndicatorsDetailsForm
      values={indicatorValue}
      onSave={onSave}
      onDismiss={onDismiss}
      readOnly={!canUpdateIndicator}
      header={t('details')}
      isUpdate={true}
      renderTemplate={(renderProps) => <PageWrapper {...renderProps} />}
    />
  );
};

export default Tab;
