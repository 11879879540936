import Table from '@risksmart-app/components/Table';
import { FC } from 'react';
import { useGetRegisterTableProps } from 'src/pages/issues/config';

import { CustomAttributeFields } from '@/components/Form/CustomAttributes/CustomAttributeSchema';
import { GetIssuesQuery } from '@/generated/graphql';
import { CollectionData } from '@/utils/collectionUtils';
type IssueFlatField = CollectionData<GetIssuesQuery['issue'][number]>;
interface Props {
  loading: boolean;
  records: IssueFlatField[] | undefined;
  customAttributeSchema?: CustomAttributeFields[] | null;
}

const AssessmentIssueRegister: FC<Props> = ({
  loading,
  records,
  customAttributeSchema,
}) => {
  const tableProps = useGetRegisterTableProps(records, customAttributeSchema);
  return <Table variant="embedded" {...tableProps} loading={loading} />;
};

export default AssessmentIssueRegister;
