import { Box } from '@cloudscape-design/components-themed';
import {
  Forbidden,
  PageNotFound,
} from '@risksmart-app/components/errors/errors';
import * as Sentry from '@sentry/browser';
import { FC, useEffect } from 'react';
import { useRouteError } from 'react-router-dom';

import { handleError } from '@/utils/errorUtils';

import AccessDeniedPage from './AccessDeniedPage';
import ErrorContent from './ErrorContent';
import NotFoundPage from './NotFoundPage';

interface Props {}

const Page: FC<Props> = () => {
  const error = useRouteError();
  const isNotFound = error instanceof PageNotFound;
  const isForbidden = error instanceof Forbidden;
  useEffect(() => {
    if (isNotFound || isForbidden) {
      Sentry.captureMessage(error.message);
      return;
    }
    if (error) {
      handleError(error);
    }
  }, [error, isForbidden, isNotFound]);

  if (isNotFound) return <NotFoundPage />;
  if (isForbidden) return <AccessDeniedPage />;
  return (
    <ErrorContent
      title={'Sorry, we couldn’t make that happen'}
      imgSrc={'/errors/rubiks-cube.png'}
      imgAlt={'binoculars'}
    >
      <Box variant="p">Please try again later</Box>
    </ErrorContent>
  );
};

export default Page;
