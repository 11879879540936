import { Box } from '@cloudscape-design/components-themed';
import { createContext, FC, ReactNode } from 'react';
import ErrorContent from 'src/pages/error/ErrorContent';

import Loading from '@/components/Loading';
import { GetRoleAccessQuery, useGetRoleAccessQuery } from '@/generated/graphql';

export const PermissionsContext = createContext<
  GetRoleAccessQuery['role_access'] | null
>(null);

type Props = { children: ReactNode };

export const PermissionsProvider: FC<Props> = ({ children }) => {
  const { data, loading, error } = useGetRoleAccessQuery({});
  const roleAccess = data?.role_access;

  return (
    <PermissionsContext.Provider value={roleAccess ? roleAccess : null}>
      {loading && <Loading />}
      {error && (
        <ErrorContent
          title={'Sorry, we couldn’t make that happen'}
          imgSrc={'/errors/rubiks-cube.png'}
          imgAlt={'binoculars'}
        >
          <Box variant="p">Please try again later</Box>
        </ErrorContent>
      )}
      {!error && !loading && children}
    </PermissionsContext.Provider>
  );
};
