import Button from '@risksmart-app/components/Button';
import { FC, ReactNode, useState } from 'react';

import {
  GetUserGroupsQuery,
  GetUsersQuery,
  useGetUserSearchPreferencesQuery,
  useUpsertUserSearchPreferencesMutation,
} from '@/generated/graphql';

import {
  HidableOption,
  HidableOptionGroup,
} from '../Form/ControlledMultiselect/types';
import { Filter, useGroupAndUserOptions } from './useGroupAndUserOptions';
import UserSearchPreferencesForm from './UserSearchPreferencesForm';

type RenderOptions = {
  onBlur: () => void;
  onChange: (options: readonly HidableOption[]) => void;
  statusType: 'loading' | 'error' | undefined;
  options: (HidableOptionGroup | HidableOption)[];
  userGroups: GetUserGroupsQuery | undefined;
  users: GetUsersQuery | undefined;
  preferencesButton: ReactNode;
};

type Props = {
  disabledValues?: Set<string>;
  includeGroups: boolean;
  userFilter?: Filter<GetUsersQuery['user'][number]>;
  groupFilter?: Filter<GetUserGroupsQuery['user_group'][number]>;
  children: (options: RenderOptions) => ReactNode;
  addEmptyOption?: boolean;
};

const UserSearchPreferences: FC<Props> = ({
  disabledValues,
  userFilter,
  groupFilter,
  includeGroups,
  addEmptyOption,
  children,
}) => {
  const [showPreferences, setShowPreferences] = useState(false);
  const { data: userSearchPreferencesData, refetch } =
    useGetUserSearchPreferencesQuery();
  const [upsetUserSearchPreferences] = useUpsertUserSearchPreferencesMutation();
  const userSearchPreferences =
    userSearchPreferencesData?.user_search_preferences?.[0];
  const {
    optionItems,
    statusType,
    addRecentUser,
    commitRecentUsers,
    hasDirectoryDepartments,
    hasJobTitle,
    hasUserLocation,
    userGroups,
    users,
  } = useGroupAndUserOptions({
    addEmptyOption,
    includeGroups,
    userFilter,
    groupFilter,
    disabledValues,
    displayedAttributes: userSearchPreferences,
    hideGroups: !userSearchPreferences?.ShowGroups,
    hideInActiveUsers: userSearchPreferences?.FilterByActivePlatformUsers,
  });

  const preferencesButton = (
    <Button
      onClick={() => setShowPreferences(true)}
      iconName="settings"
      variant="icon"
    />
  );

  return (
    <>
      {children({
        onBlur: commitRecentUsers,
        onChange: (options) => {
          for (const option of options) {
            if ('type' in option && option.type === 'user') {
              addRecentUser(option.value!);
            }
          }
        },
        statusType,
        options: optionItems,
        userGroups,
        users,
        preferencesButton,
      })}
      {showPreferences && (
        <UserSearchPreferencesForm
          values={userSearchPreferences}
          onSave={async (data) => {
            await upsetUserSearchPreferences({ variables: data });
            await refetch();
          }}
          onDismiss={() => setShowPreferences(false)}
          showJobTitleToggle={hasJobTitle}
          showDirectoryDepartmentsToggle={hasDirectoryDepartments}
          showUserLocationToggle={hasUserLocation}
        />
      )}
    </>
  );
};

export default UserSearchPreferences;
