
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_145pa_10ntw_143",
  "focus-lock": "awsui_focus-lock_145pa_10ntw_178",
  "calendar": "awsui_calendar_145pa_10ntw_182",
  "date-picker-container": "awsui_date-picker-container_145pa_10ntw_198",
  "date-picker-trigger": "awsui_date-picker-trigger_145pa_10ntw_203",
  "date-picker-input": "awsui_date-picker-input_145pa_10ntw_207",
  "open-calendar-button": "awsui_open-calendar-button_145pa_10ntw_212"
};
  