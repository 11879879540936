import { PageNotFound } from '@risksmart-app/components/errors/errors';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import { FC } from 'react';

import ControlledTabs from '@/components/ControlledTabs/ControlledTabs';
import { useGetDocumentFileByIdQuery } from '@/generated/graphql';

import { PageLayout } from '../../../../../../layouts';
import { useTabs } from './useTabs';

type Props = {
  activeTabId: 'details' | 'attestations';
};

const DocumentFileModal: FC<Props> = ({ activeTabId }) => {
  const documentFileId = useGetGuidParam('id');

  const { data: documentFileData } = useGetDocumentFileByIdQuery({
    fetchPolicy: 'no-cache',
    variables: { id: documentFileId! },
    skip: !documentFileId,
  });
  if (documentFileData?.document_file.length === 0) {
    throw new PageNotFound(`Version with id ${documentFileId} not found`);
  }

  const version = documentFileData?.document_file?.[0];
  const header = version
    ? `${version?.parent?.Title} (${version?.Version})`
    : 'Loading'; // TODO: translation

  const tabs = useTabs();

  return (
    <PageLayout title={header}>
      <ControlledTabs
        activeTabId={activeTabId}
        tabs={tabs}
        variant="container"
      />
    </PageLayout>
  );
};

export default DocumentFileModal;
