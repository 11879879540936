import i18n from 'src/i18n';

import { policyFilesUrl } from '@/utils/urls';

import { GetItem } from './types';

export const getItem: GetItem = (item, lookupData) => {
  const policyVersionId = item.data?.objectId;
  const documentFile = lookupData?.documentFiles?.[policyVersionId];
  return {
    message: documentFile?.ParentDocumentId
      ? i18n.t('notifications.messages.policyDocumentVersionApproval')
      : `${i18n.t(
          'notifications.messages.policyDocumentVersionApproval'
        )}:${i18n.t('notifications.unknown')}`,
    url: documentFile ? policyFilesUrl(documentFile.ParentDocumentId) : null,
  };
};
