import Button from '@risksmart-app/components/Button';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import EmptyCollection from './EmptyCollection';

type Props = {
  onClearClick: () => void;
};

const NoMatchesCollection: FC<Props> = ({ onClearClick }) => {
  const { t } = useTranslation(['common']);
  return (
    <EmptyCollection
      action={<Button onClick={onClearClick}>{t('clearFilter')}</Button>}
      title={t('noMatches')}
      subtitle={t('noMatchFound')}
    />
  );
};

export default NoMatchesCollection;
