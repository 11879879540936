import { ContentTable } from 'pdfmake/interfaces';
import { useTranslation } from 'react-i18next';
import { UNRATED } from 'src/pages/controls/lookupData';

import {
  Risk_Assessment_Result_Control_Type_Enum,
  useGetRiskAssessmentResultsByRiskIdAndControlTypeLazyQuery,
} from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { toLocalDate } from '@/utils/dateUtils';
import { createTable, tableHeaders } from '@/utils/pdf/table';

const useAssessmentsExportTable = (
  riskId: string,
  controlType: Risk_Assessment_Result_Control_Type_Enum
): [() => Promise<ContentTable>, boolean] => {
  const { getLabel: getStatusLabel } = useRating('assessment_status');
  const { getLabel: getLikelihoodLabel } = useRating('likelihood');
  const { getLabel: getImpactLabel } = useRating('impact');
  const { getLabel: getRatingLabel } = useRating(
    controlType === Risk_Assessment_Result_Control_Type_Enum.Controlled
      ? 'risk_controlled'
      : 'risk_uncontrolled'
  );
  const [getAssessments, getAssessmentsResult] =
    useGetRiskAssessmentResultsByRiskIdAndControlTypeLazyQuery({
      variables: {
        RiskId: riskId,
        ControlType: controlType,
      },
    });

  const { t: assessmentsColumns } = useTranslation(['common']);
  const createExportTable = async () => {
    const { data: assessmentsData } = await getAssessments();
    const assessmentsTableData = (
      assessmentsData?.risk_assessment_result ?? []
    ).map((au) => [
      au.parents?.[0]?.assessment?.Title ?? '-',
      getStatusLabel(au.parents?.[0]?.assessment?.Status) ?? '-',
      getRatingLabel(au.Rating ?? UNRATED.value),
      getImpactLabel(au.Impact ?? UNRATED.value),
      getLikelihoodLabel(au.Likelihood ?? UNRATED.value),
      toLocalDate(au.parents?.[0]?.assessment?.StartDate) ?? '-',
      toLocalDate(au.parents?.[0]?.assessment?.ActualCompletionDate) ?? '-',
      au.parents?.[0]?.assessment?.completedByUser?.FriendlyName ?? '-',
    ]);

    return createTable({
      widths: '*',
      body: [
        tableHeaders([
          assessmentsColumns('assessments.columns.Title'),
          assessmentsColumns('assessments.columns.Status'),
          assessmentsColumns('assessmentResults.fields.Rating'),
          assessmentsColumns('assessmentResults.fields.Impact'),
          assessmentsColumns('assessmentResults.fields.Likelihood'),
          assessmentsColumns('assessments.columns.StartDate'),
          assessmentsColumns('assessments.columns.CompletionDate'),
          assessmentsColumns('assessments.columns.CompletionBy'),
        ]),
        ...assessmentsTableData,
      ],
    });
  };
  return [createExportTable, getAssessmentsResult.loading];
};

export default useAssessmentsExportTable;
