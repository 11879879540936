
    import './styles.scoped.css';
    export default {
  "grid": "awsui_grid_14yj0_f5atp_143",
  "no-gutters": "awsui_no-gutters_14yj0_f5atp_182",
  "grid-column": "awsui_grid-column_14yj0_f5atp_187",
  "colspan-1": "awsui_colspan-1_14yj0_f5atp_198",
  "push-1": "awsui_push-1_14yj0_f5atp_202",
  "pull-1": "awsui_pull-1_14yj0_f5atp_205",
  "colspan-2": "awsui_colspan-2_14yj0_f5atp_208",
  "push-2": "awsui_push-2_14yj0_f5atp_212",
  "pull-2": "awsui_pull-2_14yj0_f5atp_215",
  "colspan-3": "awsui_colspan-3_14yj0_f5atp_218",
  "push-3": "awsui_push-3_14yj0_f5atp_222",
  "pull-3": "awsui_pull-3_14yj0_f5atp_225",
  "colspan-4": "awsui_colspan-4_14yj0_f5atp_228",
  "push-4": "awsui_push-4_14yj0_f5atp_232",
  "pull-4": "awsui_pull-4_14yj0_f5atp_235",
  "colspan-5": "awsui_colspan-5_14yj0_f5atp_238",
  "push-5": "awsui_push-5_14yj0_f5atp_242",
  "pull-5": "awsui_pull-5_14yj0_f5atp_245",
  "colspan-6": "awsui_colspan-6_14yj0_f5atp_248",
  "push-6": "awsui_push-6_14yj0_f5atp_252",
  "pull-6": "awsui_pull-6_14yj0_f5atp_255",
  "colspan-7": "awsui_colspan-7_14yj0_f5atp_258",
  "push-7": "awsui_push-7_14yj0_f5atp_262",
  "pull-7": "awsui_pull-7_14yj0_f5atp_265",
  "colspan-8": "awsui_colspan-8_14yj0_f5atp_268",
  "push-8": "awsui_push-8_14yj0_f5atp_272",
  "pull-8": "awsui_pull-8_14yj0_f5atp_275",
  "colspan-9": "awsui_colspan-9_14yj0_f5atp_278",
  "push-9": "awsui_push-9_14yj0_f5atp_282",
  "pull-9": "awsui_pull-9_14yj0_f5atp_285",
  "colspan-10": "awsui_colspan-10_14yj0_f5atp_288",
  "push-10": "awsui_push-10_14yj0_f5atp_292",
  "pull-10": "awsui_pull-10_14yj0_f5atp_295",
  "colspan-11": "awsui_colspan-11_14yj0_f5atp_298",
  "push-11": "awsui_push-11_14yj0_f5atp_302",
  "pull-11": "awsui_pull-11_14yj0_f5atp_305",
  "colspan-12": "awsui_colspan-12_14yj0_f5atp_308",
  "push-12": "awsui_push-12_14yj0_f5atp_312",
  "pull-12": "awsui_pull-12_14yj0_f5atp_315",
  "push-0": "awsui_push-0_14yj0_f5atp_318",
  "pull-0": "awsui_pull-0_14yj0_f5atp_321",
  "offset-1": "awsui_offset-1_14yj0_f5atp_324",
  "offset-2": "awsui_offset-2_14yj0_f5atp_327",
  "offset-3": "awsui_offset-3_14yj0_f5atp_330",
  "offset-4": "awsui_offset-4_14yj0_f5atp_333",
  "offset-5": "awsui_offset-5_14yj0_f5atp_336",
  "offset-6": "awsui_offset-6_14yj0_f5atp_339",
  "offset-7": "awsui_offset-7_14yj0_f5atp_342",
  "offset-8": "awsui_offset-8_14yj0_f5atp_345",
  "offset-9": "awsui_offset-9_14yj0_f5atp_348",
  "offset-10": "awsui_offset-10_14yj0_f5atp_351",
  "offset-11": "awsui_offset-11_14yj0_f5atp_354",
  "restore-pointer-events": "awsui_restore-pointer-events_14yj0_f5atp_358"
};
  