import { ParseKeys } from 'i18next/typescript/t';
import { FC } from 'react';

import { HelpContent } from './HelpProvider';
import {
  useI18NSummaryHelpContent,
  useSummaryHelpContent,
} from './useSummaryHelpContent';

export type SummaryHelpContentProps = {
  summaryHelpContent: HelpContent | null;
  translationKey: string;
};

export const SummaryHelpContent: FC<SummaryHelpContentProps> = ({
  summaryHelpContent,
  translationKey,
}) => {
  useSummaryHelpContent(translationKey, summaryHelpContent);
  return null;
};

export const I18nSummaryHelpContent: FC<{
  translationKey: ParseKeys<'common'>;
}> = ({ translationKey }) => {
  useI18NSummaryHelpContent(translationKey);
  return null;
};
