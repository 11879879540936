import { FC } from 'react';

import { SelectInput } from '@/components/Form/ControlledSelect/ControlledSelect';
import { OptionWithColor } from '@/components/Form/form-utils';

import { CustomAttributeProps } from './CustomAttributeProps';

interface CustomAttributeSelectProps extends CustomAttributeProps {
  schema?: { enum?: string[] };
}

const emptyOption: OptionWithColor = {
  value: '',
  label: '-',
  color: 'light-grey',
};

export const CustomAttributeSelect: FC<CustomAttributeSelectProps> = ({
  label,
  onChange,
  value,
  disabled,
  error,
  schema,
  description,
}) => {
  const { enum: options = [] } = schema ?? {};
  const selectOptions = options.map((item) => ({
    value: item,
    label: item,
    disabled: false,
  }));
  if (value && !options.includes(value)) {
    selectOptions.push({ value, label: value, disabled: true });
  }
  return (
    <SelectInput
      description={description}
      label={label}
      onChange={(value) =>
        value ? onChange(value.toString()) : onChange(null)
      }
      value={value}
      options={[emptyOption, ...selectOptions]}
      disabled={disabled}
      placeholder="Select"
      errorMessage={error}
    />
  );
};
