import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  ControlGroupFormFieldData,
  defaultValues,
  useControlGroupSchema,
} from 'src/pages/control-groups/update/forms/controlGroupSchema';
import { useHasPermission } from 'src/rbac/Permission';

import { Form } from '@/components/Form';
import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import {
  GetControlByIdQuery,
  Parent_Type_Enum,
  useGetControlGroupByIdQuery,
  useUpdateControlGroupMutation,
} from '@/generated/graphql';
import { evictField } from '@/utils/graphqlUtils';

import ControlGroupFormFields from '../../forms/ControlGroupFormFields';

type Props = {
  control?: GetControlByIdQuery['control'][0];
};

const Tab: FC<Props> = () => {
  useI18NSummaryHelpContent('controlGroups.detailsHelp');
  const controlGroupId = useGetGuidParam('controlGroupId');
  const navigate = useNavigate();
  const { t } = useTranslation(['common']);
  const ControlGroupSchema = useControlGroupSchema(controlGroupId);
  const canEditControlGroup = useHasPermission('update:control_group');
  const { data, error, refetch } = useGetControlGroupByIdQuery({
    variables: { _eq: controlGroupId },
  });
  if (error) {
    throw error;
  }
  const initialValues = data?.control_group[0];

  const [mutate] = useUpdateControlGroupMutation({
    update: (cache) => {
      evictField(cache, 'control_group');
    },
  });

  const onSave = async (data: ControlGroupFormFieldData) => {
    if (!initialValues) {
      throw new Error('Control group not found');
    }
    await mutate({
      variables: {
        ...data,
        OriginalTimestamp: initialValues.ModifiedAtTimestamp,
        Id: initialValues.Id,
        Owner: data.Owner.value,
      },
    });
    await refetch();
  };

  return (
    <Form
      formId={'control-group-form'}
      defaultValues={defaultValues}
      values={
        initialValues
          ? {
              ...initialValues,
              Owner: { value: initialValues.Owner, type: 'user' },
            }
          : undefined
      }
      i18n={t('controlGroups')}
      onSave={onSave}
      onDismiss={() => navigate(-1)}
      schema={ControlGroupSchema}
      readOnly={!canEditControlGroup}
      parentType={Parent_Type_Enum.ControlGroup}
      header={t('details')}
    >
      <ControlGroupFormFields readOnly={!canEditControlGroup} />
    </Form>
  );
};

export default Tab;
