import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAggregation } from 'src/providers/AggregationProvider';
import {
  getAllContributorsCellValue,
  getAllOwnersCellValue,
} from 'src/rbac/contributorHelper';

import { useRating } from '@/hooks/use-rating';
import { useRiskScoreFormatters } from '@/hooks/useRiskScore';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';

import { getAppetitePerformance } from './calculateAppetitePerformance';
import { AppetiteLatestFields, AppetiteTableFields } from './types';

export const useLabelledFields = (
  records: AppetiteLatestFields[] | undefined
) => {
  const { getLabel } = useRating('risk_appetite');
  const { t } = useTranslation(['common']);
  const tiers = t('tiers', { returnObjects: true });
  const { getByValue: getAppetitePerformanceByValue } = useRating(
    'appetite_performance'
  );
  const { t: rt } = useTranslation('ratings');
  const controlledRatings = rt('risk_controlled', { returnObjects: true });

  const { riskModel } = useAggregation();
  const formatters = useRiskScoreFormatters();
  const posture = useIsFeatureVisibleToOrg('posture');

  return useMemo<AppetiteTableFields[] | undefined>(() => {
    const risksWithAppetite = records?.filter((r) => r.appetites?.length > 0);

    return risksWithAppetite?.map((d) => {
      let controlledRating = null;

      if (riskModel === 'default') {
        controlledRating =
          d.assessmentResults.length > 0
            ? d.assessmentResults[0].riskAssessmentResult?.Rating
            : null;
      } else {
        controlledRating = d.riskScore?.ResidualScore;
      }

      // We filter out risks without appetites above
      const appetite = d.appetites[0].appetite!;

      const performance = getAppetitePerformance({
        ...appetite,
        controlledRating,
        controlledRatings,
        useRanges: riskModel !== 'default',
        posture,
      });

      return {
        ...d,
        TierLabelled: tiers[String(d.Tier) as keyof typeof tiers],
        ControlledRating: controlledRating,
        ParentTitle: d.Title,
        LowerAppetite: appetite.LowerAppetite,
        UpperAppetite: appetite.UpperAppetite,
        Performance: performance,
        ParentRiskId: d.Id,
        Statement: appetite.Statement,
        LowerAppetiteLabelled: appetite.LowerAppetite
          ? getLabel(appetite.LowerAppetite)
          : 'Undefined',
        UpperAppetiteLabelled: appetite.UpperAppetite
          ? getLabel(appetite.UpperAppetite)
          : 'Undefined',
        ControlledRatingLabelled:
          formatters({
            residualRating: controlledRating,
          }).getResidualLabel() ?? 'Undefined',
        PerformanceLabelled:
          getAppetitePerformanceByValue(performance)?.label ?? 'Undefined',
        CreatedAtTimestamp: appetite.CreatedAtTimestamp,
        ModifiedAtTimestamp: appetite.ModifiedAtTimestamp,
        ModifiedByUserName: appetite.modifiedByUser?.FriendlyName ?? null,
        ModifiedByUser: appetite.ModifiedByUser,
        CustomAttributeData: appetite.CustomAttributeData,
        allOwners: getAllOwnersCellValue(d),
        allContributors: getAllContributorsCellValue(d),
      };
    });
  }, [
    getAppetitePerformanceByValue,
    getLabel,
    records,
    tiers,
    formatters,
    riskModel,
    controlledRatings,
    posture,
  ]);
};
