import i18n from 'src/i18n';

import { auditItemSearch } from '@/utils/urls';

import { GetItem } from './types';

export const getItem: GetItem = (item, lookupData) => {
  const controlId = item.data?.objectId;
  const control = lookupData.controls?.[controlId];
  return {
    message: i18n.t('notifications.messages.controlDelete', {
      title: control?.Title ?? i18n.t('notifications.unknown'),
    }),
    url: control ? auditItemSearch(control.Id) : null,
  };
};
