import { useNotifications } from '@risksmart-app/components/Notifications/notification-context';
import { FieldValues } from 'react-hook-form';

import { Parent_Type_Enum, useGetRiskListQuery } from '@/generated/graphql';
import { getFriendlyId } from '@/utils/friendlyId';

import { ControlledBaseProps } from '..';
import ControlledSelect from '../ControlledSelect';

interface Props<T extends FieldValues> extends ControlledBaseProps<T> {
  addEmptyOption?: boolean;
  disabled?: boolean;
  single?: boolean;
  testId?: string;
}

export const ControlledRiskSelect = <T extends FieldValues>({
  ...props
}: Props<T>) => {
  const { addNotification } = useNotifications();

  const { data, loading } = useGetRiskListQuery({
    fetchPolicy: 'no-cache',
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });

  const options =
    data?.node.map((r) => ({
      value: r.Id,
      label:
        r.risk?.Title ?? getFriendlyId(Parent_Type_Enum.Risk, r.SequentialId),
    })) ?? [];

  return (
    <ControlledSelect
      statusType={loading ? 'loading' : 'finished'}
      {...props}
      options={options}
      filteringType="auto"
    />
  );
};
