import {
  RadioGroup,
  RadioGroupProps,
} from '@cloudscape-design/components-themed';
import { FormField } from '@risksmart-app/web/src/components/Form/Form/FormField';
import { useMemo } from 'react';
import { FieldValues } from 'react-hook-form';

import HelpLink from '@/components/HelpPanel/HelpLink';

import { ControlledBaseProps } from '..';
import { Controller } from '../FieldController/Controller';
import { useIsFieldReadOnly } from '../Form/CustomisableForm/hooks/useIsFieldReadOnly';
import styles from './style.module.scss';

export interface ControlledRadioGroupProps<T extends FieldValues, TOutput>
  extends ControlledBaseProps<T> {
  items: RadioGroupProps['items'] | undefined;
  transform: {
    input: (value: TOutput) => string;
    output: (value: string) => TOutput;
  };
  onChange?: (item: TOutput) => void;
  disabled?: boolean;
  testId?: string;
  hideLabel?: boolean;
}

export type Transform<TOutput> = {
  input: (value: TOutput) => string;
  output: (value: string) => TOutput;
};

export const noTransform: Transform<string> = {
  input: function (value) {
    return value;
  },
  output: function (value) {
    return value;
  },
};
export const numberTransform: Transform<number> = {
  input: function (value) {
    return value?.toString() ?? '';
  },
  output: function (value) {
    return Number(value);
  },
};

export const ControlledRadioGroup = <T extends FieldValues, TOutput = string>({
  name,
  control,
  label,
  hideLabel,
  items,
  transform,
  onChange,
  disabled,
  forceRequired,
  defaultRequired,
  testId,
  description,
  ...props
}: ControlledRadioGroupProps<T, TOutput>) => {
  const { error } = control.getFieldState(name);
  const readOnly = useIsFieldReadOnly(name);
  const xItems = useMemo<RadioGroupProps.RadioButtonDefinition[] | undefined>(
    () => items?.map((i) => ({ ...i, disabled: disabled || readOnly })),
    [disabled, readOnly, items]
  );
  return (
    <Controller
      name={name}
      defaultRequired={defaultRequired}
      control={control}
      forceRequired={forceRequired}
      render={({ field: { ref, onChange: formOnChange, value } }) => (
        <div className={styles.radioGroup}>
          {hideLabel ? (
            <RadioGroup
              ref={ref}
              name={name}
              items={xItems}
              value={transform.input(value)}
              onChange={(e) => {
                const value = transform.output(e.detail.value);
                formOnChange(value);
                onChange?.(value);
              }}
              {...props}
            />
          ) : (
            <FormField
              label={label}
              errorText={error?.message}
              stretch
              testId={testId}
              info={
                description && (
                  <HelpLink title={label} content={description} id={label} />
                )
              }
            >
              <RadioGroup
                ref={ref}
                name={name}
                items={xItems}
                value={transform.input(value)}
                onChange={(e) => {
                  const value = transform.output(e.detail.value);
                  formOnChange(value);
                  onChange?.(value);
                }}
                {...props}
              />
            </FormField>
          )}
        </div>
      )}
    />
  );
};
