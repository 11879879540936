import { Cost_Type_Enum } from '@/generated/graphql';

export const calculateCostTotal = (
  consequences: { CostType: Cost_Type_Enum; CostValue: number }[],
  costType: Cost_Type_Enum
) =>
  consequences
    .filter((c) => c.CostType === costType)
    .reduce((previous, current) => previous + current.CostValue, 0);

export const getCost = (
  consequence: { CostType: Cost_Type_Enum; CostValue: number },
  costType: Cost_Type_Enum
) => {
  if (consequence.CostType === costType) {
    return consequence.CostValue;
  }
  return 0;
};
