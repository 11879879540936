import { useCollection } from '@cloudscape-design/collection-hooks';
import { SpaceBetween } from '@cloudscape-design/components-themed';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/notification-context';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import Table from '@risksmart-app/components/Table';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Permission, useHasPermission } from 'src/rbac/Permission';

import DeleteModal from '@/components/DeleteModal/DeleteModal';
import EmptyEntityCollection from '@/components/EmptyCollection/EmptyEntityCollection';
import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import TabHeader from '@/components/TabHeader';
import {
  GetObligationByIdQuery,
  useDeleteImpactsMutation,
  useGetObligationImpactsByParentIdQuery,
} from '@/generated/graphql';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { evictField } from '@/utils/graphqlUtils';

import ImpactModel from '../../../modals/ImpactModal';
import { ImpactFields, usePerformanceColumnDefinitions } from './config';

type Props = {
  obligation: GetObligationByIdQuery['obligation'][number];
};

const Tab: FC<Props> = ({ obligation }) => {
  useI18NSummaryHelpContent('impacts.tabHelp');
  const { t } = useTranslation(['common']);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const obligationId = useGetGuidParam('obligationId');
  const [isEditOpen, setIsEditOpen] = useState(false);
  const { addNotification } = useNotifications();

  const [selectedImpacts, setSelectedImpacts] = useState<ImpactFields[]>([]);

  const [openImpactId, setOpenImpactId] = useState<string | undefined>();

  const userCanDeleteImpacts = useHasPermission(
    'delete:obligation_impact',
    obligation
  );

  const [deleteImpacts, deleteResult] = useDeleteImpactsMutation({
    update: (cache) => evictField(cache, 'obligation_impact'),
  });

  const { data, loading, refetch } = useGetObligationImpactsByParentIdQuery({
    variables: {
      _eq: obligationId,
    },
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });

  const handleImpactModalOpen = () => {
    setIsEditOpen(true);
  };

  const handleImpactModalClose = () => {
    setOpenImpactId(undefined);
    setIsEditOpen(false);
  };

  const impactColumnDefinitions = usePerformanceColumnDefinitions((impact) => {
    setOpenImpactId(impact.Id);
    setIsEditOpen(true);
  });

  const onDelete = useDeleteResultNotification({
    entityName: t('impacts.entity_name'),
    failureAction: () => {
      setIsDeleteModalVisible(false);
    },
    asyncAction: async () => {
      await deleteImpacts({
        variables: {
          Ids: selectedImpacts.map((impact) => impact.Id),
        },
      });
      setSelectedImpacts([]);
      setIsDeleteModalVisible(false);
      return true;
    },
  });

  const labelledFields = useMemo<ImpactFields[]>(() => {
    return data?.obligation_impact || [];
  }, [data?.obligation_impact]);

  const { items, collectionProps } = useCollection(labelledFields, {
    propertyFiltering: {
      filteringProperties: [],
      empty: (
        <EmptyEntityCollection
          entityLabel={t('impacts.entity_name')}
          action={
            <Permission
              permission="insert:obligation_impact"
              parentObject={obligation}
            >
              <Button formAction="none" onClick={handleImpactModalOpen}>
                {t('impacts.create_new_button')}
              </Button>
            </Permission>
          }
        />
      ),
    },
    sorting: {
      defaultState: {
        sortingColumn: impactColumnDefinitions[0],
      },
    },
  });

  return (
    <>
      <Table
        {...collectionProps}
        selectionType="multi"
        selectedItems={selectedImpacts}
        trackBy="Id"
        onSelectionChange={({ detail }) => {
          setSelectedImpacts(detail.selectedItems);
        }}
        resizableColumns={true}
        header={
          <SpaceBetween size="m">
            <TabHeader
              variant="h2"
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Permission
                    permission="delete:obligation_impact"
                    parentObject={obligation}
                  >
                    <Button
                      formAction="none"
                      variant="normal"
                      disabled={
                        !selectedImpacts.length || !userCanDeleteImpacts
                      }
                      onClick={() => setIsDeleteModalVisible(true)}
                    >
                      {t('impacts.delete_button')}
                    </Button>
                  </Permission>
                  <Permission
                    permission="insert:obligation_impact"
                    parentObject={obligation}
                  >
                    <Button
                      variant="primary"
                      formAction="none"
                      onClick={handleImpactModalOpen}
                    >
                      {t('impacts.create_new_button')}
                    </Button>
                  </Permission>
                </SpaceBetween>
              }
            >
              {t('impacts.tab_title')}
            </TabHeader>
          </SpaceBetween>
        }
        variant="embedded"
        loading={loading}
        columnDefinitions={impactColumnDefinitions}
        items={items}
        loadingText={t('impacts.loading_message') ?? ''}
        sortingDisabled={false}
      />
      {isEditOpen && obligationId && (
        <ImpactModel
          Id={openImpactId}
          onDismiss={handleImpactModalClose}
          parentObligationId={obligationId}
        />
      )}
      <DeleteModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        header={t('impacts.delete_modal_title')}
        onDelete={onDelete}
        onDismiss={() => {
          refetch();
          setOpenImpactId(undefined);
          setSelectedImpacts([]);
          setIsDeleteModalVisible(false);
        }}
      >
        {t('impacts.confirm_delete_message')}
      </DeleteModal>
    </>
  );
};

export default Tab;
