import { useTranslation } from 'react-i18next';
import { MAX_COL_WIDTH } from 'src/App.config';

import { CustomAttributeFields } from '@/components/Form/CustomAttributes/CustomAttributeSchema';
import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import { useRating } from '@/hooks/use-rating';
import { useGetContributorsFieldConfig } from '@/utils/table/hooks/useGetContributorsFieldConfig';
import { useGetDepartmentFieldConfig } from '@/utils/table/hooks/useGetDepartmentFieldConfig';
import { useGetOwnersFieldConfig } from '@/utils/table/hooks/useGetOwnersFieldConfig';
import {
  StatefulTableOptions,
  useGetStatelessTableProps,
  UseGetTablePropsOptions,
} from '@/utils/table/hooks/useGetStatelessTableProps';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import { useGetTagFieldConfig } from '@/utils/table/hooks/useGetTagFieldConfig';
import { TableFields, TablePropsWithActions } from '@/utils/table/types';
import { dateColumn } from '@/utils/table/utils/dateColumn';
import { controlDetailsUrl } from '@/utils/urls';

import { ControlFlatFields, ControlTableFields } from './types';
import { useLabelledFields } from './useLabelledFields';

const useGetFieldConfig = (): TableFields<ControlTableFields> => {
  const allOwners = useGetOwnersFieldConfig<ControlTableFields>();
  const allContributors = useGetContributorsFieldConfig<ControlTableFields>();
  const tagField = useGetTagFieldConfig<ControlTableFields>();
  const { getByValue } = useRating('effectiveness');
  const departmentField = useGetDepartmentFieldConfig<ControlTableFields>();
  const { t: stc } = useTranslation(['common'], { keyPrefix: 'columns' });
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'controls.columns',
  });
  return {
    SequentialIdLabel: { header: stc('id'), sortingField: 'SequentialId' },
    Title: {
      header: st('title'),
      cell: (item) => (
        <Link variant="secondary" href={controlDetailsUrl(item.Id)}>
          {item.Title}
        </Link>
      ),
      maxWidth: MAX_COL_WIDTH,
      isRowHeader: true,
    },
    Type: {
      header: st('type'),
      cell: (item) => item.Type || 'None',
    },
    ParentTitle: {
      header: st('parent_title'),
      cell: (item) => {
        if (!item.ParentTitle) {
          return '-';
        }
        return `${item.ParentTitle} (${item.ParentType})`;
      },
      maxWidth: MAX_COL_WIDTH,
      exportVal: (item) => {
        if (!item.ParentTitle) {
          return '';
        }
        return `${item.ParentTitle} (${item.ParentType})`;
      },
    },
    allOwners,
    allContributors,
    DesignEffectivenessLabelled: {
      header: st('design_effectiveness'),
      cell: (item) => {
        return (
          <SimpleRatingBadge rating={getByValue(item.DesignEffectiveness)}>
            {item.DesignEffectivenessLabelled}
          </SimpleRatingBadge>
        );
      },
      exportVal: (item) => getByValue(item.DesignEffectiveness)?.label ?? '',
    },
    PerformanceEffectivenessLabelled: {
      header: st('performance_effectiveness'),
      cell: (item) => {
        return (
          <SimpleRatingBadge rating={getByValue(item.PerformanceEffectiveness)}>
            {item.PerformanceEffectivenessLabelled}
          </SimpleRatingBadge>
        );
      },
      exportVal: (item) =>
        getByValue(item.PerformanceEffectiveness)?.label ?? '',
    },
    OverallEffectivenessLabelled: {
      header: st('effectiveness'),
      cell: (item) => {
        return (
          <SimpleRatingBadge rating={getByValue(item.OverallEffectiveness)}>
            {item.OverallEffectivenessLabelled}
          </SimpleRatingBadge>
        );
      },
      exportVal: (item) => getByValue(item.OverallEffectiveness)?.label ?? '',
    },
    OpenIssues: {
      header: st('open_issues'),
      cell: (item) => item.OpenIssues ?? '-',
      filterOptions: {
        filteringProperties: {
          operators: ['!=', '>', '<', '>=', '<='],
        },
      },
    },
    IssueCount: {
      header: st('issues'),
      cell: (item) => item.IssueCount ?? '-',
      filterOptions: {
        filteringProperties: {
          operators: ['!=', '>', '<', '>=', '<='],
        },
      },
    },

    OpenActions: {
      header: st('open_actions'),
      cell: (item) => item.OpenActions ?? '-',
      filterOptions: {
        filteringProperties: {
          operators: ['!=', '>', '<', '>=', '<='],
        },
      },
    },
    tags: tagField,
    //------------------
    departments: departmentField,
    CreatedAtTimestamp: dateColumn(stc('created_on'), 'CreatedAtTimestamp'),
    Description: {
      header: st('description'),
      cell: (item) => item.Description || '-',
      maxWidth: MAX_COL_WIDTH,
    },
    Id: {
      header: stc('guid'),
    },
    TestFrequency: {
      header: st('test_frequency'),
      cell: (item) => {
        return item.TestFrequency ?? '-';
      },
    },
    ModifiedAtTimestamp: dateColumn(stc('updated_on'), 'ModifiedAtTimestamp'),
    CreatedByUser: {
      header: stc('created_by_id'),
    },
    CreatedByUserName: {
      header: stc('created_by_username'),
    },
    ParentType: {
      header: st('parent_type'),
    },
    LatestRatingDate: dateColumn(st('latest_rating_date'), 'LatestRatingDate'),
    NextTestDate: dateColumn(st('next_test_date'), 'NextTestDate'),
  };
};

const useGetControlTableProps = (
  records: ControlFlatFields[] | undefined,
  customAttributeSchema?: CustomAttributeFields | null
): UseGetTablePropsOptions<ControlTableFields> => {
  const { t: st } = useTranslation(['common'], { keyPrefix: 'controls' });
  const fields = useGetFieldConfig();

  const labelledFields = useLabelledFields(records);

  return {
    data: labelledFields,
    customAttributeSchema,
    entityLabel: st('entity_name'),
    emptyCollectionAction: <></>,
    storageKey: 'ControlRegisterTable-PreferencesV2',
    enableFiltering: true,
    initialColumns: [
      'Title',
      'Type',
      'ParentTitle',
      'allOwners',
      'OverallEffectivenessLabelled',
      'OpenIssues',
      'OpenActions',
      'tags',
    ],
    fields,
  };
};

export const useGetCollectionTableProps = (
  records: ControlFlatFields[] | undefined,
  customAttributeSchema?: CustomAttributeFields | null
): TablePropsWithActions<ControlTableFields> => {
  const props = useGetControlTableProps(records, customAttributeSchema);
  return useGetTableProps(props);
};

export const useGetControlSmartWidgetTableProps = (
  records: ControlFlatFields[] | undefined,
  customAttributeSchema: CustomAttributeFields | null,
  statefulTableOptions: StatefulTableOptions<ControlTableFields>
): TablePropsWithActions<ControlTableFields> => {
  const props = useGetControlTableProps(records, customAttributeSchema);
  return useGetStatelessTableProps<ControlTableFields>({
    ...props,
    ...statefulTableOptions,
    enableFiltering: false,
  });
};
