import {
  Container,
  ExpandableSection,
  Spinner,
  TextContent,
} from '@cloudscape-design/components-themed';
import parse from 'html-react-parser';
import { FC, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import sanitizeHtml from 'sanitize-html';

import ControlledDatePicker from '@/components/Form/ControlledDatePicker';
import ControlledGroupAndUserSelect from '@/components/Form/ControlledGroupAndUserSelect';
import ControlledRadioGroup from '@/components/Form/ControlledRadioGroup';
import { numberTransform } from '@/components/Form/ControlledRadioGroup/ControlledRadioGroup';
import CustomisableForm from '@/components/Form/Form/CustomisableForm/CustomisableForm';
import { useRiskSmartForm } from '@/components/Form/Form/CustomisableForm/RiskSmartFormContext';
import { GetImpactListQuery, useGetImpactListQuery } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';

import { ImpactRatingsFormFieldData } from './MultipleImpactRatingsFormSchema';
import RadioBar from './RadioBar/RadioBar';
import styles from './style.module.scss';

export enum TestIds {
  ImpactId = 'ImpactId',
  RatedItemId = 'RatedItemId',
  CompletedBy = 'CompletedBy',
  Ratings = 'Ratings',
  TestDate = 'TestDate',
  Likelihood = 'Likelihood',
}

const MultipleImpactRatingsFormFields: FC = () => {
  const { control } = useFormContext<ImpactRatingsFormFieldData>();
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'impactRatingsMultiple',
  });

  const { data: impacts, loading } = useGetImpactListQuery();
  const [sortedImpacts, setSortedImpacts] =
    useState<GetImpactListQuery['impact']>();
  const [selectedImpactId, setSelectedImpactId] = useState<string | null>(null);
  const [hasRatingGuidance, setHasRatingGuidance] = useState<boolean>(false);

  useEffect(() => {
    if (impacts?.impact) {
      const impactsCopy = [...impacts.impact];
      const sortedImpacts = impactsCopy.sort((a, b) => {
        return a.SequentialId - b.SequentialId;
      });

      setSortedImpacts(sortedImpacts);
      if (sortedImpacts.length > 0) {
        setSelectedImpactId(sortedImpacts[0].Id);
      }
    }
  }, [impacts]);

  const selectedImpact = useMemo(() => {
    return impacts?.impact.find((impact) => impact.Id === selectedImpactId);
  }, [selectedImpactId, impacts]);

  const sanitisedRatingGuidance = useMemo(() => {
    setHasRatingGuidance(!!selectedImpact?.RatingGuidance);

    return parse(sanitizeHtml(selectedImpact?.RatingGuidance ?? ''));
  }, [selectedImpact]);

  const { options } = useRating('impact');
  const ratingsOptions = options.map((option) => ({
    ...option,
    value: String(option.value),
  }));

  const { options: likelihoodOptions } = useRating('likelihood');
  const likelihoodRadioOptions = likelihoodOptions.map((option) => ({
    ...option,
    value: String(option.value),
  }));

  const { editMode } = useRiskSmartForm();
  const editModePadding = editMode ? 'p-0' : 'pb-6';

  return (
    <>
      {loading ? (
        <div className={'flex w-full h-[53vh] items-center justify-center'}>
          <Spinner size="large" />
        </div>
      ) : (
        <CustomisableForm>
          <ControlledGroupAndUserSelect
            testId={TestIds.CompletedBy}
            key="completion-by"
            defaultRequired={true}
            name="CompletedBy"
            label={st('fields.CompletedBy')}
            description={st('fields.CompletedBy_help')}
            control={control}
            includeGroups={false}
            addEmptyOption={true}
          />

          <ControlledDatePicker
            testId={TestIds.TestDate}
            key="testDate"
            forceRequired={true}
            name="TestDate"
            label={st('fields.TestDate')}
            description={st('fields.TestDate_help')}
            control={control}
          />
          <div
            className="pb-5 mb-5 border-0 border-b-[1.5px] border-solid border-grey150"
            key="Likelihood"
          >
            <RadioBar label={st('fields.Likelihood')}>
              <ControlledRadioGroup<ImpactRatingsFormFieldData, number>
                testId={TestIds.Likelihood}
                label=""
                hideLabel={true}
                forceRequired={true}
                name="Likelihood"
                control={control}
                transform={numberTransform}
                items={likelihoodRadioOptions}
              />
            </RadioBar>
          </div>
          <div
            className={`flex flex-col w-full ${editModePadding} gap-y-6`}
            key="ratings"
          >
            <div className={styles.radioList}>
              {sortedImpacts &&
                sortedImpacts.map((impact, index) => (
                  <RadioBar
                    label={impact.Name}
                    onClick={() => setSelectedImpactId(impact.Id)}
                    key={`impact-rating-${index}`}
                  >
                    <ControlledRadioGroup<ImpactRatingsFormFieldData, number>
                      testId={`${TestIds.Ratings}-${index}`}
                      key={`impact-rating-${index}`}
                      label=""
                      hideLabel={true}
                      forceRequired={true}
                      name={`Ratings.${index}.Rating`}
                      control={control}
                      transform={numberTransform}
                      items={ratingsOptions}
                    />
                  </RadioBar>
                ))}
            </div>
            {selectedImpact ? (
              <Container>
                <div className={styles.expandable}>
                  <ExpandableSection
                    headerText={`${st('rating_guidance_description')}: ${selectedImpact.Name}`}
                    defaultExpanded={true}
                  >
                    <div className={'pt-4 px-3'}>
                      <TextContent>
                        {hasRatingGuidance
                          ? sanitisedRatingGuidance
                          : st('no_rating_guidance')}
                      </TextContent>
                    </div>
                  </ExpandableSection>
                </div>
              </Container>
            ) : null}
          </div>
        </CustomisableForm>
      )}
    </>
  );
};

export default MultipleImpactRatingsFormFields;
