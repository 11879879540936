import { withAuthenticationRequired } from '@auth0/auth0-react';
import { NavMenuProvider } from '@risksmart-app/components/Navigation/NavMenuProvider';
import { ToolsProvider } from '@risksmart-app/components/Tools/ToolsProvider';
import { Navigate, Outlet, ScrollRestoration } from 'react-router-dom';
import { FeaturesProvider } from 'src/rbac/FeatureProvider';
import { PermissionsProvider } from 'src/rbac/PermissionProvider';
import { TaxonomyProvider } from 'src/taxonomy/TaxonomyProvider';

import { MessagesRequester } from '@/components/Messages/MessageRequester';
import { MessagesProvider } from '@/components/Messages/MessagesProvider';
import useRisksmartUser from '@/hooks/useRisksmartUser';
import { isUserInOrganization } from '@/utils/authUtils';
import { logoutUrl } from '@/utils/urls';

function ProtectedLayout() {
  const { user } = useRisksmartUser();

  if (!isUserInOrganization(user)) {
    console.warn('User is not part of an organisation, logging out');
    return <Navigate to={logoutUrl()} />;
  }

  return (
    <FeaturesProvider>
      <PermissionsProvider>
        <NavMenuProvider>
          <ToolsProvider>
            <TaxonomyProvider>
              <MessagesProvider>
                <MessagesRequester>
                  <>
                    <ScrollRestoration />
                    <Outlet />
                  </>
                </MessagesRequester>
              </MessagesProvider>
            </TaxonomyProvider>
          </ToolsProvider>
        </NavMenuProvider>
      </PermissionsProvider>
    </FeaturesProvider>
  );
}

export default withAuthenticationRequired(ProtectedLayout);
