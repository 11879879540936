import { useMemo } from 'react';

import { Risk_Assessment_Result_Control_Type_Enum } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { useRiskScore } from '@/hooks/useRiskScore';

import { useControlTypeLabel } from '../../../../assessments/forms/RiskAssessmentResultFormFields';
import { UNRATED } from '../../../../controls/lookupData';
import {
  InternalAuditRiskAssessmentResultFlatFields,
  InternalAuditRiskAssessmentResultRegisterFields,
} from './types';

export const useInternalAuditRatingLabelledFields = (
  riskId: string,
  records: InternalAuditRiskAssessmentResultFlatFields[] | undefined
) => {
  const statusRating = useRating('assessment_status');
  const getControlTypeLabel = useControlTypeLabel();
  const uncontrolledRiskRating = useRating('risk_uncontrolled');
  const controlledRiskRating = useRating('risk_controlled');
  const likelihoodRating = useRating('likelihood');
  const impactRating = useRating('impact');
  const riskScores = useRiskScore(riskId);
  return useMemo<InternalAuditRiskAssessmentResultRegisterFields[]>(() => {
    return (
      records?.map((d) => {
        const InternalAuditReport = d.parents.find(
          (p) => p.internalAuditReport
        )?.internalAuditReport;

        return {
          ...d,
          StatusLabelled:
            InternalAuditReport !== undefined
              ? statusRating.getLabel(InternalAuditReport?.Status) ?? '-"'
              : '-;',
          NextTestDate: InternalAuditReport?.NextTestDate ?? '-',
          ActualCompletionDate:
            InternalAuditReport?.ActualCompletionDate ?? '-',
          ParentTitle: InternalAuditReport?.Title ?? '-',
          ControlTypeLabelled:
            getControlTypeLabel(d.ControlType) || UNRATED.label,
          CompletionDate: InternalAuditReport?.ActualCompletionDate ?? '-',
          RatingLabelled: !riskScores.showScore
            ? d.ControlType ===
              Risk_Assessment_Result_Control_Type_Enum.Controlled
              ? controlledRiskRating.getLabel(d.Rating)
              : uncontrolledRiskRating.getLabel(d.Rating) ?? UNRATED.label
            : '-',
          ImpactLabelled: impactRating.getLabel(d.Impact) ?? UNRATED.label,
          LikelihoodLabelled:
            likelihoodRating.getLabel(d.Likelihood) ?? UNRATED.label,
        };
      }) || []
    );
  }, [
    records,
    statusRating,
    getControlTypeLabel,
    controlledRiskRating,
    uncontrolledRiskRating,
    impactRating,
    likelihoodRating,
    riskScores,
  ]);
};
