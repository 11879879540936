import { JsonSchema7, UISchemaElement } from '@jsonforms/core';

import TableCollectionRenderers, {
  jsonFormsDispatchRenderer,
} from './Renderers/CollectionLayouts/TableCollectionRenderers';

export const getCustomAttributeCollections = (
  schema: JsonSchema7,
  uiSchema: UISchemaElement
) => {
  const renderers = TableCollectionRenderers;
  return jsonFormsDispatchRenderer({
    renderers,
    rootSchema: schema,
    schema,
    uischema: uiSchema,
  });
};
