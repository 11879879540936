import i18n from 'i18next';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useHasPermissionLazy } from 'src/rbac/Permission';

import ControlledInput from '@/components/Form/ControlledInput';
import ControlledSelect from '@/components/Form/ControlledSelect';
import { ControlledSwitch } from '@/components/Form/ControlledSwitch/ControlledSwitch';
import { FormField } from '@/components/Form/Form/FormField';
import { useIsFeatureVisibleToOrgLazy } from '@/utils/featureFlags';

import { WidgetDataSource } from '../../Gigawidget/types';
import { dataSources } from '../dataSources';
import { Precision, SettingsSchema } from '../settingsSchema';
import { chartTypeLabels, datePrecisionLabels } from '../util';
import { CategorySelector } from './CategorySelector';
import { WidgetAggregateField } from './WidgetAggregateField';
import { WidgetPropertyFilter } from './WidgetPropertyFilter';

export enum TestIds {
  Datasource = 'dataSource',
  ChartType = 'chartType',
  Filtering = 'filtering',
  Category = 'category',
  SubCategory = 'subCategory',
  DatePrecision = 'datePrecision',
  AggregateFunction = 'aggregateFunction',
  AggregateField = 'aggregateField',
}

export const UniversalWidgetSettingsFormFields = () => {
  const { control, watch, setValue } = useFormContext<SettingsSchema>();
  const hasPermission = useHasPermissionLazy();
  const isFeatureVisibleToOrg = useIsFeatureVisibleToOrgLazy();
  const customTitle = watch('customTitle');
  const customUnit = watch('customUnit');
  const selectedDataSource = watch('dataSource') as keyof typeof dataSources;
  const chartType = watch('chartType');
  const aggregationType = watch('aggregationType');
  const categoryGetter = watch('categoryGetter');
  const categoryGetters = (dataSources[selectedDataSource] as WidgetDataSource)
    ?.categoryGetters;
  const chosenCategory = categoryGetters?.find(
    (cg) => cg.id === categoryGetter
  );

  useEffect(() => {
    if (!['bar', 'stacked-bar'].includes(chartType)) {
      setValue('subCategoryGetter', '');
    }
  }, [chartType, setValue]);

  useEffect(() => {
    if (!chosenCategory?.date) {
      setValue('precision', undefined);
    }
  }, [chosenCategory, setValue]);

  return (
    <div>
      <ControlledSelect
        testId={TestIds.Datasource}
        placeholder={'Select data source'}
        options={Object.entries(dataSources)
          .map(([value, ds]) => ({
            value,
            label: i18n.format(i18n.t(ds.entityNamePlural), 'capitalizeAll'),
            hasAccess: ds.hasAccess(hasPermission, isFeatureVisibleToOrg),
          }))
          .filter((a) => a.hasAccess)
          .sort((a, b) => a.label.localeCompare(b.label))}
        name={'dataSource'}
        label={'Data source'}
        control={control}
        disabled={!!dataSources[selectedDataSource]}
      />

      {dataSources[selectedDataSource] ? (
        <>
          <ControlledSelect
            testId={TestIds.ChartType}
            options={Object.keys(chartTypeLabels).map((value) => ({
              value,
              ...chartTypeLabels[value as keyof typeof chartTypeLabels],
            }))}
            name={'chartType'}
            label={'Chart type'}
            placeholder={'Select chart type'}
            control={control}
          />

          <Controller
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormField
                testId={TestIds.Filtering}
                label={'Filtering (optional)'}
              >
                <WidgetPropertyFilter
                  value={value}
                  onChange={onChange}
                  dataSource={dataSources[selectedDataSource]}
                />
              </FormField>
            )}
            name={'filtering'}
          />

          {['bar', 'stacked-bar', 'pie', 'donut'].includes(chartType) && (
            <CategorySelector
              testId={TestIds.Category}
              dataSource={dataSources[selectedDataSource]}
              name={'categoryGetter'}
              label={'Category'}
              control={control}
              includeDateValues={true}
            />
          )}

          {chosenCategory?.date ? (
            <ControlledSelect
              placeholder={'Select date precision'}
              options={Precision.map((value) => ({
                value,
                label: datePrecisionLabels[value],
              }))}
              name={'precision'}
              label={'Date Precision (optional)'}
              control={control}
            />
          ) : null}

          {['bar', 'stacked-bar'].includes(chartType) && (
            <CategorySelector
              testId={TestIds.SubCategory}
              dataSource={dataSources[selectedDataSource]}
              name={'subCategoryGetter'}
              // TODO: translation
              label={'Sub category (optional)'}
              control={control}
              addEmptyOption={true}
            />
          )}

          {['bar', 'stacked-bar', 'pie', 'donut', 'kpi'].includes(
            chartType
          ) && (
            <ControlledSelect
              // TODO: translation
              placeholder={'Aggregate function'}
              testId={TestIds.AggregateFunction}
              options={[
                { value: 'count', label: 'Count' },
                { value: 'sum', label: 'Sum' },
                { value: 'mean', label: 'Mean' },
                { value: 'max', label: 'Max' },
                { value: 'min', label: 'Min' },
              ]}
              name={'aggregationType'}
              // TODO: translation
              label={'Aggregate function'}
              control={control}
            />
          )}

          {['sum', 'mean', 'max', 'min'].includes(aggregationType) && (
            <WidgetAggregateField
              testId={TestIds.AggregateField}
              control={control}
              dataSource={dataSources[selectedDataSource]}
              name="aggregationField"
              label="Aggregation field"
            />
          )}
          <div className={'mb-4'}>
            <ControlledSwitch
              name={'showFilters'}
              label={'Show Inline Filters'}
              control={control}
            />
          </div>
          <div className={'mb-4'}>
            <ControlledSwitch
              name={'ignoreDashboardDateFilter'}
              label={'Ignore Dashboard Date Filter'}
              control={control}
            />
          </div>
          <div className={'mb-4'}>
            <ControlledSwitch
              name={'customTitle'}
              label={'Custom Title'}
              control={control}
            />
          </div>
          {customTitle && (
            <ControlledInput
              control={control}
              name={'title'}
              label={'Custom Title'}
            />
          )}
          {chartType === 'kpi' && (
            <>
              <div className={'mb-4'}>
                <ControlledSwitch
                  name={'customUnit'}
                  label={'Custom Unit'}
                  control={control}
                />
              </div>
              {customUnit && (
                <ControlledInput
                  control={control}
                  name={'unit'}
                  label={'Custom Unit'}
                />
              )}
            </>
          )}
        </>
      ) : null}
    </div>
  );
};
