
    import './styles.scoped.css';
    export default {
  "selectable-item": "awsui_selectable-item_15o6u_sgzb1_143",
  "pad-bottom": "awsui_pad-bottom_15o6u_sgzb1_165",
  "has-background": "awsui_has-background_15o6u_sgzb1_172",
  "highlighted": "awsui_highlighted_15o6u_sgzb1_175",
  "selected": "awsui_selected_15o6u_sgzb1_175",
  "disabled": "awsui_disabled_15o6u_sgzb1_193",
  "next-item-selected": "awsui_next-item-selected_15o6u_sgzb1_203",
  "is-keyboard": "awsui_is-keyboard_15o6u_sgzb1_215",
  "parent": "awsui_parent_15o6u_sgzb1_219",
  "interactiveGroups": "awsui_interactiveGroups_15o6u_sgzb1_223",
  "child": "awsui_child_15o6u_sgzb1_239",
  "virtual": "awsui_virtual_15o6u_sgzb1_254",
  "measure-strut": "awsui_measure-strut_15o6u_sgzb1_265",
  "measure-strut-first": "awsui_measure-strut-first_15o6u_sgzb1_274",
  "screenreader-content": "awsui_screenreader-content_15o6u_sgzb1_278",
  "option-content": "awsui_option-content_15o6u_sgzb1_284"
};
  