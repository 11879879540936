import { TabsProps } from '@cloudscape-design/components-themed';

import Tab from './tabs/Tab';

export const useTabs = ({
  appetiteId,
  parentId,
}: {
  appetiteId?: string;
  parentId?: string;
}) => {
  const tabs: TabsProps.Tab[] = [
    {
      // TODO translations
      label: 'Details',
      id: 'details',
      content: <Tab Id={appetiteId} ParentId={parentId} />,
    },
  ];

  return tabs;
};
