import { useTranslation } from 'react-i18next';

import { CustomAttributeFields } from '@/components/Form/CustomAttributes/CustomAttributeSchema';
import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import { useRating } from '@/hooks/use-rating';
import { useGetContributorsFieldConfig } from '@/utils/table/hooks/useGetContributorsFieldConfig';
import { useGetDepartmentFieldConfig } from '@/utils/table/hooks/useGetDepartmentFieldConfig';
import { useGetOwnersFieldConfig } from '@/utils/table/hooks/useGetOwnersFieldConfig';
import { UseGetTablePropsOptions } from '@/utils/table/hooks/useGetStatelessTableProps';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import { useGetTagFieldConfig } from '@/utils/table/hooks/useGetTagFieldConfig';
import { TableFields, TablePropsWithActions } from '@/utils/table/types';
import { dateColumn } from '@/utils/table/utils/dateColumn';
import { internalAuditDetailsUrl } from '@/utils/urls';

import { MAX_COL_WIDTH } from '../../App.config';
import { InternalAuditFields, InternalAuditRegisterFields } from './types';
import { useLabelledFields } from './useLabelledFields';

export const useGetFieldConfig =
  (): TableFields<InternalAuditRegisterFields> => {
    const { t } = useTranslation('common', {
      keyPrefix: `internalAudits.columns`,
    });
    const allOwners = useGetOwnersFieldConfig<InternalAuditRegisterFields>();
    const allContributors =
      useGetContributorsFieldConfig<InternalAuditRegisterFields>();
    const tagField = useGetTagFieldConfig<InternalAuditRegisterFields>();
    const departmentField =
      useGetDepartmentFieldConfig<InternalAuditRegisterFields>();
    const { t: gt } = useTranslation(['common'], { keyPrefix: 'columns' });
    const { getByValue: statusGetByValue } = useRating(
      'internal_audit_entity_status'
    );
    const { getByValue: getOutcomeLabel } = useRating(
      'internal_audit_report_outcome'
    );
    return {
      Title: {
        header: t('Title'),
        cell: (item) => {
          return (
            <Link variant="secondary" href={internalAuditDetailsUrl(item.Id)}>
              {item.Title}
            </Link>
          );
        },
        isRowHeader: true,
      },
      CreatedAtTimestamp: dateColumn(t('created_on'), 'CreatedAtTimestamp'),
      ModifiedByUser: {
        header: t('updated_by_id'),
      },
      SequentialIdLabel: {
        header: t('id'),
        sortingField: 'SequentialId',
      },
      Id: {
        header: gt('guid'),
      },
      BusinessArea: {
        header: t('BusinessArea'),
      },
      ModifiedAtTimestamp: dateColumn(t('updated_on'), 'ModifiedAtTimestamp'),
      CreatedByUser: {
        header: t('created_by_id'),
      },
      UserName: {
        header: t('created_by_username'),
      },
      ReportStatusLabelled: {
        header: t('ReportStatusLabelled'),
        sortingField: 'ReportStatusLabelled',
        cell: (item) => {
          return (
            <SimpleRatingBadge rating={statusGetByValue(item.ReportStatus)} />
          );
        },
        maxWidth: MAX_COL_WIDTH,
        isRowHeader: true,
      },
      AuditRatingLabelled: {
        header: t('AuditRatingLabelled'),
        sortingField: 'AuditRatingLabelled',
        cell: (item) => {
          return (
            <SimpleRatingBadge rating={getOutcomeLabel(item.AuditRating)} />
          );
        },
      },
      OpenActionCount: {
        header: t('OpenActionCount'),
      },
      OpenIssueCount: {
        header: t('OpenIssueCount'),
      },
      LatestReportDate: dateColumn(t('LatestReportDate'), 'LatestReportDate'),
      allOwners,
      allContributors,
      tags: tagField,
      departments: departmentField,
    };
  };

const useGetProps = (
  records: InternalAuditFields[] | undefined,
  customAttributeSchema?: CustomAttributeFields | null
): UseGetTablePropsOptions<InternalAuditRegisterFields> => {
  const { t: at } = useTranslation('common', { keyPrefix: 'internalAudits' });
  const fields = useGetFieldConfig();
  const labelledFields = useLabelledFields(records);
  return {
    data: labelledFields,
    customAttributeSchema: customAttributeSchema,
    entityLabel: at('entity'),
    emptyCollectionAction: <></>,
    storageKey: 'InternalAuditRegister-Preferences',
    enableFiltering: true,
    initialColumns: [
      'Title',
      'BusinessArea',
      'LatestReportDate',
      'ReportStatusLabelled',
      'AuditRatingLabelled',
      'OpenActionCount',
      'OpenIssueCount',
      'allOwners',
    ],
    fields,
  };
};

export const useGetCollectionTableProps = (
  records: InternalAuditFields[] | undefined,
  customAttributeSchema?: CustomAttributeFields | null
): TablePropsWithActions<InternalAuditRegisterFields> => {
  const props = useGetProps(records, customAttributeSchema);
  return useGetTableProps(props);
};
