import { useMemo } from 'react';
import { Control, FieldValues, Path } from 'react-hook-form';

import ControlledSelect from '@/components/Form/ControlledSelect';
import { convertSchemasToFieldConfigs } from '@/utils/table/utils/customAttributes';

import { useGetWidgetData } from '../../Gigawidget/hooks/useGetWidgetData';
import { WidgetDataSource } from '../../Gigawidget/types';
import {
  getCustomAttributeOptions,
  getDateFieldOptions,
  getStandardFieldOptions,
} from './formHelpers';

type Props<T extends FieldValues, TDataSource extends WidgetDataSource> = {
  dataSource: TDataSource;
  name: Path<T>;
  label: string;
  includeDateValues?: boolean;
  control: Control<T>;
  testId?: string;
  addEmptyOption?: boolean;
};

export const CategorySelector = <
  T extends FieldValues,
  TDataSource extends WidgetDataSource,
>({
  dataSource,
  label,
  name,
  includeDateValues,
  control,
  testId,
  addEmptyOption,
}: Props<T, TDataSource>) => {
  const categoryGetters = dataSource.categoryGetters;
  const { data } = useGetWidgetData({ dataSource, cacheOnly: true });

  const customAttributeOptions = useMemo(() => {
    const fieldConfig = convertSchemasToFieldConfigs(
      data?.form_configuration
        .filter(
          (config: Record<string, unknown>) => !!config.customAttributeSchema
        )
        .map(
          (config: Record<'customAttributeSchema', unknown>) =>
            config.customAttributeSchema!
        ) ?? [],
      data?.form_configuration[0]?._typename
    );

    if (fieldConfig) {
      return Object.entries(fieldConfig).map(([id, config]) => ({
        value: `custom:${id}`,
        label: config.header,
      }));
    }
  }, [data]);

  return (
    <ControlledSelect
      addEmptyOption={addEmptyOption}
      testId={testId}
      placeholder={'Select category'}
      disabled={!categoryGetters}
      options={[
        ...getStandardFieldOptions(categoryGetters),
        ...getDateFieldOptions(categoryGetters, includeDateValues),
        ...getCustomAttributeOptions(customAttributeOptions),
      ]}
      name={name}
      label={label}
      control={control}
    />
  );
};
