import Button from '@risksmart-app/components/Button';
import { Ref, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDashboardWidgetSettings } from '../../../context/useDashboardWidgetSettings';
import { WidgetRef } from '../types';
import { dataSources } from './dataSources';
import { FilterPreview } from './FilterPreview';
import { UniversalWidgetSettingsModal } from './form/UniversalWidgetSettingsModal';
import { settingsSchema } from './settingsSchema';
import { settingsToTitle } from './settingsToTitle';
import { UniversalChart } from './UniversalChart';
import { GigawidgetSettings } from './util';

export const UniversalWidget = (_props: unknown, ref: Ref<WidgetRef>) => {
  const [settings, setSettings] =
    useDashboardWidgetSettings<GigawidgetSettings>();
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const { t } = useTranslation('common', {
    keyPrefix: 'dashboard.widgets.gigawidget',
  });

  const dataSource = useMemo(() => {
    if (settings) {
      return dataSources[settings.dataSource];
    }
  }, [settings]);

  const [isSettingsValid, setIsSettingsValid] = useState(false);
  useEffect(() => {
    if (!settings) {
      setIsSettingsValid(false);

      return;
    }

    setIsSettingsValid(settingsSchema.safeParse(settings).success);
  }, [settings]);

  useImperativeHandle(
    ref,
    (): WidgetRef => ({
      openSettings: () => setShowSettingsModal(true),
    })
  );

  return (
    <div className={'h-full flex flex-col gap-2'}>
      {showSettingsModal && (
        <UniversalWidgetSettingsModal
          onDismiss={() => setShowSettingsModal(false)}
          onSave={async (data) =>
            setSettings({
              ...(data as GigawidgetSettings),
              title: data.customTitle
                ? data.title ?? ''
                : settingsToTitle(data as GigawidgetSettings),
            })
          }
        />
      )}

      {!settings && !dataSource ? (
        <div className={'flex flex-col justify-center items-center h-5/6'}>
          <h3 className={'m-0'}>{t('not_configured_title')}</h3>
          <p>{t('not_configured')}</p>
          <Button onClick={() => setShowSettingsModal(true)}>
            {t('configure_button')}
          </Button>
        </div>
      ) : null}

      {settings && dataSource && (
        <div className={'flex-1'}>
          <UniversalChart settings={settings} dataSource={dataSource} />
        </div>
      )}

      {(settings?.filtering?.tokens?.length ?? 0) > 0 &&
      dataSource &&
      settings &&
      isSettingsValid &&
      settings.showFilters ? (
        <div className={'print:hidden'}>
          <FilterPreview settings={settings} dataSource={dataSource} />
        </div>
      ) : null}
    </div>
  );
};

UniversalWidget.displayName = 'UniversalWidget';
