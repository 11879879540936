import { ContentTable } from 'pdfmake/interfaces';
import { useTranslation } from 'react-i18next';

import { useGetAcceptancesByParentRiskIdLazyQuery } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { toLocalDate } from '@/utils/dateUtils';
import { createTable, tableHeaders } from '@/utils/pdf/table';

const useAcceptancesExportTable = (
  riskId: string
): [() => Promise<ContentTable>, boolean] => {
  const { getLabel: getStatusLabel } = useRating('acceptance_status');
  const [getAcceptances, getAcceptancesResult] =
    useGetAcceptancesByParentRiskIdLazyQuery({
      variables: {
        ParentId: riskId,
      },
    });

  const { t: acceptancesColumns } = useTranslation(['common'], {
    keyPrefix: 'acceptances.columns',
  });
  const createExportTable = async () => {
    const { data: appetiteData } = await getAcceptances();
    const acceptanceTableData = (appetiteData?.acceptance ?? []).map((i) => [
      i.Title,
      i.Details,
      toLocalDate(i.DateAcceptedFrom),
      toLocalDate(i.DateAcceptedTo),
      getStatusLabel(i.Status),
    ]);

    return createTable({
      widths: '*',
      body: [
        tableHeaders([
          acceptancesColumns('title'),
          acceptancesColumns('description'),
          acceptancesColumns('accepted_from'),
          acceptancesColumns('accepted_to'),
          acceptancesColumns('status'),
        ]),
        ...acceptanceTableData,
      ],
    });
  };
  return [createExportTable, getAcceptancesResult.loading];
};

export default useAcceptancesExportTable;
