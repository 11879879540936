
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_wih1l_frppv_151",
  "tools": "awsui_tools_wih1l_frppv_162",
  "tools-filtering": "awsui_tools-filtering_wih1l_frppv_170",
  "tools-align-right": "awsui_tools-align-right_wih1l_frppv_184",
  "tools-pagination": "awsui_tools-pagination_wih1l_frppv_188",
  "tools-preferences": "awsui_tools-preferences_wih1l_frppv_188",
  "tools-small": "awsui_tools-small_wih1l_frppv_194",
  "table": "awsui_table_wih1l_frppv_200",
  "table-layout-fixed": "awsui_table-layout-fixed_wih1l_frppv_206",
  "wrapper": "awsui_wrapper_wih1l_frppv_210",
  "variant-stacked": "awsui_variant-stacked_wih1l_frppv_217",
  "wrapper-content-measure": "awsui_wrapper-content-measure_wih1l_frppv_217",
  "variant-container": "awsui_variant-container_wih1l_frppv_217",
  "has-footer": "awsui_has-footer_wih1l_frppv_220",
  "has-header": "awsui_has-header_wih1l_frppv_223",
  "cell-merged": "awsui_cell-merged_wih1l_frppv_240",
  "cell-merged-content": "awsui_cell-merged-content_wih1l_frppv_252",
  "empty": "awsui_empty_wih1l_frppv_270",
  "loading": "awsui_loading_wih1l_frppv_274",
  "selection-control": "awsui_selection-control_wih1l_frppv_283",
  "selection-control-header": "awsui_selection-control-header_wih1l_frppv_290",
  "dark-header": "awsui_dark-header_wih1l_frppv_300",
  "header-secondary": "awsui_header-secondary_wih1l_frppv_305",
  "table-has-header": "awsui_table-has-header_wih1l_frppv_323",
  "header-controls": "awsui_header-controls_wih1l_frppv_327",
  "variant-full-page": "awsui_variant-full-page_wih1l_frppv_330",
  "variant-embedded": "awsui_variant-embedded_wih1l_frppv_336",
  "variant-borderless": "awsui_variant-borderless_wih1l_frppv_336",
  "footer-wrapper": "awsui_footer-wrapper_wih1l_frppv_341",
  "footer": "awsui_footer_wih1l_frppv_341",
  "footer-with-pagination": "awsui_footer-with-pagination_wih1l_frppv_349",
  "footer-pagination": "awsui_footer-pagination_wih1l_frppv_357",
  "thead-active": "awsui_thead-active_wih1l_frppv_361",
  "row": "awsui_row_wih1l_frppv_362",
  "row-selected": "awsui_row-selected_wih1l_frppv_363"
};
  