import { SpaceBetween } from '@cloudscape-design/components-themed';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/notification-context';
import Table from '@risksmart-app/components/Table';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout } from 'src/layouts';
import { Permission } from 'src/rbac/Permission';

import {
  useGetImpactsQuery,
  useInsertImpactMutation,
} from '@/generated/graphql';
import { getCounter } from '@/utils/collectionUtils';
import { evictField } from '@/utils/graphqlUtils';

import { useGetCollectionTableProps } from './config';
import ImpactModal from './ImpactModal';

const Page: FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { t } = useTranslation(['common'], {});
  const { t: st } = useTranslation(['common'], { keyPrefix: 'impacts' });
  const { addNotification } = useNotifications();
  const { data, loading, refetch } = useGetImpactsQuery({
    fetchPolicy: 'no-cache',
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });

  const [insert] = useInsertImpactMutation({
    update: (cache) => {
      evictField(cache, 'impact_aggregate');
      evictField(cache, 'impact');
    },
  });

  const tableProps = useGetCollectionTableProps(
    data?.impact,
    data?.form_configuration?.[0]?.customAttributeSchema
  );

  const counter = getCounter(tableProps.totalItemsCount, loading);
  const title = st('register_title');

  return (
    <PageLayout
      helpTranslationKey="impacts.registerHelp"
      title={title}
      counter={counter}
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button iconName="download" onClick={tableProps.exportToCsv}>
            {t('export')}
          </Button>
          <Permission permission="insert:impact">
            <Button variant="primary" onClick={() => setIsModalVisible(true)}>
              {st('create_new_button')}
            </Button>
          </Permission>
        </SpaceBetween>
      }
    >
      <Table {...tableProps} loading={loading} />
      {isModalVisible && (
        <ImpactModal
          onDismiss={() => setIsModalVisible(false)}
          onSaving={async (data) => {
            await insert({
              variables: {
                ...data,
                OwnerUserIds: data.Owners.filter((c) => c.type === 'user').map(
                  (c) => c.value
                ),
                OwnerGroupIds: data.Owners.filter(
                  (c) => c.type === 'userGroup'
                ).map((c) => c.value),
              },
            });
            refetch();
          }}
        />
      )}
    </PageLayout>
  );
};

export default Page;
