import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomAttributeFields } from '@/components/Form/CustomAttributes/CustomAttributeSchema';
import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import { Parent_Type_Enum } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { EMPTY_CELL } from '@/utils/collectionUtils';
import { getFriendlyId } from '@/utils/friendlyId';
import { useGetDepartmentFieldConfig } from '@/utils/table/hooks/useGetDepartmentFieldConfig';
import { useGetOwnersFieldConfig } from '@/utils/table/hooks/useGetOwnersFieldConfig';
import {
  StatefulTableOptions,
  useGetStatelessTableProps,
  UseGetTablePropsOptions,
} from '@/utils/table/hooks/useGetStatelessTableProps';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import { useGetTagFieldConfig } from '@/utils/table/hooks/useGetTagFieldConfig';
import { TableFields, TablePropsWithActions } from '@/utils/table/types';
import { dateColumn } from '@/utils/table/utils/dateColumn';
import { issueDetailsUrl } from '@/utils/urls';

import { ConsequenceFlatField, ConsequenceRegisterFields } from './types';
import { useLabelledFields } from './useLabelledFields';

const useGetFieldConfig = (
  onConsequenceClick?: (consequence: ConsequenceRegisterFields) => void
): TableFields<ConsequenceRegisterFields> => {
  const { t } = useTranslation(['common'], { keyPrefix: 'columns' });
  const allOwners = useGetOwnersFieldConfig<ConsequenceRegisterFields>();
  const departmentField =
    useGetDepartmentFieldConfig<ConsequenceRegisterFields>();
  const tagField = useGetTagFieldConfig<ConsequenceRegisterFields>();

  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'consequences.columns',
  });
  const status = useRating('issue_assessment_status');
  const severity = useRating('severity');

  const { getByValue: getCriticalityByValue } = useRating('criticality');

  return useMemo(
    () => ({
      Title: {
        header: st('title'),
        cell: (item) => (
          <Link onFollow={() => onConsequenceClick?.(item)}>{item.Title}</Link>
        ),
        isRowHeader: true,
      },
      TypeLabelled: {
        header: st('type'),
      },
      IssueTitle: {
        header: st('issue'),
        cell: (item) => (
          <Link href={issueDetailsUrl(item.ParentIssueId)}>
            {item.IssueTitle}
          </Link>
        ),
        isRowHeader: true,
      },
      IssueSequentialId: {
        header: st('issueId'),
        cell: (item) =>
          item.IssueSequentialId
            ? getFriendlyId(Parent_Type_Enum.Issue, item.IssueSequentialId)
            : '-',
        exportVal: (item) =>
          item.IssueSequentialId
            ? getFriendlyId(Parent_Type_Enum.Issue, item.IssueSequentialId)
            : '-',
      },
      IssueSeverityLabelled: {
        header: st('issueSeverity'),
        cell: (item) => {
          const rating = severity.getByValue(item.IssueSeverity);
          return rating ? (
            <SimpleRatingBadge rating={rating}>
              {item.IssueSeverity}
            </SimpleRatingBadge>
          ) : (
            EMPTY_CELL
          );
        },
      },
      IssueStatusLabelled: {
        header: st('issueStatus'),
        cell: (item) => {
          const rating = status.getByValue(item.IssueStatus);
          return rating ? <SimpleRatingBadge rating={rating} /> : EMPTY_CELL;
        },
      },
      IssueTypeLabelled: {
        header: st('issueType'),
      },
      IssueRaisedDate: dateColumn(st('issueRaisedDate'), 'IssueRaisedDate'),
      IssueClosedDate: dateColumn(st('issueClosedDate'), 'IssueClosedDate'),
      allOwners,
      departments: departmentField,
      tags: tagField,
      CostTypeLabelled: {
        header: st('costType'),
      },
      CostValue: {
        header: st('costValue'),
        fieldType: 'number',
      },
      CostPounds: {
        header: st('costPounds'),
        fieldType: 'number',
        footerVal: (records) =>
          records.reduce(
            (previousVal, currentValue) =>
              previousVal + (currentValue.CostPounds ?? 0),
            0
          ),
      },
      CostHours: {
        header: st('costHours'),
        fieldType: 'number',
        footerVal: (records) =>
          records.reduce(
            (previousVal, currentValue) =>
              previousVal + (currentValue.CostHours ?? 0),
            0
          ),
      },
      CostNumber: {
        header: st('costNumber'),
        fieldType: 'number',
        footerVal: (records) =>
          records.reduce(
            (previousVal, currentValue) =>
              previousVal + (currentValue.CostNumber ?? 0),
            0
          ),
      },
      CriticalityLabelled: {
        header: st('criticality'),
        cell: (item) => (
          <SimpleRatingBadge rating={getCriticalityByValue(item.Criticality)} />
        ),
      },
      Description: {
        header: st('description'),
      },

      CreatedAtTimestamp: dateColumn(t('created_on'), 'CreatedAtTimestamp'),
      ModifiedAtTimestamp: dateColumn(t('updated_on'), 'ModifiedAtTimestamp'),
      ModifiedByUser: { header: t('updated_by_id') },
      ModifiedByUserName: { header: t('updated_by_username') },
      CreatedByUserName: { header: t('created_by_username') },
    }),
    [
      allOwners,
      departmentField,
      getCriticalityByValue,
      onConsequenceClick,
      severity,
      st,
      status,
      t,
      tagField,
    ]
  );
};

const useGetIssueTableProps = (
  records: ConsequenceFlatField[] | undefined,
  customAttributeSchema: CustomAttributeFields | null,
  onConsequenceClick?: (consequence: ConsequenceRegisterFields) => void
): UseGetTablePropsOptions<ConsequenceRegisterFields> => {
  const { t } = useTranslation(['common']);
  const data = useLabelledFields(records);
  const fields = useGetFieldConfig(onConsequenceClick);
  return useMemo(
    () => ({
      data,
      customAttributeSchema,
      entityLabel: t('consequence_one'),
      emptyCollectionAction: <></>,
      storageKey: 'ConsequencesRegisterTable-PreferencesV1',
      enableFiltering: true,
      initialColumns: [
        'Title',
        'IssueTitle',
        'CostTypeLabelled',
        'CostValue',
        'CriticalityLabelled',
      ],
      fields,
    }),
    [customAttributeSchema, data, fields, t]
  );
};

export const useGetRegisterTableProps = (
  records: ConsequenceFlatField[] | undefined,
  customAttributeSchema: CustomAttributeFields | null,
  onConsequenceClick?: (consequence: ConsequenceRegisterFields) => void
): TablePropsWithActions<ConsequenceRegisterFields> => {
  const props = useGetIssueTableProps(
    records,
    customAttributeSchema,
    onConsequenceClick
  );
  return useGetTableProps(props);
};

export const useGetConsequenceSmartWidgetTableProps = (
  records: ConsequenceFlatField[] | undefined,
  customAttributeSchema: CustomAttributeFields | null,
  statefulTableOptions: StatefulTableOptions<ConsequenceRegisterFields>
): TablePropsWithActions<ConsequenceRegisterFields> => {
  const props = useGetIssueTableProps(records, customAttributeSchema);
  return useGetStatelessTableProps<ConsequenceRegisterFields>({
    ...props,
    ...statefulTableOptions,
    enableFiltering: false,
  });
};
