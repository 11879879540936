import { CsvFieldType, TableFields } from '../types';

export function recordsToExportArray<T extends { [key: string]: unknown }>(
  items: readonly T[],
  fields: TableFields<T>,
  visibleColumns: readonly string[] | undefined
): CsvFieldType[][] {
  const columnsToExport = visibleColumns ? visibleColumns : Object.keys(fields);
  const data: CsvFieldType[][] = [];
  const headers: string[] = [];
  for (const fieldName of columnsToExport) {
    if (visibleColumns && !visibleColumns.includes(fieldName)) {
      continue;
    }
    headers.push(fields[fieldName].header);
  }
  data.push(headers);

  for (const item of items) {
    const record: CsvFieldType[] = [];
    for (const fieldName of columnsToExport) {
      if (fields[fieldName].exportVal) {
        record.push(fields[fieldName].exportVal?.(item)?.toString() ?? '');
      } else {
        const value = item[fieldName];
        if (
          typeof value === 'string' ||
          typeof value === 'number' ||
          typeof value === 'boolean' ||
          typeof value === 'undefined' ||
          value === null
        ) {
          record.push(value);
        }
      }
    }
    data.push(record);
  }

  const footerRecord = getFooterRow(fields, columnsToExport, items);
  if (footerRecord) {
    data.push(footerRecord);
  }
  return data;
}

const getFooterRow = <T extends { [key: string]: unknown }>(
  fields: TableFields<T>,
  columnsToExport: readonly string[],
  items: readonly T[]
) => {
  const includeFooter =
    Object.values(fields).filter((c) => c.footerVal || c.footerExportVal)
      .length > 0;
  if (includeFooter) {
    const footerRecord: CsvFieldType[] = [];
    for (const fieldName of columnsToExport) {
      const cellDef = fields[fieldName];
      if (cellDef.footerExportVal) {
        footerRecord.push(cellDef.footerExportVal(items)?.toString() ?? '');
      } else if (cellDef.footerVal) {
        footerRecord.push(cellDef.footerVal(items)?.toString() ?? '');
      } else {
        footerRecord.push('');
      }
    }
    return footerRecord;
  }
  return null;
};
