import {
  Box,
  ColumnLayout,
  FormField as CFFormField,
  FormFieldProps,
  Popover,
} from '@cloudscape-design/components-themed';
import Button from '@risksmart-app/components/Button';
import { AlertCircle } from '@untitled-ui/icons-react';
import { ReactNode, useContext, useMemo, useRef, useState } from 'react';

import { useGetFormFieldOptionsByParentTypeQuery } from '@/generated/graphql';

import { CustomFieldContext } from '../CustomAttributes/Context/CustomFieldContext';
import { NewFieldFormFields } from '../CustomAttributes/EditFields/NewFieldSchema';
import { EditFieldModal, EditMode } from '../EditFieldModal/EditFieldModal';
import { ControlledFieldContext } from '../FieldController/ControlledFieldContext';
import FormEditButton from './CustomisableForm/FormEditButton';
import { useRiskSmartForm } from './CustomisableForm/RiskSmartFormContext';

type Props = Omit<FormFieldProps, 'id' | 'label'> & {
  id?: string;
  disableBottomPadding?: boolean;
  actions?: React.ReactNode;
  label?: string;
  testId?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  previewChangesFormatter?: (value: any) => string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hasFieldChanged?: (value: any) => boolean;
};

export const FormField = ({
  testId,
  disableBottomPadding,
  actions,
  previewChangesFormatter,
  hasFieldChanged,
  ...props
}: Props) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const customField = useContext(CustomFieldContext);
  const controlledField = useContext(ControlledFieldContext);
  const { parentType, editMode, previewChanges } = useRiskSmartForm();
  const { label, children, secondaryControl, ...formFieldProps } = props;
  const popoverRef = useRef<HTMLSpanElement>(null);

  const { data } = useGetFormFieldOptionsByParentTypeQuery({
    variables: { parentType: parentType! },
    skip: !parentType,
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
  });

  const visible = useMemo(() => {
    if (!data || !controlledField) return true;

    const fieldConfig = data?.form_field_configuration ?? [];
    const fieldId = controlledField?.field.name;
    const field = fieldConfig.find((f) => f.FieldId === fieldId);
    return !field?.Hidden;
  }, [data, controlledField]);

  const fieldChanged = !!(
    previewChanges &&
    (hasFieldChanged
      ? hasFieldChanged(previewChanges[controlledField?.field.name])
      : previewChanges[controlledField?.field.name])
  );

  const required = useMemo(() => {
    if (!data || !controlledField) {
      return true;
    }

    const fieldConfig = data?.form_field_configuration ?? [];
    const fieldId = controlledField?.field.name;
    const field = fieldConfig.find((f) => f.FieldId === fieldId);

    return field ? field.Required : controlledField?.defaultRequired;
  }, [data, controlledField]);

  const mappedValues = useMemo<NewFieldFormFields | undefined>(
    () =>
      customField?.currentField
        ? {
            ...customField?.currentField,
            Options: customField.currentField.Options?.map((o) => ({
              value: o,
              generatedId: crypto.randomUUID(),
            })),
          }
        : undefined,
    [customField?.currentField]
  );

  if (!editMode && !visible) return null;

  let secondaryControlElement: ReactNode | undefined = secondaryControl;
  if (fieldChanged) {
    secondaryControlElement = (
      <>
        {secondaryControl}
        <Popover
          renderWithPortal
          fixedWidth
          triggerType={'custom'}
          header={'Changes'}
          size={'large'}
          content={
            <ColumnLayout columns={2} variant="text-grid">
              <div>
                <Box variant="awsui-key-label">Original Value</Box>
                <div>
                  {previewChangesFormatter?.(
                    previewChanges[controlledField?.field.name]?.from
                  ) ??
                    String(previewChanges[controlledField?.field.name]?.from)}
                </div>
              </div>
              <div>
                <Box variant="awsui-key-label">New Value</Box>
                <div>
                  {previewChangesFormatter?.(
                    previewChanges[controlledField?.field.name]?.to
                  ) ?? String(previewChanges[controlledField?.field.name]?.to)}
                </div>
              </div>
            </ColumnLayout>
          }
        >
          <span ref={popoverRef}>
            <Button
              iconSvg={
                <span className={'grid place-items-center'}>
                  <AlertCircle
                    viewBox={'0 0 24 24'}
                    className={'w-8 h-8 text-[orange] -ml-4'}
                  />
                </span>
              }
              variant={'inline-icon'}
            />
          </span>
        </Popover>
      </>
    );
  }
  return (
    <div className={`${editMode && !visible ? 'opacity-50' : ''}`}>
      <div
        className={
          visible ? (editMode || disableBottomPadding ? 'p-0' : 'pb-6') : ''
        }
      >
        <CFFormField
          data-testid={`form-field-${testId}`}
          secondaryControl={secondaryControlElement}
          label={
            <div className={'inline-flex items-center justify-between'}>
              <div className="flex gap-2 items-center">
                <div>
                  {label}{' '}
                  {controlledField?.forceRequired || required
                    ? ''
                    : '(optional)'}
                </div>
                {editMode && (customField || controlledField) ? (
                  <FormEditButton onClick={() => setShowEditModal(true)} />
                ) : null}
              </div>
              {actions}
            </div>
          }
          {...formFieldProps}
          stretch={false}
        >
          <div>
            <div
              className={
                fieldChanged ? `outline outline-2 outline-[orange] rounded` : ''
              }
            >
              {children}
            </div>
          </div>
        </CFFormField>
      </div>
      {parentType && (customField || controlledField) && showEditModal ? (
        <EditFieldModal
          parentType={parentType}
          onDismiss={() => setShowEditModal(false)}
          fieldId={controlledField?.field.name}
          values={mappedValues}
          fieldPath={customField?.fieldPath}
          customField={!!customField}
          editMode={EditMode.Update}
          label={label}
        />
      ) : null}
    </div>
  );
};
