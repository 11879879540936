import { FC } from 'react';

import { MultiSelect } from '@/components/Form/ControlledMultiselect/ControlledMultiselect';

import { CustomAttributeProps } from './CustomAttributeProps';

interface CustomAttributeMultiSelectProps
  extends CustomAttributeProps<string[] | null> {
  schema?: { enum?: string[] };
}

export const CustomAttributeMultiSelect: FC<
  CustomAttributeMultiSelectProps
> = ({ label, onChange, value, disabled, error, schema, description }) => {
  const { enum: options = [] } = schema ?? {};
  const multiselectOptions = options.map((item) => ({
    value: item,
    label: item,
    disabled: false,
  }));
  return (
    <MultiSelect
      description={description}
      label={label}
      onChange={(e) => onChange(e.detail.selectedOptions.map((c) => c.value!))}
      options={multiselectOptions}
      disabled={disabled}
      placeholder="Select"
      errorMessage={error}
      tokenSection={<></>}
      selectedOptions={multiselectOptions.filter((o) =>
        value?.includes(o.value)
      )}
      renderTokens={false}
    />
  );
};
