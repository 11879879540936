import { useMemo } from 'react';
import {
  getAllContributorsCellValue,
  getAllOwnersCellValue,
} from 'src/rbac/contributorHelper';

import { Parent_Type_Enum } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { getFriendlyId } from '@/utils/friendlyId';

import { InternalAuditFields, InternalAuditRegisterFields } from './types';

const ReportStatusEnum = {
  Unallocated: 'unallocated',
  Inprogress: 'inprogress',
  Planned: 'planned',
  NotScheduled: 'notscheduled',
} as const;
type ReportStatusEnum =
  (typeof ReportStatusEnum)[keyof typeof ReportStatusEnum];

export const useLabelledFields = (
  records: InternalAuditFields[] | undefined
) => {
  const { getLabel: getStatusLabel } = useRating(
    'internal_audit_entity_status'
  );
  const { getLabel: getOutcomeLabel } = useRating(
    'internal_audit_report_outcome'
  );
  const labelledFields = useMemo<
    InternalAuditRegisterFields[] | undefined
  >(() => {
    return records?.map((d) => {
      const completedReports = d.internalAuditReports.filter(
        (d) => d?.ActualCompletionDate && d.Status === 'complete'
      );
      let latestReport = undefined;
      if (completedReports.length > 0) {
        latestReport = completedReports.reduce((r, o) =>
          new Date(o!.ActualCompletionDate!) >
          new Date(r!.ActualCompletionDate!)
            ? o
            : r
        );
      }
      let reportStatus: ReportStatusEnum = ReportStatusEnum.Unallocated;
      if (
        d.internalAuditReports.filter((c) => c?.Status === 'inprogress')
          .length > 0
      ) {
        reportStatus = ReportStatusEnum.Inprogress;
      } else if (
        d.internalAuditReports.filter((c) => c?.Status === 'notstarted')
          .length > 0
      ) {
        reportStatus = ReportStatusEnum.Planned;
      } else if (
        d.internalAuditReports.filter((c) => c?.Status === 'complete').length >
        0
      ) {
        reportStatus = ReportStatusEnum.NotScheduled;
      }

      return {
        ...d,
        SequentialIdLabel: getFriendlyId(
          Parent_Type_Enum.InternalAuditEntity,
          d.SequentialId
        ),
        Title: d.Title || '-',
        CreatedBy: d.CreatedByUser || '-',
        UserName: d.createdByUser?.FriendlyName ?? null,
        ModifiedBy: d.ModifiedByUser || '-',
        allOwners: getAllOwnersCellValue(d),
        allContributors: getAllContributorsCellValue(d),
        BusinessArea: d.businessArea?.Title || '-',
        LatestReportDate: latestReport?.ActualCompletionDate || '-',
        AuditRating: latestReport?.Outcome,
        AuditRatingLabelled: latestReport?.Outcome
          ? getOutcomeLabel(latestReport?.Outcome)
          : '-',
        OpenActionCount: d.actions.filter((c) => c.action?.Status !== 'closed')
          .length,
        OpenIssueCount: d.issues.filter(
          (c) => c.issue?.assessment?.Status !== 'closed'
        ).length,
        ReportStatus: reportStatus,
        ReportStatusLabelled: reportStatus ? getStatusLabel(reportStatus) : '-',
      };
    });
  }, [records, getStatusLabel, getOutcomeLabel]);
  return labelledFields;
};
