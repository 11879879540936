
    import './styles.scoped.css';
    export default {
  "custom-range": "awsui_custom-range_16zmw_pw6u0_143",
  "custom-range--no-padding": "awsui_custom-range--no-padding_16zmw_pw6u0_149",
  "custom-range-form-controls": "awsui_custom-range-form-controls_16zmw_pw6u0_153",
  "custom-range-duration": "awsui_custom-range-duration_16zmw_pw6u0_157",
  "custom-range-unit": "awsui_custom-range-unit_16zmw_pw6u0_158",
  "vertical": "awsui_vertical_16zmw_pw6u0_164",
  "custom-range-duration-input": "awsui_custom-range-duration-input_16zmw_pw6u0_175",
  "custom-range-unit-select": "awsui_custom-range-unit-select_16zmw_pw6u0_176",
  "relative-range-radio-group": "awsui_relative-range-radio-group_16zmw_pw6u0_180"
};
  