
    import './styles.scoped.css';
    export default {
  "placeholder": "awsui_placeholder_dwuol_1if1a_143",
  "item": "awsui_item_dwuol_1if1a_148",
  "checkbox": "awsui_checkbox_dwuol_1if1a_152",
  "filter": "awsui_filter_dwuol_1if1a_161",
  "trigger": "awsui_trigger_dwuol_1if1a_166",
  "layout-strut": "awsui_layout-strut_dwuol_1if1a_172",
  "list-bottom": "awsui_list-bottom_dwuol_1if1a_178",
  "selected-icon": "awsui_selected-icon_dwuol_1if1a_182",
  "show-label-tag": "awsui_show-label-tag_dwuol_1if1a_186",
  "inline-token-trigger": "awsui_inline-token-trigger_dwuol_1if1a_190",
  "inline-token-list": "awsui_inline-token-list_dwuol_1if1a_197",
  "inline-token": "awsui_inline-token_dwuol_1if1a_190",
  "visual-refresh": "awsui_visual-refresh_dwuol_1if1a_226",
  "inline-token-hidden-placeholder": "awsui_inline-token-hidden-placeholder_dwuol_1if1a_233",
  "inline-token-counter": "awsui_inline-token-counter_dwuol_1if1a_239",
  "inline-token-trigger--disabled": "awsui_inline-token-trigger--disabled_dwuol_1if1a_243"
};
  