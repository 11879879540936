import { useCollection } from '@cloudscape-design/collection-hooks';
import { SpaceBetween } from '@cloudscape-design/components-themed';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/notification-context';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import Table from '@risksmart-app/components/Table';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  ObjectWithContributors,
  Permission,
  useHasPermission,
} from 'src/rbac/Permission';

import DeleteModal from '@/components/DeleteModal/DeleteModal';
import EmptyEntityCollection from '@/components/EmptyCollection/EmptyEntityCollection';
import TabHeader from '@/components/TabHeader';
import {
  useDeleteDocumentFilesMutation,
  useGetDocumentFilesByDocumentIdQuery,
} from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { evictField } from '@/utils/graphqlUtils';

import {
  DocumentFileTableFields,
  useDocumentFileColumnDefinitions,
} from './config';

interface Props {
  parent: ObjectWithContributors;
}

const Tab: FC<Props> = ({ parent }) => {
  const documentId = useGetGuidParam('documentId');
  const { getLabel: getStatusLabel } = useRating('document_file_status');
  const { getLabel: getTypeLabel } = useRating('document_file_type');
  const { addNotification } = useNotifications();
  const navigate = useNavigate();
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'documentFiles',
  });

  const canDeleteDocumentFile = useHasPermission(
    'delete:document_file',
    parent
  );
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<DocumentFileTableFields[]>(
    []
  );

  const { data, loading, refetch } = useGetDocumentFilesByDocumentIdQuery({
    variables: {
      documentId,
    },
    fetchPolicy: 'no-cache',
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });

  const [deleteDocumentFiles, deleteResult] = useDeleteDocumentFilesMutation({
    update: (cache) => {
      evictField(cache, 'document_file');
    },
  });

  const handleEditFile = (item: DocumentFileTableFields) => {
    navigate(`update/${item.id}`);
  };

  const handleFileOpen = () => {
    navigate('create');
  };

  const onDelete = useDeleteResultNotification({
    entityName: st('entity_name'),
    asyncAction: async () => {
      await deleteDocumentFiles({
        variables: {
          documentFileIds: selectedFiles.map((s) => s.id),
        },
      });
      setSelectedFiles([]);
      setIsDeleteModalVisible(false);
      await refetch();
      return true;
    },
    failureAction: () => {
      setIsDeleteModalVisible(false);
    },
  });

  const columnDefinitions = useDocumentFileColumnDefinitions(handleEditFile);

  const labelledFields = useMemo<DocumentFileTableFields[]>(() => {
    return (
      data?.document_file.map((a) => ({
        fileName: a.file?.FileName ?? null,
        version: a.Version,
        type: getTypeLabel(a.Type),
        status: getStatusLabel(a.Status),
        statusValue: a.Status,
        reviewDate: a.ReviewDate,
        reviewedBy: a.reviewedBy?.FriendlyName ?? '-',
        reviewDue: a.NextReviewDate,
        createdAtTimestamp: a.CreatedAtTimestamp,
        id: a.Id,
        changeRequests: a.changeRequests,
      })) || []
    );
  }, [data?.document_file, getStatusLabel, getTypeLabel]);

  const { items, collectionProps } = useCollection(labelledFields, {
    propertyFiltering: {
      filteringProperties: [],
      empty: (
        <EmptyEntityCollection
          entityLabel={st('entity_name')}
          action={
            <Permission permission="insert:document_file" parentObject={parent}>
              <Button formAction="none" onClick={handleFileOpen}>
                {st('add_button')}
              </Button>
            </Permission>
          }
        />
      ),
    },
    sorting: {
      defaultState: {
        isDescending: true,
        sortingColumn: columnDefinitions[6],
      },
    },
  });

  return (
    <>
      <Table
        {...collectionProps}
        selectionType={canDeleteDocumentFile ? 'multi' : undefined}
        selectedItems={selectedFiles}
        trackBy="id"
        onSelectionChange={({ detail }) => {
          setSelectedFiles(detail.selectedItems);
        }}
        resizableColumns={true}
        header={
          <SpaceBetween size="m">
            <TabHeader
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Permission
                    permission="delete:document_file"
                    parentObject={parent}
                  >
                    <Button
                      formAction="none"
                      variant="normal"
                      disabled={!selectedFiles.length}
                      onClick={() => setIsDeleteModalVisible(true)}
                    >
                      {t('delete')}
                    </Button>
                  </Permission>
                  <Permission
                    permission="insert:document_file"
                    parentObject={parent}
                  >
                    <Button
                      variant="primary"
                      formAction="none"
                      iconName="upload"
                      onClick={handleFileOpen}
                    >
                      {st('add_button')}
                    </Button>
                  </Permission>
                </SpaceBetween>
              }
            >
              {st('tab_title')}
            </TabHeader>
          </SpaceBetween>
        }
        variant="embedded"
        loading={loading}
        columnDefinitions={columnDefinitions}
        items={items}
        loadingText={st('loading_message')}
        sortingDisabled={false}
      />
      <DeleteModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        header={t('delete')}
        onDelete={onDelete}
        onDismiss={() => setIsDeleteModalVisible(false)}
      >
        {st('confirm_delete_message')}
      </DeleteModal>
    </>
  );
};

export default Tab;
