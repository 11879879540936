
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_gfwv3_rz0r7_183",
  "horizontal": "awsui_horizontal_gfwv3_rz0r7_188",
  "vertical": "awsui_vertical_gfwv3_rz0r7_192",
  "list": "awsui_list_gfwv3_rz0r7_196",
  "list-item": "awsui_list-item_gfwv3_rz0r7_216",
  "toggle-container-inline": "awsui_toggle-container-inline_gfwv3_rz0r7_223",
  "toggle": "awsui_toggle_gfwv3_rz0r7_223",
  "description": "awsui_description_gfwv3_rz0r7_319",
  "separator": "awsui_separator_gfwv3_rz0r7_323"
};
  