
    import './styles.scoped.css';
    export default {
  "code-editor-refresh": "awsui_code-editor-refresh_1gl9c_1048n_156",
  "code-editor": "awsui_code-editor_1gl9c_1048n_156",
  "pane": "awsui_pane_1gl9c_1048n_506",
  "pane__close-container": "awsui_pane__close-container_1gl9c_1048n_517",
  "pane__list": "awsui_pane__list_1gl9c_1048n_522",
  "pane__table": "awsui_pane__table_1gl9c_1048n_529",
  "pane__item": "awsui_pane__item_1gl9c_1048n_535",
  "pane__cell": "awsui_pane__cell_1gl9c_1048n_535",
  "pane__item--highlighted": "awsui_pane__item--highlighted_1gl9c_1048n_549",
  "pane__location": "awsui_pane__location_1gl9c_1048n_570",
  "pane__description": "awsui_pane__description_1gl9c_1048n_570",
  "focus-lock": "awsui_focus-lock_1gl9c_1048n_585",
  "editor": "awsui_editor_1gl9c_1048n_632",
  "editor-refresh": "awsui_editor-refresh_1gl9c_1048n_660",
  "status-bar": "awsui_status-bar_1gl9c_1048n_665",
  "status-bar-with-hidden-pane": "awsui_status-bar-with-hidden-pane_1gl9c_1048n_673",
  "status-bar__left": "awsui_status-bar__left_1gl9c_1048n_677",
  "status-bar__left-virtual": "awsui_status-bar__left-virtual_1gl9c_1048n_684",
  "status-bar__right": "awsui_status-bar__right_1gl9c_1048n_688",
  "status-bar__language-mode": "awsui_status-bar__language-mode_1gl9c_1048n_692",
  "status-bar__cursor-position": "awsui_status-bar__cursor-position_1gl9c_1048n_692",
  "status-bar__cog-button": "awsui_status-bar__cog-button_1gl9c_1048n_698",
  "status-bar-virtual": "awsui_status-bar-virtual_1gl9c_1048n_703",
  "tab-button": "awsui_tab-button_1gl9c_1048n_709",
  "tab-button--refresh": "awsui_tab-button--refresh_1gl9c_1048n_741",
  "tab-button--warnings": "awsui_tab-button--warnings_1gl9c_1048n_744",
  "tab-button--active": "awsui_tab-button--active_1gl9c_1048n_753",
  "tab-button--disabled": "awsui_tab-button--disabled_1gl9c_1048n_769",
  "tab-button--divider": "awsui_tab-button--divider_1gl9c_1048n_802",
  "tab-button--errors": "awsui_tab-button--errors_1gl9c_1048n_809",
  "loading-screen": "awsui_loading-screen_1gl9c_1048n_813",
  "error-screen": "awsui_error-screen_1gl9c_1048n_814"
};
  