
    import './styles.scoped.css';
    export default {
  "content": "awsui_content_1wepg_jtz9u_143",
  "description": "awsui_description_1wepg_jtz9u_144",
  "label": "awsui_label_1wepg_jtz9u_145",
  "outline": "awsui_outline_1wepg_jtz9u_153",
  "show-outline": "awsui_show-outline_1wepg_jtz9u_156",
  "native-input": "awsui_native-input_1wepg_jtz9u_160",
  "wrapper": "awsui_wrapper_1wepg_jtz9u_164",
  "label-wrapper": "awsui_label-wrapper_1wepg_jtz9u_170",
  "empty-content": "awsui_empty-content_1wepg_jtz9u_181",
  "description-bottom-padding": "awsui_description-bottom-padding_1wepg_jtz9u_191",
  "label-disabled": "awsui_label-disabled_1wepg_jtz9u_199",
  "description-disabled": "awsui_description-disabled_1wepg_jtz9u_200",
  "control": "awsui_control_1wepg_jtz9u_205"
};
  