import { Header, HeaderProps } from '@cloudscape-design/components-themed';
import { FC } from 'react';

import styles from './TabHeader.module.scss';
interface Props extends HeaderProps {}

const TabHeader: FC<Props> = ({
  children,
  variant = 'h2',
  headingTagOverride = 'h2',
  ...rest
}) => {
  return (
    <div className={styles.header} data-testid="tab-header">
      <Header
        variant={variant}
        headingTagOverride={headingTagOverride}
        {...rest}
      >
        <div data-testid="tab-title">{children}</div>
      </Header>
    </div>
  );
};

export default TabHeader;
