import { useAxios } from '../AxiosProvider';

type Response = { url: string };

export const useGetReportUrl = () => {
  const axios = useAxios();
  return async (dashboardId: string): Promise<Response> => {
    const { data } = await axios.get<Response>(`/reports/${dashboardId}`);
    return data;
  };
};
