import { useCallback } from 'react';

import { useAxios } from '../data/AxiosProvider';

export const useCustomOrgLogo = () => {
  const axios = useAxios();

  return useCallback(async () => {
    const { data } = await axios.get('/logo', { responseType: 'blob' });
    const blob = new Blob([data], { type: 'image/jpeg' });
    const url = URL.createObjectURL(blob);
    if (!url) throw new Error('Failed to fetch custom logo');
    return url;
  }, [axios]);
};
