import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MAX_COL_WIDTH } from 'src/App.config';

import { CustomAttributeFields } from '@/components/Form/CustomAttributes/CustomAttributeSchema';
import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import { useRating } from '@/hooks/use-rating';
import { useGetContributorsFieldConfig } from '@/utils/table/hooks/useGetContributorsFieldConfig';
import { useGetDepartmentFieldConfig } from '@/utils/table/hooks/useGetDepartmentFieldConfig';
import { useGetOwnersFieldConfig } from '@/utils/table/hooks/useGetOwnersFieldConfig';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import { useGetTagFieldConfig } from '@/utils/table/hooks/useGetTagFieldConfig';
import {
  DefaultSortingState,
  TableFields,
  TablePropsWithActions,
} from '@/utils/table/types';
import { dateColumn } from '@/utils/table/utils/dateColumn';

import { AcceptanceFlatFields, AcceptanceTableFields } from './types';
import { useLabelledFields } from './useLabelledFields';

const useGetFieldConfig = (): TableFields<AcceptanceTableFields> => {
  const allOwners = useGetOwnersFieldConfig<AcceptanceTableFields>();
  const tagField = useGetTagFieldConfig<AcceptanceTableFields>();
  const departmentField = useGetDepartmentFieldConfig<AcceptanceTableFields>();
  const allContributors =
    useGetContributorsFieldConfig<AcceptanceTableFields>();
  const status = useRating('acceptance_status');
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'acceptances.columns',
  });
  const { t } = useTranslation(['common'], {
    keyPrefix: 'columns',
  });
  return useMemo(
    () => ({
      Title: {
        header: st('title'),
        cell: (acceptance) => (
          <Link isRelativeUrl={true} variant="secondary" href={acceptance.Id}>
            {acceptance.Title}
          </Link>
        ),
        isRowHeader: true,
        maxWidth: MAX_COL_WIDTH,
      },
      Details: { header: st('description'), maxWidth: MAX_COL_WIDTH },
      ParentTitle: { header: st('associations'), maxWidth: MAX_COL_WIDTH },
      Tier: { header: st('tier') },
      allOwners,
      allContributors,
      DateAcceptedFrom: dateColumn(st('accepted_from'), 'DateAcceptedFrom'),
      DateAcceptedTo: dateColumn(st('accepted_to'), 'DateAcceptedTo'),
      StatusLabelled: {
        header: st('status'),
        cell: (item) => {
          const rating = status.getByValue(item.Status);
          return (
            <SimpleRatingBadge rating={rating}>
              {item.StatusLabelled}
            </SimpleRatingBadge>
          );
        },
      },
      Id: { header: t('guid') },
      SequentialIdLabel: { header: t('id'), sortingField: 'SequentialId' },
      ModifiedAtTimestamp: dateColumn(t('updated_on'), 'ModifiedAtTimestamp'),
      ModifiedByUser: { header: t('updated_by_id') },
      ModifiedByUserName: { header: t('updated_by_username') },
      requestedBy: { header: t('requested_by') },
      approvedBy: { header: t('approved_by') },
      tags: tagField,
      departments: departmentField,
    }),
    [allContributors, allOwners, departmentField, st, status, t, tagField]
  );
};

export const useGetCollectionTableProps = (
  records: AcceptanceFlatFields[] | undefined,
  customAttributeSchema?: CustomAttributeFields | null,
  defaultSortingState?: DefaultSortingState<AcceptanceTableFields> | undefined
): TablePropsWithActions<AcceptanceTableFields> => {
  const { t: st } = useTranslation(['common'], { keyPrefix: 'acceptances' });

  const labelledFields = useLabelledFields(records);
  const fields = useGetFieldConfig();

  return useGetTableProps({
    data: labelledFields,
    customAttributeSchema: customAttributeSchema,
    entityLabel: st('entity_name'),
    emptyCollectionAction: <></>,
    storageKey: 'AcceptanceRegisterTable-PreferencesV1',
    enableFiltering: true,
    initialColumns: [
      'Title',
      'Details',
      'ParentTitle',
      'Tier',
      'allOwners',
      'DateAcceptedFrom',
      'DateAcceptedTo',
      'StatusLabelled',
    ],
    fields,
    defaultSortingState,
  });
};
