import { ContentTable } from 'pdfmake/interfaces';
import { useTranslation } from 'react-i18next';

import { useGetDocumentAssessmentResultsByParentIdLazyQuery } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { toLocalDate } from '@/utils/dateUtils';
import { createTable, tableHeaders } from '@/utils/pdf/table';

const usePerformanceExportTable = (
  documentId: string
): [() => Promise<ContentTable>, boolean] => {
  const { getLabel: getAssessmentStatusLabel } = useRating('assessment_status');
  const { getLabel: getPerformanceResultLabel } =
    useRating('performance_result');

  const [getDocumentAssessmentsResults, getDocumentAssessmentsResultsResult] =
    useGetDocumentAssessmentResultsByParentIdLazyQuery({
      variables: {
        ParentId: documentId,
      },
      fetchPolicy: 'no-cache',
    });

  const { t: documentAssessmentsColumns } = useTranslation(['common'], {
    keyPrefix: 'documentAssessments.columns',
  });
  const createExportTable = async () => {
    const { data: assessmentData } = await getDocumentAssessmentsResults();
    const assessmentsTableData = (
      assessmentData?.document_assessment_result ?? []
    ).map((au) => [
      au.parents?.filter((p) => p.assessment)[0]?.assessment?.Title ?? '-',
      getAssessmentStatusLabel(
        au.parents?.filter((p) => p.assessment)[0]?.assessment?.Status ?? '-'
      ),
      getPerformanceResultLabel(au.Rating),
      toLocalDate(
        au.parents?.filter((p) => p.assessment)[0]?.assessment?.StartDate
      ),
      toLocalDate(
        au.parents?.filter((p) => p.assessment)[0]?.assessment
          ?.ActualCompletionDate
      ),
      au.parents?.filter((p) => p.assessment)[0]?.assessment?.completedByUser
        ?.FriendlyName ?? '-',
    ]);

    return createTable({
      widths: '*',
      body: [
        tableHeaders([
          documentAssessmentsColumns('Title'),
          documentAssessmentsColumns('Status'),
          documentAssessmentsColumns('Result'),
          documentAssessmentsColumns('StartDate'),
          documentAssessmentsColumns('CompletionDate'),
          documentAssessmentsColumns('CompletionBy'),
        ]),
        ...assessmentsTableData,
      ],
    });
  };
  return [createExportTable, getDocumentAssessmentsResultsResult.loading];
};

export default usePerformanceExportTable;
