import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomAttributeFields } from '@/components/Form/CustomAttributes/CustomAttributeSchema';
import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import { Parent_Type_Enum } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { EMPTY_CELL } from '@/utils/collectionUtils';
import { getFriendlyId } from '@/utils/friendlyId';
import { useGetOwnersFieldConfig } from '@/utils/table/hooks/useGetOwnersFieldConfig';
import {
  StatefulTableOptions,
  useGetStatelessTableProps,
  UseGetTablePropsOptions,
} from '@/utils/table/hooks/useGetStatelessTableProps';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import { TableFields, TablePropsWithActions } from '@/utils/table/types';
import { dateColumn } from '@/utils/table/utils/dateColumn';
import { issueDetailsUrl } from '@/utils/urls';

import { CauseFlatField, CauseRegisterFields } from './types';
import { useLabelledFields } from './useLabelledFields';

const useGetFieldConfig = (
  onCauseClick?: (cause: CauseRegisterFields) => void
): TableFields<CauseRegisterFields> => {
  const { t } = useTranslation(['common'], { keyPrefix: 'columns' });
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'causes.columns',
  });
  const { getByValue } = useRating('significance');
  const status = useRating('issue_assessment_status');
  const severity = useRating('severity');
  const allOwners = useGetOwnersFieldConfig<CauseRegisterFields>();

  return useMemo(
    () => ({
      Title: {
        header: st('title'),
        cell: (item) => (
          <Link onFollow={() => onCauseClick?.(item)}>{item.Title}</Link>
        ),
        isRowHeader: true,
      },
      IssueSequentialId: {
        header: st('issueId'),
        cell: (item) =>
          item.IssueSequentialId
            ? getFriendlyId(Parent_Type_Enum.Issue, item.IssueSequentialId)
            : '-',
        exportVal: (item) =>
          item.IssueSequentialId
            ? getFriendlyId(Parent_Type_Enum.Issue, item.IssueSequentialId)
            : '-',
      },
      IssueTitle: {
        header: st('issue'),
        cell: (item) => (
          <Link href={issueDetailsUrl(item.ParentIssueId)}>
            {item.IssueTitle}
          </Link>
        ),
        isRowHeader: true,
      },
      IssueSeverityLabelled: {
        header: st('issueSeverity'),
        cell: (item) => {
          const rating = severity.getByValue(item.IssueSeverity);
          return rating ? (
            <SimpleRatingBadge rating={rating}>
              {item.IssueSeverity}
            </SimpleRatingBadge>
          ) : (
            EMPTY_CELL
          );
        },
      },
      IssueStatusLabelled: {
        header: st('issueStatus'),
        cell: (item) => {
          const rating = status.getByValue(item.IssueStatus);
          return rating ? <SimpleRatingBadge rating={rating} /> : EMPTY_CELL;
        },
      },
      IssueRaisedDate: dateColumn(st('issueRaisedDate'), 'IssueRaisedDate'),
      IssueClosedDate: dateColumn(st('issueClosedDate'), 'IssueClosedDate'),
      allOwners,
      SignificanceLabelled: {
        header: st('significance'),
        cell: (item) => (
          <SimpleRatingBadge rating={getByValue(item.Significance)} />
        ),
      },
      Description: {
        header: st('description'),
      },
      CreatedAtTimestamp: dateColumn(t('created_on'), 'CreatedAtTimestamp'),
      ModifiedAtTimestamp: dateColumn(t('updated_on'), 'ModifiedAtTimestamp'),
      ModifiedByUser: { header: t('updated_by_id') },
      ModifiedByUserName: { header: t('updated_by_username') },
      CreatedByUserName: { header: t('created_by_username') },
    }),
    [allOwners, getByValue, onCauseClick, severity, st, status, t]
  );
};

const useGetCauseTableProps = (
  records: CauseFlatField[] | undefined,
  customAttributeSchema: CustomAttributeFields | null,
  onConsequenceClick?: (consequence: CauseRegisterFields) => void
): UseGetTablePropsOptions<CauseRegisterFields> => {
  const { t } = useTranslation(['common']);
  const data = useLabelledFields(records);
  const fields = useGetFieldConfig(onConsequenceClick);
  return useMemo(
    () => ({
      data,
      customAttributeSchema,
      entityLabel: t('cause_one'),
      emptyCollectionAction: <></>,
      storageKey: 'CausesRegisterTable-PreferencesV1',
      enableFiltering: true,
      initialColumns: ['Title', 'IssueTitle', 'Significance', 'Description'],
      fields,
    }),
    [customAttributeSchema, data, fields, t]
  );
};

export const useGetRegisterTableProps = (
  records: CauseFlatField[] | undefined,
  customAttributeSchema: CustomAttributeFields | null,
  onConsequenceClick?: (consequence: CauseRegisterFields) => void
): TablePropsWithActions<CauseRegisterFields> => {
  const props = useGetCauseTableProps(
    records,
    customAttributeSchema,
    onConsequenceClick
  );
  return useGetTableProps(props);
};

export const useGetCauseSmartWidgetTableProps = (
  records: CauseFlatField[] | undefined,
  customAttributeSchema: CustomAttributeFields | null,
  statefulTableOptions: StatefulTableOptions<CauseRegisterFields>
): TablePropsWithActions<CauseRegisterFields> => {
  const props = useGetCauseTableProps(records, customAttributeSchema);
  return useGetStatelessTableProps<CauseRegisterFields>({
    ...props,
    ...statefulTableOptions,
    enableFiltering: false,
  });
};
