import i18n from 'src/i18n';

import { actionDetailsUrl } from '@/utils/urls';

import { GetItem } from './types';

export const getItem: GetItem = (item, lookupData) => {
  const actionId = item.data?.objectId ?? item.data?.actionId;
  const action = lookupData.actions?.[actionId];
  return {
    message: i18n.t('notifications.messages.actionDue', {
      title: action?.Title ?? i18n.t('notifications.unknown'),
    }),
    url: action ? actionDetailsUrl(action.Id) : null,
  };
};
