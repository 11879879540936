import { FC } from 'react';

import { useGetDocumentFileByIdQuery } from '@/generated/graphql';

type Props = {
  id: string | undefined;
};

const Breadcrumb: FC<Props> = ({ id }) => {
  if (!id) {
    throw new Error('Missing id param');
  }
  const { data } = useGetDocumentFileByIdQuery({
    variables: {
      id,
    },
  });
  return <>{data?.document_file[0].Version || ''}</>;
};

export default Breadcrumb;
