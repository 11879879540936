import { z } from 'zod';

import { Appetite_Type_Enum } from '@/generated/graphql';

import {
  CustomAttributeDataSchema,
  FilesSchema,
  NullableStringDateSchema,
} from '../../../../schemas/global';

export const AppetiteSchema = z
  .object({
    ImpactId: z.string().uuid().nullish(),
    Statement: z.string().nullable().optional(),
    LowerAppetite: z.number().nullable().optional(),
    UpperAppetite: z.number().nullable().optional(),
    EffectiveDate: NullableStringDateSchema,
    AppetiteType: z.nativeEnum(Appetite_Type_Enum),
    ImpactAppetite: z.number().nullable().optional(),
    LikelihoodAppetite: z.number().nullable().optional(),
  })
  .superRefine((val, ctx) => {
    if (val.AppetiteType === Appetite_Type_Enum.Impact && !val.ImpactId) {
      ctx.addIssue({
        message: 'Required',
        code: z.ZodIssueCode.custom,
        path: ['ImpactId'],
      });
    }

    if (val.AppetiteType === Appetite_Type_Enum.Impact && !val.ImpactAppetite) {
      ctx.addIssue({
        message: 'Required',
        code: z.ZodIssueCode.custom,
        path: ['ImpactAppetite'],
      });
    }

    if (
      val.AppetiteType === Appetite_Type_Enum.Likelihood &&
      !val.LikelihoodAppetite
    ) {
      ctx.addIssue({
        message: 'Required',
        code: z.ZodIssueCode.custom,
        path: ['LikelihoodAppetite'],
      });
    }

    if (val.AppetiteType === Appetite_Type_Enum.Risk && !val.UpperAppetite) {
      ctx.addIssue({
        message: 'Required',
        code: z.ZodIssueCode.custom,
        path: ['UpperAppetite'],
      });
    }
  })
  .and(FilesSchema)
  .and(CustomAttributeDataSchema);

export type AppetiteFormFieldsData = z.infer<typeof AppetiteSchema>;

export const defaultValues: AppetiteFormFieldsData = {
  newFiles: [],
  files: [],
  CustomAttributeData: null,
  Statement: '',
  LowerAppetite: undefined,
  UpperAppetite: undefined,
  EffectiveDate: null,
  AppetiteType: Appetite_Type_Enum.Risk,
  ImpactAppetite: undefined,
  LikelihoodAppetite: undefined,
  ImpactId: null,
};
