import { SpaceBetween } from '@cloudscape-design/components-themed';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AssessmentResultModal from 'src/pages/assessments/modals/AssessmentResultModal';
import {
  defaultValues,
  ObligationFormFieldData,
} from 'src/pages/compliance/obligations/forms/obligationSchema';
import { getContributors, getOwners } from 'src/rbac/contributorHelper';
import { useHasPermission } from 'src/rbac/Permission';

import {
  contributorUpdateFields,
  departmentInsertInputBuilder,
  ownerUpdateFields,
  tagInsertInputBuilder,
} from '@/components/Form';
import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import LatestRatingsPreview from '@/components/LatestRatingsPreview';
import { ResultProps } from '@/components/LatestRatingsPreview/LatestRatingsPreview';
import {
  GetObligationByIdQuery,
  namedOperations,
  Parent_Type_Enum,
  useGetLatestComplianceMonitoringAssessmentObligationAssessmentResultByObligationIdQuery,
  useGetLatestInternalAuditReportObligationAssessmentResultByObligationIdQuery,
  useGetLatestObligationAssessmentResultByObligationIdQuery,
  useUpdateObligationMutation,
} from '@/generated/graphql';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';
import { evictField } from '@/utils/graphqlUtils';

import { AssessmentTypeEnum } from '../../../../../assessments/useAssessmentTypeConfig';
import ObligationDetailsForm from '../../../forms/ObligationDetailsForm';

type Props = {
  obligation: GetObligationByIdQuery['obligation'][number];
};

const Tab: FC<Props> = ({ obligation }) => {
  useI18NSummaryHelpContent('obligations.detailsHelp');
  const canUpdateObligation = useHasPermission('update:obligation', obligation);
  const navigate = useNavigate();
  const { t } = useTranslation(['common']);
  const refetchQueries = [namedOperations.Query.getObligationById];
  const [updateObligation] = useUpdateObligationMutation({
    update: (cache) => evictField(cache, 'obligation'),
    refetchQueries,
  });
  const onSave = async (data: ObligationFormFieldData) => {
    if (!obligation) {
      throw new Error('No obligation');
    }
    await updateObligation({
      variables: {
        ...data,
        Id: obligation!.Id!,
        ...ownerUpdateFields(data, obligation.Id),
        ...contributorUpdateFields(data, obligation.Id),
        CustomAttributeData: data.CustomAttributeData,
        tags: tagInsertInputBuilder(data.TagTypeIds, obligation.Id),
        departments: departmentInsertInputBuilder(
          data.DepartmentTypeIds,
          obligation.Id
        ),
      },
    });
  };
  const complianceMonitoringEnabled = useIsFeatureVisibleToOrg(
    'compliance_monitoring'
  );
  const internalAuditEnabled = useIsFeatureVisibleToOrg('internal_audit');
  const canViewCompliance = useHasPermission(
    'read:compliance_monitoring_assessment',
    obligation
  );
  const canViewInternalAudit = useHasPermission(
    'read:internal_audit_report',
    obligation
  );
  const skipInternalAudit = !internalAuditEnabled || !canViewInternalAudit;
  const skipComplianceMonitoring =
    !complianceMonitoringEnabled || !canViewCompliance;

  const { data: assessmentResult } =
    useGetLatestObligationAssessmentResultByObligationIdQuery({
      variables: {
        ObligationId: obligation.Id,
      },
    });
  const { data: internalAuditResults } =
    useGetLatestInternalAuditReportObligationAssessmentResultByObligationIdQuery(
      {
        variables: {
          ObligationId: obligation.Id,
        },
        skip: skipInternalAudit,
      }
    );
  const { data: complianceMonitoringResults } =
    useGetLatestComplianceMonitoringAssessmentObligationAssessmentResultByObligationIdQuery(
      {
        variables: {
          ObligationId: obligation.Id,
        },
        skip: skipComplianceMonitoring,
      }
    );

  const [selectedAssessmentResultId, setSelectedAssessmentResultId] = useState<
    string | undefined
  >();
  const [selectedAssessmentMode, setSelectedAssessmentMode] =
    useState<AssessmentTypeEnum>('rating');
  const [showAssessmentResultModal, setShowAssessmentResultModal] =
    useState<boolean>(false);

  const onDismiss = () => navigate(-1);

  return (
    <>
      <ObligationDetailsForm
        values={{
          ...defaultValues,
          ...obligation,
          Owners: getOwners(obligation),
          Contributors: getContributors(obligation),
          ancestorContributors: obligation?.ancestorContributors ?? [],
        }}
        defaultValues={defaultValues}
        onSave={onSave}
        onDismiss={onDismiss}
        readOnly={!canUpdateObligation}
        aside={
          <SpaceBetween size="m">
            {assessmentResult?.obligation_assessment_result?.[0] && (
              <LatestRatingsPreview
                ratingsTitle={t(
                  'obligationsAssessments.obligationRatingSubheading'
                )}
                onClick={(id) => {
                  setSelectedAssessmentMode('rating');
                  setSelectedAssessmentResultId(id);
                  setShowAssessmentResultModal(true);
                }}
                assessmentResults={[
                  {
                    id: assessmentResult.obligation_assessment_result?.[0]?.Id,
                    rating:
                      assessmentResult?.obligation_assessment_result?.[0]
                        ?.Rating,
                    completionDate:
                      assessmentResult?.obligation_assessment_result?.[0]
                        ?.TestDate,
                    title: 'Latest assessment rating',
                    ratingType: 'performance_result',
                  },
                ]}
              />
            )}
            {complianceMonitoringResults &&
              complianceMonitoringResults.obligation_assessment_result.length >
                0 && (
                <LatestRatingsPreview
                  ratingsTitle={t('ratings.complianceRatingSubheading')}
                  assessmentResults={complianceMonitoringResults.obligation_assessment_result.map(
                    (c) =>
                      ({
                        id: c.Id,
                        title:
                          c?.parents.length > 0
                            ? c?.parents[0].complianceMonitoringAssessment
                                ?.Title ?? '-'
                            : '-',
                        rating: c.Rating,
                        ratingType: 'performance_result',
                        completionDate: c.TestDate,
                      }) as ResultProps
                  )}
                  onClick={(id) => {
                    setSelectedAssessmentMode(
                      'compliance_monitoring_assessment'
                    );
                    setSelectedAssessmentResultId(id);
                    setShowAssessmentResultModal(true);
                  }}
                />
              )}
            {internalAuditResults &&
              internalAuditResults.obligation_assessment_result.length > 0 && (
                <LatestRatingsPreview
                  ratingsTitle={t('ratings.internalAuditRatingSubheading')}
                  assessmentResults={internalAuditResults.obligation_assessment_result.map(
                    (c) =>
                      ({
                        id: c.Id,
                        title:
                          c?.parents?.length > 0
                            ? c?.parents[0].internalAuditReport?.Title ?? '-'
                            : '-',
                        rating: c.Rating,
                        ratingType: 'performance_result',
                        completionDate: c.TestDate,
                      }) as ResultProps
                  )}
                  onClick={(id) => {
                    setSelectedAssessmentMode('internal_audit_report');
                    setSelectedAssessmentResultId(id);
                    setShowAssessmentResultModal(true);
                  }}
                />
              )}
          </SpaceBetween>
        }
      />
      {showAssessmentResultModal && (
        <AssessmentResultModal
          id={selectedAssessmentResultId}
          resultType={Parent_Type_Enum.ObligationAssessmentResult}
          onDismiss={() => setShowAssessmentResultModal(false)}
          i18n={t('assessmentResults')}
          assessmentMode={selectedAssessmentMode}
        />
      )}
    </>
  );
};

export default Tab;
