import { ApolloCache } from '@apollo/client';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CauseFormFields,
  CauseFormSchema,
  defaultValues,
} from 'src/pages/issues/update/forms/causeSchema';
import { useHasPermission } from 'src/rbac/Permission';

import { ModalForm } from '@/components/Form';
import {
  Parent_Type_Enum,
  useGetCauseByIdQuery,
  useGetIssueByIdQuery,
  useInsertCauseMutation,
  useUpdateCauseMutation,
} from '@/generated/graphql';
import { evictField } from '@/utils/graphqlUtils';

import CauseForm from '../../forms/CauseForm';

type Props = {
  onDismiss: (saved: boolean) => void;
  issueId: string;
  causeId?: string;
};

const CauseModal: FC<Props> = ({ onDismiss, issueId, causeId }) => {
  const onUpdate = (cache: ApolloCache<unknown>) => {
    evictField(cache, 'cause');
    evictField(cache, 'cause_aggregate');
  };
  const { t } = useTranslation('common');

  const [insert] = useInsertCauseMutation({
    update: onUpdate,
  });
  const [update] = useUpdateCauseMutation({
    update: onUpdate,
  });
  const { data: issueData } = useGetIssueByIdQuery({
    variables: {
      _eq: issueId!,
    },
    skip: !issueId,
    fetchPolicy: 'no-cache',
  });
  const parent = issueData?.issue?.[0];
  const canEditCause = useHasPermission('update:cause', parent);
  const canCreateCause = useHasPermission('insert:cause', parent);

  const { data, loading, error } = useGetCauseByIdQuery({
    variables: {
      _eq: causeId!,
    },
    skip: !causeId,
    fetchPolicy: 'no-cache',
  });
  if (error) {
    throw error;
  }

  const cause = data?.cause[0];
  const canModify = cause ? canEditCause : canCreateCause;

  const onSave = async (data: CauseFormFields) => {
    if (cause) {
      const result = await update({
        variables: {
          ...data,
          ParentIssueId: issueId,
          Id: causeId ?? null,
          OriginalTimestamp: cause?.ModifiedAtTimestamp ?? null,
          CustomAttributeData: data.CustomAttributeData || undefined,
        },
      });
      if (result.data?.update_cause?.affected_rows !== 1) {
        throw new Error(
          'Records not updated. Record may have been updated by another user'
        );
      }
    } else {
      await insert({
        variables: {
          ...data,
          ParentIssueId: issueId,
          CustomAttributeData: data.CustomAttributeData || undefined,
        },
      });
    }
  };

  if (loading) return null;
  return (
    <ModalForm
      onDismiss={onDismiss}
      onSave={onSave}
      defaultValues={defaultValues}
      i18n={t('causes')}
      schema={CauseFormSchema}
      values={cause}
      formId={'cause-form'}
      visible={true}
      readOnly={!canModify}
      parentType={Parent_Type_Enum.Cause}
    >
      <CauseForm readOnly={!canModify} />
    </ModalForm>
  );
};

export default CauseModal;
