import {
  PropertyFilterOption,
  PropertyFilterProperty,
} from '@cloudscape-design/collection-hooks';
import { Alert } from '@cloudscape-design/components-themed';
import Button from '@risksmart-app/components/Button';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CustomisableRibbonFormFields from '@/components/CustomisableRibbon/CustomisableRibbonFormFields';
import { DEFAULT_FILTERS } from '@/components/CustomisableRibbon/defaultFilters';

import { Container } from '../../../../theme/risksmart-themed/components';
import { IssueRegisterFields } from '../../pages/issues/types';
import { CustomisableRibbonModalFields } from '../../schemas/customisableRibbonModalSchema';
import styles from './style.module.scss';

interface Props {
  filteringProperties: readonly PropertyFilterProperty[];
  // TODO: use a generic type for items when we update all registers to the new date filtering
  items: readonly IssueRegisterFields[] | undefined;
  filteringOptions: readonly PropertyFilterOption[] | undefined;
}

const CustomisableRibbonForm = ({
  filteringProperties,
  filteringOptions,
  items,
}: Props) => {
  const { t } = useTranslation(['common'], {
    keyPrefix: 'customisableRibbons',
  });

  const { watch, setValue, clearErrors } =
    useFormContext<CustomisableRibbonModalFields>();
  const filters = watch('Filters');

  const addEditableFilter = () =>
    setValue('Filters', [
      ...filters,
      {
        id: crypto.randomUUID(),
        title: 'All Issues',
        itemOrder: filters.length,
        itemFilterQuery: { tokens: [], operation: 'and' },
      },
    ]);

  const removeEditableFilter = (id: string) => {
    const newFilters = filters.filter((filter) => filter.id !== id);

    newFilters.map((filter, index) => {
      filter.title = filters[index].title;
      filter.itemOrder = index;

      return filter;
    });

    setValue('Filters', newFilters);
  };

  const handleAddFilter = () => {
    addEditableFilter();
    clearErrors();
  };

  const handleResetFilter = () => {
    // TODO: Replace this with a prop that is passed in from the register
    setValue('Filters', DEFAULT_FILTERS);
    clearErrors();
  };

  return (
    <span
      data-testid="customisable-ribbon-form"
      className={'flex flex-col gap-5'}
    >
      <Alert
        statusIconAriaLabel={'Warning'}
        type={'warning'}
        header={t('ribbon_alert')}
      />
      {filters.length ? (
        filters.map((filter, index) => (
          <Container key={`${filter.id}`}>
            <div className={styles.customisableRibbonForm}>
              <CustomisableRibbonFormFields
                index={index}
                items={items}
                removeEditableFilter={() => removeEditableFilter(filter.id)}
                filteringProperties={filteringProperties}
                filteringOptions={filteringOptions}
              />
            </div>
          </Container>
        ))
      ) : (
        <Container>
          <div
            className={
              'flex flex-grow flex-col ' +
              'items-center justify-center ' +
              'max-h-[170px] gap-y-8 m-[40px]'
            }
          >
            <h3 className={'font-normal m-0'}>
              {t('restoreDefaultsDescription')}
            </h3>
            <Button onClick={handleResetFilter}>
              {t('restoreDefaultsButton')}
            </Button>
          </div>
        </Container>
      )}
      <Button onClick={handleAddFilter}>{t('addFilterButton')}</Button>
    </span>
  );
};

export default CustomisableRibbonForm;
