import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ControlledDatePicker from '@/components/Form/ControlledDatePicker';
import { ControlledFileUpload } from '@/components/Form/ControlledFileUpload/ControlledFileUpload';
import ControlledGroupAndUserContributorMultiSelect from '@/components/Form/ControlledGroupAndUserContributorMultiSelect';
import ControlledInput from '@/components/Form/ControlledInput';
import ControlledRadioGroup from '@/components/Form/ControlledRadioGroup';
import { noTransform } from '@/components/Form/ControlledRadioGroup/ControlledRadioGroup';
import ControlledRating from '@/components/Form/ControlledRating';
import ControlledTextarea from '@/components/Form/ControlledTextarea';
import DepartmentSelector from '@/components/Form/DepartmentSelector';
import ConditionalField from '@/components/Form/Form/CustomisableForm/ConditionalField';
import CustomisableForm from '@/components/Form/Form/CustomisableForm/CustomisableForm';
import FieldGroup from '@/components/Form/Form/CustomisableForm/FieldGroup';
import TagSelector from '@/components/Form/TagSelector';
import { Action_Status_Enum, Contributor_Type_Enum } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';

import { ActionFormFieldData } from './actionsSchema';

type Props = {
  readonly?: boolean;
};

export enum TestIds {
  Owners = 'owners',
  Priority = 'priority',
}

const ActionFormFields: FC<Props> = ({ readonly }) => {
  const { control, watch } = useFormContext<ActionFormFieldData>();

  const { options } = useRating('action_status');
  const statusOptions = options.map((option) => ({
    ...option,
    value: String(option.value),
  }));
  const status = watch('Status');

  const { t: st } = useTranslation(['common'], { keyPrefix: 'actions' });
  const { t } = useTranslation(['common']);

  return (
    <CustomisableForm readOnly={readonly}>
      <ControlledInput
        forceRequired={true}
        key="title"
        name="Title"
        label={t('fields.Title')}
        control={control}
        placeholder={st('fields.Title_placeholder') ?? ''}
        description={st('fields.Title_help')}
        disabled={readonly}
      />

      <ControlledTextarea
        key="description"
        name="Description"
        defaultRequired={true}
        label={t('fields.Description')}
        placeholder={st('fields.Description_placeholder') ?? ''}
        control={control}
        description={st('fields.Description_help')}
        disabled={readonly}
      />
      <ControlledGroupAndUserContributorMultiSelect
        key="owners"
        testId={TestIds.Owners}
        forceRequired={true}
        control={control}
        inheritedContributorsName="ancestorContributors"
        contributorType={Contributor_Type_Enum.Owner}
        includeGroups={true}
        label={t('fields.Owner')}
        name="Owners"
        placeholder={t('fields.Owner_placeholder')}
        description={st('fields.Owner_help')}
        disabled={readonly}
      />

      <ControlledGroupAndUserContributorMultiSelect
        key="contributors"
        control={control}
        contributorType={Contributor_Type_Enum.Contributor}
        includeGroups={true}
        inheritedContributorsName="ancestorContributors"
        label={t('fields.Contributor')}
        name="Contributors"
        placeholder={t('fields.Contributor_placeholder')}
        description={st('fields.Contributor_help')}
        disabled={readonly}
      />

      <FieldGroup key="statusAndDates">
        <ControlledRadioGroup
          key="status"
          forceRequired={true}
          label={t('fields.Status')}
          description={st('fields.Status_help')}
          name="Status"
          control={control}
          transform={noTransform}
          items={statusOptions}
          disabled={readonly}
        />

        <ControlledDatePicker
          key="dateRaised"
          forceRequired={true}
          name="DateRaised"
          label={st('fields.DateRaised')}
          description={st('fields.DateRaised_help')}
          control={control}
          disabled={readonly}
        />

        <ControlledDatePicker
          key="dateDue"
          forceRequired={true}
          name="DateDue"
          label={st('fields.TargetCloseDate')}
          description={st('fields.TargetCloseDate_help')}
          control={control}
          disabled={readonly}
        />

        <ConditionalField
          condition={status === Action_Status_Enum.Closed}
          key="closedDate"
        >
          <ControlledDatePicker
            key="closedDate"
            name="ClosedDate"
            forceRequired
            label={st('fields.ClosedDate')}
            description={st('fields.ClosedDate_help')}
            control={control}
            disabled={readonly}
          />
        </ConditionalField>
      </FieldGroup>

      <ControlledRating
        key="priority"
        testId={TestIds.Priority}
        defaultRequired
        name="Priority"
        label={st('fields.Priority')}
        placeholder="Select"
        control={control}
        type="priority"
        description={st('fields.Priority_help')}
        disabled={readonly}
      />

      <ControlledFileUpload
        key="attachFiles"
        label={t('fields.newFiles')}
        description={t('fields.newFiles_help')}
        control={control}
        name="newFiles"
        saveFilesName="files"
        disabled={readonly}
      />

      <TagSelector
        key="tags"
        name="tags"
        control={control}
        disabled={readonly}
      />
      <DepartmentSelector
        key="departments"
        name="departments"
        control={control}
        disabled={readonly}
      />
    </CustomisableForm>
  );
};

export default ActionFormFields;
