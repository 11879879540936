import i18n from 'src/i18n';

import { policyDetailsUrl } from '@/utils/urls';

import { GetItem } from './types';

export const getItem: GetItem = (item, lookupData) => {
  const documentId = item.data?.objectId;
  const document = lookupData.documents?.[documentId];
  return {
    message: i18n.t('notifications.messages.documentInsert', {
      title: document?.Title ?? i18n.t('notifications.unknown'),
    }),
    url: document ? policyDetailsUrl(document.Id) : null,
  };
};
