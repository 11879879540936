import { FormField, SpaceBetween } from '@cloudscape-design/components-themed';
import { useContext, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { ControlledSwitch } from '../ControlledSwitch/ControlledSwitch';
import { FormFieldOptions } from '../CustomAttributes/EditFields/NewFieldSchema';
import { ControlledFieldContext } from '../FieldController/ControlledFieldContext';

function CustomisableFieldOptions() {
  const { control, watch, setValue } = useFormContext<FormFieldOptions>();

  const controlledField = useContext(ControlledFieldContext);

  const required = watch('Required');
  const hidden = watch('Hidden');
  const readOnly = watch('ReadOnly');

  useEffect(() => {
    if (required) {
      setValue('Hidden', false);
      setValue('ReadOnly', false);
    }
  }, [required, setValue]);

  useEffect(() => {
    if (hidden) {
      setValue('Required', false);
      setValue('ReadOnly', false);
    }
  }, [hidden, setValue]);

  useEffect(() => {
    if (readOnly) {
      setValue('Required', false);
      setValue('Hidden', false);
    }
  }, [readOnly, setValue]);

  return (
    <FormField label="Options">
      <SpaceBetween size="xxs" direction="vertical">
        <ControlledSwitch
          disabled={controlledField?.forceRequired}
          name="Required"
          control={control}
          label="Required"
          testId="Required"
        />
        <ControlledSwitch
          disabled={controlledField?.forceRequired}
          name="Hidden"
          control={control}
          label="Hidden"
          testId="Hidden"
        />
        <ControlledSwitch
          disabled={controlledField?.forceRequired}
          name="ReadOnly"
          control={control}
          label="Read Only"
          testId="ReadOnly"
        />
      </SpaceBetween>
    </FormField>
  );
}

export default CustomisableFieldOptions;
