import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { DashboardFilter } from 'src/context/DashboardFilter';
import { useDashboardFilter } from 'src/context/useDashboardFilter';

import NumberWidget from '../../../widgets/NumberWidget/NumberWidget';
import { useGetWidgetData } from '../../hooks/useGetWidgetData';
import {
  AggregationType,
  GigawidgetCommonProps,
  WidgetDataSource,
} from '../../types';
import { aggregate } from '../../util/categoryFunctions';

export type GigaNumberWidgetProps<TDataSource extends WidgetDataSource> =
  GigawidgetCommonProps<TDataSource> & {
    unit?: string;
    onClickUrl?: (filter: DashboardFilter) => string | undefined;
    aggregationType: AggregationType;
  };

export const GigaNumberWidget = <TDataSource extends WidgetDataSource>({
  dataSource,
  variables,
  dateFilterOptions,
  unit,
  onClickUrl,
  propertyFilterQuery,
  aggregationType,
  aggregationField,
}: GigaNumberWidgetProps<TDataSource>) => {
  const navigate = useNavigate();
  const { filters } = useDashboardFilter();

  const {
    tableProps: { allItems },
    loading,
  } = useGetWidgetData({
    dataSource,
    variables,
    dateFilterOptions,
    propertyFilterQuery,
  });

  const number = useMemo(() => {
    if (!allItems) return 0;
    const category = {
      key: '',
      label: '',
      data: Array.from(allItems).filter(
        (item) => !aggregationField || item[aggregationField] != null
      ),
      aggregatedValue: 0,
    };
    if (category.data.length === 0 && aggregationType !== 'count')
      return undefined;
    aggregate([category], aggregationType, aggregationField);
    return category.aggregatedValue;
  }, [allItems, aggregationField, aggregationType]);

  const handleClick = () => {
    if (onClickUrl) {
      const url = onClickUrl(filters);
      if (url) {
        navigate(url);
      }
    }
  };

  return (
    <NumberWidget
      unit={unit}
      value={number}
      loading={loading}
      onClick={handleClick}
    />
  );
};
