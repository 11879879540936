import { useTranslation } from 'react-i18next';

import { CustomAttributeFields } from '@/components/Form/CustomAttributes/CustomAttributeSchema';
import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import { Parent_Type_Enum } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';
import { getFriendlyId } from '@/utils/friendlyId';
import { useGetContributorsFieldConfig } from '@/utils/table/hooks/useGetContributorsFieldConfig';
import { useGetOwnersFieldConfig } from '@/utils/table/hooks/useGetOwnersFieldConfig';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import { TableFields, TablePropsWithActions } from '@/utils/table/types';
import { dateColumn } from '@/utils/table/utils/dateColumn';

import { AppetiteLatestFields, AppetiteTableFields } from './types';
import { useLabelledFields } from './useLabelledFields';

const useGetFieldConfig = (): TableFields<AppetiteTableFields> => {
  const usePosture = useIsFeatureVisibleToOrg('posture');
  const allOwners = useGetOwnersFieldConfig<AppetiteTableFields>();
  const allContributors = useGetContributorsFieldConfig<AppetiteTableFields>();
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'appetites.columns',
  });
  const { t } = useTranslation(['common'], {
    keyPrefix: 'columns',
  });
  const { getByLabel } = useRating('risk_controlled');
  const { getByValue } = useRating('risk_appetite');
  const { getByValue: getAppetitePerformanceByValue } = useRating(
    'appetite_performance'
  );
  const posture = useIsFeatureVisibleToOrg('posture');
  return {
    ParentTitle: {
      header: st('risk'),
      cell: (item) => (
        <Link
          href={item.appetites[0].appetite?.Id}
          isRelativeUrl={true}
          variant="secondary"
        >
          {item.ParentTitle}
        </Link>
      ),
      isRowHeader: true,
    },
    TierLabelled: {
      header: st('tier'),
    },
    allOwners,
    allContributors,
    ...(!posture
      ? {
          LowerAppetiteLabelled: {
            header: st('lowerAppetite'),
            cell: (item) => (
              <SimpleRatingBadge rating={getByValue(item.LowerAppetite)} />
            ),
          },
        }
      : {}),
    UpperAppetiteLabelled: {
      header: usePosture ? st('posture') : st('upperAppetite'),
      cell: (item) => (
        <SimpleRatingBadge rating={getByValue(item.UpperAppetite)} />
      ),
    },
    ControlledRatingLabelled: {
      header: st('controlledRating'),
      cell: (item) => (
        <SimpleRatingBadge rating={getByLabel(item.ControlledRatingLabelled)} />
      ),
    },
    PerformanceLabelled: {
      header: st('appetitePerformance'),
      cell: (item) => {
        if (!item.Performance) {
          return '-';
        }

        const performanceItem = getAppetitePerformanceByValue(
          item.Performance
        ) ?? {
          label: 'Undefined',
          color: 'light-grey',
        };
        return <SimpleRatingBadge rating={performanceItem} />;
      },
    },
    ParentRiskId: { header: st('parentRiskId') },
    Statement: { header: st('statement') },
    CreatedAtTimestamp: dateColumn(t('created_on'), 'CreatedAtTimestamp'),
    Id: { header: t('guid') },
    SequentialId: {
      header: t('id'),
      cell: (item) =>
        item.SequentialId
          ? getFriendlyId(Parent_Type_Enum.Appetite, item.SequentialId)
          : '-',
      exportVal: (item) =>
        item.SequentialId
          ? getFriendlyId(Parent_Type_Enum.Appetite, item.SequentialId)
          : '-',
    },
    ModifiedAtTimestamp: dateColumn(t('updated_on'), 'ModifiedAtTimestamp'),
    ModifiedByUser: { header: t('updated_by_id') },
    ModifiedByUserName: { header: t('updated_by_username') },
  };
};

export const useGetCollectionTableProps = (
  records: AppetiteLatestFields[] | undefined,
  customAttributeSchema?: CustomAttributeFields | null
): TablePropsWithActions<AppetiteTableFields> => {
  const labelledFields = useLabelledFields(records);
  const { t: st } = useTranslation(['common'], { keyPrefix: 'appetites' });
  const fields = useGetFieldConfig();
  const posture = useIsFeatureVisibleToOrg('posture');
  const initialColumns: (keyof AppetiteTableFields)[] = [
    'ParentTitle',
    'TierLabelled',
    'owners',
    'UpperAppetiteLabelled',
    'ControlledRatingLabelled',
    'PerformanceLabelled',
  ];
  if (!posture) {
    initialColumns.push('LowerAppetiteLabelled');
  }
  return useGetTableProps({
    data: labelledFields,
    customAttributeSchema: customAttributeSchema,
    entityLabel: st('entity_name'),
    emptyCollectionAction: <></>,
    storageKey: 'AppetiteRegisterTable-PreferencesV2',
    enableFiltering: true,
    initialColumns,
    fields,
  });
};
