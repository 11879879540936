
    import './styles.scoped.css';
    export default {
  "wrapper": "awsui_wrapper_z5mul_1xp43_179",
  "wrapper--fit-height": "awsui_wrapper--fit-height_z5mul_1xp43_215",
  "inner-wrapper--fit-height": "awsui_inner-wrapper--fit-height_z5mul_1xp43_220",
  "has-default-filter": "awsui_has-default-filter_z5mul_1xp43_226",
  "content": "awsui_content_z5mul_1xp43_230",
  "content--reserve-filter": "awsui_content--reserve-filter_z5mul_1xp43_237",
  "content--reserve-legend": "awsui_content--reserve-legend_z5mul_1xp43_241",
  "content--fit-height": "awsui_content--fit-height_z5mul_1xp43_245",
  "filter-container": "awsui_filter-container_z5mul_1xp43_249"
};
  