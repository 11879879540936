import { ApolloCache } from '@apollo/client';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHasPermission } from 'src/rbac/Permission';
import {
  ConsequenceFormSchema,
  defaultValues,
} from 'src/schemas/consequenceSchema';

import { ModalForm } from '@/components/Form';
import {
  Parent_Type_Enum,
  useGetConsequenceByIdQuery,
  useGetIssueByIdQuery,
  useInsertConsequenceMutation,
  useUpdateConsequenceMutation,
} from '@/generated/graphql';
import { evictField } from '@/utils/graphqlUtils';

import ConsequenceForm from '../../forms/ConsequenceForm';

type Props = {
  onDismiss: (saved: boolean) => void;
  issueId: string;
  consequenceId?: string;
};

const ConsequenceModal: FC<Props> = ({ onDismiss, issueId, consequenceId }) => {
  const { t } = useTranslation('common');
  const onUpdate = (cache: ApolloCache<unknown>) => {
    evictField(cache, 'consequence');
    evictField(cache, 'consequence_aggregate');
  };
  const { data: issueData } = useGetIssueByIdQuery({
    variables: {
      _eq: issueId!,
    },
    skip: !issueId,
    fetchPolicy: 'no-cache',
  });
  const parent = issueData?.issue?.[0];
  const { data, loading, error } = useGetConsequenceByIdQuery({
    variables: {
      _eq: consequenceId!,
    },
    skip: !consequenceId,
    fetchPolicy: 'no-cache',
  });
  if (error) {
    throw error;
  }

  const canEditConsequence = useHasPermission('update:consequence', parent);
  const canCreateConsequence = useHasPermission('insert:consequence', parent);
  const consequence = data?.consequence[0];
  const canModify = consequence ? canEditConsequence : canCreateConsequence;

  const [insert] = useInsertConsequenceMutation({
    update: onUpdate,
  });
  const [update] = useUpdateConsequenceMutation({
    update: onUpdate,
  });

  if (loading) return null;

  return (
    <ModalForm
      onDismiss={onDismiss}
      onSave={async (data) => {
        const mutate = consequence ? update : insert;
        await mutate({
          variables: {
            ...data,
            ParentIssueId: issueId,
            Id: consequence?.Id ?? null,
            OriginalTimestamp: consequence?.ModifiedAtTimestamp ?? null,
            CustomAttributeData: data.CustomAttributeData || undefined,
          },
        });
      }}
      defaultValues={defaultValues}
      i18n={t('consequences')}
      schema={ConsequenceFormSchema}
      values={consequence}
      formId={'consequence-form'}
      visible={true}
      readOnly={!canModify}
      parentType={Parent_Type_Enum.Consequence}
    >
      <ConsequenceForm readOnly={!canModify} />
    </ModalForm>
  );
};

export default ConsequenceModal;
