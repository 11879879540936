export var colorChartsRed300 = "var(--color-charts-red-300-l64jkq, #ea7158)";
export var colorChartsRed400 = "var(--color-charts-red-400-wa91b2, #dc5032)";
export var colorChartsRed500 = "var(--color-charts-red-500-clvvu0, #d13313)";
export var colorChartsRed600 = "var(--color-charts-red-600-n5f5z7, #ba2e0f)";
export var colorChartsRed700 = "var(--color-charts-red-700-9qnabz, #a82a0c)";
export var colorChartsRed800 = "var(--color-charts-red-800-03myov, #972709)";
export var colorChartsRed900 = "var(--color-charts-red-900-t7y4st, #892407)";
export var colorChartsRed1000 = "var(--color-charts-red-1000-2569eu, #7d2105)";
export var colorChartsRed1100 = "var(--color-charts-red-1100-utabtk, #721e03)";
export var colorChartsRed1200 = "var(--color-charts-red-1200-p41vo4, #671c00)";
export var colorChartsOrange300 = "var(--color-charts-orange-300-8xdemd, #e07941)";
export var colorChartsOrange400 = "var(--color-charts-orange-400-98kh01, #cc5f21)";
export var colorChartsOrange500 = "var(--color-charts-orange-500-3exfo1, #bc4d01)";
export var colorChartsOrange600 = "var(--color-charts-orange-600-tyzxch, #a84401)";
export var colorChartsOrange700 = "var(--color-charts-orange-700-9es7jg, #983c02)";
export var colorChartsOrange800 = "var(--color-charts-orange-800-zzyr3p, #8a3603)";
export var colorChartsOrange900 = "var(--color-charts-orange-900-ozn3mk, #7e3103)";
export var colorChartsOrange1000 = "var(--color-charts-orange-1000-2j9x6g, #732c02)";
export var colorChartsOrange1100 = "var(--color-charts-orange-1100-gbb7zd, #692801)";
export var colorChartsOrange1200 = "var(--color-charts-orange-1200-k1a2bc, #602400)";
export var colorChartsYellow300 = "var(--color-charts-yellow-300-ud5z8m, #b2911c)";
export var colorChartsYellow400 = "var(--color-charts-yellow-400-er893i, #9c7b0b)";
export var colorChartsYellow500 = "var(--color-charts-yellow-500-zf6yw3, #8a6b05)";
export var colorChartsYellow600 = "var(--color-charts-yellow-600-e1b0zr, #7b5f04)";
export var colorChartsYellow700 = "var(--color-charts-yellow-700-3ihyrv, #6f5504)";
export var colorChartsYellow800 = "var(--color-charts-yellow-800-2luor7, #654d03)";
export var colorChartsYellow900 = "var(--color-charts-yellow-900-k8cds4, #5d4503)";
export var colorChartsYellow1000 = "var(--color-charts-yellow-1000-9ibbxn, #553f03)";
export var colorChartsYellow1100 = "var(--color-charts-yellow-1100-igbqae, #4d3901)";
export var colorChartsYellow1200 = "var(--color-charts-yellow-1200-qg1amh, #483300)";
export var colorChartsGreen300 = "var(--color-charts-green-300-gduyir, #67a353)";
export var colorChartsGreen400 = "var(--color-charts-green-400-jvx3c1, #41902c)";
export var colorChartsGreen500 = "var(--color-charts-green-500-uj364g, #1f8104)";
export var colorChartsGreen600 = "var(--color-charts-green-600-mbov5u, #1a7302)";
export var colorChartsGreen700 = "var(--color-charts-green-700-m7xx1p, #176702)";
export var colorChartsGreen800 = "var(--color-charts-green-800-97d577, #145d02)";
export var colorChartsGreen900 = "var(--color-charts-green-900-79vtkm, #125502)";
export var colorChartsGreen1000 = "var(--color-charts-green-1000-dw0ace, #104d01)";
export var colorChartsGreen1100 = "var(--color-charts-green-1100-fdxgm3, #0f4601)";
export var colorChartsGreen1200 = "var(--color-charts-green-1200-0w78ld, #0d4000)";
export var colorChartsTeal300 = "var(--color-charts-teal-300-b1amlp, #2ea597)";
export var colorChartsTeal400 = "var(--color-charts-teal-400-75fmn4, #1c8e81)";
export var colorChartsTeal500 = "var(--color-charts-teal-500-5p6o7k, #0d7d70)";
export var colorChartsTeal600 = "var(--color-charts-teal-600-1ar5in, #096f64)";
export var colorChartsTeal700 = "var(--color-charts-teal-700-3m4vci, #06645a)";
export var colorChartsTeal800 = "var(--color-charts-teal-800-0fx1b9, #045b52)";
export var colorChartsTeal900 = "var(--color-charts-teal-900-a0x7u4, #03524a)";
export var colorChartsTeal1000 = "var(--color-charts-teal-1000-n2wukj, #014b44)";
export var colorChartsTeal1100 = "var(--color-charts-teal-1100-3rvzfn, #01443e)";
export var colorChartsTeal1200 = "var(--color-charts-teal-1200-hfik8s, #003e38)";
export var colorChartsBlue1300 = "var(--color-charts-blue-1-300-uwr1wq, #529ccb)";
export var colorChartsBlue1400 = "var(--color-charts-blue-1-400-y4dmzv, #3184c2)";
export var colorChartsBlue1500 = "var(--color-charts-blue-1-500-jj2qqx, #0273bb)";
export var colorChartsBlue1600 = "var(--color-charts-blue-1-600-ntcnzw, #0166ab)";
export var colorChartsBlue1700 = "var(--color-charts-blue-1-700-vhr7bu, #015b9d)";
export var colorChartsBlue1800 = "var(--color-charts-blue-1-800-zq4na2, #015292)";
export var colorChartsBlue1900 = "var(--color-charts-blue-1-900-pu28p4, #014a87)";
export var colorChartsBlue11000 = "var(--color-charts-blue-1-1000-a1wpur, #01437d)";
export var colorChartsBlue11100 = "var(--color-charts-blue-1-1100-fyl0u6, #003c75)";
export var colorChartsBlue11200 = "var(--color-charts-blue-1-1200-y913fp, #00366d)";
export var colorChartsBlue2300 = "var(--color-charts-blue-2-300-sh1kxw, #688ae8)";
export var colorChartsBlue2400 = "var(--color-charts-blue-2-400-s269w5, #5978e3)";
export var colorChartsBlue2500 = "var(--color-charts-blue-2-500-y9grom, #4066df)";
export var colorChartsBlue2600 = "var(--color-charts-blue-2-600-ljsd9s, #3759ce)";
export var colorChartsBlue2700 = "var(--color-charts-blue-2-700-8pne2y, #314fbf)";
export var colorChartsBlue2800 = "var(--color-charts-blue-2-800-v3m6dn, #2c46b1)";
export var colorChartsBlue2900 = "var(--color-charts-blue-2-900-2rg9qw, #273ea5)";
export var colorChartsBlue21000 = "var(--color-charts-blue-2-1000-ore46l, #23379b)";
export var colorChartsBlue21100 = "var(--color-charts-blue-2-1100-rj1e0b, #1f3191)";
export var colorChartsBlue21200 = "var(--color-charts-blue-2-1200-i1tjtx, #1b2b88)";
export var colorChartsPurple300 = "var(--color-charts-purple-300-egsaob, #a783e1)";
export var colorChartsPurple400 = "var(--color-charts-purple-400-y2qlpj, #9469d6)";
export var colorChartsPurple500 = "var(--color-charts-purple-500-akrt38, #8456ce)";
export var colorChartsPurple600 = "var(--color-charts-purple-600-dnhyx7, #7749bf)";
export var colorChartsPurple700 = "var(--color-charts-purple-700-sk05tr, #6b40b2)";
export var colorChartsPurple800 = "var(--color-charts-purple-800-g5mys1, #6237a7)";
export var colorChartsPurple900 = "var(--color-charts-purple-900-ap3bee, #59309d)";
export var colorChartsPurple1000 = "var(--color-charts-purple-1000-pymhe3, #512994)";
export var colorChartsPurple1100 = "var(--color-charts-purple-1100-f2iopl, #4a238b)";
export var colorChartsPurple1200 = "var(--color-charts-purple-1200-aic8dr, #431d84)";
export var colorChartsPink300 = "var(--color-charts-pink-300-cctxfi, #da7596)";
export var colorChartsPink400 = "var(--color-charts-pink-400-kpryka, #ce567c)";
export var colorChartsPink500 = "var(--color-charts-pink-500-tccj7z, #c33d69)";
export var colorChartsPink600 = "var(--color-charts-pink-600-4bki3e, #b1325c)";
export var colorChartsPink700 = "var(--color-charts-pink-700-gexeia, #a32952)";
export var colorChartsPink800 = "var(--color-charts-pink-800-9fchm4, #962249)";
export var colorChartsPink900 = "var(--color-charts-pink-900-z4eeu8, #8b1b42)";
export var colorChartsPink1000 = "var(--color-charts-pink-1000-7uou4m, #81143b)";
export var colorChartsPink1100 = "var(--color-charts-pink-1100-j0uevl, #780d35)";
export var colorChartsPink1200 = "var(--color-charts-pink-1200-jpnl1t, #6f062f)";
export var colorChartsStatusCritical = "var(--color-charts-status-critical-vygogl, #7d2105)";
export var colorChartsStatusHigh = "var(--color-charts-status-high-xmh5vl, #ba2e0f)";
export var colorChartsStatusMedium = "var(--color-charts-status-medium-le4kdn, #cc5f21)";
export var colorChartsStatusLow = "var(--color-charts-status-low-seyj13, #b2911c)";
export var colorChartsStatusPositive = "var(--color-charts-status-positive-g1vr65, #67a353)";
export var colorChartsStatusInfo = "var(--color-charts-status-info-10v3c7, #3184c2)";
export var colorChartsStatusNeutral = "var(--color-charts-status-neutral-f30kx5, #7d8998)";
export var colorChartsThresholdNegative = "var(--color-charts-threshold-negative-jxxft8, #d91515)";
export var colorChartsThresholdPositive = "var(--color-charts-threshold-positive-gxjdw9, #037f0c)";
export var colorChartsThresholdInfo = "var(--color-charts-threshold-info-jheddl, #0972d3)";
export var colorChartsThresholdNeutral = "var(--color-charts-threshold-neutral-9351o8, #5f6b7a)";
export var colorChartsLineGrid = "var(--color-charts-line-grid-9oxvql, #d1d5db)";
export var colorChartsLineTick = "var(--color-charts-line-tick-mf5hvw, #d1d5db)";
export var colorChartsLineAxis = "var(--color-charts-line-axis-r7291n, #d1d5db)";
export var colorChartsPaletteCategorical1 = "var(--color-charts-palette-categorical-1-ld9m1p, #688ae8)";
export var colorChartsPaletteCategorical2 = "var(--color-charts-palette-categorical-2-jwteam, #c33d69)";
export var colorChartsPaletteCategorical3 = "var(--color-charts-palette-categorical-3-tlwr5r, #2ea597)";
export var colorChartsPaletteCategorical4 = "var(--color-charts-palette-categorical-4-prlvih, #8456ce)";
export var colorChartsPaletteCategorical5 = "var(--color-charts-palette-categorical-5-04vsso, #e07941)";
export var colorChartsPaletteCategorical6 = "var(--color-charts-palette-categorical-6-2wgmsi, #3759ce)";
export var colorChartsPaletteCategorical7 = "var(--color-charts-palette-categorical-7-w06irv, #962249)";
export var colorChartsPaletteCategorical8 = "var(--color-charts-palette-categorical-8-1qnvcp, #096f64)";
export var colorChartsPaletteCategorical9 = "var(--color-charts-palette-categorical-9-0342zm, #6237a7)";
export var colorChartsPaletteCategorical10 = "var(--color-charts-palette-categorical-10-u73kns, #a84401)";
export var colorChartsPaletteCategorical11 = "var(--color-charts-palette-categorical-11-tloc2a, #273ea5)";
export var colorChartsPaletteCategorical12 = "var(--color-charts-palette-categorical-12-23xv0d, #780d35)";
export var colorChartsPaletteCategorical13 = "var(--color-charts-palette-categorical-13-tuq7ce, #03524a)";
export var colorChartsPaletteCategorical14 = "var(--color-charts-palette-categorical-14-bom4ss, #4a238b)";
export var colorChartsPaletteCategorical15 = "var(--color-charts-palette-categorical-15-kpqcpe, #7e3103)";
export var colorChartsPaletteCategorical16 = "var(--color-charts-palette-categorical-16-pzlo2l, #1b2b88)";
export var colorChartsPaletteCategorical17 = "var(--color-charts-palette-categorical-17-spn4mx, #ce567c)";
export var colorChartsPaletteCategorical18 = "var(--color-charts-palette-categorical-18-kxbv8i, #003e38)";
export var colorChartsPaletteCategorical19 = "var(--color-charts-palette-categorical-19-cl97gf, #9469d6)";
export var colorChartsPaletteCategorical20 = "var(--color-charts-palette-categorical-20-gzcnb4, #602400)";
export var colorChartsPaletteCategorical21 = "var(--color-charts-palette-categorical-21-vyqcuq, #4066df)";
export var colorChartsPaletteCategorical22 = "var(--color-charts-palette-categorical-22-6r7gq7, #a32952)";
export var colorChartsPaletteCategorical23 = "var(--color-charts-palette-categorical-23-7c98ot, #0d7d70)";
export var colorChartsPaletteCategorical24 = "var(--color-charts-palette-categorical-24-0i0wmq, #6b40b2)";
export var colorChartsPaletteCategorical25 = "var(--color-charts-palette-categorical-25-7j0tso, #bc4d01)";
export var colorChartsPaletteCategorical26 = "var(--color-charts-palette-categorical-26-eql7mt, #2c46b1)";
export var colorChartsPaletteCategorical27 = "var(--color-charts-palette-categorical-27-8doiqc, #81143b)";
export var colorChartsPaletteCategorical28 = "var(--color-charts-palette-categorical-28-tlstes, #045b52)";
export var colorChartsPaletteCategorical29 = "var(--color-charts-palette-categorical-29-noa7ft, #512994)";
export var colorChartsPaletteCategorical30 = "var(--color-charts-palette-categorical-30-ttbs6s, #8a3603)";
export var colorChartsPaletteCategorical31 = "var(--color-charts-palette-categorical-31-lz0ukh, #1f3191)";
export var colorChartsPaletteCategorical32 = "var(--color-charts-palette-categorical-32-ncpn1t, #da7596)";
export var colorChartsPaletteCategorical33 = "var(--color-charts-palette-categorical-33-fpbcye, #01443e)";
export var colorChartsPaletteCategorical34 = "var(--color-charts-palette-categorical-34-sk46nw, #a783e1)";
export var colorChartsPaletteCategorical35 = "var(--color-charts-palette-categorical-35-wwtyjx, #692801)";
export var colorChartsPaletteCategorical36 = "var(--color-charts-palette-categorical-36-7v0fgy, #5978e3)";
export var colorChartsPaletteCategorical37 = "var(--color-charts-palette-categorical-37-b2rc0w, #b1325c)";
export var colorChartsPaletteCategorical38 = "var(--color-charts-palette-categorical-38-nlzlk1, #1c8e81)";
export var colorChartsPaletteCategorical39 = "var(--color-charts-palette-categorical-39-esgczm, #7749bf)";
export var colorChartsPaletteCategorical40 = "var(--color-charts-palette-categorical-40-0hdln6, #cc5f21)";
export var colorChartsPaletteCategorical41 = "var(--color-charts-palette-categorical-41-bsk8cf, #314fbf)";
export var colorChartsPaletteCategorical42 = "var(--color-charts-palette-categorical-42-97om84, #8b1b42)";
export var colorChartsPaletteCategorical43 = "var(--color-charts-palette-categorical-43-euzcqg, #06645a)";
export var colorChartsPaletteCategorical44 = "var(--color-charts-palette-categorical-44-a7wos6, #59309d)";
export var colorChartsPaletteCategorical45 = "var(--color-charts-palette-categorical-45-iz0wz2, #983c02)";
export var colorChartsPaletteCategorical46 = "var(--color-charts-palette-categorical-46-nzigym, #23379b)";
export var colorChartsPaletteCategorical47 = "var(--color-charts-palette-categorical-47-croxgd, #6f062f)";
export var colorChartsPaletteCategorical48 = "var(--color-charts-palette-categorical-48-tptw1p, #014b44)";
export var colorChartsPaletteCategorical49 = "var(--color-charts-palette-categorical-49-6lrkes, #431d84)";
export var colorChartsPaletteCategorical50 = "var(--color-charts-palette-categorical-50-mqbaxr, #732c02)";
export var colorBackgroundButtonNormalActive = "var(--color-background-button-normal-active-a8zstw, #D0D0D9)";
export var colorBackgroundButtonNormalDefault = "var(--color-background-button-normal-default-9pq06t, #ffffff)";
export var colorBackgroundButtonNormalDisabled = "var(--color-background-button-normal-disabled-0wpv3j, #FFFFFF)";
export var colorBackgroundButtonNormalHover = "var(--color-background-button-normal-hover-z9lq0w, #EDEDF2)";
export var colorBackgroundButtonPrimaryActive = "var(--color-background-button-primary-active-np20o9, #079589)";
export var colorBackgroundButtonPrimaryDefault = "var(--color-background-button-primary-default-p0bd3z, #00DECB)";
export var colorBackgroundButtonPrimaryDisabled = "var(--color-background-button-primary-disabled-31l9pn, #e9ebed)";
export var colorBackgroundButtonPrimaryHover = "var(--color-background-button-primary-hover-kp0svp, #15BEB0)";
export var colorBackgroundCellShaded = "var(--color-background-cell-shaded-7v0jy4, #f8f8f8)";
export var colorBackgroundContainerContent = "var(--color-background-container-content-tahwlv, #FFFFFF)";
export var colorBackgroundContainerHeader = "var(--color-background-container-header-306hge, #FFFFFF)";
export var colorBackgroundControlChecked = "var(--color-background-control-checked-2lg7t1, #00DECB)";
export var colorBackgroundControlDefault = "var(--color-background-control-default-cbyx1x, #ffffff)";
export var colorBackgroundControlDisabled = "var(--color-background-control-disabled-fmj334, #D0D0D9)";
export var colorBackgroundDropdownItemDefault = "var(--color-background-dropdown-item-default-4gbuwv, #ffffff)";
export var colorBackgroundDropdownItemFilterMatch = "var(--color-background-dropdown-item-filter-match-h5rjsp, #f2f8fd)";
export var colorBackgroundDropdownItemHover = "var(--color-background-dropdown-item-hover-h85x0k, #EDEDF2)";
export var colorBackgroundHomeHeader = "var(--color-background-home-header-3z21uf, #0F0F2D)";
export var colorBackgroundInputDefault = "var(--color-background-input-default-hy9gpo, rgba(0,0,0,0))";
export var colorBackgroundInputDisabled = "var(--color-background-input-disabled-ab59a3, #e9ebed)";
export var colorBackgroundItemSelected = "var(--color-background-item-selected-5mmcp9, #ECFBFA)";
export var colorBackgroundLayoutMain = "var(--color-background-layout-main-9l5lye, #F6F6FB)";
export var colorBackgroundLayoutToggleActive = "var(--color-background-layout-toggle-active-aa8dko, #414d5c)";
export var colorBackgroundLayoutToggleDefault = "var(--color-background-layout-toggle-default-o2evu2, #414d5c)";
export var colorBackgroundLayoutToggleHover = "var(--color-background-layout-toggle-hover-awem6j, #5f6b7a)";
export var colorBackgroundLayoutToggleSelectedActive = "var(--color-background-layout-toggle-selected-active-xfk480, #0972d3)";
export var colorBackgroundLayoutToggleSelectedDefault = "var(--color-background-layout-toggle-selected-default-caco0q, #0972d3)";
export var colorBackgroundLayoutToggleSelectedHover = "var(--color-background-layout-toggle-selected-hover-kjak2q, #065299)";
export var colorBackgroundNotificationBlue = "var(--color-background-notification-blue-g2q2z0, #0972d3)";
export var colorBackgroundNotificationGreen = "var(--color-background-notification-green-1jtqmb, #037f0c)";
export var colorBackgroundNotificationRed = "var(--color-background-notification-red-ovptxu, #d91515)";
export var colorBackgroundNotificationYellow = "var(--color-background-notification-yellow-seoi5y, #ffe457)";
export var colorBackgroundPopover = "var(--color-background-popover-g2wwi9, #ffffff)";
export var colorBackgroundSegmentActive = "var(--color-background-segment-active-366806, #0972d3)";
export var colorBackgroundSegmentDefault = "var(--color-background-segment-default-3arros, #ffffff)";
export var colorBackgroundSegmentDisabled = "var(--color-background-segment-disabled-20i2pl, #FFFFFF)";
export var colorBackgroundSegmentHover = "var(--color-background-segment-hover-jysoxv, #ffffff)";
export var colorBackgroundStatusError = "var(--color-background-status-error-rpatq8, #fff7f7)";
export var colorBackgroundStatusInfo = "var(--color-background-status-info-hqz9l0, #f2f8fd)";
export var colorBackgroundStatusSuccess = "var(--color-background-status-success-jp14wa, #f2fcf3)";
export var colorBackgroundStatusWarning = "var(--color-background-status-warning-3ebc0h, #fffce9)";
export var colorBackgroundToggleCheckedDisabled = "var(--color-background-toggle-checked-disabled-jsx0fl, #D0D0D9)";
export var colorBorderButtonNormalActive = "var(--color-border-button-normal-active-x4s2dj, #033160)";
export var colorBorderButtonNormalDefault = "var(--color-border-button-normal-default-u48nzj, #B9B9C6)";
export var colorBorderButtonNormalDisabled = "var(--color-border-button-normal-disabled-40wkb4, #9ba7b6)";
export var colorBorderButtonNormalHover = "var(--color-border-button-normal-hover-i4mavr, #EDEDF2)";
export var colorBorderButtonPrimaryDisabled = "var(--color-border-button-primary-disabled-q34ikb, #e9ebed)";
export var colorBorderContainerTop = "var(--color-border-container-top-736buh, transparent)";
export var colorBorderControlDefault = "var(--color-border-control-default-0fzxw0, #7d8998)";
export var colorBorderDividerDefault = "var(--color-border-divider-default-l3mwda, #EDEDF2)";
export var colorBorderDividerSecondary = "var(--color-border-divider-secondary-o380pj, #EDEDF2)";
export var colorBorderDropdownItemHover = "var(--color-border-dropdown-item-hover-461g78, #7d8998)";
export var colorBorderInputDefault = "var(--color-border-input-default-yxlveu, #B9B9C6)";
export var colorBorderInputFocused = "var(--color-border-input-focused-3d15sl, #033160)";
export var colorBorderItemFocused = "var(--color-border-item-focused-dgpli5, #00DECB)";
export var colorBorderDropdownItemFocused = "var(--color-border-dropdown-item-focused-5hbaf3, #414d5c)";
export var colorBorderItemSelected = "var(--color-border-item-selected-9pi6jb, #00DECB)";
export var colorBorderSegmentActive = "var(--color-border-segment-active-9ah16u, #414d5c)";
export var colorBorderSegmentDefault = "var(--color-border-segment-default-a5zrkd, #414d5c)";
export var colorBorderSegmentDisabled = "var(--color-border-segment-disabled-ylnixy, #414d5c)";
export var colorBorderSegmentHover = "var(--color-border-segment-hover-ggg2rz, #414d5c)";
export var colorBorderStatusError = "var(--color-border-status-error-dj6icm, #d91515)";
export var colorBorderStatusInfo = "var(--color-border-status-info-4bigx2, #0972d3)";
export var colorBorderStatusSuccess = "var(--color-border-status-success-wmgb40, #037f0c)";
export var colorBorderStatusWarning = "var(--color-border-status-warning-52glov, #8d6605)";
export var colorForegroundControlDefault = "var(--color-foreground-control-default-a6mcld, #ffffff)";
export var colorForegroundControlDisabled = "var(--color-foreground-control-disabled-arj71s, #ffffff)";
export var colorTextAccent = "var(--color-text-accent-e0nq1y, #00DECB)";
export var colorTextBodyDefault = "var(--color-text-body-default-rrqlvl, #2D2D53)";
export var colorTextBodySecondary = "var(--color-text-body-secondary-w9jvno, #414d5c)";
export var colorTextBreadcrumbCurrent = "var(--color-text-breadcrumb-current-ld6zt9, #5f6b7a)";
export var colorTextBreadcrumbIcon = "var(--color-text-breadcrumb-icon-3tajtw, #7d8998)";
export var colorTextButtonNormalActive = "var(--color-text-button-normal-active-s0lakm, #033160)";
export var colorTextButtonNormalDefault = "var(--color-text-button-normal-default-657rsr, #2D2D53)";
export var colorTextButtonNormalHover = "var(--color-text-button-normal-hover-poee4w, #2D2D53)";
export var colorTextButtonPrimaryActive = "var(--color-text-button-primary-active-z2kaws, #FFFFFF)";
export var colorTextButtonPrimaryDefault = "var(--color-text-button-primary-default-6ur4ns, #2D2D53)";
export var colorTextButtonPrimaryHover = "var(--color-text-button-primary-hover-3br5ke, #FFFFFF)";
export var colorTextCounter = "var(--color-text-counter-zqugin, #5f6b7a)";
export var colorTextDropdownItemDefault = "var(--color-text-dropdown-item-default-wjgxlf, #121233)";
export var colorTextDropdownItemDisabled = "var(--color-text-dropdown-item-disabled-6oq3n6, #9ba7b6)";
export var colorTextDropdownItemFilterMatch = "var(--color-text-dropdown-item-filter-match-vf4vx2, #0972d3)";
export var colorTextDropdownItemHighlighted = "var(--color-text-dropdown-item-highlighted-tsc4jf, #2D2D53)";
export var colorTextEmpty = "var(--color-text-empty-2wfcyr, #5f6b7a)";
export var colorTextFormDefault = "var(--color-text-form-default-ctcr2x, #121233)";
export var colorTextFormSecondary = "var(--color-text-form-secondary-dxc248, #5f6b7a)";
export var colorTextGroupLabel = "var(--color-text-group-label-jpw8sm, #414d5c)";
export var colorTextLabelGenAi = "var(--color-text-label-gen-ai-76ots7, #7300e5)";
export var colorTextHeadingDefault = "var(--color-text-heading-default-zyf4ia, #2D2D53)";
export var colorTextHeadingSecondary = "var(--color-text-heading-secondary-37b2tq, #414d5c)";
export var colorTextHomeHeaderDefault = "var(--color-text-home-header-default-efg900, #e9ebed)";
export var colorTextHomeHeaderSecondary = "var(--color-text-home-header-secondary-v3y5co, #b6bec9)";
export var colorTextInputDisabled = "var(--color-text-input-disabled-f8pjm5, #9ba7b6)";
export var colorTextInputPlaceholder = "var(--color-text-input-placeholder-mx1ygd, #5f6b7a)";
export var colorTextInteractiveActive = "var(--color-text-interactive-active-ox3ozh, #000716)";
export var colorTextInteractiveDefault = "var(--color-text-interactive-default-lnx6lk, #414d5c)";
export var colorTextInteractiveDisabled = "var(--color-text-interactive-disabled-z7a3t4, #9ba7b6)";
export var colorTextInteractiveHover = "var(--color-text-interactive-hover-29p8hv, #2D2D53)";
export var colorTextInteractiveInvertedDefault = "var(--color-text-interactive-inverted-default-yhw7hw, #d1d5db)";
export var colorTextInteractiveInvertedHover = "var(--color-text-interactive-inverted-hover-zzkght, #fbfbfb)";
export var colorTextLabel = "var(--color-text-label-o6oir3, #121233)";
export var colorTextLayoutToggle = "var(--color-text-layout-toggle-zybz0r, #ffffff)";
export var colorTextLayoutToggleActive = "var(--color-text-layout-toggle-active-p0m0v1, #ffffff)";
export var colorTextLayoutToggleHover = "var(--color-text-layout-toggle-hover-1r0lo8, #0972d3)";
export var colorTextLayoutToggleSelected = "var(--color-text-layout-toggle-selected-b93usl, #ffffff)";
export var colorTextLinkDefault = "var(--color-text-link-default-clkp58, #5C5C79)";
export var colorTextLinkHover = "var(--color-text-link-hover-7qsbis, #0F0F2D)";
export var colorTextNotificationDefault = "var(--color-text-notification-default-rdawv9, #fbfbfb)";
export var colorTextSegmentActive = "var(--color-text-segment-active-6s77n9, #ffffff)";
export var colorTextSegmentDefault = "var(--color-text-segment-default-nncpby, #414d5c)";
export var colorTextSegmentHover = "var(--color-text-segment-hover-k5ujfk, #0972d3)";
export var colorTextStatusError = "var(--color-text-status-error-wdvepn, #d91515)";
export var colorTextStatusInactive = "var(--color-text-status-inactive-m7co45, #5f6b7a)";
export var colorTextStatusInfo = "var(--color-text-status-info-1remvz, #00DECB)";
export var colorTextStatusSuccess = "var(--color-text-status-success-bpiyjm, #037f0c)";
export var colorTextStatusWarning = "var(--color-text-status-warning-yik8vi, #8d6605)";
export var colorTextTopNavigationTitle = "var(--color-text-top-navigation-title-v7rgbf, #000716)";
export var colorBoardPlaceholderActive = "var(--color-board-placeholder-active-jh49z8, #e9ebed)";
export var colorBoardPlaceholderHover = "var(--color-board-placeholder-hover-ombmcs, #d3e7f9)";
export var colorDragPlaceholderActive = "var(--color-drag-placeholder-active-j62q9o, #e9ebed)";
export var colorDragPlaceholderHover = "var(--color-drag-placeholder-hover-4im65s, #d3e7f9)";
export var fontFamilyBase = "var(--font-family-base-ck7vn5, Sora, sans-serif)";
export var fontFamilyMonospace = "var(--font-family-monospace-bftdwf, Monaco, Menlo, Consolas, 'Courier Prime', Courier, 'Courier New', monospace)";
export var fontSizeBodyM = "var(--font-size-body-m-x4okxb, 14px)";
export var fontSizeBodyS = "var(--font-size-body-s-asqx2i, 12px)";
export var fontSizeDisplayL = "var(--font-size-display-l-htkq3e, 42px)";
export var fontSizeHeadingXl = "var(--font-size-heading-xl-ypc05a, 24px)";
export var fontSizeHeadingL = "var(--font-size-heading-l-ou0d0l, 20px)";
export var fontSizeHeadingM = "var(--font-size-heading-m-sjd256, 18px)";
export var fontSizeHeadingS = "var(--font-size-heading-s-yykzif, 16px)";
export var fontSizeHeadingXs = "var(--font-size-heading-xs-wo4hpf, 14px)";
export var fontWeightHeadingXl = "var(--font-weight-heading-xl-yvsksd, 700)";
export var fontWeightHeadingL = "var(--font-weight-heading-l-f8711v, 700)";
export var fontWeightHeadingM = "var(--font-weight-heading-m-m2ekmb, 700)";
export var fontWeightHeadingS = "var(--font-weight-heading-s-cwn6wc, 700)";
export var fontWeightHeadingXs = "var(--font-weight-heading-xs-l7rqme, 700)";
export var lineHeightBodyM = "var(--line-height-body-m-30ar75, 20px)";
export var lineHeightBodyS = "var(--line-height-body-s-7zv1j5, 16px)";
export var lineHeightDisplayL = "var(--line-height-display-l-8nioft, 48px)";
export var lineHeightHeadingXl = "var(--line-height-heading-xl-avbttk, 30px)";
export var lineHeightHeadingL = "var(--line-height-heading-l-mmm3my, 24px)";
export var lineHeightHeadingM = "var(--line-height-heading-m-50evfk, 22px)";
export var lineHeightHeadingS = "var(--line-height-heading-s-4i6ewn, 20px)";
export var lineHeightHeadingXs = "var(--line-height-heading-xs-aeleja, 18px)";
export var borderRadiusAlert = "var(--border-radius-alert-mdtrse, 12px)";
export var borderRadiusBadge = "var(--border-radius-badge-0z09dt, 4px)";
export var borderRadiusButton = "var(--border-radius-button-ypmfry, 20px)";
export var borderRadiusCalendarDayFocusRing = "var(--border-radius-calendar-day-focus-ring-545h0w, 3px)";
export var borderRadiusContainer = "var(--border-radius-container-thqibo, 10px)";
export var borderRadiusControlCircularFocusRing = "var(--border-radius-control-circular-focus-ring-w07mub, 4px)";
export var borderRadiusControlDefaultFocusRing = "var(--border-radius-control-default-focus-ring-u8zbsz, 4px)";
export var borderRadiusDropdown = "var(--border-radius-dropdown-0dhh79, 8px)";
export var borderRadiusFlashbar = "var(--border-radius-flashbar-hcdl9q, 12px)";
export var borderRadiusItem = "var(--border-radius-item-05df9h, 8px)";
export var borderRadiusInput = "var(--border-radius-input-rzg4nx, 5px)";
export var borderRadiusPopover = "var(--border-radius-popover-x6vvtk, 5px)";
export var borderRadiusTabsFocusRing = "var(--border-radius-tabs-focus-ring-1q0rjo, 20px)";
export var borderRadiusTiles = "var(--border-radius-tiles-ddqd1q, 5px)";
export var borderRadiusToken = "var(--border-radius-token-7hj3bb, 5px)";
export var borderRadiusTutorialPanelItem = "var(--border-radius-tutorial-panel-item-kydjvp, 5px)";
export var motionEasingResponsive = "var(--motion-easing-responsive-vbn2t8, cubic-bezier(0, 0, 0, 1))";
export var motionEasingSticky = "var(--motion-easing-sticky-56u8cd, cubic-bezier(1, 0, 0.83, 1))";
export var motionEasingExpressive = "var(--motion-easing-expressive-jz8pes, cubic-bezier(0.84, 0, 0.16, 1))";
export var motionDurationResponsive = "var(--motion-duration-responsive-67fgfp, 115ms)";
export var motionDurationExpressive = "var(--motion-duration-expressive-0by09d, 165ms)";
export var motionDurationComplex = "var(--motion-duration-complex-vy4sma, 250ms)";
export var motionKeyframesFadeIn = "var(--motion-keyframes-fade-in-gpabjb, awsui-fade-in-35003c)";
export var motionKeyframesFadeOut = "var(--motion-keyframes-fade-out-kva0ei, awsui-fade-out-35003c)";
export var motionKeyframesStatusIconError = "var(--motion-keyframes-status-icon-error-ldv006, awsui-status-icon-error-35003c)";
export var motionKeyframesScalePopup = "var(--motion-keyframes-scale-popup-d42fd1, awsui-scale-popup-35003c)";
export var spaceContainerHorizontal = "var(--space-container-horizontal-wfukh3, 20px)";
export var spaceFieldHorizontal = "var(--space-field-horizontal-gg19kw, 12px)";
export var spaceScaledXxxs = "var(--space-scaled-xxxs-27y4hv, 2px)";
export var spaceScaledXxs = "var(--space-scaled-xxs-7597g1, 4px)";
export var spaceScaledXs = "var(--space-scaled-xs-26e2du, 8px)";
export var spaceScaledS = "var(--space-scaled-s-aqzyko, 12px)";
export var spaceScaledM = "var(--space-scaled-m-mo5yse, 16px)";
export var spaceScaledL = "var(--space-scaled-l-0hpmd7, 20px)";
export var spaceScaledXl = "var(--space-scaled-xl-kswcw7, 24px)";
export var spaceScaledXxl = "var(--space-scaled-xxl-wbot5q, 32px)";
export var spaceScaledXxxl = "var(--space-scaled-xxxl-fdg8ai, 40px)";
export var spaceStaticXxxs = "var(--space-static-xxxs-3gu9os, 2px)";
export var spaceStaticXxs = "var(--space-static-xxs-82cdfi, 4px)";
export var spaceStaticXs = "var(--space-static-xs-7sfb63, 8px)";
export var spaceStaticS = "var(--space-static-s-n2eb28, 12px)";
export var spaceStaticM = "var(--space-static-m-lrhgl2, 16px)";
export var spaceStaticL = "var(--space-static-l-0xsx8r, 20px)";
export var spaceStaticXl = "var(--space-static-xl-qalw6z, 24px)";
export var spaceStaticXxl = "var(--space-static-xxl-02zgl2, 32px)";
export var spaceStaticXxxl = "var(--space-static-xxxl-qj5g91, 40px)";
export var shadowContainerActive = "var(--shadow-container-active-kl29x9, 0px 1px 1px 1px #e9ebed, 0px 6px 36px #0007161a)";