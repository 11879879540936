import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ObjectWithContributors, useHasPermission } from 'src/rbac/Permission';

import ButtonDropdown from '@/components/ButtonDropdown';

export type Props = {
  selectedDashboard: ObjectWithContributors | undefined | null;
  onAddWidget: () => void;
  onReset: () => void;
  onSave: () => void;
  onSaveAs: () => void;
  onEdit: () => void;
  onExport: () => void;
  onClear: () => void;
};

const ActionsButton: FC<Props> = ({
  onAddWidget,
  onReset,
  onSave,
  onSaveAs,
  onEdit,
  onExport,
  onClear,
  selectedDashboard,
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'dashboard.actions' });
  const canUpdate = useHasPermission(
    'update:dashboard',
    selectedDashboard ?? undefined
  );
  const getMenuOptions = () => {
    const defaultOptions = [
      { text: t('addWidget'), id: 'add_widget' },
      { text: t('new'), id: 'reset_dashboard' },
      ...(canUpdate ? [{ text: t('save'), id: 'save_dashboard' }] : []),
      {
        text: t('saveAs'),
        id: 'save_as_dashboard',
      },
      ...(canUpdate
        ? [
            {
              text: t('edit'),
              id: 'edit_dashboard',
            },
          ]
        : []),
      { text: t('clear'), id: 'clear' },
      { text: t('export'), id: 'export_dashboard' },
    ];

    return defaultOptions;
  };

  return (
    <ButtonDropdown
      items={getMenuOptions()}
      onItemClick={async (event) => {
        switch (event.detail.id) {
          case 'add_widget':
            onAddWidget();
            return;
          case 'reset_dashboard':
            onReset();
            return;
          case 'save_dashboard':
            onSave();
            return;
          case 'save_as_dashboard':
            onSaveAs();
            return;
          case 'edit_dashboard':
            onEdit();
            return;
          case 'export_dashboard':
            onExport();
            return;
          case 'clear':
            onClear();
            return;
          default:
            return;
        }
      }}
    >
      Actions
    </ButtonDropdown>
  );
};

export default ActionsButton;
