import { OptionDefinition } from '@cloudscape-design/components-themed/internal/components/option/interfaces';
import { FormField } from '@risksmart-app/web/src/components/Form/Form/FormField';
import { isEqual } from 'lodash';
import { useEffect } from 'react';
import { Control, FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ControlledBaseProps } from '@/components/Form';
import HelpLink from '@/components/HelpPanel/HelpLink';
import Tokens from '@/components/Tokens/Tokens';
import { TagType } from '@/types/index';

import { Controller } from '../FieldController/Controller';
import { useIsFieldReadOnly } from '../Form/CustomisableForm/hooks/useIsFieldReadOnly';
import { getSelectedOptions } from '../form-utils';
import Multiselect from '../MultiSelect';
import styles from './style.module.scss';
import { useTagOptions } from './useTagOptions';

interface Props<T extends FieldValues = FieldValues>
  extends Omit<ControlledBaseProps<T>, 'label'> {
  control: Control<T>;
  label?: string;
  disabled?: boolean;
  hideTokens?: boolean;
  testId?: string;
}

export const TagSelector = <T extends FieldValues = FieldValues>({
  control,
  label,
  name,
  placeholder,
  disabled,
  forceRequired,
  defaultRequired,
  hideTokens,
  description,
  testId,
  ...props
}: Props<T>) => {
  const { tags, optionItems: options } = useTagOptions();
  const { setValue } = useFormContext();
  const { t } = useTranslation(['common']);

  const { error } = control.getFieldState(name);
  const readOnly = useIsFieldReadOnly(name);

  const updateTags = (selectedOptions: readonly OptionDefinition[]) => {
    const selectedIds = selectedOptions.map((option) => option.value!);
    setValue('TagTypeIds', selectedIds);
  };
  description = description ?? t('fields.Tags_help');
  label = label ?? t('fields.Tags');
  return (
    <Controller
      defaultRequired={defaultRequired}
      forceRequired={forceRequired}
      name={name}
      control={control}
      render={({ field: { ref, onChange, onBlur, value } }) => {
        const fieldValuesAsOptions = convertTagsToOptions(value || [], tags);
        const selectedOptions = getSelectedOptions(
          fieldValuesAsOptions,
          options
        );

        const selectedValues: TagType[] | undefined = value;
        const removeToken = (itemValue: string) => {
          const newOptions = (selectedValues ?? []).filter(
            (v) => v.TagTypeId !== itemValue
          );
          onChange(newOptions);
        };

        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          updateTags(selectedOptions);
        }, [selectedOptions]);

        return (
          <FormField
            testId={testId}
            className={styles.root}
            label={label}
            info={
              description && (
                <HelpLink id={label} title={label} content={description} />
              )
            }
            errorText={error?.message}
            stretch
            hasFieldChanged={(val) => {
              return !isEqual(val.from, val.to);
            }}
            previewChangesFormatter={(
              val: [{ TagTypeId: string }] | undefined | null
            ) => {
              if (Array.isArray(val)) {
                return val
                  .map((v) => v.TagTypeId)
                  .map((v) => tags?.find((t) => t.TagTypeId === v)?.Name)
                  .join(', ');
              }

              return '-';
            }}
          >
            <Multiselect
              ref={ref}
              options={options}
              selectedOptions={selectedOptions}
              onBlur={onBlur}
              onChange={(e) => {
                onChange(convertOptionsToTagTypes(e.detail.selectedOptions));
              }}
              placeholder={placeholder ?? t('select')}
              empty={t('noMatchedFound')}
              disabled={disabled || readOnly}
              {...props}
              hideTokens
            />
            {!hideTokens && (
              <Tokens
                disabled={disabled || readOnly}
                onRemove={removeToken}
                tokens={selectedOptions.map((o) => ({
                  value: o.value!,
                  label: o.label!,
                }))}
              />
            )}
          </FormField>
        );
      }}
    />
  );
};

/*
  Converts a single tag option definition back to a tag type
*/
const convertOptionToTagType = (option: OptionDefinition): TagType => ({
  Name: option.label || '',
  Description: option.description || '',
  TagTypeId: option.value || '',
});

/*
  Converts an array of option definitions back to tag types
*/
const convertOptionsToTagTypes = (
  options: readonly OptionDefinition[]
): TagType | Array<TagType> =>
  options.map((option) => convertOptionToTagType(option));

/*
  Converts a single tag type to a single tag option definition
*/
const convertTagTypeToOption = (tag: TagType): OptionDefinition => ({
  label: tag.Name || '',
  description: tag.Description || '',
  value: tag.TagTypeId,
});

function convertTagsToOptions(
  input: TagType | Array<TagType>,
  tagTypes: Array<TagType>
): OptionDefinition[] {
  const tags = Array.isArray(input) ? input : [input];
  return tags.map((tag) => {
    const tagType = tagTypes.find(
      ({ TagTypeId }) => TagTypeId === tag.TagTypeId
    );
    if (!tagType) return {};
    return convertTagTypeToOption(tagType);
  });
}
