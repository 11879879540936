import {
  Box,
  Container,
  Link,
  SpaceBetween,
} from '@cloudscape-design/components-themed';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/notification-context';
import { isAxiosError } from 'axios';
import { FC, useState } from 'react';
import { useGetReportUrl } from 'src/data/rest/useGetReportUrl';

interface Props {
  dashboardId: string;
  heading: string;
  description: string;
  author: string;
  imageSrc: string;
}

const ReportsListItem: FC<Props> = (props) => {
  const getReportUrl = useGetReportUrl();
  const [loading, setLoading] = useState(false);
  const { addNotification } = useNotifications();
  const openReport = async (dashboardId: string) => {
    try {
      setLoading(true);
      const report = await getReportUrl(dashboardId);
      window.open(report.url);
    } catch (e) {
      if (isAxiosError(e)) {
        addNotification({
          type: 'error',
          content: <>{e.response?.data?.message || e.message}</>,
        });
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <Container
      media={{
        content: <img src={props.imageSrc} alt="Report" />,
        position: 'side',
        width: '33%',
      }}
    >
      <SpaceBetween direction="vertical" size="s">
        <SpaceBetween direction="vertical" size="xxs">
          <Box variant="h2">
            <Link
              fontSize="heading-m"
              onFollow={() => openReport(props.dashboardId)}
            >
              {props.heading}
            </Link>
          </Box>
          <Box variant="small">{props.author}</Box>
        </SpaceBetween>
        <Box variant="p">{props.description}</Box>
        <Button
          disabled={loading}
          onClick={() => openReport(props.dashboardId)}
        >
          View report
        </Button>
      </SpaceBetween>
    </Container>
  );
};

export default ReportsListItem;
