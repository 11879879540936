import { SideNavigationProps } from '@cloudscape-design/components-themed';

import { Item } from './Item';
import { Icon } from './Navigation';

export interface SectionGroupProps {
  item: SideNavigationProps.SectionGroup & Icon;
}
export const SectionGroup = ({ item }: SectionGroupProps) => {
  return <Item>{item.title}</Item>;
};
