import { useCollection } from '@cloudscape-design/collection-hooks';
import { SpaceBetween } from '@cloudscape-design/components-themed';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/notification-context';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import Table from '@risksmart-app/components/Table';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddControlModal from 'src/pages/control-groups/AddControlModal';
import {
  ControlsTableFields,
  useControlColumnDefinitions,
} from 'src/pages/controls/tab/config';
import { ObjectWithContributors, Permission } from 'src/rbac/Permission';

import EmptyEntityCollection from '@/components/EmptyCollection/EmptyEntityCollection';
import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import RemovalModal from '@/components/RemoveModal';
import TabHeader from '@/components/TabHeader';
import {
  useGetControlsByParentIdQuery,
  useRemoveParentControlsMutation,
} from '@/generated/graphql';
import { useRemoveResultNotification } from '@/hooks/useMutationResultNotification';

const translationKeyPrefix = 'controls';

interface Props {
  parent: ObjectWithContributors;
}

const Tab: FC<Props> = ({ parent }) => {
  useI18NSummaryHelpContent('controls.tabHelp');
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], {
    keyPrefix: translationKeyPrefix,
  });
  const { t: sst } = useTranslation(['common'], {
    keyPrefix: 'addControl',
  });
  const [selectedControls, setSelectedControls] = useState<
    ControlsTableFields[]
  >([]);
  const controlGroupId = useGetGuidParam('controlGroupId');
  const { addNotification } = useNotifications();
  const columnDefinitions = useControlColumnDefinitions();
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [removeControls] = useRemoveParentControlsMutation({});
  const { data, loading, refetch } = useGetControlsByParentIdQuery({
    variables: {
      ParentId: controlGroupId,
    },
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });
  const labelledFields = useMemo<ControlsTableFields[]>(
    () =>
      data?.control.map((c) => ({
        ...c,
        OwnerName: c.owners,
        OverallEffectiveness: c.testResults[0]?.OverallEffectiveness ?? null,
      })) || [],
    [data?.control]
  );

  const { items, collectionProps } = useCollection(labelledFields, {
    propertyFiltering: {
      filteringProperties: [],
      empty: (
        <EmptyEntityCollection
          entityLabel={st('entity_name')}
          action={
            <Permission permission="insert:control" parentObject={parent}>
              <Button
                variant="primary"
                formAction="none"
                onClick={() => setIsAddModalVisible(true)}
              >
                {st('add_button')}
              </Button>
            </Permission>
          }
        />
      ),
    },
    sorting: {
      defaultState: {
        sortingColumn: columnDefinitions[0],
      },
    },
  });
  const onRemove = useRemoveResultNotification({
    asyncAction: async () => {
      await removeControls({
        variables: {
          ParentId: parent.Id,
          ControlIds: selectedControls.map((s) => s.Id),
        },
      });
      setSelectedControls([]);
      refetch();
      setIsDeleteModalVisible(false);
      return true;
    },
    failureAction: () => {
      setIsDeleteModalVisible(false);
    },
    entityName: st('entity_name'),
  });

  return (
    <>
      <Table
        {...collectionProps}
        header={
          <SpaceBetween size="m">
            <TabHeader
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Permission permission="delete:control" parentObject={parent}>
                    <Button
                      formAction="none"
                      variant="normal"
                      disabled={!selectedControls.length}
                      onClick={() => setIsDeleteModalVisible(true)}
                    >
                      {t('remove')}
                    </Button>
                  </Permission>
                  <Permission permission="insert:control" parentObject={parent}>
                    <Button
                      variant="primary"
                      formAction="none"
                      onClick={() => setIsAddModalVisible(true)}
                    >
                      {st('add_button')}
                    </Button>
                  </Permission>
                </SpaceBetween>
              }
            >
              {st('tab_title')}
            </TabHeader>
          </SpaceBetween>
        }
        trackBy="Id"
        selectionType="multi"
        onSelectionChange={({ detail }) => {
          setSelectedControls(detail.selectedItems);
        }}
        selectedItems={selectedControls}
        variant="embedded"
        loading={loading}
        columnDefinitions={columnDefinitions}
        items={items}
        loadingText={st('loading_message') ?? ''}
        sortingDisabled={false}
        resizableColumns={true}
      />
      <RemovalModal
        isVisible={isDeleteModalVisible}
        header={t('remove')}
        onRemove={onRemove}
        onDismiss={() => {
          setIsDeleteModalVisible(false);
        }}
      >
        {sst('confirm_remove_message')}
      </RemovalModal>
      {isAddModalVisible && (
        <AddControlModal
          excludedControlIds={data?.control.map((c) => c.Id)}
          onDismiss={() => {
            setIsAddModalVisible(false);
            refetch();
          }}
          controlGroupId={parent.Id}
        />
      )}
    </>
  );
};

export default Tab;
