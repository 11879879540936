import {
  PropertyFilterProperty,
  PropertyFilterQuery,
  PropertyFilterToken,
} from '@cloudscape-design/collection-hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterPropertyDashboardItem } from '@/components/RegisterDashboard/FilterPropertyDashboardItem';
import RegisterDashboard from '@/components/RegisterDashboard/RegisterDashboard';
import { emptyFilterQuery } from '@/utils/collectionUtils';

import { ObligationTableFields } from './types';

type ObligationSummaryProps = {
  items: readonly ObligationTableFields[] | undefined;
  propertyFilterQuery: PropertyFilterQuery;
  onFilterQueryChanged: (query: PropertyFilterQuery) => void;
  filteringProperties: readonly PropertyFilterProperty<unknown>[];
};

export const ObligationSummary: FC<ObligationSummaryProps> = ({
  items,
  onFilterQueryChanged,
  propertyFilterQuery,
  filteringProperties,
}) => {
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'obligations.dashboard',
  });
  const { t: tr } = useTranslation(['ratings']);

  type PerformanceValue = {
    value: string;
    label: string;
    tokens?: PropertyFilterToken[];
  };
  const performanceValues = tr('performance_summary').map(
    ({ value, label }) => {
      const item: PerformanceValue = { value: `${value}`, label };
      if (value === 3) {
        item.tokens = tr('performance_result')
          .filter(({ value: prValue }) => [3, 4].includes(prValue))
          .map((pr) => ({
            propertyKey: 'LatestAssessmentResultsLabelled',
            value: pr.label,
            operator: '=',
          }));
      }
      return item;
    }
  );

  return (
    <RegisterDashboard>
      {performanceValues.map(({ value, label, tokens }) => {
        return (
          <FilterPropertyDashboardItem
            key={value}
            onClick={onFilterQueryChanged}
            itemFilterQuery={{
              tokens: tokens ?? [
                {
                  propertyKey: 'LatestAssessmentResultsLabelled',
                  value: label,
                  operator: '=',
                },
              ],
              operation: 'or',
            }}
            tableFilterQuery={propertyFilterQuery}
            title={label}
            filteringProperties={filteringProperties}
            items={items ?? []}
          />
        );
      })}
      <FilterPropertyDashboardItem
        onClick={onFilterQueryChanged}
        itemFilterQuery={{
          tokens: [
            {
              propertyKey: 'LatestAssessmentResultsLabelled',
              value: tr('assessment_status').filter(
                ({ value }) => value === 'inprogress'
              )[0].label,
              operator: '=',
            },
          ],
          operation: 'or',
        }}
        tableFilterQuery={propertyFilterQuery}
        title={st('in_progress')}
        items={items ?? []}
        filteringProperties={filteringProperties}
      />
      <FilterPropertyDashboardItem
        onClick={onFilterQueryChanged}
        itemFilterQuery={emptyFilterQuery}
        tableFilterQuery={propertyFilterQuery}
        title={st('all')}
        items={items ?? []}
        filteringProperties={filteringProperties}
      />
    </RegisterDashboard>
  );
};
