import { Box, Container, Grid } from '@cloudscape-design/components-themed';
import Button from '@risksmart-app/components/Button';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PageLayout } from 'src/layouts';

import { logoutUrl, reportAnIssueUrl } from '@/utils/urls';

const Page: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], { keyPrefix: 'issues' });
  const defaultTitle = st('report_issue_title');

  return (
    <PageLayout title={defaultTitle} actions={<></>}>
      <Container>
        <Box
          textAlign="center"
          margin={{ vertical: 'l' }}
          padding={{ vertical: 'xxl' }}
        >
          <Box margin="l" variant="h2">
            {st('issue_submitted_title')}
          </Box>
          <img alt={'Thumbs up'} src="/report-an-issue/thumbsUp.png" />
          <Grid
            gridDefinition={[
              {
                colspan: { xs: 10, s: 8, m: 6 },
                offset: { xs: 1, s: 2, m: 3 },
              },
            ]}
          >
            <Box margin="l" textAlign="center">
              {`We've let an administrator know and have captured your details
            incase they need to contact you.`}
            </Box>
          </Grid>
          <Box margin="l" textAlign="center">
            <Box margin="xxs" display="inline-block">
              <Button
                variant="primary"
                formAction="none"
                onClick={() => navigate(logoutUrl())}
              >
                {t('signOut')}
              </Button>
            </Box>
            <Box margin="xxs" display="inline-block">
              <Button
                formAction="none"
                variant="normal"
                onClick={() => navigate(reportAnIssueUrl())}
              >
                {st('report_another_issue')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </PageLayout>
  );
};

export default Page;
