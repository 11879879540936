import { FeedItem, GenericData } from '@knocklabs/client';
import { useKnockFeed } from '@knocklabs/react-notification-feed';
import { useMemo } from 'react';

import { useGetNotificationListDetailsQuery } from '@/generated/graphql';

import notifications from './NotificationTypes';
import { Lookup, NotificationLookupData } from './NotificationTypes/types';

export type NotificationItem = {
  url: string | null;
  message: string | null;
  feedItem: FeedItem<GenericData>;
};

export const useNotificationItems = (): {
  loading: boolean;
  items: NotificationItem[];
} => {
  const { feedClient, useFeedStore } = useKnockFeed();
  const items = useFeedStore((state) => state.items);

  const variables = useMemo(
    () => ({
      issueIds: getUniqueIds(items, 'objectId').concat(
        getUniqueIds(items, 'issueId')
      ),
      riskIds: getUniqueIds(items, 'objectId'),
      controlIds: getUniqueIds(items, 'objectId'),
      actionIds: getUniqueIds(items, 'objectId').concat(
        getUniqueIds(items, 'actionId')
      ),
      documentFileIds: getUniqueIds(items, 'objectId'),
      documentIds: getUniqueIds(items, 'objectId'),
    }),
    [items]
  );

  const { loading: notificationListDetailsLoading, data } =
    useGetNotificationListDetailsQuery({
      variables,
      skip:
        variables.actionIds.length === 0 &&
        variables.riskIds.length === 0 &&
        variables.controlIds.length === 0 &&
        variables.issueIds.length === 0 &&
        variables.documentIds.length === 0 &&
        variables.documentFileIds.length === 0,
    });

  const lookupData: NotificationLookupData = useMemo(() => {
    return {
      issues: createLookup(data?.issue),
      risks: createLookup(data?.risk),
      controls: createLookup(data?.control),
      actions: createLookup(data?.action),
      documentFiles: createLookup(data?.document_file),
      documents: createLookup(data?.document),
    };
  }, [data]);

  const newItems: NotificationItem[] = items
    .filter((item) => notifications[item.source.key])
    .map((item) => {
      const details = notifications[item.source.key](item, lookupData);
      return {
        url: details.url,
        message: details.message,
        feedItem: item,
      };
    });

  return {
    loading: feedClient.getState().loading || notificationListDetailsLoading,
    items: newItems,
  };
};

function createLookup<T extends { Id: string }>(
  data: T[] | undefined
): Lookup<T> | undefined {
  if (!data) {
    return undefined;
  }
  return data.reduce<Lookup<T>>((previous, dataItem) => {
    previous[dataItem.Id] = dataItem;
    return previous;
  }, {});
}

function onlyUnique<T>(value: T, index: number, array: T[]) {
  return array.indexOf(value) === index;
}

function getUniqueIds(items: FeedItem<GenericData>[], key: string): string[] {
  return items
    .filter((i) => i.data && key in i.data)
    .map((i) => i.data![key] as string)
    .filter(onlyUnique);
}
