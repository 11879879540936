import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { FormContext } from '@/components/Form/Form/FormContext';
import { FormContextProps } from '@/components/Form/Form/types';
import { Parent_Type_Enum } from '@/generated/graphql';

import IndicatorsDetailsFormFields from './IndicatorDetailsFormFields';
import {
  defaultValues,
  IndicatorFormDataFields,
  indicatorSchema,
} from './indicatorSchema';

export type Props = Omit<
  FormContextProps<IndicatorFormDataFields>,
  'schema' | 'formId' | 'parentType' | 'defaultValues' | 'i18n'
> & { isUpdate?: boolean };

const IndicatorDetailsForm: FC<Props> = (props) => {
  const { t } = useTranslation('common');
  return (
    <FormContext
      {...props}
      schema={indicatorSchema}
      defaultValues={defaultValues}
      i18n={t('indicators')}
      formId={'indicator-form'}
      parentType={Parent_Type_Enum.Indicator}
    >
      <IndicatorsDetailsFormFields
        readOnly={props.readOnly}
        isUpdate={props.isUpdate}
      />
    </FormContext>
  );
};

export default IndicatorDetailsForm;
