import { useStorage } from 'src/state/useStorage';

import { TablePreferences, TablePropsWithActions } from '../types';
import { useFiltersFromUrlHash } from './useFiltersFromUrlHash';
import {
  useGetStatelessTableProps,
  UseGetTablePropsOptions,
} from './useGetStatelessTableProps';

export function useGetTableProps<T extends Record<string, unknown>>(
  options: UseGetTablePropsOptions<T>
): TablePropsWithActions<T> {
  const { sortingState, setSortingState, propertyFilter, setPropertyFilter } =
    useFiltersFromUrlHash<T>();

  const [preferences, setPreferences] = useStorage<
    TablePreferences<T> | undefined
  >(undefined, {
    localStorageKey: options.storageKey,
  });

  return useGetStatelessTableProps({
    ...options,
    sortingState,
    setSortingState,
    propertyFilter,
    setPropertyFilter,
    preferences,
    setPreferences,
  });
}
