import { FC } from 'react';

interface Props {
  items: { label: string }[];
}

const CommaSeparatedCell: FC<Props> = ({ items }) => {
  return <span>{items.map((u) => u.label).join(', ')}</span>;
};

export default CommaSeparatedCell;
