import {
  PropertyFilterOperator,
  PropertyFilterProperty,
} from '@cloudscape-design/collection-hooks';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import TagsPopover from '@/components/TagsPopover';
import { TagPartsFragment, useGetTagsQuery } from '@/generated/graphql';

import { FieldConfig } from '../types';

export function useGetTagFieldConfig<
  T extends {
    Id: string;
    tags: TagPartsFragment[];
  },
>(): FieldConfig<T> {
  const { data: tags } = useGetTagsQuery();
  const { t } = useTranslation(['common'], { keyPrefix: 'columns' });
  return useMemo(
    () => ({
      header: t('tags'),
      cell: (item) => <TagsPopover id={item.Id} tags={item.tags} />,
      filterOptions: {
        filteringProperties: createTagFieldPropertyFilter(tags?.tag_type || []),
      },
      exportVal: (item) =>
        item.tags.map((tag) => tag.type?.Name || '').join(','),
    }),
    [t, tags?.tag_type]
  );
}

const createTagFieldPropertyFilter = (
  tags: { Name: string | undefined; TagTypeId: string }[]
): Partial<PropertyFilterProperty> => {
  const getTagById = (tagTypeId: string) =>
    tags.find((t) => t.TagTypeId === tagTypeId)?.Name || '-';
  const hasTag = (tags: unknown, tagTypeId: string) =>
    !!((tags || []) as { Name: string; TagTypeId: string }[]).find(
      (t) => t.TagTypeId === tagTypeId
    );

  const doesNotHaveTag = (tags: unknown, departmentTypeId: string) =>
    !hasTag(tags, departmentTypeId);
  return {
    operators: [
      ...(['=', ':'] as PropertyFilterOperator[]).map((operator) => ({
        operator,
        format: getTagById,
        match: hasTag,
      })),
      ...(['!=', '!:'] as PropertyFilterOperator[]).map((operator) => ({
        operator,
        format: getTagById,
        match: doesNotHaveTag,
      })),
      {
        operator: '<',
        format: (num: string): string => {
          const parsed = z.coerce.number().default(0).safeParse(num);
          if (parsed.success) {
            return parsed.data.toString();
          }
          return '';
        },
        match: (tags: unknown, num: string) => {
          const parsed = z.coerce.number().default(0).safeParse(num);
          if (parsed.success) {
            return ((tags || []) as unknown[]).length < parsed.data;
          }
          return false;
        },
      },
    ],
  };
};
