import i18n from 'i18next';
import { useMemo } from 'react';
import { RiskRegisterFields } from 'src/pages/risks/types';

import { useGetAppetitesGroupedByImpactQuery } from '@/generated/graphql';
import { GetWidgetRisksDocument } from '@/generated/graphql.typed';
import { useRiskScores } from '@/hooks/useRiskScore';
import { Colour, colours } from '@/utils/colours';
import { riskRegisterUrl } from '@/utils/urls';

import { useGetRiskSmartWidgetTableProps } from '../../../risks/config';
import { UNRATED } from '../../Gigawidget/types';
import {
  dateRangeFilter,
  departmentsFilter,
  tagsFilter,
} from '../../Gigawidget/util/filterHelpers';
import { createDataSource } from '../createDataSource';
import {
  dashboardDateClickthroughFilter,
  dateClickthroughFilter,
  defaultClickthroughFilter,
  tagAndDepartmentCategoryGetters,
} from '../dataSourceHelpers';

export default createDataSource({
  hasAccess: () => true,
  documentNode: GetWidgetRisksDocument,
  defaultVariables: { where: {} },
  useTablePropsHook: (data, options) => {
    const { scores } = useRiskScores();
    const customAttributeSchema = useMemo(
      () =>
        data?.form_configuration[0]?.customAttributeSchema
          ? [data.form_configuration[0].customAttributeSchema]
          : [],
      [data?.form_configuration]
    );
    const riskData = useMemo(
      () => data?.risk.map((r) => ({ ...r, assessmentResults: [] })),
      [data]
    );
    const { data: impactAppetites } = useGetAppetitesGroupedByImpactQuery();

    return useGetRiskSmartWidgetTableProps(
      riskData,
      scores,
      impactAppetites?.impact,
      customAttributeSchema,
      options
    );
  },
  customAttributeSchema: (data) =>
    data?.form_configuration[0]?.customAttributeSchema,
  entityNamePlural: 'risk_other',
  entityNameSingular: 'risk_one',
  fields: 'risks.fields',
  dashboardFilterConfig: {
    tagsFilter: (tags) => ({ where: { tags: tagsFilter(tags) } }),
    departmentsFilter: (departments) => ({
      where: { departments: departmentsFilter(departments) },
    }),
    dateFilter: (dateRange, precision) => ({
      where: { CreatedAtTimestamp: dateRangeFilter(dateRange, precision) },
    }),
    dateClickthroughFilter:
      dashboardDateClickthroughFilter('CreatedAtTimestamp'),
  },
  clickThroughUrl: (filter) => riskRegisterUrl(filter),
  categoryGetters: [
    ...tagAndDepartmentCategoryGetters<RiskRegisterFields>(),
    {
      id: 'tier',
      name: () => i18n.t('risks.columns.risk_tier'),
      categoryGetter: (item) => item.TierLabelled,
      clickthroughFilter: defaultClickthroughFilter('TierLabelled'),
    },
    {
      id: 'status',
      name: () => i18n.t('risks.columns.risk_status'),
      categoryGetter: (item) => item.StatusLabelled ?? UNRATED,
      clickthroughFilter: defaultClickthroughFilter('StatusLabelled', {
        unratedValue: '',
      }),
    },
    {
      id: 'treatment',
      name: () => i18n.t('risks.columns.risk_treatment'),
      categoryGetter: (item) => item.TreatmentLabelled ?? UNRATED,
      clickthroughFilter: defaultClickthroughFilter('TreatmentLabelled'),
    },
    {
      id: 'owner',
      name: () => i18n.t('risks.columns.risk_owner'),
      categoryGetter: (item) =>
        item.allOwners.map((owner) => ({ key: owner.id, label: owner.label })),
      clickthroughFilter: (category) => [
        {
          propertyKey: 'allOwners',
          operator: '=',
          value: category.key,
        },
      ],
    },
    {
      id: 'controlledRating',
      name: () => i18n.t('risks.columns.controlled_rating'),
      categoryGetter: (item) => ({
        key: item.ControlledRatingLabelled ?? UNRATED,
        label: item.ControlledRatingLabelled ?? UNRATED,
        sortKey: item.ControlledRating?.toString(),
      }),
      ratingColourKey: 'risk_controlled',
      clickthroughFilter: defaultClickthroughFilter(
        'ControlledRatingLabelled',
        {
          unratedValue: UNRATED,
        }
      ),
      categoryOverrideFunction: (category, ratingFns, riskFormatters) => {
        const rating = riskFormatters({
          residualScore: category.data[0]?.ControlledRating,
        }).getResidual();

        return {
          title: rating?.label ?? UNRATED,
          color:
            colours[(rating?.color ?? 'light-grey') as Colour]
              ?.backgroundColor ?? rating?.color,
        };
      },
    },
    {
      id: 'uncontrolledRating',
      name: () => i18n.t('risks.columns.uncontrolled_rating'),
      categoryGetter: (item) => ({
        key: item.UncontrolledRatingLabelled ?? UNRATED,
        label: item.UncontrolledRatingLabelled ?? UNRATED,
        sortKey: item.UncontrolledRating?.toString(),
      }),
      ratingColourKey: 'risk_uncontrolled',
      clickthroughFilter: defaultClickthroughFilter(
        'UncontrolledRatingLabelled',
        {
          unratedValue: UNRATED,
        }
      ),
      categoryOverrideFunction: (category, ratingFns, riskFormatters) => {
        const rating = riskFormatters({
          inherentScore: category.data[0]?.UncontrolledRating,
        }).getInherent();

        return {
          title: rating?.label ?? UNRATED,
          color:
            colours[(rating?.color ?? 'light-grey') as Colour]
              ?.backgroundColor ?? rating?.color,
        };
      },
    },
    {
      id: 'createdDate',
      name: () => i18n.t('columns.created_on'),
      categoryGetter: (data) => new Date(data.CreatedAtTimestamp),
      date: true,
      dateFilter: (dateRange, precision) => ({
        where: { CreatedAtTimestamp: dateRangeFilter(dateRange, precision) },
      }),
      clickthroughFilter: dateClickthroughFilter('CreatedAtTimestamp'),
    },
  ],
});
