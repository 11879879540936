import { ContentTable } from 'pdfmake/interfaces';
import { useTranslation } from 'react-i18next';

import { useGetIssuesByParentIdLazyQuery } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { toLocalDate } from '@/utils/dateUtils';
import { getOwnerValue } from '@/utils/pdf/ownerValue';
import { createTable, tableHeaders } from '@/utils/pdf/table';
import { getTagsValue } from '@/utils/pdf/tagsValue';

const useIssuesExportTable = (
  parentId: string
): [() => Promise<ContentTable>, boolean] => {
  const [getIssues, getIssuesResult] = useGetIssuesByParentIdLazyQuery({
    variables: {
      ParentId: parentId,
    },
  });
  const { getLabel: getIssueAssessmentStatusLabel } = useRating(
    'issue_assessment_status'
  );
  const { getLabel: getSeverityLabel } = useRating('severity');
  const { t: columns } = useTranslation(['common'], {
    keyPrefix: 'columns',
  });
  const { t: issuesColumns } = useTranslation(['common'], {
    keyPrefix: 'issues.columns',
  });
  const createExportTable = async () => {
    const { data: issueData } = await getIssues();
    const issuesTableData = (issueData?.issue ?? []).map((i) => [
      i.Title,
      getOwnerValue(i),
      getSeverityLabel(i.assessment?.Severity),
      getIssueAssessmentStatusLabel(i.assessment?.Status),
      toLocalDate(i.CreatedAtTimestamp),
      toLocalDate(i.assessment?.TargetCloseDate),
      getTagsValue(i),
    ]);

    return createTable({
      widths: ['*', 50, 50, 70, 70, 50, 70],
      body: [
        tableHeaders([
          issuesColumns('title'),
          columns('owner'),
          issuesColumns('severity'),
          issuesColumns('status'),
          issuesColumns('raised'),
          issuesColumns('target_close_date'),
          columns('tags'),
        ]),
        ...issuesTableData,
      ],
    });
  };
  return [createExportTable, getIssuesResult.loading];
};

export default useIssuesExportTable;
