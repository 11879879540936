import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomAttributeFields } from '@/components/Form/CustomAttributes/CustomAttributeSchema';
import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import { useRating } from '@/hooks/use-rating';
import { useGetContributorsFieldConfig } from '@/utils/table/hooks/useGetContributorsFieldConfig';
import { useGetDepartmentFieldConfig } from '@/utils/table/hooks/useGetDepartmentFieldConfig';
import { useGetOwnersFieldConfig } from '@/utils/table/hooks/useGetOwnersFieldConfig';
import {
  StatefulTableOptions,
  useGetStatelessTableProps,
  UseGetTablePropsOptions,
} from '@/utils/table/hooks/useGetStatelessTableProps';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import { useGetTagFieldConfig } from '@/utils/table/hooks/useGetTagFieldConfig';
import { TableFields, TablePropsWithActions } from '@/utils/table/types';
import { dateColumn } from '@/utils/table/utils/dateColumn';
import { yesNoCell } from '@/utils/table/utils/yesNoCell';
import { actionDetailsUrl } from '@/utils/urls';

import { ActionFields, ActionTableFields } from './types';
import { useLabelledFields } from './useLabelledFields';

const useGetFieldConfig = (): TableFields<ActionTableFields> => {
  const allOwners = useGetOwnersFieldConfig<ActionTableFields>();
  const allContributors = useGetContributorsFieldConfig<ActionTableFields>();
  const tagField = useGetTagFieldConfig<ActionTableFields>();
  const departmentField = useGetDepartmentFieldConfig<ActionTableFields>();
  const { getByValue: getPriority } = useRating('priority');
  const { getByValue: getStatus } = useRating('action_status');
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'actions.columns',
  });
  const { t } = useTranslation(['common'], {
    keyPrefix: 'columns',
  });
  return useMemo(
    () => ({
      SequentialIdLabel: { header: t('id'), sortingField: 'SequentialId' },
      Title: {
        header: st('title'),
        cell: (item) => (
          <Link variant="secondary" href={actionDetailsUrl(item.Id)}>
            {item.Title}
          </Link>
        ),
        isRowHeader: true,
      },
      allOwners,
      allContributors,
      ParentTitle: {
        header: st('parent_title'),
        cell: (item) => {
          if (!item.ParentTitle) {
            return '-';
          }
          return `${item.ParentTitle} (${item.ParentType})`;
        },
      },
      DateRaised: dateColumn(st('date_raised'), 'DateRaised'),
      DateDue: dateColumn(st('due_date'), 'DateDue'),
      ClosedDate: dateColumn(st('closed_date'), 'ClosedDate'),
      StatusLabelled: {
        header: st('status'),
        cell: (item) => {
          return <SimpleRatingBadge rating={getStatus(item.Status)} />;
        },
      },
      PriorityLabelled: {
        header: st('priority'),
        cell: (item) => {
          return <SimpleRatingBadge rating={getPriority(item.Priority)} />;
        },
        sortingField: 'Priority',
      },
      tags: tagField,
      departments: departmentField,
      Id: { header: t('guid') },
      ParentId: { header: st('parent_id') },
      ParentType: { header: st('parent_type') },
      ModifiedAtTimestamp: dateColumn(t('updated_on'), 'ModifiedAtTimestamp'),
      ModifiedByUser: { header: t('updated_by_id') },
      ModifiedByUserName: { header: st('modified_by_username') },
      CreatedByUserName: { header: st('created_by_username') },
      Overdue: { header: st('overdue'), cell: yesNoCell('Overdue') },
      UpdateCount: { header: st('updateCount') },
      LatestUpdateCreatedAtTimestamp: dateColumn(
        st('latestUpdateCreatedAtTimestamp'),
        'LatestUpdateCreatedAtTimestamp'
      ),
      LatestUpdateDescription: { header: st('latestUpdateDescription') },
      LatestUpdateTitle: { header: st('latestUpdateTitle') },
      Description: { header: st('description') },
    }),
    [
      allContributors,
      allOwners,
      departmentField,
      getPriority,
      getStatus,
      st,
      t,
      tagField,
    ]
  );
};

const useGetActionTableProps = (
  records: ActionFields[] | undefined,
  customAttributeSchema?: CustomAttributeFields | null
): UseGetTablePropsOptions<ActionTableFields> => {
  const labelledFields = useLabelledFields(records);
  const { t } = useTranslation(['common'], { keyPrefix: 'actions' });
  const fields = useGetFieldConfig();
  return {
    data: labelledFields,
    customAttributeSchema: customAttributeSchema,
    entityLabel: t('entity_name'),
    emptyCollectionAction: <></>,
    storageKey: 'ActionsRegisterTable-PreferencesV1',
    enableFiltering: true,
    initialColumns: [
      'Title',
      'owners',
      'ParentTitle',
      'DateRaised',
      'DateDue',
      'ClosedDate',
      'StatusLabelled',
      'PriorityLabelled',
      'tags',
    ],
    fields,
  };
};

export const useGetCollectionTableProps = (
  records: ActionFields[] | undefined,
  customAttributeSchema?: CustomAttributeFields | null
): TablePropsWithActions<ActionTableFields> => {
  const props = useGetActionTableProps(records, customAttributeSchema);
  return useGetTableProps(props);
};

export const useGetActionSmartWidgetTableProps = (
  records: ActionFields[] | undefined,
  customAttributeSchema: CustomAttributeFields | null,
  statefulTableOptions: StatefulTableOptions<ActionTableFields>
): TablePropsWithActions<ActionTableFields> => {
  const props = useGetActionTableProps(records, customAttributeSchema);
  return useGetStatelessTableProps<ActionTableFields>({
    ...props,
    ...statefulTableOptions,
    enableFiltering: false,
  });
};
