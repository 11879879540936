import { TableProps } from '@cloudscape-design/components-themed';
import { useTranslation } from 'react-i18next';

import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import { GetObligationImpactsByParentIdQuery } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';

export type ImpactFields =
  GetObligationImpactsByParentIdQuery['obligation_impact'][0];

export function usePerformanceColumnDefinitions(
  onEdit: (impact: ImpactFields) => void
) {
  const { getByValue } = useRating('impact');
  const { t } = useTranslation(['common'], { keyPrefix: 'impacts' });
  const impactColumnDefinitions: TableProps<ImpactFields>['columnDefinitions'] =
    [
      {
        id: 'title',
        header: t('columns.Title'),
        cell: (item) => (
          <Link variant="secondary" href="#" onFollow={() => onEdit(item)}>
            {item.Description}
          </Link>
        ),
        sortingField: 'Impact of non-adherence',
        isRowHeader: true,
      },
      {
        id: 'impact',
        header: t('columns.Impact'),
        cell: (item) => (
          <SimpleRatingBadge rating={getByValue(item.ImpactRating || 0)} />
        ),
        sortingField: 'Impact',
      },
    ];
  return impactColumnDefinitions;
}
