import { Box } from '@cloudscape-design/components-themed';
import { FC, MutableRefObject, ReactNode, useRef, useState } from 'react';

import { getColorStyles } from '@/utils/colours';

import ChartPopover from '../../../../../../../node_modules/@cloudscape-design/components-themed/internal/components/chart-popover';
import { WidgetLoading } from '../WidgetLoading';
import styles from './style.module.scss';

type Props = {
  loading?: boolean;
  xAxisTitle: string;
  yAxisTitle: string;
  // eslint-disable-next-line etc/no-commented-out-code
  /** [y-axis][x-axis] */
  data: HeatmapCellData[][];
  renderPopoverContent: (data: TooltipData) => ReactNode;
  onCellClick?: (data: CellClickData) => void;
};

export type HeatmapCellData = {
  value: number;
  background: string;
  label?: string;
};

type TooltipData = {
  data: HeatmapCellData;
  ref: MutableRefObject<HTMLDivElement | null>;
  x: number;
  y: number;
};

type CellClickData = {
  data: HeatmapCellData;
  x: number;
  y: number;
};

const HeatmapWidget: FC<Props> = ({
  xAxisTitle,
  yAxisTitle,
  data,
  renderPopoverContent,
  loading,
  onCellClick,
}) => {
  const [tooltipData, setTooltipData] = useState<TooltipData>();
  if (loading) {
    return <WidgetLoading />;
  }
  const rows: ReactNode[] = [];
  for (let row = data.length - 1; row >= 0; row--) {
    const rowData = data[row];
    const rowCells: ReactNode[] = [];

    for (let column = 0; column < rowData.length; column++) {
      const cellData = rowData[column];
      rowCells.push(
        <HeatmapCell
          onClick={(data) =>
            onCellClick?.({
              data,
              y: row,
              x: column,
            })
          }
          key={`${row}-${column}`}
          data={cellData}
          onMouseOver={(ref, data) =>
            setTooltipData({
              ref,
              data,
              y: row,
              x: column,
            })
          }
        />
      );
    }
    rows.push(
      <div key={row} className={styles.row}>
        {rowCells}
      </div>
    );
  }
  return (
    <div className={styles.gridContainer}>
      <div className={styles.yAxisTitle}>
        <Box variant="h5" margin={{ left: 'xs' }}>
          {yAxisTitle}
        </Box>
      </div>
      <div
        className={styles.table}
        onMouseLeave={() => setTooltipData(undefined)}
      >
        {rows}
      </div>
      <div className={styles.xAxisTitle}>
        <Box variant="h5" margin={{ top: 'xxs' }}>
          {xAxisTitle}
        </Box>
      </div>
      {tooltipData && (
        <ChartPopover
          title={<></>}
          trackRef={tooltipData.ref}
          trackKey={1}
          onDismiss={() => setTooltipData(undefined)}
          container={null}
          size="small"
          onMouseLeave={() => setTooltipData(undefined)}
        >
          {renderPopoverContent(tooltipData)}
        </ChartPopover>
      )}
    </div>
  );
};

type HeatmapCellProps = {
  data: HeatmapCellData;
  onClick?: (data: HeatmapCellData) => void;
  onMouseOver: (
    ref: MutableRefObject<HTMLDivElement | null>,
    data: HeatmapCellData
  ) => void;
};

const HeatmapCell: FC<HeatmapCellProps> = ({ data, onMouseOver, onClick }) => {
  const popoverRef = useRef<HTMLDivElement | null>(null);
  const className = styles.cell;
  const style = getColorStyles(data.background);
  return (
    <div
      ref={popoverRef}
      className={className}
      onClick={() => onClick?.(data)}
      onMouseOver={() => onMouseOver(popoverRef, data)}
      {...{ style }}
    >
      <div className={styles.innerCell}>
        <Box variant="span">{data.value}</Box>
      </div>
    </div>
  );
};

export default HeatmapWidget;

export const initializeHeatmapCells = (
  y: number,
  x: number,
  getCellConfig: (
    r: number,
    c: number
  ) => { background: string; label?: string }
): HeatmapCellData[][] => {
  const cells: HeatmapCellData[][] = [];
  for (let r = 0; r < y; r++) {
    const column: HeatmapCellData[] = [];
    for (let c = 0; c < x; c++) {
      const cellData = getCellConfig(r, c);
      column.push({
        value: 0,
        background: cellData.background,
        label: cellData.label,
      });
    }
    cells.push(column);
  }
  return cells;
};
