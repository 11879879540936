import { TabsProps } from '@cloudscape-design/components-themed';

import Tab from './tabs/Tab';
export const useTabs = ({
  acceptanceId,
  parentId,
}: {
  acceptanceId?: string;
  parentId?: string;
}) => {
  const tabs: TabsProps.Tab[] = [
    {
      // TODO translations
      label: 'Details',
      id: 'details',
      content: <Tab Id={acceptanceId} ParentId={parentId} />,
    },
  ];

  return tabs;
};
