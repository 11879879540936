import { SpaceBetween } from '@cloudscape-design/components-themed';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/notification-context';
import Table from '@risksmart-app/components/Table';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout } from 'src/layouts';
import { AssessmentSummary } from 'src/pages/assessments/AssessmentSummary';
import { useGetCollectionTableProps } from 'src/pages/assessments/config';
import { Permission } from 'src/rbac/Permission';

import { useGetComplianceMonitoringAssessmentsQuery } from '@/generated/graphql';
import { handleError } from '@/utils/errorUtils';
import { complianceMonitoringAssessmentAddUrl } from '@/utils/urls';

const ComplianceMonitoringAssessmentsPage: FC = () => {
  const { t } = useTranslation('common');
  const { addNotification } = useNotifications();
  const { t: st } = useTranslation('common', {
    keyPrefix: 'complianceMonitoringAssessment',
  });
  const { data, loading } = useGetComplianceMonitoringAssessmentsQuery({
    onError: (error) => {
      handleError(error);
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });
  const tableProps = useGetCollectionTableProps(
    'compliance_monitoring_assessment',
    data?.compliance_monitoring_assessment,
    data?.form_configuration?.[0]?.customAttributeSchema
  );
  const assessmentCount = useMemo(() => {
    if (loading) {
      return '';
    }
    return `(${data?.compliance_monitoring_assessment?.length})`;
  }, [data, loading]);
  return (
    <PageLayout
      helpTranslationKey={'complianceMonitoringAssessment.registerHelp'}
      title={st('register_title')}
      counter={assessmentCount}
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button iconName="download" onClick={tableProps.exportToCsv}>
            {t('export')}
          </Button>
          <Permission permission={'insert:compliance_monitoring_assessment'}>
            <Button
              variant="primary"
              href={complianceMonitoringAssessmentAddUrl()}
            >
              {st('create_new_button')}
            </Button>
          </Permission>
        </SpaceBetween>
      }
    >
      <AssessmentSummary
        propertyFilterQuery={tableProps.propertyFilterQuery}
        items={tableProps?.allItems}
        onFilterQueryChanged={tableProps.actions.setPropertyFiltering}
        filteringProperties={tableProps.filteringProperties}
        assessmentMode={'compliance_monitoring_assessment'}
      />
      <Table {...tableProps} loading={loading} />
    </PageLayout>
  );
};

export default ComplianceMonitoringAssessmentsPage;
