import {
  ItemsPalette,
  ItemsPaletteProps,
} from '@cloudscape-design/board-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { WidgetDefinition } from '../types';
import EmptyPalette from './EmptyPalette';
import { WidgetBoardItem } from './WidgetBoardItem';

type ItemPaletteProps = {
  items: ItemsPaletteProps.Item<WidgetDefinition>[];
};

const ItemPalette: FC<ItemPaletteProps> = ({ items }) => {
  const { t } = useTranslation(['common']);
  return (
    <div>
      {items.length === 0 && <EmptyPalette />}
      <ItemsPalette
        items={items}
        renderItem={(item, context) => (
          <WidgetBoardItem
            widget={item.data}
            showPreview={context.showPreview}
          />
        )}
        i18nStrings={{
          liveAnnouncementDndStarted: t(
            'dashboard.live_announcement_dnd_started'
          ),
          liveAnnouncementDndDiscarded: t(
            'dashboard.live_announcement_dnd_discarded'
          ),
          navigationAriaLabel: t('dashboard.palette_navigation_aria_label'),
          navigationAriaDescription: t(
            'dashboard.palette_navigation_aria_description'
          ),
          navigationItemAriaLabel: (item) =>
            t(item.data.translationKeyPrefix, { returnObjects: true })?.title ??
            '',
        }}
      />
    </div>
  );
};

export default ItemPalette;
