import i18n from 'src/i18n';

import { publicPolicyFileUrl } from '@/utils/urls';

import { GetItem } from './types';

export const getItem: GetItem = (item) => {
  const url = publicPolicyFileUrl(
    item.data?.parentObjectId ?? '',
    item.data?.objectId ?? ''
  );

  return {
    message: i18n.t('notifications.messages.policyAttestationReminder'),
    url,
  };
};
