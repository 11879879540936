import { useTranslation } from 'react-i18next';

import {
  GetAuditLogsQuery,
  Risksmart_Audit_Log_Bool_Exp,
  useGetAuditLogsLazyQuery,
  useGetUsersQuery,
} from '@/generated/graphql';
import { CollectionData } from '@/utils/collectionUtils';
import { useGetLazyTableProps } from '@/utils/table/hooks/useGetLazyTableProps';
import { LazyDataset, TableFields } from '@/utils/table/types';
import { dateColumn } from '@/utils/table/utils/dateColumn';
import { translatedExactFilterOperators } from '@/utils/table/utils/filterUtils';

type AuditLogFields = CollectionData<
  GetAuditLogsQuery['risksmart_audit_log'][0]
>;

export type AuditLogRegisterFields = Omit<AuditLogFields, 'PerformedByUser'> & {
  Id: string;
  PerformedByUser: string;
};

const snakeToTitleCase = (snakeStr: string): string => {
  return snakeStr
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

const useAuditFields = () => {
  const { t } = useTranslation(['common']);
  const { data: users } = useGetUsersQuery();
  const auditLogTypes = t('objectTypes');
  const auditLogActions = t('auditLog.auditLogActions');

  const objectTypeFilteringOptions = Object.entries(auditLogTypes).map(
    ([value, label]) => ({ value, label })
  );

  const actionFilteringOptions = Object.entries(auditLogActions).map(
    ([value, label]) => ({ value, label })
  );

  const fields: TableFields<AuditLogRegisterFields> = {
    ObjectType: {
      header: t('columns.type'),
      sortingDisabled: true,
      filterOptions: {
        filteringProperties: {
          operators: translatedExactFilterOperators(objectTypeFilteringOptions),
        },
        filteringOptions: objectTypeFilteringOptions,
      },
    },
    Action: {
      header: t('columns.action'),
      sortingDisabled: true,
      filterOptions: {
        filteringProperties: {
          operators: translatedExactFilterOperators(actionFilteringOptions),
        },
        filteringOptions: actionFilteringOptions,
      },
    },
    Item: {
      header: t('columns.item'),
    },
    Id: {
      header: t('columns.id'),
    },
    PerformedByUser: {
      header: t('columns.action_performed_by'),
      sortingField: 'PerformedByUser.UserName',
      filterOptions: {
        filteringProperties: { key: 'PerformedByUser.UserName' },
        filteringOptions: users?.user
          .filter((u) => u.FriendlyName !== null)
          .map((u) => ({
            value: u.FriendlyName!,
            label: u.FriendlyName!,
          })),
      },
    },
    ModifiedAtTimestamp: dateColumn(
      t('columns.datetime'),
      'ModifiedAtTimestamp',
      undefined,
      true
    ),
  };

  return fields;
};

export const useGetAuditTableProps = () => {
  const fields = useAuditFields();
  const { t } = useTranslation(['taxonomy', 'common']);
  const actionFieldMapping = t('common:auditLog.auditLogActions', {
    returnObjects: true,
  });
  const objectTypeMapping = t('common:objectTypes', {
    returnObjects: true,
  });
  const [getAuditLogs] = useGetAuditLogsLazyQuery();

  const fetchAuditLogs: LazyDataset<AuditLogRegisterFields> = async ({
    limit,
    offset,
    orderBy,
    where,
  }) => {
    const { PerformedByUser: _, ...ordering } = orderBy;
    const { data } = await getAuditLogs({
      variables: {
        limit,
        offset,
        where: where as Risksmart_Audit_Log_Bool_Exp,
        orderBy: [ordering],
      },
    });

    const getTranslatedObjectType = (string: string | null | undefined) => {
      return string !== null
        ? objectTypeMapping?.[string as keyof typeof objectTypeMapping] ??
            snakeToTitleCase(string ?? '')
        : 'Unknown';
    };

    const getItemField = (
      item: GetAuditLogsQuery['risksmart_audit_log'][0]
    ) => {
      if (item.ObjectType === 'form_configuration') {
        return getTranslatedObjectType(item.Item);
      } else {
        return item.Item ?? '-';
      }
    };

    const mappedData =
      data?.risksmart_audit_log.map(
        (d) =>
          ({
            ...d,
            Action:
              actionFieldMapping?.[
                d.Action as keyof typeof actionFieldMapping
              ] ?? '',
            ObjectType: getTranslatedObjectType(d.ObjectType),
            PerformedByUser: d.PerformedByUser?.FriendlyName ?? 'System',
            Id: d.Id ?? '-',
            Item: getItemField(d),
          }) satisfies AuditLogRegisterFields
      ) ?? [];

    return {
      data: mappedData,
      totalCount: data?.risksmart_audit_log_aggregate.aggregate?.count ?? 0,
    };
  };

  return useGetLazyTableProps({
    data: fetchAuditLogs,
    entityLabel: 'audit_log',
    emptyCollectionAction: <></>,
    storageKey: 'AuditLogRegisterTable-PreferencesV2',
    enableFiltering: true,
    initialColumns: [
      'ObjectType',
      'Action',
      'Item',
      'Id',
      'PerformedByUser',
      'ModifiedAtTimestamp',
    ],
    fields,
    defaultSortingState: {
      sortingColumn: 'ModifiedAtTimestamp',
      sortingDirection: 'desc',
    },
  });
};
