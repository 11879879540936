
    import './styles.scoped.css';
    export default {
  "button-dropdown": "awsui_button-dropdown_sne0l_1ixq8_143",
  "items-list-container": "awsui_items-list-container_sne0l_1ixq8_147",
  "awsui-motion-fade-in-0": "awsui_awsui-motion-fade-in-0_sne0l_1ixq8_1",
  "rotate-up": "awsui_rotate-up_sne0l_1ixq8_166",
  "rotate-down": "awsui_rotate-down_sne0l_1ixq8_181",
  "header": "awsui_header_sne0l_1ixq8_196",
  "title": "awsui_title_sne0l_1ixq8_207",
  "description": "awsui_description_sne0l_1ixq8_208",
  "split-trigger-wrapper": "awsui_split-trigger-wrapper_sne0l_1ixq8_212",
  "trigger-item": "awsui_trigger-item_sne0l_1ixq8_215",
  "trigger-button": "awsui_trigger-button_sne0l_1ixq8_218",
  "visual-refresh": "awsui_visual-refresh_sne0l_1ixq8_229",
  "split-trigger": "awsui_split-trigger_sne0l_1ixq8_212",
  "dropdown-trigger": "awsui_dropdown-trigger_sne0l_1ixq8_237",
  "test-utils-button-trigger": "awsui_test-utils-button-trigger_sne0l_1ixq8_241"
};
  