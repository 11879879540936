import { ApolloCache } from '@apollo/client';
import { useNotifications } from '@risksmart-app/components/Notifications/notification-context';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  defaultValues,
  IssueAssessmentFields,
  IssueAssessmentSchema,
} from 'src/pages/issues/update/forms/IssueAssessmentForm/issueAssessmentSchema';
import { useHasPermission } from 'src/rbac/Permission';

import { Form } from '@/components/Form';
import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import {
  GetIssueAssessmentByParentIdQuery,
  GetIssueByIdQuery,
  Parent_Type_Enum,
  useGetIssueAssessmentByParentIdQuery,
  useInsertIssueAssessmentMutation,
  useUpdateIssueAssessmentMutation,
} from '@/generated/graphql';
import { evictField } from '@/utils/graphqlUtils';

import IssueAssessmentForm from '../../forms/IssueAssessmentForm/IssueAssessmentForm';

type Props = {
  issue: GetIssueByIdQuery['issue'][number];
};

export const getAssociatedControlIds = (
  data: GetIssueAssessmentByParentIdQuery | undefined
) =>
  data?.issue_parent
    .filter((i) => i.parent?.ObjectType === Parent_Type_Enum.Control)
    .map((i) => i.ParentId) ?? [];

const Tab: FC<Props> = ({ issue }) => {
  const navigate = useNavigate();
  useI18NSummaryHelpContent('issueAssessment.help');
  const { addNotification } = useNotifications();
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'issueAssessment',
  });
  const { t } = useTranslation(['common']);
  const issueId = useGetGuidParam('issueId');
  const { data, refetch } = useGetIssueAssessmentByParentIdQuery({
    variables: {
      parentIssueId: issueId,
    },
    fetchPolicy: 'no-cache',
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });
  const canCreateIssueAssessment = useHasPermission(
    'insert:issue_assessment',
    issue
  );
  const canEditIssueAssessment = useHasPermission(
    'update:issue_assessment',
    issue
  );
  const assessment = data?.issue_assessment?.[0];
  const obligationIds =
    data?.issue_parent
      .filter((i) => i.parent?.ObjectType === Parent_Type_Enum.Obligation)
      .map((i) => i.ParentId) ?? [];
  const controlIds = getAssociatedControlIds(data);
  const documentIds =
    data?.issue_parent
      .filter((i) => i.parent?.ObjectType === Parent_Type_Enum.Document)
      .map((i) => i.ParentId) ?? [];

  const canModify = assessment
    ? canEditIssueAssessment
    : canCreateIssueAssessment;

  const clearCache = (cache: ApolloCache<unknown>) => {
    evictField(cache, 'issue');
    evictField(cache, 'control');
    evictField(cache, 'issue_assessment_aggregate');
    evictField(cache, 'issue_assessment_audit');
  };

  const [updateIssueAssessment] = useUpdateIssueAssessmentMutation({
    update: clearCache,
  });
  const [insertIssueAssessment] = useInsertIssueAssessmentMutation({
    update: clearCache,
  });

  const onSave = async (data: IssueAssessmentFields) => {
    const policyBreachVariables: Partial<IssueAssessmentFields> =
      data.PolicyBreach
        ? {}
        : {
            PoliciesBreached: undefined,
            PolicyOwner: undefined,
            PolicyOwnerCommentary: undefined,
          };

    const thirdPartyVariables: Partial<IssueAssessmentFields> =
      data.IssueCausedByThirdParty
        ? {}
        : {
            ThirdPartyResponsible: undefined,
          };

    const systemVariables: Partial<IssueAssessmentFields> =
      data.IssueCausedBySystemIssue
        ? {}
        : {
            SystemResponsible: undefined,
          };

    const regulationVariables: Partial<IssueAssessmentFields> =
      data.RegulatoryBreach
        ? {}
        : {
            Rationale: undefined,
            RegulationsBreached: undefined,
            Reportable: undefined,
          };

    if (assessment) {
      await updateIssueAssessment({
        variables: {
          ...data,
          Id: assessment.Id,
          OriginalTimestamp: assessment.ModifiedAtTimestamp,
          ...thirdPartyVariables,
          ...regulationVariables,
          ...systemVariables,
          ...policyBreachVariables,

          AssociatedControlIds: data.AssociatedControlIds.map((a) => a.value),
          RegulationsBreachedIds: data.RegulationsBreachedIds.map(
            (a) => a.value
          ),
          PoliciesBreachedIds: data.PoliciesBreachedIds.map((a) => a.value),
          PolicyOwner: data.PolicyOwner?.value,
          CertifiedIndividual: data.CertifiedIndividual?.value,
        },
      });
    } else {
      await insertIssueAssessment({
        variables: {
          ...data,
          ParentIssueId: issueId,
          ...thirdPartyVariables,
          ...regulationVariables,
          ...systemVariables,
          ...policyBreachVariables,
          AssociatedControlIds: data.AssociatedControlIds.map((a) => a.value),
          RegulationsBreachedIds: data.RegulationsBreachedIds.map(
            (a) => a.value
          ),
          PoliciesBreachedIds: data.PoliciesBreachedIds.map((a) => a.value),
          PolicyOwner: data.PolicyOwner?.value,
          CertifiedIndividual: data.CertifiedIndividual?.value,
        },
      });
    }
    await refetch();
  };

  return (
    <>
      <Form
        formId="update-issue-assessment-form"
        values={{
          ...defaultValues,
          ...assessment,
          tags: issue.tags,
          AssociatedControlIds: controlIds.map((id) => ({ value: id })) ?? [],
          RegulationsBreachedIds:
            obligationIds.map((id) => ({ value: id })) ?? [],
          PoliciesBreachedIds: documentIds.map((id) => ({ value: id })) ?? [],
          CertifiedIndividual: assessment?.CertifiedIndividual
            ? { value: assessment.CertifiedIndividual, type: 'user' }
            : null,
          PolicyOwner: assessment?.PolicyOwner
            ? { value: assessment.PolicyOwner, type: 'user' }
            : null,
        }}
        defaultValues={defaultValues}
        i18n={t('issueAssessment')}
        onSave={onSave}
        onDismiss={() => navigate('..')}
        schema={IssueAssessmentSchema}
        readOnly={!canModify}
        header={st('tab_title')}
        parentType={Parent_Type_Enum.IssueAssessment}
        approvalConfig={{ object: { Id: assessment?.Id ?? '' } }}
      >
        <IssueAssessmentForm readOnly={!canModify} />
      </Form>
    </>
  );
};

export default Tab;
