import {
  PropertyFilterProperty,
  PropertyFilterQuery,
} from '@cloudscape-design/collection-hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterPropertyDashboardItem } from '@/components/RegisterDashboard/FilterPropertyDashboardItem';
import RegisterDashboard from '@/components/RegisterDashboard/RegisterDashboard';
import { Version_Status_Enum } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { emptyFilterQuery } from '@/utils/collectionUtils';
import { getCurrentDayDate } from '@/utils/dateUtils';

import { getReviewDueDateCutoff } from './helpers';
import { PolicyRegisterFields } from './types';

type Props = {
  items: readonly PolicyRegisterFields[] | undefined;
  propertyFilterQuery: PropertyFilterQuery;
  onFilterQueryChanged: (query: PropertyFilterQuery) => void;
  filteringProperties: readonly PropertyFilterProperty<unknown>[];
};

export const PolicySummary: FC<Props> = ({
  items,
  onFilterQueryChanged,
  propertyFilterQuery,
  filteringProperties,
}) => {
  const currentDate = getCurrentDayDate();
  const { t } = useTranslation(['common'], {
    keyPrefix: 'policy.summary_category_titles',
  });
  const { getLabel: getDocumentFileStatusLabel } = useRating(
    'document_file_status'
  );
  const statusLabels = [
    getDocumentFileStatusLabel(Version_Status_Enum.Published),
    getDocumentFileStatusLabel(Version_Status_Enum.PendingApproval),
    getDocumentFileStatusLabel(Version_Status_Enum.Draft),
  ];

  return (
    <RegisterDashboard>
      {statusLabels.map((statusLabel) => (
        <FilterPropertyDashboardItem
          key={statusLabel}
          onClick={onFilterQueryChanged}
          tableFilterQuery={propertyFilterQuery}
          itemFilterQuery={{
            tokens: [
              {
                propertyKey: 'Status',
                value: statusLabel,
                operator: '=',
              },
            ],
            operation: 'and',
          }}
          title={statusLabel}
          items={items ?? []}
          filteringProperties={filteringProperties}
        />
      ))}
      <FilterPropertyDashboardItem
        onClick={onFilterQueryChanged}
        tableFilterQuery={propertyFilterQuery}
        itemFilterQuery={{
          tokens: [
            {
              propertyKey: 'NextReviewDate',
              value: getReviewDueDateCutoff().toISOString(),
              operator: '<',
            },
          ],
          operation: 'and',
        }}
        title={t('review_due')}
        items={items ?? []}
        filteringProperties={filteringProperties}
      />
      <FilterPropertyDashboardItem
        onClick={onFilterQueryChanged}
        tableFilterQuery={propertyFilterQuery}
        itemFilterQuery={{
          tokens: [
            {
              propertyKey: 'NextReviewDate',
              value: currentDate.toISOString(),
              operator: '<',
            },
          ],
          operation: 'and',
        }}
        title={t('overdue')}
        items={items ?? []}
        filteringProperties={filteringProperties}
      />
      <FilterPropertyDashboardItem
        onClick={onFilterQueryChanged}
        itemFilterQuery={emptyFilterQuery}
        tableFilterQuery={propertyFilterQuery}
        title={t('total')}
        items={items ?? []}
        filteringProperties={filteringProperties}
      />
    </RegisterDashboard>
  );
};
