
    import './styles.scoped.css';
    export default {
  "arrow": "awsui_arrow_xjuzf_kcs0d_285",
  "arrow-outer": "awsui_arrow-outer_xjuzf_kcs0d_289",
  "arrow-inner": "awsui_arrow-inner_xjuzf_kcs0d_289",
  "refresh": "awsui_refresh_xjuzf_kcs0d_323",
  "arrow-position-right-top": "awsui_arrow-position-right-top_xjuzf_kcs0d_333",
  "arrow-position-right-bottom": "awsui_arrow-position-right-bottom_xjuzf_kcs0d_333",
  "arrow-position-left-top": "awsui_arrow-position-left-top_xjuzf_kcs0d_336",
  "arrow-position-left-bottom": "awsui_arrow-position-left-bottom_xjuzf_kcs0d_336",
  "arrow-position-top-center": "awsui_arrow-position-top-center_xjuzf_kcs0d_339",
  "arrow-position-top-right": "awsui_arrow-position-top-right_xjuzf_kcs0d_339",
  "arrow-position-top-left": "awsui_arrow-position-top-left_xjuzf_kcs0d_339",
  "arrow-position-top-responsive": "awsui_arrow-position-top-responsive_xjuzf_kcs0d_339",
  "arrow-position-bottom-center": "awsui_arrow-position-bottom-center_xjuzf_kcs0d_342",
  "arrow-position-bottom-right": "awsui_arrow-position-bottom-right_xjuzf_kcs0d_342",
  "arrow-position-bottom-left": "awsui_arrow-position-bottom-left_xjuzf_kcs0d_342",
  "arrow-position-bottom-responsive": "awsui_arrow-position-bottom-responsive_xjuzf_kcs0d_342",
  "body": "awsui_body_xjuzf_kcs0d_488",
  "body-overflow-visible": "awsui_body-overflow-visible_xjuzf_kcs0d_527",
  "has-dismiss": "awsui_has-dismiss_xjuzf_kcs0d_531",
  "dismiss": "awsui_dismiss_xjuzf_kcs0d_536",
  "dismiss-control": "awsui_dismiss-control_xjuzf_kcs0d_544",
  "header-row": "awsui_header-row_xjuzf_kcs0d_548",
  "header": "awsui_header_xjuzf_kcs0d_548",
  "content": "awsui_content_xjuzf_kcs0d_574",
  "content-overflow-visible": "awsui_content-overflow-visible_xjuzf_kcs0d_582",
  "container": "awsui_container_xjuzf_kcs0d_728",
  "container-body": "awsui_container-body_xjuzf_kcs0d_736",
  "container-body-variant-annotation": "awsui_container-body-variant-annotation_xjuzf_kcs0d_779",
  "container-body-size-small": "awsui_container-body-size-small_xjuzf_kcs0d_784",
  "fixed-width": "awsui_fixed-width_xjuzf_kcs0d_787",
  "container-body-size-medium": "awsui_container-body-size-medium_xjuzf_kcs0d_791",
  "container-body-size-large": "awsui_container-body-size-large_xjuzf_kcs0d_798",
  "container-arrow": "awsui_container-arrow_xjuzf_kcs0d_810",
  "container-arrow-position-right-top": "awsui_container-arrow-position-right-top_xjuzf_kcs0d_814",
  "container-arrow-position-right-bottom": "awsui_container-arrow-position-right-bottom_xjuzf_kcs0d_814",
  "container-arrow-position-left-top": "awsui_container-arrow-position-left-top_xjuzf_kcs0d_830",
  "container-arrow-position-left-bottom": "awsui_container-arrow-position-left-bottom_xjuzf_kcs0d_830",
  "container-arrow-position-top-center": "awsui_container-arrow-position-top-center_xjuzf_kcs0d_846",
  "container-arrow-position-top-right": "awsui_container-arrow-position-top-right_xjuzf_kcs0d_846",
  "container-arrow-position-top-left": "awsui_container-arrow-position-top-left_xjuzf_kcs0d_846",
  "container-arrow-position-top-responsive": "awsui_container-arrow-position-top-responsive_xjuzf_kcs0d_846",
  "container-arrow-position-bottom-center": "awsui_container-arrow-position-bottom-center_xjuzf_kcs0d_862",
  "container-arrow-position-bottom-right": "awsui_container-arrow-position-bottom-right_xjuzf_kcs0d_866",
  "container-arrow-position-bottom-left": "awsui_container-arrow-position-bottom-left_xjuzf_kcs0d_870",
  "awsui-motion-fade-in": "awsui_awsui-motion-fade-in_xjuzf_kcs0d_1",
  "root": "awsui_root_xjuzf_kcs0d_1060",
  "trigger": "awsui_trigger_xjuzf_kcs0d_1096",
  "trigger-type-text": "awsui_trigger-type-text_xjuzf_kcs0d_1103",
  "trigger-inner-text": "awsui_trigger-inner-text_xjuzf_kcs0d_1138",
  "popover-inline-content": "awsui_popover-inline-content_xjuzf_kcs0d_1142"
};
  