import { ParentTypeEnum } from '../../generated/graphql';

export const workflows = {
  'document': [
    'publish-document-version',
  ],
  'risk': [
    'update-risk-details',
    'open-acceptance',
    'delete-acceptance',
    'delete-risk',
  ],
  'control': [
    'delete-control',
    'update-control-details',
  ],
  'issue': [
    'delete-issue',
    'close-issue-assessment'
  ],
  'action': [
    'close-action',
    'delete-action',
    'update-action-details',
  ]
} as const satisfies Partial<Record<ParentTypeEnum, readonly string[]>>;

export type WorkflowId = typeof workflows[keyof typeof workflows][number]
