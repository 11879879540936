
    import './styles.scoped.css';
    export default {
  "breadcrumb-group": "awsui_breadcrumb-group_d19fg_4pwt8_143",
  "item": "awsui_item_d19fg_4pwt8_181",
  "breadcrumb-group-list": "awsui_breadcrumb-group-list_d19fg_4pwt8_216",
  "ellipsis": "awsui_ellipsis_d19fg_4pwt8_228",
  "icon": "awsui_icon_d19fg_4pwt8_238",
  "mobile": "awsui_mobile_d19fg_4pwt8_243",
  "mobile-short": "awsui_mobile-short_d19fg_4pwt8_256"
};
  