
    import './styles.scoped.css';
    export default {
  "column-layout": "awsui_column-layout_vvxn7_1dcx2_179",
  "grid": "awsui_grid_vvxn7_1dcx2_214",
  "grid-no-gutters": "awsui_grid-no-gutters_vvxn7_1dcx2_220",
  "grid-variant-text-grid": "awsui_grid-variant-text-grid_vvxn7_1dcx2_224",
  "grid-breakpoint-default": "awsui_grid-breakpoint-default_vvxn7_1dcx2_232",
  "grid-columns-1": "awsui_grid-columns-1_vvxn7_1dcx2_235",
  "grid-breakpoint-xxs": "awsui_grid-breakpoint-xxs_vvxn7_1dcx2_235",
  "grid-breakpoint-xs": "awsui_grid-breakpoint-xs_vvxn7_1dcx2_238",
  "grid-columns-2": "awsui_grid-columns-2_vvxn7_1dcx2_241",
  "grid-columns-3": "awsui_grid-columns-3_vvxn7_1dcx2_247",
  "grid-columns-4": "awsui_grid-columns-4_vvxn7_1dcx2_253",
  "grid-vertical-borders": "awsui_grid-vertical-borders_vvxn7_1dcx2_269",
  "grid-horizontal-borders": "awsui_grid-horizontal-borders_vvxn7_1dcx2_302"
};
  