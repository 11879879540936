
    import './styles.scoped.css';
    export default {
  "background": "awsui_background_hyvsj_gpwe7_147",
  "scrolling-background": "awsui_scrolling-background_hyvsj_gpwe7_150",
  "sticky-background": "awsui_sticky-background_hyvsj_gpwe7_156",
  "has-sticky-notifications": "awsui_has-sticky-notifications_hyvsj_gpwe7_164",
  "breadcrumbs": "awsui_breadcrumbs_hyvsj_gpwe7_175",
  "has-sticky-background": "awsui_has-sticky-background_hyvsj_gpwe7_185",
  "drawers-container": "awsui_drawers-container_hyvsj_gpwe7_197",
  "has-open-drawer": "awsui_has-open-drawer_hyvsj_gpwe7_208",
  "disable-body-scroll": "awsui_disable-body-scroll_hyvsj_gpwe7_231",
  "drawers-desktop-triggers-container": "awsui_drawers-desktop-triggers-container_hyvsj_gpwe7_236",
  "has-multiple-triggers": "awsui_has-multiple-triggers_hyvsj_gpwe7_277",
  "drawers-mobile-triggers-container": "awsui_drawers-mobile-triggers-container_hyvsj_gpwe7_287",
  "drawers-trigger-content": "awsui_drawers-trigger-content_hyvsj_gpwe7_291",
  "drawers-trigger-overflow": "awsui_drawers-trigger-overflow_hyvsj_gpwe7_305",
  "drawers-trigger": "awsui_drawers-trigger_hyvsj_gpwe7_291",
  "drawer": "awsui_drawer_hyvsj_gpwe7_197",
  "drawer-content-container": "awsui_drawer-content-container_hyvsj_gpwe7_336",
  "drawer-close-button": "awsui_drawer-close-button_hyvsj_gpwe7_345",
  "drawer-content": "awsui_drawer-content_hyvsj_gpwe7_336",
  "drawer-content-hidden": "awsui_drawer-content-hidden_hyvsj_gpwe7_353",
  "drawer-slider": "awsui_drawer-slider_hyvsj_gpwe7_356",
  "is-drawer-open": "awsui_is-drawer-open_hyvsj_gpwe7_363",
  "content": "awsui_content_hyvsj_gpwe7_389",
  "layout": "awsui_layout_hyvsj_gpwe7_414",
  "has-max-content-width": "awsui_has-max-content-width_hyvsj_gpwe7_485",
  "content-type-dashboard": "awsui_content-type-dashboard_hyvsj_gpwe7_500",
  "content-type-table": "awsui_content-type-table_hyvsj_gpwe7_514",
  "content-type-cards": "awsui_content-type-cards_hyvsj_gpwe7_514",
  "is-overlap-disabled": "awsui_is-overlap-disabled_hyvsj_gpwe7_520",
  "is-hide-mobile-toolbar": "awsui_is-hide-mobile-toolbar_hyvsj_gpwe7_523",
  "has-content-gap-left": "awsui_has-content-gap-left_hyvsj_gpwe7_537",
  "has-content-gap-right": "awsui_has-content-gap-right_hyvsj_gpwe7_540",
  "content-first-child-notifications": "awsui_content-first-child-notifications_hyvsj_gpwe7_550",
  "has-breadcrumbs": "awsui_has-breadcrumbs_hyvsj_gpwe7_553",
  "content-first-child-header": "awsui_content-first-child-header_hyvsj_gpwe7_556",
  "has-header": "awsui_has-header_hyvsj_gpwe7_556",
  "content-first-child-main": "awsui_content-first-child-main_hyvsj_gpwe7_574",
  "disable-content-paddings": "awsui_disable-content-paddings_hyvsj_gpwe7_574",
  "has-split-panel": "awsui_has-split-panel_hyvsj_gpwe7_611",
  "split-panel-position-bottom": "awsui_split-panel-position-bottom_hyvsj_gpwe7_611",
  "block-body-scroll": "awsui_block-body-scroll_hyvsj_gpwe7_619",
  "unfocusable": "awsui_unfocusable_hyvsj_gpwe7_624",
  "container": "awsui_container_hyvsj_gpwe7_634",
  "is-navigation-open": "awsui_is-navigation-open_hyvsj_gpwe7_660",
  "is-tools-open": "awsui_is-tools-open_hyvsj_gpwe7_663",
  "is-split-panel-open": "awsui_is-split-panel-open_hyvsj_gpwe7_663",
  "split-panel-position-side": "awsui_split-panel-position-side_hyvsj_gpwe7_663",
  "has-active-drawer": "awsui_has-active-drawer_hyvsj_gpwe7_663",
  "mobile-toolbar": "awsui_mobile-toolbar_hyvsj_gpwe7_672",
  "remove-high-contrast-header": "awsui_remove-high-contrast-header_hyvsj_gpwe7_689",
  "mobile-toolbar-nav": "awsui_mobile-toolbar-nav_hyvsj_gpwe7_693",
  "mobile-toolbar-breadcrumbs": "awsui_mobile-toolbar-breadcrumbs_hyvsj_gpwe7_697",
  "mobile-toolbar-tools": "awsui_mobile-toolbar-tools_hyvsj_gpwe7_701",
  "navigation-container": "awsui_navigation-container_hyvsj_gpwe7_710",
  "show-navigation": "awsui_show-navigation_hyvsj_gpwe7_748",
  "animating": "awsui_animating_hyvsj_gpwe7_771",
  "showButtons": "awsui_showButtons_hyvsj_gpwe7_1",
  "navigation": "awsui_navigation_hyvsj_gpwe7_710",
  "openNavigation": "awsui_openNavigation_hyvsj_gpwe7_1",
  "animated-content": "awsui_animated-content_hyvsj_gpwe7_833",
  "hide-navigation": "awsui_hide-navigation_hyvsj_gpwe7_842",
  "notifications": "awsui_notifications_hyvsj_gpwe7_852",
  "sticky-notifications": "awsui_sticky-notifications_hyvsj_gpwe7_858",
  "split-panel-bottom": "awsui_split-panel-bottom_hyvsj_gpwe7_875",
  "position-bottom": "awsui_position-bottom_hyvsj_gpwe7_922",
  "openSplitPanelBottom": "awsui_openSplitPanelBottom_hyvsj_gpwe7_1",
  "split-panel-side": "awsui_split-panel-side_hyvsj_gpwe7_951",
  "position-side": "awsui_position-side_hyvsj_gpwe7_964",
  "tools-container": "awsui_tools-container_hyvsj_gpwe7_986",
  "tools": "awsui_tools_hyvsj_gpwe7_986",
  "openTools": "awsui_openTools_hyvsj_gpwe7_1",
  "has-tools-form-persistence": "awsui_has-tools-form-persistence_hyvsj_gpwe7_1077",
  "hide-tools": "awsui_hide-tools_hyvsj_gpwe7_1087",
  "show-tools": "awsui_show-tools_hyvsj_gpwe7_1099",
  "has-tools-form": "awsui_has-tools-form_hyvsj_gpwe7_1077",
  "trigger-badge-wrapper": "awsui_trigger-badge-wrapper_hyvsj_gpwe7_1182",
  "trigger": "awsui_trigger_hyvsj_gpwe7_1182",
  "selected": "awsui_selected_hyvsj_gpwe7_1259",
  "badge": "awsui_badge_hyvsj_gpwe7_1279",
  "trigger-wrapper": "awsui_trigger-wrapper_hyvsj_gpwe7_1283",
  "dot": "awsui_dot_hyvsj_gpwe7_1294"
};
  