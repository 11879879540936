
    import './styles.scoped.css';
    export default {
  "option": "awsui_option_1p2cx_12kdh_151",
  "disabled": "awsui_disabled_1p2cx_12kdh_191",
  "parent": "awsui_parent_1p2cx_12kdh_194",
  "highlighted": "awsui_highlighted_1p2cx_12kdh_197",
  "content": "awsui_content_1p2cx_12kdh_201",
  "label-content": "awsui_label-content_1p2cx_12kdh_208",
  "label": "awsui_label_1p2cx_12kdh_208",
  "tag": "awsui_tag_1p2cx_12kdh_215",
  "label-tag": "awsui_label-tag_1p2cx_12kdh_216",
  "label-prefix": "awsui_label-prefix_1p2cx_12kdh_226",
  "tags": "awsui_tags_1p2cx_12kdh_239",
  "description": "awsui_description_1p2cx_12kdh_240",
  "selected": "awsui_selected_1p2cx_12kdh_251",
  "icon": "awsui_icon_1p2cx_12kdh_265",
  "icon-size-big": "awsui_icon-size-big_1p2cx_12kdh_272",
  "filtering-match-highlight": "awsui_filtering-match-highlight_1p2cx_12kdh_276",
  "trigger-variant": "awsui_trigger-variant_1p2cx_12kdh_291"
};
  