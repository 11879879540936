import { FieldValues } from 'react-hook-form';

import { usePopoverHelp } from '@/components/HelpPanel/usePopoverHelp';

import { FormContext } from './FormContext';
import { ModalProps, ModalWrapper } from './ModalWrapper';
import { CommonProps } from './types';

export const ModalForm = <TFieldValues extends FieldValues>(
  props: CommonProps<TFieldValues> & ModalProps
) => {
  usePopoverHelp();
  return (
    <FormContext
      {...props}
      renderTemplate={(renderProps) => (
        <ModalWrapper {...renderProps} visible={props.visible} />
      )}
    />
  );
};
