import { SpaceBetween } from '@cloudscape-design/components-themed';
import Button from '@risksmart-app/components/Button';
import { PageNotFound } from '@risksmart-app/components/errors/errors';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PageLayout } from 'src/layouts';
import { Permission } from 'src/rbac/Permission';
import {
  useGetDetailParentPath,
  useGetDetailPath,
} from 'src/routes/useGetDetailParentPath';

import ControlledTabs from '@/components/ControlledTabs';
import DeleteModal from '@/components/DeleteModal/DeleteModal';
import {
  Parent_Type_Enum,
  useDeleteIndicatorsMutation,
  useGetIndicatorByIdQuery,
} from '@/generated/graphql';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { getFriendlyId } from '@/utils/friendlyId';
import { evictField } from '@/utils/graphqlUtils';

import { useTabs } from './config';
import useExporter from './useExporter';

type Props = {
  activeTabId: 'details' | 'results' | 'linkedItems';
};

const Page: FC<Props> = ({ activeTabId }) => {
  const indicatorId = useGetGuidParam('indicatorId');
  const [exportItem, { loading: exporting }] = useExporter(indicatorId);
  const navigate = useNavigate();
  const { t } = useTranslation(['common']);

  const parentPath = useGetDetailParentPath(indicatorId);
  const detailPath = useGetDetailPath(indicatorId);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const { t: st } = useTranslation(['common'], { keyPrefix: 'indicators' });
  const defaultTitle = st('fallback_title');

  const { data, error } = useGetIndicatorByIdQuery({
    variables: {
      id: indicatorId,
    },
    fetchPolicy: 'no-cache',
  });
  if (error) {
    throw error;
  }
  const indicator = data?.indicator?.[0];
  const tabs = useTabs(detailPath, indicator);
  const [deleteIndicator, deleteResult] = useDeleteIndicatorsMutation({
    update: (cache) => evictField(cache, 'indicator'),
  });

  const onDelete = useDeleteResultNotification({
    asyncAction: async () => {
      if (!indicator) {
        throw new Error('Indicator not selected');
      }
      await deleteIndicator({
        variables: {
          ids: [indicator.Id],
        },
      });
      navigate(parentPath);
      setIsDeleteModalVisible(false);
      return true;
    },
    entityName: st('entity_name'),
    failureAction: () => {
      setIsDeleteModalVisible(false);
    },
  });

  if (data?.indicator.length === 0) {
    throw new PageNotFound(`Indicator with id ${indicatorId} not found`);
  }
  const counter =
    indicator &&
    `(${getFriendlyId(Parent_Type_Enum.Indicator, indicator.SequentialId)})`;
  return (
    <PageLayout
      title={indicator?.Title ?? defaultTitle}
      meta={{
        title: defaultTitle,
      }}
      counter={counter}
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button iconName="download" disabled={exporting} onClick={exportItem}>
            {t('export')}
          </Button>
          <Permission permission="delete:indicator" parentObject={indicator}>
            <Button
              variant="normal"
              formAction="none"
              onClick={() => {
                setIsDeleteModalVisible(true);
              }}
            >
              {st('delete_title')}
            </Button>
          </Permission>
        </SpaceBetween>
      }
    >
      <ControlledTabs
        activeTabId={activeTabId}
        tabs={tabs}
        variant="container"
      />
      <DeleteModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        header={st('delete_title')}
        onDelete={onDelete}
        onDismiss={() => setIsDeleteModalVisible(false)}
      >
        {st('confirm_delete_message')}
      </DeleteModal>
    </PageLayout>
  );
};

export default Page;
