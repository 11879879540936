import { isApolloError } from '@apollo/client';
import { Alert } from '@cloudscape-design/components-themed';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNotifications } from '@risksmart-app/components/Notifications/notification-context';
import { useTools } from '@risksmart-app/components/Tools/ToolsProvider';
import axios from 'axios';
import { ReactNode, useEffect, useState } from 'react';
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ZodSchema } from 'zod';

import { ChangeRequestAlert } from '@/components/ChangeRequestsPreview/ChangeRequestAlert';
import { DeleteRequestAlert } from '@/components/ChangeRequestsPreview/DeleteRequestAlert';
import { useChangeRequests } from '@/hooks/useChangeRequests';
import { mutationResultNotification } from '@/hooks/useMutationResultNotification';
import { getErrorMessage, handleError } from '@/utils/errorUtils';

import { RiskSmartFormProvider } from './CustomisableForm/RiskSmartFormContext';
import FormActions from './FormActions';
import { FormContextProps, SaveAction } from './types';

const mapChanges = (
  from: Record<string, unknown>,
  to: Record<string, unknown>
) => {
  const changes: Record<string, { from: unknown; to: unknown }> = {};
  for (const key in to) {
    if (from?.[key] !== to?.[key])
      changes[key] = { from: from?.[key], to: to?.[key] };
  }
  return changes;
};

// It sets up methods and state for the form, and passes them down to the `ControlledForm` component
export const FormContext = <TFieldValues extends FieldValues>(
  props: FormContextProps<TFieldValues>
) => {
  const { search } = useLocation();
  const {
    defaultValues,
    values,
    schema,
    onSave,
    onDelete,
    readOnly,
    renderTemplate,
    submitActions,
    secondaryActions,
    mapPreviewedChanges,
  } = props;
  const { t: st } = useTranslation('common');
  const [onSaveFn, setOnSave] = useState<(() => Promise<void>) | undefined>();
  const [beforeSaveHooks, setBeforeSaveHooks] = useState<
    (() => Promise<boolean>)[]
  >([]);

  const { addNotification } = useNotifications();
  const [viewingChangeRequest, setViewingChangeRequest] = useState(false);
  const [customFormValidation, setCustomFormValidation] = useState<
    (schema: ZodSchema) => ZodSchema
  >(() => (s: ZodSchema) => s);
  const [toolsContent, setToolsContent] = useTools();

  const methods = useForm<TFieldValues>({
    resolver: zodResolver(customFormValidation(schema)),
    defaultValues,
    values,
    shouldFocusError: true,
  });

  const searchParams = new URLSearchParams(search);
  const showRequest = searchParams.get('showRequest') === 'true';
  const changeRequestId = searchParams.get('requestId');

  const {
    pendingChangeRequests,
    pendingDeleteRequests,
    canAmendChangeRequest,
    changeRequests,
  } = useChangeRequests(props.approvalConfig?.object);

  const inFlightChangeApproval = !!(
    pendingChangeRequests.length > 0 && props.approvalConfig?.object
  );

  const inFlightDeleteApproval = !!(
    pendingDeleteRequests.length > 0 && props.approvalConfig?.object
  );

  useEffect(() => {
    if (viewingChangeRequest) {
      const changeRequest = inFlightChangeApproval
        ? pendingChangeRequests[0]
        : pendingDeleteRequests[0];
      if (!changeRequest) return;

      // Update form values with requested changes
      methods.reset(
        mapPreviewedChanges?.(values, changeRequest.RequestedChanges) ?? {
          ...values,
          ...changeRequest.RequestedChanges,
        }
      );
      setToolsContent(`change-request:${props.approvalConfig?.object?.Id}`);
    } else {
      if (inFlightChangeApproval) {
        methods.reset(values);
      }
      if (
        toolsContent?.startsWith('change-request') &&
        (pendingChangeRequests[0] || pendingDeleteRequests[0])
      )
        setToolsContent(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewingChangeRequest, inFlightChangeApproval]);

  useEffect(() => {
    if (!toolsContent?.startsWith('change-request')) {
      setViewingChangeRequest(false);
    }
  }, [toolsContent]);

  useEffect(() => {
    if (
      showRequest &&
      changeRequests.length > 0 &&
      props.approvalConfig?.object?.Id
    ) {
      setViewingChangeRequest(true);
      setToolsContent(
        changeRequestId
          ? `change-request:${props.approvalConfig?.object?.Id}:${changeRequestId}`
          : `change-request:${props.approvalConfig?.object?.Id}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, pendingChangeRequests]);

  const entityName = props.i18n.entity_name;

  const onDismiss = (saved: boolean) => {
    methods.reset();
    props.onDismiss?.(saved);
  };

  const handleSubmitWrapper = (action: SaveAction<TFieldValues>) => {
    const handler: SubmitHandler<TFieldValues> = async (data) => {
      const beforeSave = await Promise.all(
        beforeSaveHooks.map((hook) => hook())
      );

      if (beforeSave.some((result) => !result)) return false;

      return mutationResultNotification<TFieldValues>({
        addNotification,
        successMessageKey: values
          ? 'update_success_message'
          : 'create_success_message',
        entityName,
        asyncAction: async (data) => {
          try {
            await action(data);
            methods.reset();
            onDismiss(true);
            return true;
          } catch (error) {
            const e = error as Error;

            if (
              (isApolloError(e) &&
                e.graphQLErrors.some((er) =>
                  er.message.includes(
                    'You need to create a change request to perform this action.'
                  )
                )) ||
              e.message.includes('attestation warning error')
            ) {
              // dont show error in form if its due to a change request
              return true;
            }

            handleError(e);
            if (axios.isAxiosError(e) && e.config?.url?.startsWith('/files')) {
              // @ts-ignore
              methods.setError('newFiles', {
                message: getErrorMessage(e) || 'Unable to upload files',
              });
            } else if (isApolloError(e)) {
              // @ts-ignore
              methods.setError('global', {
                message: e.message,
                type: e.graphQLErrors?.[0]?.extensions?.code as string,
              });
            }
            return false;
          }
        },
      })(data);
    };

    return handler;
  };

  const defaultSubmitActions = [
    {
      label: st('save'),
      action: onSave,
    },
  ];

  const defaultOnSave = methods.handleSubmit(handleSubmitWrapper(onSave));

  const inFlightApprovalReadOnly =
    (!viewingChangeRequest && inFlightChangeApproval) ||
    (viewingChangeRequest &&
      pendingChangeRequests[0] &&
      !canAmendChangeRequest(pendingChangeRequests[0]));

  const thereIsAPendingChangeRequestsButSelectedChangeRequestIsNotOneOfThem =
    pendingChangeRequests.length > 0 &&
    !!changeRequestId &&
    changeRequestId !== pendingChangeRequests[0]?.Id;

  const thereAreNoPendingChangeRequestsAndViewingAResolvedOne =
    pendingChangeRequests.length === 0 && !!changeRequestId;

  const viewingHistoricalChangeRequest: boolean =
    thereAreNoPendingChangeRequestsAndViewingAResolvedOne ||
    thereIsAPendingChangeRequestsButSelectedChangeRequestIsNotOneOfThem;

  const formActions: ReactNode = (
    <FormActions
      readOnly={
        inFlightApprovalReadOnly || readOnly || viewingHistoricalChangeRequest
      }
      onDismiss={onDismiss}
      formId={props.formId}
      methods={methods}
      onDelete={onDelete}
      submitActions={(submitActions || defaultSubmitActions).map((sa) => ({
        ...sa,
        action: methods.handleSubmit(handleSubmitWrapper(sa.action)),
      }))}
      secondaryActions={
        secondaryActions?.map((sa) => ({
          ...sa,
        })) || []
      }
    />
  );

  const body = (
    <div className={'flex-col flex gap-5'} data-testid="form-context">
      {inFlightChangeApproval && !viewingHistoricalChangeRequest && (
        <ChangeRequestAlert
          viewing={viewingChangeRequest}
          entityName={entityName}
          onToggleView={() => setViewingChangeRequest((prev) => !prev)}
          readOnly={inFlightApprovalReadOnly}
          test-id="change-request-alert"
        />
      )}
      {inFlightDeleteApproval && !viewingHistoricalChangeRequest && (
        <DeleteRequestAlert
          viewing={viewingChangeRequest}
          entityName={entityName}
          onToggleView={() => setViewingChangeRequest((prev) => !prev)}
          test-id="delete-request-alert"
        />
      )}
      {viewingHistoricalChangeRequest && (
        <Alert type="info">{st('approvals.historical_alert.body')}</Alert>
      )}
      {props.children}
    </div>
  );

  return (
    <>
      <RiskSmartFormProvider
        parentType={props.parentType}
        setCustomFormValidation={setCustomFormValidation}
        onSave={onSaveFn}
        beforeSaveHooks={beforeSaveHooks}
        setOnSave={setOnSave}
        setBeforeSaveHooks={setBeforeSaveHooks}
        previewChanges={
          inFlightChangeApproval && viewingChangeRequest && !!values
            ? mapChanges(values, pendingChangeRequests[0]?.RequestedChanges)
            : null
        }
        readOnly={
          inFlightApprovalReadOnly || readOnly || viewingHistoricalChangeRequest
        }
        defaultOnSave={defaultOnSave}
      >
        <FormProvider {...methods}>
          {renderTemplate({ ...props, actions: formActions, children: body })}
        </FormProvider>
      </RiskSmartFormProvider>
    </>
  );
};
