import ExpandableSection from '@cloudscape-design/components/expandable-section';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/notification-context';
import Table from '@risksmart-app/components/Table';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DeleteModal from '@/components/DeleteModal';
import TabHeader from '@/components/TabHeader';
import {
  Parent_Type_Enum,
  useDeleteAssessmentResultsMutation,
  useGetDocumentAssessmentResultsByParentIdQuery,
} from '@/generated/graphql';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { getCounter } from '@/utils/collectionUtils';
import { evictField } from '@/utils/graphqlUtils';

import { SpaceBetween } from '../../../../../../../theme/risksmart-themed/components';
import {
  ObjectWithContributors,
  Permission,
  useHasPermission,
} from '../../../../../rbac/Permission';
import AssessmentResultModal from '../../../../assessments/modals/AssessmentResultModal';
import { useGetCollectionTableProps } from './documentRatingConfig';
import { DocumentAssessmentResultRegisterFields } from './types';

interface Props {
  parent: ObjectWithContributors;
}

const DocumentRatingRegister: FC<Props> = ({ parent }) => {
  const { t: st } = useTranslation(['common']);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const { addNotification } = useNotifications();

  const [selectedAssessmentResults, setSelectedAssessmentResults] = useState<
    DocumentAssessmentResultRegisterFields[]
  >([]);

  const [openAssessmentResultId, setOpenAssessmentResultId] = useState<
    string | undefined
  >();

  const userCanDeleteAssessmentResults = useHasPermission(
    'delete:document_assessment_result',
    parent
  );

  const [deleteAssessmentResults, deleteResult] =
    useDeleteAssessmentResultsMutation({
      update: (cache) => {
        evictField(cache, 'document_assessment_result');
        evictField(cache, 'assessment');
        evictField(cache, 'document_assessment_result_aggregate');
      },
    });

  const { data, loading, refetch } =
    useGetDocumentAssessmentResultsByParentIdQuery({
      variables: {
        ParentId: parent.Id,
      },
      onError: (error) => {
        addNotification({
          type: 'error',
          content: <>{error.message}</>,
        });
      },
    });

  const handleAssessmentResultModalClose = () => {
    setOpenAssessmentResultId(undefined);
    setIsEditOpen(false);
  };

  const onAddRating = () => {
    setIsEditOpen(true);
  };

  const onOpenResult = (id?: string) => {
    setOpenAssessmentResultId(id);
    setIsEditOpen(true);
  };

  const onDelete = useDeleteResultNotification({
    entityName: st('documentAssessments.entity_name'),
    failureAction: () => {
      setIsDeleteModalVisible(false);
    },
    asyncAction: async () => {
      await deleteAssessmentResults({
        variables: {
          Ids: selectedAssessmentResults.map((assessment) => assessment.Id),
        },
      });
      setSelectedAssessmentResults([]);
      setIsDeleteModalVisible(false);
      return true;
    },
  });

  const tableProps = useGetCollectionTableProps(
    parent,
    data?.document_assessment_result,
    onOpenResult,
    onAddRating,
    data?.form_configuration?.[0]?.customAttributeSchema
  );

  return (
    <>
      <SpaceBetween size="m">
        <TabHeader
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Permission
                permission="delete:document_assessment_result"
                parentObject={parent}
              >
                <Button
                  formAction="none"
                  variant="normal"
                  disabled={
                    !selectedAssessmentResults.length ||
                    !userCanDeleteAssessmentResults
                  }
                  onClick={() => setIsDeleteModalVisible(true)}
                >
                  {st('documentAssessments.delete_button')}
                </Button>
              </Permission>
              <Permission
                permission="insert:document_assessment_result"
                parentObject={parent}
              >
                <Button
                  iconName="add-plus"
                  variant="primary"
                  formAction="none"
                  onClick={onAddRating}
                >
                  {st('documentAssessments.add_rating_button')}
                </Button>
              </Permission>
            </SpaceBetween>
          }
        >
          {st('documentAssessments.tab_title')}
        </TabHeader>
      </SpaceBetween>
      <ExpandableSection
        header={
          <div className={'flex space-x-2'}>
            <span>{st('documentAssessments.documentRatingSubheading')}</span>
            <span className={'text-grey font-normal'}>
              {getCounter(tableProps.totalItemsCount ?? 0, loading)}
            </span>
          </div>
        }
        defaultExpanded={true}
      >
        <Table
          {...tableProps}
          selectionType="multi"
          selectedItems={selectedAssessmentResults}
          trackBy="Id"
          onSelectionChange={({ detail }) => {
            setSelectedAssessmentResults(detail.selectedItems);
          }}
          resizableColumns={true}
          variant="embedded"
          loading={loading}
          data-testid="document-rating-table"
        />
      </ExpandableSection>
      {isEditOpen && (
        <AssessmentResultModal
          id={openAssessmentResultId}
          onDismiss={handleAssessmentResultModalClose}
          assessedItem={parent}
          resultType={Parent_Type_Enum.DocumentAssessmentResult}
          hideTypeSelector={true}
          i18n={st('ratings')}
          assessmentMode={'rating'}
        />
      )}
      <DeleteModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        header={st('assessmentResults.delete_modal_title')}
        onDelete={onDelete}
        onDismiss={() => {
          refetch();
          setOpenAssessmentResultId(undefined);
          setSelectedAssessmentResults([]);
          setIsDeleteModalVisible(false);
        }}
      >
        {st('assessmentResults.confirm_delete_message')}
      </DeleteModal>
    </>
  );
};

export default DocumentRatingRegister;
