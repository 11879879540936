import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getStatusByDate } from 'src/pages/assessments/config';
import {
  getAllContributorsCellValue,
  getAllOwnersCellValue,
} from 'src/rbac/contributorHelper';
import { RecursivePartial } from 'src/testing/stub';

import {
  Obligation_Assessment_Result,
  Parent_Type_Enum,
} from '@/generated/graphql';
import { getFriendlyId } from '@/utils/friendlyId';

import { ObligationFields, ObligationTableFields } from './types';

export const useGetLabelledFields = (
  records: ObligationFields[] | undefined,
  latestAssessmentResults:
    | Array<RecursivePartial<Obligation_Assessment_Result> | undefined | null>
    | undefined
) => {
  const { t: tr } = useTranslation(['ratings']);
  const { t: tc } = useTranslation(['common']);
  const { t } = useTranslation(['common'], { keyPrefix: 'obligations' });
  const frequency = useMemo(() => tc('frequency'), [tc]);
  return useMemo<ObligationTableFields[] | undefined>(() => {
    return records?.map((d) => {
      const latestAssessmentResult = latestAssessmentResults?.find((oar) => {
        return oar?.parents?.find((p) => p?.ParentId === d.Id);
      });

      return {
        ...d,
        LatestAssessmentResultsLabelled: [
          ...tr('performance_result_unrated'),
          ...tr('performance_result'),
        ]
          .filter((res) => res.value === latestAssessmentResult?.Rating || 0)
          .map((res) => res.label)[0],
        LatestAssessmentResult: latestAssessmentResult?.Rating ?? 0,
        LatestAssessmentStatus: getStatusByDate(
          dayjs(),
          latestAssessmentResult?.parents?.find((p) => p?.assessment)
            ?.assessment?.StartDate,
          latestAssessmentResult?.parents?.find((p) => p?.assessment)
            ?.assessment?.ActualCompletionDate
        ),
        LinkedControlCount: d.controls_aggregate.aggregate?.count ?? 0,
        ParentTitle:
          d.Parent?.Title ??
          (d.parentNode
            ? getFriendlyId(
                Parent_Type_Enum.Obligation,
                d.parentNode?.SequentialId
              )
            : null) ??
          null,
        TypeLabel: t('fields.types')[d.Type] ?? '',
        CreatedBy: d.CreatedBy?.FriendlyName ?? null,
        ModifiedBy: d.ModifiedBy?.FriendlyName ?? null,
        Owner: d.owners,
        SequentialIdLabel: d.SequentialId
          ? getFriendlyId(Parent_Type_Enum.Obligation, d.SequentialId)
          : '',
        allOwners: getAllOwnersCellValue(d),
        allContributors: getAllContributorsCellValue(d),
        LatestRatingDate: d.LatestRatingDate ?? '-',
        NextTestDate: d.NextTestDate ?? '-',
        TestFrequency: d.TestFrequency ? frequency[d.TestFrequency] : null,
      };
    });
  }, [records, t, tr, latestAssessmentResults, frequency]);
};
