
    import './styles.scoped.css';
    export default {
  "dialog": "awsui_dialog_1d2i7_jgt3r_167",
  "modal-slide-up": "awsui_modal-slide-up_1d2i7_jgt3r_1",
  "awsui-motion-fade-in-0": "awsui_awsui-motion-fade-in-0_1d2i7_jgt3r_1",
  "refresh": "awsui_refresh_1d2i7_jgt3r_190",
  "awsui-motion-scale-popup": "awsui_awsui-motion-scale-popup_1d2i7_jgt3r_1",
  "root": "awsui_root_1d2i7_jgt3r_223",
  "awsui-motion-fade-in": "awsui_awsui-motion-fade-in_1d2i7_jgt3r_1",
  "hidden": "awsui_hidden_1d2i7_jgt3r_303",
  "focus-lock": "awsui_focus-lock_1d2i7_jgt3r_307",
  "small": "awsui_small_1d2i7_jgt3r_327",
  "medium": "awsui_medium_1d2i7_jgt3r_330",
  "large": "awsui_large_1d2i7_jgt3r_333",
  "max": "awsui_max_1d2i7_jgt3r_336",
  "breakpoint-xs": "awsui_breakpoint-xs_1d2i7_jgt3r_336",
  "container": "awsui_container_1d2i7_jgt3r_342",
  "content": "awsui_content_1d2i7_jgt3r_386",
  "no-paddings": "awsui_no-paddings_1d2i7_jgt3r_391",
  "header": "awsui_header_1d2i7_jgt3r_396",
  "header--text": "awsui_header--text_1d2i7_jgt3r_408",
  "footer": "awsui_footer_1d2i7_jgt3r_412",
  "footer--stuck": "awsui_footer--stuck_1d2i7_jgt3r_421",
  "dismiss-control": "awsui_dismiss-control_1d2i7_jgt3r_433",
  "modal-open": "awsui_modal-open_1d2i7_jgt3r_437"
};
  