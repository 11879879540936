import { Tabs, TabsProps } from '@cloudscape-design/components-themed';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import style from './style.module.scss';

const ControlledTabs: FC<TabsProps> = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  return (
    <div className={style.tabs}>
      <Tabs
        {...props}
        onChange={(e) => {
          if (e.detail.activeTabHref) {
            navigate(e.detail.activeTabHref, {
              state,
            });
          }
        }}
      />
    </div>
  );
};

export default ControlledTabs;
