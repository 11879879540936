import { ReactNode } from 'react';
import { DefaultValues, FieldValues, UseFormReturn } from 'react-hook-form';

import { Parent_Type_Enum } from '@/generated/graphql';
import { ObjectWithApprovals } from '@/hooks/useChangeRequests';

export enum ButtonVariant {
  Standard = 'Standard',
  Danger = 'Danger',
}
export type SubmitButtonOptions = {
  variant?: ButtonVariant;
  label: string;
  action: () => Promise<void>;
  loading?: boolean;
};

export type CommonProps<TFieldValues extends FieldValues> = Omit<
  FormContextProps<TFieldValues>,
  'renderTemplate'
>;

export type FormTemplateProps<TFieldValues extends FieldValues> =
  FormContextProps<TFieldValues> & { actions: ReactNode };

export type SaveAction<T> = (data: T) => Promise<void>;

type FormFieldsHook<TFieldValues extends FieldValues> = (
  options: UseFormReturn<TFieldValues>
) => FormFields;

type FormField = {
  component: ReactNode;
  hidden?: boolean;
};

type FormFields = Record<string, FormField>;

export type FormContextProps<TFieldValues extends FieldValues> = {
  formId: string;
  header?: ReactNode | string;
  defaultValues: DefaultValues<TFieldValues>;
  values?: TFieldValues;
  children?: ReactNode;
  fields?: FormFields | FormFieldsHook<TFieldValues>;
  onDismiss?: (saved: boolean) => void;
  onSave: SaveAction<TFieldValues>;
  onDelete?: () => Promise<void>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  schema: Zod.Schema<any, any>;
  readOnly?: boolean;
  parentType?: Parent_Type_Enum;
  aside?: ReactNode;
  renderTemplate: (props: FormTemplateProps<TFieldValues>) => ReactNode;
  submitActions?: (Omit<SubmitButtonOptions, 'action'> & {
    action: SaveAction<TFieldValues>;
  })[];
  secondaryActions?: SubmitButtonOptions[];
  approvalConfig?: {
    object?: ObjectWithApprovals;
  };
  i18n: {
    entity_name: string;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mapPreviewedChanges?: (a: TFieldValues | undefined, b: any) => TFieldValues;
};
