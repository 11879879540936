
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_fvjdu_1fg3y_143",
  "button": "awsui_button_fvjdu_1fg3y_186",
  "dots": "awsui_dots_fvjdu_1fg3y_187",
  "button-disabled": "awsui_button-disabled_fvjdu_1fg3y_215",
  "arrow": "awsui_arrow_fvjdu_1fg3y_220",
  "page-number": "awsui_page-number_fvjdu_1fg3y_230",
  "button-current": "awsui_button-current_fvjdu_1fg3y_236",
  "page-item": "awsui_page-item_fvjdu_1fg3y_253",
  "root-disabled": "awsui_root-disabled_fvjdu_1fg3y_271"
};
  