import dayjs from 'dayjs';
import { useMemo } from 'react';
import {
  getAllContributorsCellValue,
  getAllOwnersCellValue,
} from 'src/rbac/contributorHelper';

import { Parent_Type_Enum } from '@/generated/graphql';
import useEntityInfo from '@/hooks/getEntityInfo';
import { useRating } from '@/hooks/use-rating';
import { getFriendlyId } from '@/utils/friendlyId';

import { ActionFields, ActionTableFields } from './types';

export const useLabelledFields = (records: ActionFields[] | undefined) => {
  const status = useRating('action_status');
  const priority = useRating('priority');
  const getEntityInfo = useEntityInfo();
  return useMemo<ActionTableFields[]>(() => {
    return (
      records?.map((d) => {
        let parentTitle: string | null = null;
        let parentId: string | null = null;
        let parentType: string | undefined | null = null;
        const firstParent: ActionFields['parents'][number] | undefined =
          d.parents?.[0];
        parentType = firstParent?.parent?.ObjectType
          ? getEntityInfo(firstParent?.parent.ObjectType).singular
          : null;
        parentId = firstParent?.parent?.Id ?? null;
        // TODO: we need to rethink how we represent multiple parents
        if (firstParent?.risk) {
          parentTitle = firstParent.risk.Title;
        } else if (firstParent?.control) {
          parentTitle = firstParent.control.Title;
        } else if (firstParent?.issue) {
          parentTitle = firstParent.issue.Title;
        } else if (firstParent?.obligation) {
          parentTitle = firstParent.obligation.Title;
        } else if (firstParent?.document) {
          parentTitle = firstParent.document.Title;
        } else if (firstParent?.assessment) {
          parentTitle = firstParent.assessment.Title;
        }
        const latestUpdate = d.updates.length > 0 ? d.updates[0] : null;
        return {
          ...d,
          StatusLabelled:
            d.Status !== undefined ? status.getLabel(d.Status) ?? '-' : '-',
          PriorityLabelled:
            d.Priority !== undefined
              ? priority.getLabel(d.Priority) ?? '-'
              : '-',
          ParentTitle: parentTitle,
          ParentId: parentId,
          ParentType: parentType,
          ModifiedByUserName: d.modifiedByUser?.FriendlyName || '-',
          CreatedByUserName: d.createdByUser?.FriendlyName || '-',
          SequentialIdLabel: d.SequentialId
            ? getFriendlyId(Parent_Type_Enum.Action, d.SequentialId)
            : '',
          Overdue: dayjs(d.DateDue).isBefore(dayjs()) && d.Status === 'open',
          allOwners: getAllOwnersCellValue(d),
          allContributors: getAllContributorsCellValue(d),
          UpdateCount: d.updates_aggregate.aggregate?.count ?? 0,
          LatestUpdateCreatedAtTimestamp:
            latestUpdate?.ModifiedAtTimestamp ?? null,
          LatestUpdateDescription: latestUpdate?.Description ?? null,
          LatestUpdateTitle: latestUpdate?.Title ?? null,
        };
      }) || []
    );
  }, [getEntityInfo, priority, records, status]);
};
