import { RouteObject } from 'react-router-dom';
import i18n from 'src/i18n';
import OrgFeatureFlaggedRoute from 'src/rbac/OrgFeatureFlaggedRoute';

import {
  DocumentUpdateBreadcrumb,
  PolicyFileCreatePage,
  PolicyFileUpdateBreadcrumb,
  PolicyFileUpdatePage,
  PolicyUpdatePage,
} from '../pages';
import { actionRoute } from './actionRoutes.config';
import { assessmentsRoute } from './assessmentRoutes.config';
import { issueRoute } from './issueRoutes.config';

export const documentRoute: RouteObject = {
  path: ':documentId',
  handle: {
    breadcrumb: DocumentUpdateBreadcrumb,
  },
  children: [
    {
      index: true,
      element: (
        <OrgFeatureFlaggedRoute featureFlag="policy">
          <PolicyUpdatePage activeTabId="details" />
        </OrgFeatureFlaggedRoute>
      ),
    },
    {
      path: 'actions',
      handle: {
        title: () => i18n.format(i18n.t('action_other'), 'capitalize'),
      },
      children: [
        {
          index: true,
          element: (
            <OrgFeatureFlaggedRoute featureFlag="policy">
              <PolicyUpdatePage activeTabId="actions" />
            </OrgFeatureFlaggedRoute>
          ),
        },
        actionRoute,
      ],
    },
    {
      path: 'ratings',
      handle: {
        title: () => i18n.format(i18n.t('assessment_other'), 'capitalize'),
      },
      children: [
        {
          index: true,
          element: (
            <OrgFeatureFlaggedRoute featureFlag="policy">
              <PolicyUpdatePage activeTabId="ratings" />
            </OrgFeatureFlaggedRoute>
          ),
        },
        assessmentsRoute,
      ],
    },
    {
      path: 'files',
      handle: {
        title: () => i18n.format(i18n.t('versions'), 'capitalize'),
      },
      children: [
        {
          index: true,
          element: (
            <OrgFeatureFlaggedRoute featureFlag="policy">
              <PolicyUpdatePage activeTabId="files" />
            </OrgFeatureFlaggedRoute>
          ),
        },
        {
          path: 'update/:id',
          handle: {
            breadcrumb: PolicyFileUpdateBreadcrumb,
          },
          children: [
            {
              index: true,
              element: (
                <OrgFeatureFlaggedRoute featureFlag="policy">
                  <PolicyFileUpdatePage activeTabId={'details'} />
                </OrgFeatureFlaggedRoute>
              ),
            },
            {
              path: 'attestations',
              element: (
                <OrgFeatureFlaggedRoute featureFlag="attestations">
                  <PolicyFileUpdatePage activeTabId={'attestations'} />
                </OrgFeatureFlaggedRoute>
              ),
            },
          ],
        },

        {
          path: 'create',
          handle: {
            title: () => i18n.format(i18n.t('create_version'), 'capitalize'),
          },
          element: (
            <OrgFeatureFlaggedRoute featureFlag="policy">
              <PolicyFileCreatePage />
            </OrgFeatureFlaggedRoute>
          ),
        },
      ],
    },
    {
      path: 'issues',
      handle: {
        title: () => i18n.format(i18n.t('issue_other'), 'capitalize'),
      },
      children: [
        {
          index: true,
          element: (
            <OrgFeatureFlaggedRoute featureFlag="policy">
              <PolicyUpdatePage activeTabId="issues" />
            </OrgFeatureFlaggedRoute>
          ),
        },
        issueRoute,
      ],
    },
    {
      path: 'approvals',
      handle: {
        title: () => i18n.t('approvals.tab_title'),
      },
      element: <PolicyUpdatePage activeTabId="approvals" />,
    },
    {
      path: 'linked-items',
      handle: {
        title: () => i18n.t('linkedItems.tab_title'),
      },
      element: <PolicyUpdatePage activeTabId="linkedItems" />,
    },
  ],
};
