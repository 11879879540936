import i18n from 'src/i18n';

import { policyDetailsUrl } from '@/utils/urls';

import { GetItem } from './types';

export const getItem: GetItem = (item) => {
  const documentId = item.data?.objectId;
  return {
    message: i18n.t('notifications.messages.policyDocumentVersionReviewDue'),
    url: documentId ? policyDetailsUrl(documentId) : null,
  };
};
