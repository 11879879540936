
    import './styles.scoped.css';
    export default {
  "card-inner": "awsui_card-inner_p8a6i_h05w8_159",
  "root": "awsui_root_p8a6i_h05w8_191",
  "header-variant-full-page": "awsui_header-variant-full-page_p8a6i_h05w8_233",
  "header-refresh": "awsui_header-refresh_p8a6i_h05w8_233",
  "list": "awsui_list_p8a6i_h05w8_240",
  "list-grid-1": "awsui_list-grid-1_p8a6i_h05w8_251",
  "card": "awsui_card_p8a6i_h05w8_159",
  "list-grid-2": "awsui_list-grid-2_p8a6i_h05w8_254",
  "list-grid-3": "awsui_list-grid-3_p8a6i_h05w8_257",
  "list-grid-4": "awsui_list-grid-4_p8a6i_h05w8_260",
  "list-grid-5": "awsui_list-grid-5_p8a6i_h05w8_263",
  "list-grid-6": "awsui_list-grid-6_p8a6i_h05w8_266",
  "list-grid-7": "awsui_list-grid-7_p8a6i_h05w8_269",
  "list-grid-8": "awsui_list-grid-8_p8a6i_h05w8_272",
  "list-grid-9": "awsui_list-grid-9_p8a6i_h05w8_275",
  "list-grid-10": "awsui_list-grid-10_p8a6i_h05w8_278",
  "list-grid-11": "awsui_list-grid-11_p8a6i_h05w8_281",
  "list-grid-12": "awsui_list-grid-12_p8a6i_h05w8_284",
  "list-grid-13": "awsui_list-grid-13_p8a6i_h05w8_287",
  "list-grid-14": "awsui_list-grid-14_p8a6i_h05w8_290",
  "list-grid-15": "awsui_list-grid-15_p8a6i_h05w8_293",
  "list-grid-16": "awsui_list-grid-16_p8a6i_h05w8_296",
  "list-grid-17": "awsui_list-grid-17_p8a6i_h05w8_299",
  "list-grid-18": "awsui_list-grid-18_p8a6i_h05w8_302",
  "list-grid-19": "awsui_list-grid-19_p8a6i_h05w8_305",
  "list-grid-20": "awsui_list-grid-20_p8a6i_h05w8_308",
  "selection-control": "awsui_selection-control_p8a6i_h05w8_312",
  "loading": "awsui_loading_p8a6i_h05w8_322",
  "empty": "awsui_empty_p8a6i_h05w8_323",
  "has-header": "awsui_has-header_p8a6i_h05w8_330",
  "refresh": "awsui_refresh_p8a6i_h05w8_333",
  "remove-high-contrast-header": "awsui_remove-high-contrast-header_p8a6i_h05w8_333",
  "card-header": "awsui_card-header_p8a6i_h05w8_404",
  "card-header-inner": "awsui_card-header-inner_p8a6i_h05w8_412",
  "card-selectable": "awsui_card-selectable_p8a6i_h05w8_416",
  "card-selected": "awsui_card-selected_p8a6i_h05w8_419",
  "section": "awsui_section_p8a6i_h05w8_427",
  "section-header": "awsui_section-header_p8a6i_h05w8_435",
  "section-content": "awsui_section-content_p8a6i_h05w8_441",
  "footer-pagination": "awsui_footer-pagination_p8a6i_h05w8_451"
};
  