import i18n from 'i18next';
import _ from 'lodash';
import { useGetCauseSmartWidgetTableProps } from 'src/pages/causes/config';
import { CauseRegisterFields } from 'src/pages/causes/types';

import { Parent_Type_Enum } from '@/generated/graphql';
import { GetWidgetCausesDocument } from '@/generated/graphql.typed';
import { getFriendlyId } from '@/utils/friendlyId';
import { StatefulTableOptions } from '@/utils/table/hooks/useGetStatelessTableProps';
import { causesRegisterUrl } from '@/utils/urls';

import { UNRATED } from '../../Gigawidget/types';
import { dateRangeFilter } from '../../Gigawidget/util/filterHelpers';
import { createDataSource } from '../createDataSource';
import {
  dashboardDateClickthroughFilter,
  dateClickthroughFilter,
  defaultClickthroughFilter,
} from '../dataSourceHelpers';

export default createDataSource({
  hasAccess: (_, isFeatureVisibleToOrg) =>
    !isFeatureVisibleToOrg('disable-causes'),
  documentNode: GetWidgetCausesDocument,
  defaultVariables: { where: {} },
  useTablePropsHook: (
    data,
    options: StatefulTableOptions<CauseRegisterFields>
  ) =>
    useGetCauseSmartWidgetTableProps(
      data?.cause,
      data?.form_configuration[0]?.customAttributeSchema ?? null,
      options
    ),
  entityNamePlural: 'cause_other',
  entityNameSingular: 'cause_one',
  fields: 'causes.fields',
  dashboardFilterConfig: {
    dateFilter: (dateRange, precision) => ({
      where: { CreatedAtTimestamp: dateRangeFilter(dateRange, precision) },
    }),
    dateClickthroughFilter:
      dashboardDateClickthroughFilter('CreatedAtTimestamp'),
  },
  clickThroughUrl: (filters) => causesRegisterUrl(filters),
  categoryGetters: [
    {
      id: 'issueId',
      name: () => i18n.t('causes.columns.issueId'),
      categoryGetter: (item) => ({
        key: item.IssueSequentialId ?? UNRATED,
        label: item.IssueSequentialId
          ? getFriendlyId(Parent_Type_Enum.Issue, item.IssueSequentialId)
          : UNRATED,
      }),
      clickthroughFilter: defaultClickthroughFilter('IssueSequentialId', {
        categoryValue: 'key',
      }),
    },
    {
      id: 'issueRaisedDate',
      name: () => i18n.t('causes.columns.issueRaisedDate'),
      categoryGetter: (item) =>
        item.IssueRaisedDate ? new Date(item.IssueRaisedDate) : null,
      clickthroughFilter: dateClickthroughFilter('IssueRaisedDate'),
      date: true,
      dateFilter: (dateRange, precision) => ({
        where: {
          issue: { CreatedAtTimestamp: dateRangeFilter(dateRange, precision) },
        },
      }),
    },
    {
      id: 'issueClosedDate',
      name: () => i18n.t('causes.columns.issueClosedDate'),
      categoryGetter: (item) =>
        item.IssueClosedDate ? new Date(item.IssueClosedDate) : null,
      clickthroughFilter: dateClickthroughFilter('IssueClosedDate'),
      date: true,
      dateFilter: (dateRange, precision) => ({
        where: {
          issue: {
            assessment: {
              ActualCloseDate: dateRangeFilter(dateRange, precision),
            },
          },
        },
      }),
    },
    {
      id: 'issueStatus',
      name: () => i18n.t('causes.columns.issueStatus'),
      categoryGetter: (item) => ({
        key: item.IssueStatus ?? UNRATED,
        label: item.IssueStatusLabelled ?? UNRATED,
      }),
      ratingColourKey: 'issue_assessment_status',
      clickthroughFilter: defaultClickthroughFilter('IssueStatusLabelled'),
    },
    {
      id: 'issueSeverity',
      name: () => i18n.t('causes.columns.issueSeverity'),
      categoryGetter: (item) => ({
        key: item.IssueSeverity ?? UNRATED,
        label: item.IssueSeverityLabelled ?? UNRATED,
      }),
      ratingColourKey: 'severity',
      clickthroughFilter: defaultClickthroughFilter('IssueSeverityLabelled'),
    },
    {
      id: 'issueTitle',
      name: () => i18n.t('causes.columns.issue'),
      categoryGetter: (item) => ({
        key: item.IssueTitle,
        label: item.IssueTitle,
      }),
      clickthroughFilter: defaultClickthroughFilter('IssueTitle'),
    },
    {
      id: 'owner',
      name: () => i18n.t('columns.owner'),
      categoryGetter: (item) =>
        item.allOwners.map((owner) => ({ key: owner.id, label: owner.label })),
      clickthroughFilter: (category) => [
        {
          propertyKey: 'allOwners',
          operator: '=',
          value: category.key,
        },
      ],
    },
    {
      id: 'Title',
      name: () => i18n.t('causes.columns.title'),
      categoryGetter: (item) => ({
        key: item.Title,
        label: item.Title,
      }),
      clickthroughFilter: defaultClickthroughFilter('Title'),
    },
    {
      id: 'significance',
      name: () => i18n.t('causes.columns.significance'),
      categoryGetter: (item) => ({
        key: item.Significance ?? UNRATED,
        label: item.SignificanceLabelled ?? UNRATED,
      }),
      ratingColourKey: 'significance',
      clickthroughFilter: defaultClickthroughFilter('SignificanceLabelled'),
    },
    {
      id: 'createdDate',
      name: () => i18n.t('columns.created_on'),
      categoryGetter: (data) => new Date(data.CreatedAtTimestamp),
      date: true,
      dateFilter: (dateRange, precision) => ({
        where: { CreatedAtTimestamp: dateRangeFilter(dateRange, precision) },
      }),
      clickthroughFilter: dateClickthroughFilter('CreatedAtTimestamp'),
    },
  ],
});
