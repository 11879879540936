
    import './styles.scoped.css';
    export default {
  "marker": "awsui_marker_1kjc7_fb9km_151",
  "root": "awsui_root_1kjc7_fb9km_165",
  "title": "awsui_title_1kjc7_fb9km_203",
  "list": "awsui_list_1kjc7_fb9km_207",
  "marker--dimmed": "awsui_marker--dimmed_1kjc7_fb9km_256",
  "marker--highlighted": "awsui_marker--highlighted_1kjc7_fb9km_259"
};
  