import { useTranslation } from 'react-i18next';
import { useAggregation } from 'src/providers/AggregationProvider';

import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import { useRating } from '@/hooks/use-rating';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import { TableFields, TablePropsWithActions } from '@/utils/table/types';
import { dateColumn } from '@/utils/table/utils/dateColumn';
import {
  assessmentDetailsUrl,
  obligationDetailsUrl,
  policyDetailsUrl,
  riskDetailsUrl,
} from '@/utils/urls';

import {
  AssessmentResultFields,
  AssessmentResultRegisterFields,
} from './types';
import { useLabelledFields } from './useLabelledFields';

const getParentLink = (item: AssessmentResultRegisterFields) => {
  const originalItem = item?.originalResult;
  switch (originalItem.__typename) {
    case 'document_assessment_result':
      if (originalItem?.documents?.[0]?.document?.Id) {
        return policyDetailsUrl(originalItem?.documents?.[0]?.document?.Id);
      }
      break;
    case 'obligation_assessment_result':
      if (originalItem?.obligations?.[0]?.obligation?.Id) {
        return obligationDetailsUrl(
          originalItem?.obligations?.[0]?.obligation?.Id
        );
      }
      break;
    case 'risk_assessment_result':
      if (originalItem?.risks?.[0]?.risk?.Id) {
        return riskDetailsUrl(originalItem?.risks?.[0]?.risk?.Id);
      }
      break;
  }
  return null;
};

const useGetFieldConfig = (
  handleOpenRating: (id: AssessmentResultRegisterFields) => void
): TableFields<AssessmentResultRegisterFields> => {
  const { t } = useTranslation('common', {
    keyPrefix: 'assessmentResults.columns',
  });
  const { t: st } = useTranslation(['common'], {
    keyPrefix: `assessments.fields`,
  });
  const { t: tt } = useTranslation(['common'], {
    keyPrefix: 'columns',
  });
  const { getByValue: getByResultValue } = useRating('performance_result');
  const { getByValue: getEffectivenessByValue } = useRating('effectiveness');
  const { getByValue: statusGetByValue } = useRating('assessment_status');
  const { getByValue: getLikelihoodByValue } = useRating('likelihood');
  const { getByValue: getImpactByValue } = useRating('impact');
  const { getByValue: getControlledRatingByValue } =
    useRating('risk_controlled');
  const { getByValue: getUncontrolledRatingByValue } =
    useRating('risk_uncontrolled');
  const { riskModel } = useAggregation();

  const ratingFns = {
    document_assessment_result: (d: AssessmentResultFields) =>
      getByResultValue(d.Rating),
    obligation_assessment_result: (d: AssessmentResultFields) =>
      getByResultValue(d.Rating),
    test_result: (d: AssessmentResultFields) =>
      getEffectivenessByValue(d.OverallEffectiveness),
    risk_assessment_result: (d: AssessmentResultFields) =>
      d.ControlType === 'Uncontrolled'
        ? getUncontrolledRatingByValue(d.Rating)
        : getControlledRatingByValue(d.Rating),
  };

  const likelihoodFns = {
    document_assessment_result: (_d: AssessmentResultFields) => null,
    obligation_assessment_result: (_d: AssessmentResultFields) => null,
    test_result: (_d: AssessmentResultFields) => null,
    risk_assessment_result: (d: AssessmentResultFields) =>
      getLikelihoodByValue(d.Likelihood),
  };

  const impactFns = {
    document_assessment_result: (_d: AssessmentResultFields) => null,
    obligation_assessment_result: (_d: AssessmentResultFields) => null,
    test_result: (_d: AssessmentResultFields) => null,
    risk_assessment_result: (d: AssessmentResultFields) =>
      getImpactByValue(d.Impact),
  };

  return {
    TestDate: dateColumn(t('TestDate'), 'TestDate', handleOpenRating),
    AssessmentTitle: {
      header: t('Title'),
      isRowHeader: true,
      cell: (item: AssessmentResultRegisterFields) => {
        const assessment = item.originalResult.assessments.find(
          (a) => a.assessment
        )?.assessment;
        return assessment ? (
          <Link href={assessmentDetailsUrl(assessment.Id)}>
            {assessment.Title}
          </Link>
        ) : (
          '-'
        );
      },
    },
    TypeLabelled: {
      header: t('Type'),
    },
    ParentTitle: {
      header: t('Item'),
      cell: (item: AssessmentResultRegisterFields) => {
        const link = getParentLink(item);
        return link ? (
          <Link href={link}>{item.ParentTitle}</Link>
        ) : (
          <>{item.ParentTitle}</>
        );
      },
    },
    RatingLabelled: {
      header: t('Result'),
      cell: (item: AssessmentResultRegisterFields) => {
        if (
          item.originalResult.__typename === 'risk_assessment_result' &&
          riskModel === 'control_effectiveness_averages'
        ) {
          return '-';
        }

        return (
          <SimpleRatingBadge
            rating={ratingFns[
              item.originalResult.__typename as keyof typeof ratingFns
            ](item.originalResult)}
          />
        );
      },
      sortingField: 'Rating',
    },
    ImpactLabelled: {
      header: t('Impact'),
      cell: (item: AssessmentResultRegisterFields) => {
        if (item.originalResult.__typename === 'risk_assessment_result') {
          const impact = impactFns[
            item.originalResult.__typename as keyof typeof impactFns
          ](item.originalResult);

          if (impact) {
            return <SimpleRatingBadge rating={impact} />;
          }
        }

        return <>-</>;
      },
    },
    LikelihoodLabelled: {
      header: t('Likelihood'),
      cell: (item: AssessmentResultRegisterFields) => {
        if (item.originalResult.__typename === 'risk_assessment_result') {
          const likelihood = likelihoodFns[
            item.originalResult.__typename as keyof typeof likelihoodFns
          ](item.originalResult);

          if (likelihood) {
            return <SimpleRatingBadge rating={likelihood} />;
          }
        }

        return '-';
      },
    },
    StartDate: dateColumn(t('StartDate'), 'StartDate'),
    ActualCompletionDate: dateColumn(
      t('CompletionDate'),
      'ActualCompletionDate'
    ),
    CompletedByUser: {
      header: t('CompletionBy'),
    },
    Rationale: {
      header: t('Rationale'),
    },
    StatusLabelled: {
      header: st('Status'),
      cell: (item: AssessmentResultRegisterFields) => {
        return <SimpleRatingBadge rating={statusGetByValue(item.Status)} />;
      },
    },
    Id: {
      header: tt('guid'),
    },
  };
};

export const useGetCollectionTableProps = (
  records: AssessmentResultFields[] | undefined,
  handleOpenRating: (id: AssessmentResultRegisterFields) => void
): TablePropsWithActions<AssessmentResultRegisterFields> => {
  const { t: at } = useTranslation('common', {
    keyPrefix: 'assessmentResults',
  });

  const fields = useGetFieldConfig(handleOpenRating);
  const labelledFields = useLabelledFields(records);

  return useGetTableProps({
    data: labelledFields,
    entityLabel: at('entity_name'),
    emptyCollectionAction: <></>,
    storageKey: 'AssessmentResultRegister-Preferences',
    enableFiltering: true,
    initialColumns: [
      'TestDate',
      'AssessmentTitle',
      'ParentTitle',
      'TypeLabelled',
      'ImpactLabelled',
      'LikelihoodLabelled',
      'RatingLabelled',
      'StatusLabelled',
    ],
    fields,
  });
};
