import { useEffect, useState } from 'react';

import ConditionalFields from '../ConditionalField';
import { useRiskSmartForm } from '../RiskSmartFormContext';

type Element = {
  key: string;
  value: React.ReactElement;
};

type FieldOrdering = {
  FieldId: string;
  Position: number;
};

export const useElementsOrder = (
  elementsByKey: Element[],
  ordering?: FieldOrdering[],
  loadingComplete?: boolean
) => {
  const [elementsOrder, setElementsOrder] = useState<string[] | null>(null);
  const { editMode } = useRiskSmartForm();

  useEffect(() => {
    if (!ordering) {
      if (loadingComplete)
        setElementsOrder(elementsByKey?.map(({ key }) => key));
      return;
    }

    const newElementsOrder = elementsByKey
      ?.map(({ key }) => key)
      .sort((a, b) => {
        const aPos = ordering.find((f) => f.FieldId === a)?.Position;
        const bPos = ordering.find((f) => f.FieldId === b)?.Position;
        // if one of the fields is not in the field config, put it at the end
        if (aPos === undefined) return 1;
        if (bPos === undefined) return -1;
        return aPos - bPos;
      });
    setElementsOrder(newElementsOrder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordering, loadingComplete, elementsByKey.length]);

  const filteredElementsOrder = elementsOrder?.filter((key) => {
    const element = elementsByKey.find((e) => e.key === key);
    if (element && element?.value?.type === ConditionalFields) {
      return !!element.value.props?.condition || editMode;
    }
    return true;
  });

  return {
    elementsOrder,
    visibleElements: filteredElementsOrder,
    setElementsOrder,
  };
};
