import { RouteObject } from 'react-router-dom';
import i18n from 'src/i18n';
import OrgFeatureFlaggedRoute from 'src/rbac/OrgFeatureFlaggedRoute';
import ProtectedRoute from 'src/rbac/ProtectedRoute';

import { GroupUpdateBreadcrumb, GroupUpdatePage, SettingsPage } from '../pages';

export const settingsRoute: RouteObject = {
  path: '',
  children: [
    {
      index: true,
      path: '',
      element: (
        <OrgFeatureFlaggedRoute featureFlag="settings">
          <ProtectedRoute permission="read:settings">
            <SettingsPage />
          </ProtectedRoute>
        </OrgFeatureFlaggedRoute>
      ),
    },
    {
      path: 'taxonomy',
      element: (
        <ProtectedRoute permission="update:taxonomy">
          <SettingsPage activeTabId="taxonomy" />
        </ProtectedRoute>
      ),
    },
    {
      path: 'users',
      element: (
        <OrgFeatureFlaggedRoute featureFlag="settings">
          <ProtectedRoute permission="read:settings">
            <SettingsPage activeTabId="users" />
          </ProtectedRoute>
        </OrgFeatureFlaggedRoute>
      ),
    },
    {
      path: 'approvals',
      handle: {
        title: () => i18n.t('approvals.page_title'),
      },
      children: [
        {
          index: true,
          element: (
            <OrgFeatureFlaggedRoute featureFlag="approvers">
              <ProtectedRoute permission="read:settings">
                <SettingsPage activeTabId="approvals" />
              </ProtectedRoute>
            </OrgFeatureFlaggedRoute>
          ),
        },
      ],
    },
    {
      path: 'groups',
      handle: {
        title: () => i18n.t('userGroups.page_title'),
      },
      children: [
        {
          index: true,
          element: (
            <OrgFeatureFlaggedRoute featureFlag="settings">
              <ProtectedRoute permission="read:settings">
                <SettingsPage activeTabId="groups" />
              </ProtectedRoute>
            </OrgFeatureFlaggedRoute>
          ),
        },
        {
          path: ':groupId',
          handle: {
            title: () => i18n.t('userGroups.group_page_title'),
            breadcrumb: GroupUpdateBreadcrumb,
          },
          children: [
            {
              path: 'details',
              element: (
                <OrgFeatureFlaggedRoute featureFlag="settings">
                  <ProtectedRoute permission="read:settings">
                    <GroupUpdatePage activeTabId="details" />
                  </ProtectedRoute>
                </OrgFeatureFlaggedRoute>
              ),
            },
            {
              path: 'members',
              element: (
                <OrgFeatureFlaggedRoute featureFlag="settings">
                  <ProtectedRoute permission="read:settings">
                    <GroupUpdatePage activeTabId="members" />
                  </ProtectedRoute>
                </OrgFeatureFlaggedRoute>
              ),
            },
          ],
        },
      ],
    },
    {
      path: 'tags',
      element: (
        <OrgFeatureFlaggedRoute featureFlag="settings">
          <ProtectedRoute permission="read:settings">
            <SettingsPage activeTabId="tags" />
          </ProtectedRoute>
        </OrgFeatureFlaggedRoute>
      ),
    },
    {
      path: 'departments',
      element: (
        <OrgFeatureFlaggedRoute featureFlag="settings">
          <ProtectedRoute permission="read:settings">
            <SettingsPage activeTabId="departments" />
          </ProtectedRoute>
        </OrgFeatureFlaggedRoute>
      ),
    },
    {
      path: 'audit',
      element: (
        <OrgFeatureFlaggedRoute featureFlag="settings">
          <ProtectedRoute permission="read:settings">
            <SettingsPage activeTabId="audit" />
          </ProtectedRoute>
        </OrgFeatureFlaggedRoute>
      ),
    },
  ],
};
