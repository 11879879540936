import { Container, Header } from '@cloudscape-design/components-themed';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useFileUpdate } from 'src/data/rest/useFileUpdate';
import { PageLayout } from 'src/layouts';
import { IssueFormDataFields } from 'src/pages/issues/update/forms/issueSchema';

import {
  departmentInsertInputBuilder,
  ownerAndContributorInsertFields,
  tagInsertInputBuilder,
} from '@/components/Form';
import { PageWrapper } from '@/components/Form/Form/PageWrapper';
import {
  Parent_Type_Enum,
  useInsertIssueOnlyMutation,
} from '@/generated/graphql';
import { reportAnIssueSuccessUrl } from '@/utils/urls';

import IssueForm from '../update/forms/IssueForm';

const Page: FC = () => {
  const { updateFiles } = useFileUpdate();
  const navigate = useNavigate();
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], { keyPrefix: 'issues' });
  const defaultTitle = st('report_issue_title');

  const [insertIssueOnly] = useInsertIssueOnlyMutation({});

  const onSave = async (data: IssueFormDataFields) => {
    const { files, newFiles } = data;
    const id = crypto.randomUUID();
    await insertIssueOnly({
      variables: {
        ...data,
        Id: id,
        CustomAttributeData: data.CustomAttributeData || undefined,
        Tags: tagInsertInputBuilder(data.TagTypeIds),
        Departments: departmentInsertInputBuilder(data.DepartmentTypeIds),
        ...ownerAndContributorInsertFields(data),
      },
    });

    await updateFiles({
      parentType: Parent_Type_Enum.Issue,
      parentId: id,
      newFiles,
      originalFiles: [],
      selectedFiles: files,
    });
    navigate(reportAnIssueSuccessUrl());
  };

  return (
    <PageLayout title={defaultTitle} actions={<></>}>
      <Container header={<Header variant="h2">{t('details')}</Header>}>
        <IssueForm
          onSave={onSave}
          renderTemplate={(renderProps) => <PageWrapper {...renderProps} />}
        />
      </Container>
    </PageLayout>
  );
};

export default Page;
