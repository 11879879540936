import { FC } from 'react';

import { useGetRiskByIdQuery } from '@/generated/graphql';

type Props = {
  riskId: string | undefined;
};

const Breadcrumb: FC<Props> = ({ riskId }) => {
  if (!riskId) {
    throw new Error('Missing riskId param');
  }
  const { data } = useGetRiskByIdQuery({
    variables: {
      _eq: riskId,
    },
  });
  return <>{data?.risk?.[0]?.Title || ''}</>;
};

export default Breadcrumb;
