import { TableProps } from '@cloudscape-design/components-themed';
import { useTranslation } from 'react-i18next';
import { MAX_COL_WIDTH } from 'src/App.config';

import Link from '@/components/Link';
import { GetIssueUpdatesByParentIssueIdQuery } from '@/generated/graphql';
import { toLocalDate } from '@/utils/dateUtils';

type IssueUpdateFields = GetIssueUpdatesByParentIssueIdQuery['issue_update'][0];

export type IssueUpdateTableFields = IssueUpdateFields & {
  CreatedByUserName: string | null;
};

export function useUpdateColumnDefinitions(
  onEdit: (actionUpdate: IssueUpdateFields) => void
) {
  const { t } = useTranslation(['common'], {
    keyPrefix: 'columns',
  });
  const updateColumnDefinitions: TableProps<IssueUpdateTableFields>['columnDefinitions'] =
    [
      {
        id: 'title',
        header: t('title'),
        cell: (item) => (
          <Link variant="secondary" href="#" onFollow={() => onEdit(item)}>
            {item.Title}
          </Link>
        ),
        sortingField: 'Title',
        maxWidth: MAX_COL_WIDTH,
        isRowHeader: true,
      },
      {
        id: 'description',
        header: t('description'),
        cell: (item) => item.Description,
        sortingField: 'Description',
        maxWidth: MAX_COL_WIDTH,
      },
      {
        id: 'createdBy',
        header: t('created_by_username'),
        cell: (item) => item.CreatedByUserName || '-',
        sortingField: 'CreatedByUserName',
      },
      {
        id: 'date',
        header: t('date'),
        cell: (item) =>
          item.ModifiedAtTimestamp
            ? toLocalDate(item.ModifiedAtTimestamp)
            : '-',
        sortingField: 'ModifiedAtTimestamp',
      },
    ];
  return updateColumnDefinitions;
}
