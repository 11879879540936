import { useContext } from 'react';

import { GetRoleAccessQuery } from '@/generated/graphql';

import { PermissionsContext } from './PermissionProvider';

type RoleAccess = GetRoleAccessQuery['role_access'][number];

export function useRoleAccess(): RoleAccess[] {
  const context = useContext(PermissionsContext)!;
  if (context === null) {
    throw new Error('useRoleAccess must be used within PermissionsProvider');
  }
  return context;
}
