import { SpaceBetween } from '@cloudscape-design/components-themed';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/notification-context';
import Table from '@risksmart-app/components/Table';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout } from 'src/layouts';

import {
  useGetAppetitesGroupedByImpactQuery,
  useGetImpactRatingsQuery,
} from '@/generated/graphql';
import { getCounter } from '@/utils/collectionUtils';

import { useGetCollectionTableProps } from './config';
import ImpactRatingModal from './ImpactRatingModal';

const Page: FC = () => {
  const { t } = useTranslation(['common'], {});
  const [selectedRatingId, setSelectedRatingId] = useState<string>();
  const { t: st } = useTranslation(['common'], { keyPrefix: 'impactRatings' });
  const { addNotification } = useNotifications();
  const { data, loading } = useGetImpactRatingsQuery({
    fetchPolicy: 'no-cache',
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });

  const { data: impactAppetites, loading: loadingImpactAppetites } =
    useGetAppetitesGroupedByImpactQuery();

  const tableProps = useGetCollectionTableProps(
    data?.impact_rating,
    impactAppetites?.impact,
    data?.form_configuration?.[0]?.customAttributeSchema ?? null,
    (item) => setSelectedRatingId(item.Id)
  );

  const counter = getCounter(
    tableProps.totalItemsCount,
    loading || loadingImpactAppetites
  );
  const title = st('register_title');

  return (
    <PageLayout
      helpTranslationKey="impactRatings.registerHelp"
      title={title}
      counter={counter}
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button iconName="download" onClick={tableProps.exportToCsv}>
            {t('export')}
          </Button>
        </SpaceBetween>
      }
    >
      <Table {...tableProps} loading={loading || loadingImpactAppetites} />
      {selectedRatingId && (
        <ImpactRatingModal
          impactRatingId={selectedRatingId}
          onDismiss={() => setSelectedRatingId(undefined)}
          onSaving={() => Promise.reject()}
        />
      )}
    </PageLayout>
  );
};

export default Page;
