
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_4yi2u_1rain_143",
  "outline": "awsui_outline_4yi2u_1rain_179",
  "toggle-control": "awsui_toggle-control_4yi2u_1rain_201",
  "toggle-control-checked": "awsui_toggle-control-checked_4yi2u_1rain_214",
  "toggle-control-disabled": "awsui_toggle-control-disabled_4yi2u_1rain_217",
  "toggle-handle": "awsui_toggle-handle_4yi2u_1rain_224",
  "toggle-handle-checked": "awsui_toggle-handle-checked_4yi2u_1rain_239",
  "toggle-handle-disabled": "awsui_toggle-handle-disabled_4yi2u_1rain_245"
};
  