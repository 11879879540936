
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_10m3l_yk3zl_151",
  "token": "awsui_token_10m3l_yk3zl_156",
  "show-operation": "awsui_show-operation_10m3l_yk3zl_171",
  "select": "awsui_select_10m3l_yk3zl_177",
  "token-content": "awsui_token-content_10m3l_yk3zl_181",
  "dismiss-button": "awsui_dismiss-button_10m3l_yk3zl_186",
  "token-disabled": "awsui_token-disabled_10m3l_yk3zl_228"
};
  