import {
  PropertyFilterProperty,
  PropertyFilterQuery,
} from '@cloudscape-design/collection-hooks';
import { isEqual } from 'lodash';
import { FC } from 'react';

import { processItems } from '../../../../../node_modules/@cloudscape-design/collection-hooks/mjs/operations';
import { DashboardItem } from './DashboardItem';

type FilerPropertyDashboardItemProps = {
  items: readonly unknown[];
  title: string;
  itemFilterQuery: PropertyFilterQuery;
  onClick: (query: PropertyFilterQuery) => void;
  tableFilterQuery: PropertyFilterQuery;
  filteringProperties: readonly PropertyFilterProperty[];
};

export const FilterPropertyDashboardItem: FC<
  FilerPropertyDashboardItemProps
> = ({
  items,
  title,
  itemFilterQuery,
  onClick,
  tableFilterQuery,
  filteringProperties,
}) => {
  const filteredItems = processItems(
    items ?? [],
    {
      propertyFilteringQuery: itemFilterQuery,
    },
    { propertyFiltering: { filteringProperties: filteringProperties ?? [] } }
  );

  return (
    <DashboardItem
      title={title}
      value={filteredItems.filteredItemsCount ?? 0}
      selected={isEqual(itemFilterQuery, tableFilterQuery)}
      onClick={() => onClick(itemFilterQuery)}
    />
  );
};
