import { ParseKeys } from 'i18next/typescript/t';
import { FC } from 'react';
import i18next from 'src/i18n';

import { labelWithPlural } from '@/utils/utils';

import EmptyCollection from './EmptyCollection';

type Props = {
  action: JSX.Element;
  entityLabel: ParseKeys<'common'>;
};

const EmptyEntityCollection: FC<Props> = ({ action, entityLabel }) => {
  const labelValues = labelWithPlural(entityLabel);
  return (
    <EmptyCollection
      action={action}
      title={i18next.t('noItems', { entity: labelValues.single })}
      subtitle={i18next.t('noItemsToDisplay', {
        entity: labelValues.plural,
      })}
    />
  );
};

export default EmptyEntityCollection;
