
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_dm8gx_1cif4_151",
  "has-items": "awsui_has-items_dm8gx_1cif4_185",
  "no-padding": "awsui_no-padding_dm8gx_1cif4_185",
  "dismiss-button": "awsui_dismiss-button_dm8gx_1cif4_189",
  "token": "awsui_token_dm8gx_1cif4_231",
  "token-box": "awsui_token-box_dm8gx_1cif4_238",
  "token-box-error": "awsui_token-box-error_dm8gx_1cif4_257",
  "token-box-warning": "awsui_token-box-warning_dm8gx_1cif4_268",
  "token-box-disabled": "awsui_token-box-disabled_dm8gx_1cif4_279"
};
  