import * as Sentry from '@sentry/browser';
import { FC, useEffect } from 'react';
import { DashboardFilterProvider } from 'src/context/dashboard-filter';
import { useHasPermission } from 'src/rbac/Permission';

import DashboardPage from '../dashboards/Page';
import ProtectedAccessDeniedPage from '../error/ProtectedAccessDeniedPage';
import ReportAnIssuePage from '../issues/report-an-issue/Page';

const Page: FC = () => {
  const hasDashboard = useHasPermission('read:dashboard', undefined, true);
  const hasPublicIssueForm = useHasPermission('read:public_issue_form');

  useEffect(() => {
    if (!hasDashboard && !hasPublicIssueForm) {
      Sentry.captureMessage(
        'User does not have access to dashboard or report an issue.'
      );
      return;
    }
  }, [hasDashboard, hasPublicIssueForm]);

  if (hasDashboard) {
    return (
      <DashboardFilterProvider>
        <DashboardPage />
      </DashboardFilterProvider>
    );
  }
  if (hasPublicIssueForm) {
    return <ReportAnIssuePage />;
  }
  return <ProtectedAccessDeniedPage hideBackToHome={true} />;
};

export default Page;
