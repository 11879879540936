import { SpaceBetween } from '@cloudscape-design/components-themed';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/notification-context';
import Table from '@risksmart-app/components/Table';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout } from 'src/layouts';

import { useGetConsequencesQuery } from '@/generated/graphql';
import { getCounter } from '@/utils/collectionUtils';

import ConsequenceModal from '../issues/update/tabs/consequences/ConsequenceModal';
import { useGetRegisterTableProps } from './config';
import { ConsequenceFlatField, ConsequenceRegisterFields } from './types';

const Page: FC = () => {
  const [openConsequence, setOpenConsequence] =
    useState<ConsequenceFlatField | null>(null);
  const { t } = useTranslation(['common'], {});
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'consequences',
  });
  const { addNotification } = useNotifications();
  const { data, loading, refetch } = useGetConsequencesQuery({
    fetchPolicy: 'no-cache',
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });
  const customAttributeSchemas = data?.form_configuration.length
    ? data?.form_configuration.map(
        (schemaData) => schemaData.customAttributeSchema!
      )[0]
    : null;
  const tableProps = useGetRegisterTableProps(
    data?.consequence,
    customAttributeSchemas,
    (consequence: ConsequenceRegisterFields) => setOpenConsequence(consequence)
  );

  const title = st('register_title');
  const counter = getCounter(tableProps.totalItemsCount, loading);

  return (
    <PageLayout
      helpTranslationKey="consequences.registerHelp"
      title={title}
      counter={counter}
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button iconName="download" onClick={tableProps.exportToCsv}>
            {t('export')}
          </Button>
        </SpaceBetween>
      }
    >
      <Table {...tableProps} loading={loading} />
      {openConsequence && (
        <ConsequenceModal
          consequenceId={openConsequence.Id}
          onDismiss={(saved) => {
            setOpenConsequence(null);
            if (saved) {
              refetch();
            }
          }}
          issueId={openConsequence.ParentIssueId}
        />
      )}
    </PageLayout>
  );
};

export default Page;
