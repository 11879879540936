import { Spinner } from '@cloudscape-design/components-themed';
import { FC } from 'react';

export const WidgetLoading: FC = () => {
  return (
    <div className="flex h-full text-center flex-wrap content-center">
      <div className="flex-auto">
        <Spinner size="normal" />
      </div>
    </div>
  );
};
