import { TableProps } from '@cloudscape-design/components-themed';
import { useTranslation } from 'react-i18next';
import { getAllOwnersCellValue } from 'src/rbac/contributorHelper';

import CommaSeparatedCell from '@/components/CommaSeparatedCell';
import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import TagsPopover from '@/components/TagsPopover';
import { GetControlsByParentIdQuery } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';

export type ControlsTableFields =
  GetControlsByParentIdQuery['control'][number] & {
    OverallEffectiveness: number | null;
  };

export function useControlColumnDefinitions() {
  const { getByValue } = useRating('effectiveness');
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'controls.columns',
  });
  const { t } = useTranslation(['common'], {
    keyPrefix: 'columns',
  });
  const columnDefinitions: TableProps<ControlsTableFields>['columnDefinitions'] =
    [
      {
        id: 'name',
        header: st('title'),
        cell: (item) => (
          <Link variant="secondary" isRelativeUrl={true} href={`${item.Id}`}>
            {item.Title}
          </Link>
        ),
        sortingField: 'Title',
        isRowHeader: true,
      },
      {
        id: 'type',
        header: st('type'),
        cell: (item) => item.Type || 'None',
        sortingField: 'Type',
      },
      {
        id: 'owner',
        header: t('owners'),
        cell: (item) => (
          <CommaSeparatedCell items={getAllOwnersCellValue(item)} />
        ),
        sortingField: 'OwnerName',
      },
      {
        id: 'effectiveness',
        header: st('effectiveness'),
        cell: (item) => {
          return (
            <SimpleRatingBadge rating={getByValue(item.OverallEffectiveness)} />
          );
        },
        sortingField: 'OverallEffectiveness',
      },
      {
        id: 'tags',
        header: t('tags'),
        cell: (item) => <TagsPopover id={item.Id} tags={item.tags} />,
        sortingField: 'tags',
      },
    ];

  return columnDefinitions;
}
