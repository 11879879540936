import { CsvFieldType } from '@/utils/table/types';

export const downloadBlob = (fileName: string, blob: Blob) => {
  const anchor = document.createElement('a');
  document.body.appendChild(anchor);

  const objectUrl = window.URL.createObjectURL(blob);
  anchor.href = objectUrl;
  anchor.download = fileName;
  anchor.click();
  window.URL.revokeObjectURL(objectUrl);
};

export function arrayToCsv(data: CsvFieldType[][]) {
  return data
    .map((row) =>
      row
        .map((value) =>
          typeof value === 'string' ? value.replaceAll('"', '""') : value
        )

        .map((value) => (typeof value === 'string' ? `"${value}"` : value))
        .map((value) => {
          if (value === undefined || value === null) {
            return '';
          }
          return String(value);
        })
        .join(',')
    )
    .join('\r\n');
}
