import Link from '@risksmart-app/components/Link';
import { tableOptionsToQueryString } from '@risksmart-app/components/Table/tableUtils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import { useRating } from '@/hooks/use-rating';
import { UseGetTablePropsOptions } from '@/utils/table/hooks/useGetStatelessTableProps';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import { TableFields, TablePropsWithActions } from '@/utils/table/types';
import { dateColumn } from '@/utils/table/utils/dateColumn';
import { yesNoCell } from '@/utils/table/utils/yesNoCell';
import { policyFileAttestationDetailsUrl } from '@/utils/urls';

import i18n from '../../i18n';
import { AttestationFlatField, AttestationRegisterFields } from './types';
import { useLabelledFields } from './useLabelledFields';

const useGetFieldConfig = (): TableFields<AttestationRegisterFields> => {
  const { t } = useTranslation(['common'], { keyPrefix: 'columns' });
  const { t: st } = useTranslation(['taxonomy']);
  const { getByValue } = useRating('attestation_record_status');

  return useMemo(
    () => ({
      User: {
        header: t('user'),
        cell: (item) => (
          <Link
            href={
              '#' +
              tableOptionsToQueryString({
                filtering: {
                  operation: 'and',
                  tokens: [
                    {
                      propertyKey: 'User',
                      operator: '=',
                      value: item.User,
                    },
                  ],
                },
              })
            }
          >
            {item.User}
          </Link>
        ),
      },
      Document: {
        header: i18n.format(st('document_one'), 'capitalizeAll'),
        cell: (item) => (
          <Link
            href={policyFileAttestationDetailsUrl(
              item.node.documentFile?.parent?.Id ?? '',
              item.NodeId
            )}
          >
            {item.Document}
          </Link>
        ),
      },
      Active: { header: 'Active', cell: yesNoCell('Active') },
      AttestationStatus: {
        header: t('status'),
        cell: (item) => (
          <SimpleRatingBadge rating={getByValue(item.AttestationStatus)} />
        ),
      },
      CreatedAtTimestamp: dateColumn(
        t('created_on'),
        'CreatedAtTimestamp',
        undefined,
        true
      ),
      AttestedAt: dateColumn(t('attested_at'), 'AttestedAt', undefined, true),
      ExpiresAt: dateColumn(t('expires_at'), 'ExpiresAt', undefined, true),
    }),
    [getByValue, t, st]
  );
};

const useGetAttestationTableProps = (
  records: AttestationFlatField[] | undefined
): UseGetTablePropsOptions<AttestationRegisterFields> => {
  const { t } = useTranslation(['common']);
  const data = useLabelledFields(records);
  const fields = useGetFieldConfig();

  return useMemo(
    () => ({
      data,
      customAttributeSchema: [],
      entityLabel: t('attestation_one'),
      emptyCollectionAction: <></>,
      storageKey: 'AttestationRegisterTable-PreferencesV1',
      enableFiltering: true,
      defaultSortingState: {
        sortingColumn: 'Active',
        sortingDirection: 'desc',
      },
      initialColumns: [
        'User',
        'Document',
        'Active',
        'AttestationStatus',
        'AttestedAt',
        'ExpiresAt',
        'CreatedAtTimestamp',
      ],
      fields,
    }),
    [fields, t, data]
  );
};

export const useGetRegisterTableProps = (
  records: AttestationFlatField[] | undefined
): TablePropsWithActions<AttestationRegisterFields> => {
  const props = useGetAttestationTableProps(records);
  return useGetTableProps(props);
};
