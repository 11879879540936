import {
  OptionDefinition,
  OptionGroup,
} from '@cloudscape-design/components-themed/internal/components/option/interfaces';
import { useMemo } from 'react';

import {
  GetDepartmentsQuery,
  useGetDepartmentsQuery,
} from '@/generated/graphql';
import { DepartmentType } from '@/types/types';

import { StatusType } from '../ControlledSelect/ControlledSelect';
import { sortByLabel } from '../form-utils';

const cleanRawDepartmentTypes = (
  rawDepartments?: GetDepartmentsQuery
): Array<DepartmentType> => {
  return (rawDepartments?.department_type || []).map(
    ({ Name, Description, DepartmentTypeId, department_type_group }) => ({
      Name: Name,
      Description: Description || '',
      DepartmentTypeId: DepartmentTypeId,
      DepartmentTypeGroupName: department_type_group?.Name,
    })
  );
};

const departmentsToGroupDefinition = (groupedDepartments: {
  [key: string]: DepartmentType[];
}) =>
  Object.keys(groupedDepartments)
    .sort()
    .map<OptionGroup>((groupName) => ({
      label: groupName,
      options: groupedDepartments[groupName]
        .map((dept) => ({
          value: dept.DepartmentTypeId,
          label: dept.Name || '',
          description: dept.Description || '',
        }))
        .sort(sortByLabel),
    }));

export const useDepartmentOptions = () => {
  const { data, loading, error } = useGetDepartmentsQuery({});
  const departments = cleanRawDepartmentTypes(data);

  const optionItems = useMemo<(OptionDefinition | OptionGroup)[]>(() => {
    const noGroup = 'No group';
    const groupedDepartments = departments.reduce<{
      [key: string]: DepartmentType[];
    }>((acc, obj) => {
      const groupName = obj.DepartmentTypeGroupName ?? noGroup;
      acc[groupName] = acc[groupName] || [];
      acc[groupName].push(obj);
      return acc;
    }, {});

    const departmentOptions = departmentsToGroupDefinition(groupedDepartments);

    const groupedDepartmentOptions = departmentOptions.filter(
      (x) => x.label !== noGroup
    );
    const ungroupedDepartmentOptions = departmentOptions.filter(
      (x) => x.label === noGroup
    );

    if (
      groupedDepartmentOptions.length <= 0 &&
      ungroupedDepartmentOptions.length > 0
    ) {
      return ungroupedDepartmentOptions[0].options as OptionDefinition[];
    }

    return [...groupedDepartmentOptions, ...ungroupedDepartmentOptions];
  }, [departments]);

  let statusType: StatusType | undefined = undefined;
  if (loading) {
    statusType = 'loading';
  } else if (error) {
    statusType = 'error';
  }

  return { departments, optionItems, statusType };
};
