import { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DeleteModal from '@/components/DeleteModal/DeleteModal';
import {
  GetCustomAttributeSchemasByParentTypeDocument,
  GetFormFieldOptionsByParentTypeDocument,
  Parent_Type_Enum,
  useGetCustomAttributeSchemasByParentTypeQuery,
  useInsertCustomAttributeSchemaMutation,
  useInsertFormFieldOptionsMutation,
  useUpdateCustomAttributeSchemaMutation,
  useUpdateFormFieldOptionsMutation,
} from '@/generated/graphql';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';

import { TextInput } from '../ControlledInput/ControlledInput';
import {
  addFieldToSchemaData,
  removeFieldFromSchemaData,
} from '../CustomAttributes/CustomAttributeSchema';
import { NewFieldForm } from '../CustomAttributes/EditFields/NewFieldForm';
import {
  defaultValues,
  fieldOptionsSchema,
  fieldToJsonSchema,
  NewFieldFormFields,
  newFieldSchema,
} from '../CustomAttributes/EditFields/NewFieldSchema';
import { ControlledFieldContext } from '../FieldController/ControlledFieldContext';
import { useFieldConfig } from '../Form/CustomisableForm/hooks/useFieldConfig';
import { useRiskSmartForm } from '../Form/CustomisableForm/RiskSmartFormContext';
import { ModalForm } from '../Form/ModalForm';
import CustomisableFieldOptions from './CustomisableFieldOptions';

export enum EditMode {
  Update = 'update',
  Delete = 'delete',
  Create = 'create',
}

export interface EditFieldModalProps {
  parentType: Parent_Type_Enum;
  onDismiss: () => void;
  fieldId?: string;
  values?: NewFieldFormFields;
  fieldPath?: string;
  editMode?: EditMode;
  customField?: boolean;
  label?: string;
}
export const EditFieldModal: FC<EditFieldModalProps> = ({
  onDismiss,
  parentType,
  values,
  fieldPath,
  editMode = EditMode.Create,
  customField = false,
  label,
}) => {
  const { allFieldIds } = useRiskSmartForm();
  const { t } = useTranslation(['common']);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const controlledField = useContext(ControlledFieldContext);
  const [insertCustomAttributeField] = useInsertCustomAttributeSchemaMutation({
    refetchQueries: [GetCustomAttributeSchemasByParentTypeDocument],
  });
  const [updateCustomAttributeField, updateResult] =
    useUpdateCustomAttributeSchemaMutation({
      refetchQueries: [GetCustomAttributeSchemasByParentTypeDocument],
    });
  const [insertFieldConfig] = useInsertFormFieldOptionsMutation({
    refetchQueries: [GetFormFieldOptionsByParentTypeDocument],
  });
  const [updateFieldConfig] = useUpdateFormFieldOptionsMutation({
    refetchQueries: [GetFormFieldOptionsByParentTypeDocument],
  });

  const { data, loading } = useGetCustomAttributeSchemasByParentTypeQuery({
    variables: { parentType },
    fetchPolicy: 'no-cache',
  });

  const fieldId = controlledField?.field.name;
  const fieldOptionsData = useFieldConfig(fieldId, {
    defaultRequired: controlledField?.defaultRequired,
  });
  const formConfigExists = !!data?.form_configuration[0];
  const customAttributeSchemaData =
    data?.form_configuration?.[0]?.customAttributeSchema;
  const schemaId = customAttributeSchemaData?.Id;
  const title = `${parentType} custom attributes`;

  const onDelete = useDeleteResultNotification({
    entityName: t('customAttributes.entity_name'),
    asyncAction: async () => {
      if (!values || !schemaId) return false;
      const fieldData = fieldToJsonSchema(values, fieldPath);
      const updatedSchema = removeFieldFromSchemaData(fieldData, {
        uiSchema: customAttributeSchemaData.UiSchema,
        schema: customAttributeSchemaData.Schema,
      });
      await updateCustomAttributeField({
        variables: {
          Title: title,
          UiSchema: updatedSchema.uiSchema,
          Schema: updatedSchema.schema,
          Id: schemaId,
        },
      });
      onDismiss();
      return true;
    },
  });

  const onSave = async (data: NewFieldFormFields) => {
    const newField = newFieldSchema.safeParse(data);
    const fieldOptions = fieldOptionsSchema.safeParse(data);

    if (newField.success) {
      const fieldData = fieldToJsonSchema(newField.data, fieldPath);
      if (schemaId) {
        const updatedSchema = addFieldToSchemaData(fieldData, {
          uiSchema: customAttributeSchemaData.UiSchema,
          schema: customAttributeSchemaData.Schema,
        });
        await updateCustomAttributeField({
          variables: {
            Title: title,
            UiSchema: updatedSchema.uiSchema,
            Schema: updatedSchema.schema,
            Id: schemaId,
          },
        });
      } else {
        const newSchema = addFieldToSchemaData(fieldData);
        await insertCustomAttributeField({
          variables: {
            Title: title,
            UiSchema: newSchema.uiSchema,
            Schema: newSchema.schema,
            ParentType: parentType,
          },
        });
      }
    }

    if (
      fieldOptions.success &&
      !(customField && editMode === EditMode.Create)
    ) {
      if (formConfigExists) {
        await updateFieldConfig({
          variables: {
            parentType,
            fieldIds: allFieldIds,
            fieldConfig: {
              FormConfigurationParentType: parentType,
              FieldId: fieldId,
              form: null,
              ...fieldOptions.data,
            },
          },
        });
      } else {
        await insertFieldConfig({
          variables: {
            parentType,
            fieldIds: allFieldIds,
            fieldConfig: {
              FieldId: fieldId,
              form: null,
              ...fieldOptions.data,
            },
          },
        });
      }
    }
  };

  if (loading || !editMode) return null;

  return (
    <ModalForm
      i18n={t('customAttributes')}
      defaultValues={defaultValues}
      values={{
        ...defaultValues,
        ...values,
        ...fieldOptionsData,
        ...(controlledField?.forceRequired ? { Required: true } : {}),
      }}
      schema={customField ? newFieldSchema : fieldOptionsSchema}
      onSave={onSave}
      onDismiss={onDismiss}
      onDelete={
        !!values && !!fieldPath
          ? async () => setShowDeleteModal(true)
          : undefined
      }
      formId="field-configuration-form"
      visible={true}
    >
      {customField ? (
        <NewFieldForm disableTypeField={editMode === EditMode.Update} />
      ) : label ? (
        <TextInput
          name="Label"
          label={t('customAttributes.fields.label')}
          onChange={() => null}
          value={label}
          disabled={!customField}
        />
      ) : null}
      {!(customField && editMode === EditMode.Create) && (
        <CustomisableFieldOptions />
      )}
      <DeleteModal
        loading={updateResult.loading}
        isVisible={showDeleteModal}
        header={t('delete')}
        onDelete={onDelete}
        onDismiss={onDismiss}
      >
        {t('customAttributes.confirm_delete_message')}
      </DeleteModal>
    </ModalForm>
  );
};
