import { SpaceBetween } from '@cloudscape-design/components-themed';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout } from 'src/layouts';

import ReportsListItem from '@/components/ReportsList/ReportsListItem';

interface Props {}

const Page: FC<Props> = () => {
  const { t } = useTranslation(['common'], {
    keyPrefix: 'reports',
  });
  const title = t('reports_title', 'Reports');

  return (
    <PageLayout title={title} helpTranslationKey="reports.help">
      <SpaceBetween direction="vertical" size="s">
        <ReportsListItem
          dashboardId="940046ed-7fe6-4709-a06d-4a32bdbdf607"
          heading="Issues by Type"
          description="This report is a summary of all issue data segmented by type."
          author="RiskSmart Authored"
          imageSrc="/reports/dashboard_demo_1.png"
        />

        <ReportsListItem
          dashboardId="d12a9fea-1fa8-4589-8ff4-3b4b78a72c4e"
          heading="Issues Causes"
          description="This report provides a summary of all issue data segmented by cause."
          author="RiskSmart Authored"
          imageSrc="/reports/dashboard_demo_1.png"
        />

        <ReportsListItem
          dashboardId="89baabc6-32f8-48af-aa20-a5a0ce0ae804"
          heading="Issues by Raised Date"
          description="This report provides a summary of all issue data segmented by date raised."
          author="RiskSmart Authored"
          imageSrc="/reports/dashboard_demo_1.png"
        />

        <ReportsListItem
          dashboardId="f1e589fc-4a9b-45bb-9a0f-7301d6baf88c"
          heading="Actions by Priority"
          description="This report provides a summary of actions data segmented by priority."
          author="RiskSmart Authored"
          imageSrc="/reports/dashboard_demo_1.png"
        />

        <ReportsListItem
          dashboardId="acc2bf44-42c4-4d49-8c6e-b0513b1b621d"
          heading="Actions by Status"
          description="This report provides a summary of actions data segmented by status."
          author="RiskSmart Authored"
          imageSrc="/reports/dashboard_demo_1.png"
        />

        <ReportsListItem
          dashboardId="1b18e1ee-8cc8-4325-a271-4e8d7d8f3c17"
          heading="Actions by Type"
          description="This report provides a summary of actions data segmented by type."
          author="RiskSmart Authored"
          imageSrc="/reports/dashboard_demo_1.png"
        />

        <ReportsListItem
          dashboardId="767df461-bba4-459d-b1ff-c5e9eda7a980"
          heading="ERC Report"
          description="This report provides a summary of Incidents by Cause and Incidents that are flagged as Regulatory or Policy Breaches."
          author="RiskSmart Authored"
          imageSrc="/reports/dashboard_demo_1.png"
        />

        <ReportsListItem
          dashboardId="1b18e1ee-8cc8-4325-a271-4e8d7d8f3c17"
          heading="Actions by Type"
          description="This report provides a summary of actions data segmented by type."
          author="RiskSmart Authored"
          imageSrc="/reports/dashboard_demo_1.png"
        />

        <ReportsListItem
          dashboardId="767df461-bba4-459d-b1ff-c5e9eda7a980"
          heading="ERC Report"
          description="This report provides a summary of Incidents by Cause and Incidents that are flagged as Regulatory or Policy Breaches."
          author="RiskSmart Authored"
          imageSrc="/reports/dashboard_demo_1.png"
        />

        <ReportsListItem
          dashboardId="65b2a977-74ed-4a3b-b8c6-5682e46b77b5"
          heading="Attestation Report"
          description="Attestation reports covering issues by type and losses."
          author="RiskSmart Authored"
          imageSrc="/reports/dashboard_demo_1.png"
        />
      </SpaceBetween>
    </PageLayout>
  );
};

export default Page;
