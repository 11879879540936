import { useNotifications } from '@risksmart-app/components/Notifications/notification-context';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useDeleteApprovalMutation,
  useGetApprovalByIdQuery,
} from '@/generated/graphql';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { evictField } from '@/utils/graphqlUtils';

import CreateModal from './CreateModal';
import UpdateModal from './UpdateModal';

type Props = {
  open: boolean;
  onClose?: () => void;
  approvalId: string | null;
  parentId?: string;
  readOnly?: boolean;
};

const ApprovalModal: FC<Props> = ({
  open,
  onClose,
  approvalId,
  parentId,
  readOnly,
}) => {
  const { addNotification } = useNotifications();
  const { t } = useTranslation(['common'], { keyPrefix: 'approvals' });
  const [deleteApproval] = useDeleteApprovalMutation({
    update: (cache) => evictField(cache, 'approval'),
  });

  const { data, loading } = useGetApprovalByIdQuery({
    variables: {
      Id: approvalId!,
    },
    skip: !approvalId,
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });

  const approval = data?.approval;

  const onDelete = useDeleteResultNotification({
    entityName: t('entity_name'),
    asyncAction: async () => {
      if (!approvalId) return false;
      await deleteApproval({
        variables: {
          Id: approvalId,
        },
      });
      onClose?.();
      return true;
    },
  });

  return (
    <>
      {loading || !open ? null : approval ? (
        <UpdateModal
          approval={approval}
          onDismiss={onClose}
          onDelete={
            approval
              ? async () => {
                  await onDelete();
                }
              : undefined
          }
          readOnly={readOnly}
          parentId={parentId}
        />
      ) : (
        <CreateModal
          onDismiss={onClose}
          parentId={parentId}
          readOnly={readOnly}
        />
      )}
    </>
  );
};

export default ApprovalModal;
