
    import './styles.scoped.css';
    export default {
  "error-icon-shake-wrapper": "awsui_error-icon-shake-wrapper_14mhv_1x4v6_147",
  "warning-icon-shake-wrapper": "awsui_warning-icon-shake-wrapper_14mhv_1x4v6_148",
  "awsui-motion-shake-horizontally": "awsui_awsui-motion-shake-horizontally_14mhv_1x4v6_1",
  "error-icon-scale-wrapper": "awsui_error-icon-scale-wrapper_14mhv_1x4v6_178",
  "warning-icon-scale-wrapper": "awsui_warning-icon-scale-wrapper_14mhv_1x4v6_179",
  "awsui-motion-scale-popup": "awsui_awsui-motion-scale-popup_14mhv_1x4v6_1",
  "warning": "awsui_warning_14mhv_1x4v6_148",
  "error": "awsui_error_14mhv_1x4v6_147",
  "awsui-motion-fade-in-0": "awsui_awsui-motion-fade-in-0_14mhv_1x4v6_1",
  "root": "awsui_root_14mhv_1x4v6_230",
  "label": "awsui_label_14mhv_1x4v6_266",
  "info": "awsui_info_14mhv_1x4v6_280",
  "description": "awsui_description_14mhv_1x4v6_286",
  "constraint": "awsui_constraint_14mhv_1x4v6_287",
  "hints": "awsui_hints_14mhv_1x4v6_294",
  "constraint-has-validation-text": "awsui_constraint-has-validation-text_14mhv_1x4v6_295",
  "secondary-control": "awsui_secondary-control_14mhv_1x4v6_299",
  "controls": "awsui_controls_14mhv_1x4v6_303",
  "label-hidden": "awsui_label-hidden_14mhv_1x4v6_303",
  "control": "awsui_control_14mhv_1x4v6_303",
  "error__message": "awsui_error__message_14mhv_1x4v6_330",
  "warning__message": "awsui_warning__message_14mhv_1x4v6_331",
  "visually-hidden": "awsui_visually-hidden_14mhv_1x4v6_335"
};
  