
    import './styles.scoped.css';
    export default {
  "segment": "awsui_segment_8cbea_165ij_159",
  "disabled": "awsui_disabled_8cbea_165ij_239",
  "selected": "awsui_selected_8cbea_165ij_247",
  "icon": "awsui_icon_8cbea_165ij_268",
  "with-text": "awsui_with-text_8cbea_165ij_272",
  "with-no-text": "awsui_with-no-text_8cbea_165ij_278",
  "root": "awsui_root_8cbea_165ij_283",
  "segment-part": "awsui_segment-part_8cbea_165ij_318",
  "select": "awsui_select_8cbea_165ij_247",
  "segment-count-2": "awsui_segment-count-2_8cbea_165ij_337",
  "segment-count-3": "awsui_segment-count-3_8cbea_165ij_342",
  "segment-count-4": "awsui_segment-count-4_8cbea_165ij_347",
  "segment-count-5": "awsui_segment-count-5_8cbea_165ij_352",
  "segment-count-6": "awsui_segment-count-6_8cbea_165ij_357"
};
  