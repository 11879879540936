import { FieldValues } from 'react-hook-form';

import { useGetDocumentsQuery } from '@/generated/graphql';

import { ControlledBaseProps } from '..';
import ControlledSelect from '../ControlledSelect';

interface Props<T extends FieldValues> extends ControlledBaseProps<T> {
  addEmptyOption?: boolean;
  disabled?: boolean;
  excludedIds?: string[];
}

export const ControlledDocumentSelect = <T extends FieldValues>({
  excludedIds,
  ...props
}: Props<T>) => {
  const { data: documents, loading } = useGetDocumentsQuery({});

  return (
    <ControlledSelect
      filteringType="auto"
      statusType={loading ? 'loading' : 'finished'}
      {...props}
      options={
        documents?.document
          ?.filter((document) => !excludedIds?.includes(document.Id))
          .map((document) => ({
            value: String(document.Id),
            label: String(document.Title),
          })) || []
      }
    />
  );
};
