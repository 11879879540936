import { Auth0ContextInterface, useAuth0, User } from '@auth0/auth0-react';
import { useMemo } from 'react';
import {
  hasuraAllowedRoles,
  hasuraClaimsNamespace,
  hasuraDefaultRole,
  hasuraUserId,
} from 'src/rbac/jwt';
import { Roles } from 'src/rbac/roles';

export type RisksmartUser = User & {
  orgKey: string;
  orgRole: Roles;
  allowedRoles: string[];
  userId: string;
};

const useRisksmartUser = (): Auth0ContextInterface<RisksmartUser> => {
  const { user, ...rest } = useAuth0();
  return useMemo(
    () =>
      user
        ? {
            ...rest,
            user: {
              ...user,
              orgKey: user.org_id,
              orgRole: user?.[hasuraClaimsNamespace][hasuraDefaultRole],
              allowedRoles: user?.[hasuraClaimsNamespace][hasuraAllowedRoles],
              userId: user?.[hasuraClaimsNamespace][hasuraUserId],
            },
          }
        : { user: undefined, ...rest },
    [rest, user]
  );
};

export default useRisksmartUser;
