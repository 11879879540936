
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_1fn7j_1xqy1_143",
  "styled-box": "awsui_styled-box_1fn7j_1xqy1_150",
  "styled-box-checked": "awsui_styled-box-checked_1fn7j_1xqy1_156",
  "styled-box-indeterminate": "awsui_styled-box-indeterminate_1fn7j_1xqy1_156",
  "styled-box-disabled": "awsui_styled-box-disabled_1fn7j_1xqy1_160",
  "styled-line": "awsui_styled-line_1fn7j_1xqy1_174",
  "styled-line-disabled": "awsui_styled-line-disabled_1fn7j_1xqy1_179"
};
  