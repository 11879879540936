import { KnockFeedProvider } from '@knocklabs/react-notification-feed';
import { getEnv } from '@risksmart-app/components/utils/environment';
import { FC, ReactElement } from 'react';

import useRisksmartUser from '@/hooks/useRisksmartUser';

export const MessagesProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const { user } = useRisksmartUser();
  return (
    <KnockFeedProvider
      apiKey={getEnv('REACT_APP_KNOCK_PUBLIC_API_KEY')}
      feedId={getEnv('REACT_APP_KNOCK_FEED_CHANNEL_ID')}
      userId={user!.userId}
      defaultFeedOptions={{ tenant: user?.orgKey }}
    >
      {children}
    </KnockFeedProvider>
  );
};
