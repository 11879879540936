
    import './styles.scoped.css';
    export default {
  "calendar": "awsui_calendar_mgja0_19ipb_151",
  "awsui-motion-fade-in-0": "awsui_awsui-motion-fade-in-0_mgja0_19ipb_1",
  "root": "awsui_root_mgja0_19ipb_174",
  "wide": "awsui_wide_mgja0_19ipb_208",
  "focus-lock": "awsui_focus-lock_mgja0_19ipb_215",
  "trigger-wrapper": "awsui_trigger-wrapper_mgja0_19ipb_219",
  "trigger-flexbox": "awsui_trigger-flexbox_mgja0_19ipb_223",
  "calendar-container": "awsui_calendar-container_mgja0_19ipb_227",
  "one-grid": "awsui_one-grid_mgja0_19ipb_230",
  "calendar-header": "awsui_calendar-header_mgja0_19ipb_234",
  "calendar-header-months-wrapper": "awsui_calendar-header-months-wrapper_mgja0_19ipb_240",
  "calendar-header-month": "awsui_calendar-header-month_mgja0_19ipb_240",
  "calendar-next-month-btn": "awsui_calendar-next-month-btn_mgja0_19ipb_259",
  "calendar-prev-month-btn": "awsui_calendar-prev-month-btn_mgja0_19ipb_262",
  "first-grid": "awsui_first-grid_mgja0_19ipb_266",
  "second-grid": "awsui_second-grid_mgja0_19ipb_267",
  "date-and-time-container": "awsui_date-and-time-container_mgja0_19ipb_271",
  "date-and-time-wrapper": "awsui_date-and-time-wrapper_mgja0_19ipb_277",
  "footer": "awsui_footer_mgja0_19ipb_284",
  "has-clear-button": "awsui_has-clear-button_mgja0_19ipb_293",
  "footer-button-wrapper": "awsui_footer-button-wrapper_mgja0_19ipb_302",
  "icon-wrapper": "awsui_icon-wrapper_mgja0_19ipb_309",
  "label": "awsui_label_mgja0_19ipb_314",
  "label-enabled": "awsui_label-enabled_mgja0_19ipb_319",
  "label-text": "awsui_label-text_mgja0_19ipb_323",
  "label-token-nowrap": "awsui_label-token-nowrap_mgja0_19ipb_328",
  "mode-switch": "awsui_mode-switch_mgja0_19ipb_332",
  "dropdown": "awsui_dropdown_mgja0_19ipb_336",
  "dropdown-content": "awsui_dropdown-content_mgja0_19ipb_358",
  "start-date-input": "awsui_start-date-input_mgja0_19ipb_367",
  "start-time-input": "awsui_start-time-input_mgja0_19ipb_368",
  "end-date-input": "awsui_end-date-input_mgja0_19ipb_369",
  "end-time-input": "awsui_end-time-input_mgja0_19ipb_370",
  "validation-section": "awsui_validation-section_mgja0_19ipb_374",
  "validation-error": "awsui_validation-error_mgja0_19ipb_378",
  "clear-button": "awsui_clear-button_mgja0_19ipb_382",
  "apply-button": "awsui_apply-button_mgja0_19ipb_386",
  "cancel-button": "awsui_cancel-button_mgja0_19ipb_390",
  "calendar-aria-live": "awsui_calendar-aria-live_mgja0_19ipb_394"
};
  