
    import './styles.scoped.css';
    export default {
  "expandable-toggle-wrapper": "awsui_expandable-toggle-wrapper_c6tup_19602_143",
  "body-cell": "awsui_body-cell_c6tup_19602_151",
  "body-cell-content": "awsui_body-cell-content_c6tup_19602_163",
  "body-cell-wrap": "awsui_body-cell-wrap_c6tup_19602_166",
  "is-visual-refresh": "awsui_is-visual-refresh_c6tup_19602_178",
  "has-striped-rows": "awsui_has-striped-rows_c6tup_19602_190",
  "body-cell-expandable-level-1": "awsui_body-cell-expandable-level-1_c6tup_19602_193",
  "body-cell-expandable-level-2": "awsui_body-cell-expandable-level-2_c6tup_19602_196",
  "body-cell-expandable-level-3": "awsui_body-cell-expandable-level-3_c6tup_19602_199",
  "body-cell-expandable-level-4": "awsui_body-cell-expandable-level-4_c6tup_19602_202",
  "body-cell-expandable-level-5": "awsui_body-cell-expandable-level-5_c6tup_19602_205",
  "body-cell-expandable-level-6": "awsui_body-cell-expandable-level-6_c6tup_19602_208",
  "body-cell-expandable-level-7": "awsui_body-cell-expandable-level-7_c6tup_19602_211",
  "body-cell-expandable-level-8": "awsui_body-cell-expandable-level-8_c6tup_19602_214",
  "body-cell-expandable-level-9": "awsui_body-cell-expandable-level-9_c6tup_19602_217",
  "body-cell-expandable-level-next": "awsui_body-cell-expandable-level-next_c6tup_19602_220",
  "body-cell-edit-active": "awsui_body-cell-edit-active_c6tup_19602_223",
  "body-cell-interactive": "awsui_body-cell-interactive_c6tup_19602_223",
  "body-cell-editable": "awsui_body-cell-editable_c6tup_19602_223",
  "has-striped-rows-sticky-cell-pad-inline-start": "awsui_has-striped-rows-sticky-cell-pad-inline-start_c6tup_19602_289",
  "has-selection": "awsui_has-selection_c6tup_19602_322",
  "body-cell-first-row": "awsui_body-cell-first-row_c6tup_19602_358",
  "body-cell-last-row": "awsui_body-cell-last-row_c6tup_19602_361",
  "body-cell-selected": "awsui_body-cell-selected_c6tup_19602_361",
  "has-footer": "awsui_has-footer_c6tup_19602_361",
  "body-cell-shaded": "awsui_body-cell-shaded_c6tup_19602_371",
  "sticky-cell": "awsui_sticky-cell_c6tup_19602_397",
  "sticky-cell-pad-left": "awsui_sticky-cell-pad-left_c6tup_19602_405",
  "sticky-cell-last-inline-end": "awsui_sticky-cell-last-inline-end_c6tup_19602_452",
  "sticky-cell-last-inline-start": "awsui_sticky-cell-last-inline-start_c6tup_19602_460",
  "body-cell-next-selected": "awsui_body-cell-next-selected_c6tup_19602_489",
  "body-cell-prev-selected": "awsui_body-cell-prev-selected_c6tup_19602_493",
  "body-cell-editor-wrapper": "awsui_body-cell-editor-wrapper_c6tup_19602_515",
  "body-cell-success": "awsui_body-cell-success_c6tup_19602_520",
  "body-cell-editor": "awsui_body-cell-editor_c6tup_19602_515",
  "body-cell-editor-disabled": "awsui_body-cell-editor-disabled_c6tup_19602_545",
  "body-cell-editor-form": "awsui_body-cell-editor-form_c6tup_19602_554",
  "body-cell-editor-row": "awsui_body-cell-editor-row_c6tup_19602_564",
  "body-cell-editor-controls": "awsui_body-cell-editor-controls_c6tup_19602_574",
  "body-cell-expandable": "awsui_body-cell-expandable_c6tup_19602_193",
  "resizable-columns": "awsui_resizable-columns_c6tup_19602_598",
  "expandable-cell-content": "awsui_expandable-cell-content_c6tup_19602_601",
  "body-cell-edit-disabled-popover": "awsui_body-cell-edit-disabled-popover_c6tup_19602_649",
  "body-cell-has-success": "awsui_body-cell-has-success_c6tup_19602_652"
};
  