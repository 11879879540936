import { Modal, ModalProps } from '@cloudscape-design/components-themed';
import { FC } from 'react';

import style from './style.module.scss';

const RSModal: FC<ModalProps> = (props) => {
  return <Modal {...props} {...{ className: style.modal }} />;
};

export default RSModal;
