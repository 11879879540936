import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RatingKeys, RatingOption } from 'src/ratings/ratings';

import { hasColor } from '../utils';

type Value = number | string | null | undefined;

const normalizeLegacyValues = (value: Value) => {
  return value === null || value === '-' ? undefined : value;
};

export type UseRatingResponse = {
  options: RatingOption[];
  getByValue: (value: Value) => RatingOption | undefined;
  getByLabel: (label: string) => RatingOption | undefined;
  getLabel: (value: Value) => string;
  getColorClass: (value: Value) => string | null;
  getByRange: (value: Value) => RatingOption | undefined;
};

export function useRating(rating: RatingKeys): UseRatingResponse {
  const { t } = useTranslation('ratings');
  const options: {
    label: string;
    value: number | string;
    range?: [number, number];
  }[] = useMemo(
    () =>
      rating
        ? t(rating, {
            returnObjects: true,
          })
        : [],
    [t, rating]
  );

  const supportsPending = options?.some((option) => option.value === 'pending');

  const getByValue = useCallback(
    (value: Value) => {
      const foundValue = options?.find(
        (option) => option.value === normalizeLegacyValues(value)
      );
      if (foundValue) return foundValue;
      return supportsPending
        ? options?.find((option) => option.value === 'pending')
        : undefined;
    },
    [options, supportsPending]
  );

  const getByRange = useCallback(
    (value: Value) => {
      return options.find((r) => {
        if (!r.range) {
          return null;
        }
        return (
          (value as number) >= r.range[0] &&
          Math.floor(value as number) <= r.range[1]
        );
      });
    },
    [options]
  );

  const getByLabel = useCallback(
    (label: string) => {
      return options?.find((option) => option.label === label);
    },
    [options]
  );

  const getLabel = useCallback(
    (value: Value) => getByValue(value)?.label || '',
    [getByValue]
  );
  const getColorClass = useCallback(
    (value: Value): string | null => {
      const item = getByValue(value);
      return hasColor(item) ? item.color : null;
    },
    [getByValue]
  );

  return useMemo(
    () => ({
      options,
      getByValue,
      getByLabel,
      getLabel,
      getColorClass,
      getByRange,
    }),
    [getByLabel, getByRange, getByValue, getColorClass, getLabel, options]
  );
}
