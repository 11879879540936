import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import PageForm from '@/components/Form';
import { CommonProps } from '@/components/Form/Form/types';
import { Parent_Type_Enum } from '@/generated/graphql';

import TaxonomyFormFields from './TaxonomyFormFields';
import {
  defaultValues,
  TaxonomyDataFields,
  TaxonomySchema,
} from './taxonomySchema';

// TODO: possible change for Pick or just create new props
type Props = Omit<
  CommonProps<TaxonomyDataFields>,
  'schema' | 'formId' | 'parentType' | 'defaultValues'
> & { selectedTaxonomy: string };

const TaxonomyForm: FC<Props> = (props) => {
  const { t } = useTranslation('common');
  return (
    <PageForm
      {...props}
      schema={TaxonomySchema}
      defaultValues={defaultValues}
      i18n={t('taxonomy')}
      formId={'taxonomy'}
      parentType={Parent_Type_Enum.Taxonomy}
    >
      <TaxonomyFormFields
        readOnly={props.readOnly}
        selectedTaxonomy={props.selectedTaxonomy}
      />
    </PageForm>
  );
};

export default TaxonomyForm;
