import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHasPermission } from 'src/rbac/Permission';

import ControlledGroupAndUserMultiSelect from '@/components/Form/ControlledGroupAndUserMultiSelect';
import ControlledInput from '@/components/Form/ControlledInput';
import ControlledSelect from '@/components/Form/ControlledSelect';
import ControlledTextarea from '@/components/Form/ControlledTextarea';
import { Dashboard_Sharing_Type_Enum } from '@/generated/graphql';

import { DashboardFormFieldData } from './dashboardSchema';

interface Props {}

export const DashboardFormFields: FC<Props> = () => {
  const { control, watch } = useFormContext<DashboardFormFieldData>();
  const { t } = useTranslation(['common'], { keyPrefix: 'dashboard' });
  const hasOrganisationDashboardPermission = useHasPermission(
    'insert:organisation_dashboard'
  );
  const sharing = watch('sharing');
  return (
    <div>
      <ControlledInput control={control} name="name" label={t('name_label')} />

      <ControlledSelect
        control={control}
        name="sharing"
        label={t('sharing_label')}
        options={[
          {
            value: Dashboard_Sharing_Type_Enum.UserOnly,
            label: t('sharing_user_only'),
          },
          ...(hasOrganisationDashboardPermission
            ? [
                {
                  value: Dashboard_Sharing_Type_Enum.Organisation,
                  label: t('sharing_organisation'),
                },
              ]
            : []),
          {
            value: Dashboard_Sharing_Type_Enum.Custom,
            label: t('sharing_custom'),
          },
        ]}
      />

      {sharing === Dashboard_Sharing_Type_Enum.Custom && (
        <ControlledGroupAndUserMultiSelect
          includeGroups={true}
          label="Shared with"
          control={control}
          name="Contributors"
        />
      )}

      <ControlledTextarea
        control={control}
        name="description"
        label={t('description_label')}
      />
    </div>
  );
};
