import { TableProps } from '@cloudscape-design/components-themed';
import { useTranslation } from 'react-i18next';
import { getAllOwnersCellValue } from 'src/rbac/contributorHelper';

import CommaSeparatedCell from '@/components/CommaSeparatedCell';
import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import TagsPopover from '@/components/TagsPopover';
import { GetIssuesByParentIdQuery } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { toLocalDate } from '@/utils/dateUtils';

type IssueFields = GetIssuesByParentIdQuery['issue'][0];

export type IssueTableFields = IssueFields & {
  TargetCloseDate: string | null;
  Severity: number | null;
  Status: string | null;
};

export function useGetIssueColumnDefinitions() {
  const { getByValue: getSeverityByValue } = useRating('severity');
  const { getByValue: getStatusByValue } = useRating('issue_assessment_status');
  const { t: st } = useTranslation(['common'], { keyPrefix: 'issues.columns' });
  const { t } = useTranslation(['common'], { keyPrefix: 'columns' });
  const issueColumnDefinitions: TableProps<IssueTableFields>['columnDefinitions'] =
    [
      {
        id: 'title',
        header: st('title'),
        cell: (item) => (
          <Link variant="secondary" href={`${item.Id}`}>
            {item.Title}
          </Link>
        ),
        sortingField: 'Title',
        isRowHeader: true,
      },
      {
        id: 'owners',
        header: t('owners'),
        cell: (item) => (
          <CommaSeparatedCell items={getAllOwnersCellValue(item)} />
        ),
      },
      {
        id: 'severity',
        header: st('severity'),
        cell: (item) => (
          <SimpleRatingBadge rating={getSeverityByValue(item.Severity)} />
        ),
        sortingField: 'Severity',
      },

      {
        id: 'status',
        header: st('status'),
        cell: (item) => {
          const rating = getStatusByValue(item.Status);
          return <SimpleRatingBadge rating={rating} />;
        },
        sortingField: 'Status',
      },
      {
        id: 'dateIdentified',
        header: st('raised'),
        cell: (item) => toLocalDate(item.DateIdentified) || '-',
        sortingField: 'DateIdentified',
      },
      {
        id: 'targetCloseDate',
        header: st('target_close_date'),
        cell: (item) =>
          item.TargetCloseDate ? toLocalDate(item.TargetCloseDate) || '-' : '-',
        sortingField: 'TargetCloseDate',
      },
      {
        id: 'tags',
        header: t('tags'),
        cell: (item) => <TagsPopover id={item.Id} tags={item.tags} />,
        sortingField: 'tags',
      },
    ];
  return issueColumnDefinitions;
}
