import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { FormContext } from '@/components/Form/Form/FormContext';
import { PageWrapper } from '@/components/Form/Form/PageWrapper';
import { FormContextProps } from '@/components/Form/Form/types';

import UserGroupFormFields from './UserGroupFormFields';
import { UserGroupFormFieldsSchema, UserGroupSchema } from './userGroupSchema';

type Props = Omit<
  FormContextProps<UserGroupFormFieldsSchema>,
  | 'schema'
  | 'formId'
  | 'parentType'
  | 'defaultValues'
  | 'renderTemplate'
  | 'i18n'
> & {
  initialTier?: number;
  riskId?: string;
};

const UserGroupForm: FC<Props> = (props) => {
  const { t } = useTranslation(['common']);
  const defaultValues = {
    Name: props.values?.Name ?? '',
    Description: props.values?.Description ?? '',
    Email: props.values?.Email ?? '',
    OwnerContributor: props.values?.OwnerContributor ?? true,
  };

  return (
    <FormContext
      {...props}
      header={t('details')}
      formId="update-user-group-form"
      defaultValues={defaultValues}
      i18n={t('userGroups')}
      schema={UserGroupSchema}
      renderTemplate={(renderProps) => <PageWrapper {...renderProps} />}
    >
      <UserGroupFormFields />
    </FormContext>
  );
};

export default UserGroupForm;
