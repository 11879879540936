
    import './styles.scoped.css';
    export default {
  "dimmed": "awsui_dimmed_10ipo_13zl8_151",
  "marker": "awsui_marker_10ipo_13zl8_206",
  "marker--line": "awsui_marker--line_10ipo_13zl8_249",
  "marker--rectangle": "awsui_marker--rectangle_10ipo_13zl8_253",
  "marker--hollow-rectangle": "awsui_marker--hollow-rectangle_10ipo_13zl8_260",
  "marker--dashed": "awsui_marker--dashed_10ipo_13zl8_276",
  "root": "awsui_root_10ipo_13zl8_295",
  "value": "awsui_value_10ipo_13zl8_330",
  "expandable": "awsui_expandable_10ipo_13zl8_334",
  "list-item": "awsui_list-item_10ipo_13zl8_338",
  "inner-list-item": "awsui_inner-list-item_10ipo_13zl8_339",
  "key-value-pair": "awsui_key-value-pair_10ipo_13zl8_344",
  "expandable-section": "awsui_expandable-section_10ipo_13zl8_345",
  "key": "awsui_key_10ipo_13zl8_344",
  "sub-items": "awsui_sub-items_10ipo_13zl8_358",
  "list": "awsui_list_10ipo_13zl8_338",
  "compact": "awsui_compact_10ipo_13zl8_384",
  "with-sub-items": "awsui_with-sub-items_10ipo_13zl8_389",
  "full-width": "awsui_full-width_10ipo_13zl8_394",
  "announced": "awsui_announced_10ipo_13zl8_398"
};
  