import { FieldValues } from 'react-hook-form';

import { FormContext } from './FormContext';
import { PageWrapper } from './PageWrapper';
import { CommonProps } from './types';

export const PageForm = <TFieldValues extends FieldValues>(
  props: CommonProps<TFieldValues>
) => {
  return (
    <FormContext
      {...props}
      renderTemplate={(renderProps) => <PageWrapper {...renderProps} />}
    />
  );
};
