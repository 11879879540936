import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { FormContext } from '@/components/Form/Form/FormContext';
import { FormTemplateProps } from '@/components/Form/Form/types';
import { emptyFilterQuery } from '@/utils/collectionUtils';

import {
  FilterSchema,
  filterSchema,
} from '../../UniversalWidget/settingsSchema';
import { FilterSettings } from '../../UniversalWidget/util';
import { WidgetSettingsFormFields } from './WidgetSettingsFormFields';

export type Props = {
  renderTemplate: (props: FormTemplateProps<FilterSchema>) => ReactNode;
  settings: FilterSettings | null;
  onSave: (data: z.infer<typeof filterSchema>) => Promise<void>;
  onDismiss: () => void;
};

export const WidgetSettingsForm: FC<Props> = ({
  settings,
  onSave,
  renderTemplate,
  onDismiss,
}) => {
  const { t } = useTranslation('common');
  const values: FilterSchema = {
    filtering: emptyFilterQuery,
    ...(settings ?? {}),
  };

  return (
    <FormContext
      onSave={onSave}
      defaultValues={values}
      formId={'gigawidget-form'}
      schema={filterSchema}
      i18n={t('widget')}
      renderTemplate={renderTemplate}
      onDismiss={onDismiss}
    >
      <WidgetSettingsFormFields />
    </FormContext>
  );
};
