import { z } from 'zod';

import {
  CustomAttributeDataSchema,
  FilesSchema,
  StringDateSchema,
  UserOption,
  UserOptionSchema,
} from '../../../../schemas/global';
const effectiveness = z
  .number({
    invalid_type_error: 'Required',
  })
  .min(0)
  .max(4);

export const TestResultFormSchema = z
  .object({
    Title: z.string(),
    TestType: z
      .string({
        required_error: 'Required',
        invalid_type_error: 'Required',
      })
      .nullish(),
    Description: z.string(),
    DesignEffectiveness: effectiveness.nullish(),
    PerformanceEffectiveness: effectiveness.nullish(),
    OverallEffectiveness: effectiveness.nullish(),
    Id: z.string().uuid().optional(),
    ParentControlIds: z
      .array(
        z.object({
          value: z
            .string({ required_error: 'Required' })
            .uuid({ message: 'Required' }),
        })
      )
      .min(1),
    Submitter: UserOptionSchema,
    TestDate: StringDateSchema,
  })
  .and(FilesSchema)
  .and(CustomAttributeDataSchema);

export type TestResultFormFieldsData = z.infer<typeof TestResultFormSchema>;

export const defaultValues: TestResultFormFieldsData = {
  OverallEffectiveness: undefined as unknown as number,
  ParentControlIds: [],
  TestDate: '',
  TestType: undefined as unknown as string,
  Title: '',
  Description: '',
  DesignEffectiveness: null,
  PerformanceEffectiveness: null,
  newFiles: [],
  files: [],
  Submitter: null as unknown as UserOption,
  CustomAttributeData: null,
};
