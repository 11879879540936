import {
  useGetGuidParam,
  useGetOptionalGuidParam,
} from '@risksmart-app/components/routes/routes.utils';
import { useTranslation } from 'react-i18next';

import ControlledTabs from '@/components/ControlledTabs';
import {
  Parent_Type_Enum,
  useGetAcceptanceByIdQuery,
} from '@/generated/graphql';
import { getFriendlyId } from '@/utils/friendlyId';

import PageLayout from '../../../../layouts/PageLayout';
import { useTabs } from '../useTabs';

const Page = () => {
  const parentId = useGetOptionalGuidParam('riskId');
  const acceptanceId = useGetGuidParam('acceptanceId');

  const tabs = useTabs({ acceptanceId, parentId });
  const { t: st } = useTranslation(['common'], { keyPrefix: 'acceptances' });
  const { data } = useGetAcceptanceByIdQuery({
    variables: {
      _eq: acceptanceId,
    },
  });
  const fallbackTitle = st('fallback_title');
  const acceptance = data?.acceptance[0];
  const counter =
    acceptance &&
    `(${getFriendlyId(Parent_Type_Enum.Acceptance, acceptance?.SequentialId)})`;
  return (
    <PageLayout
      helpTranslationKey="acceptances.help"
      title={acceptance?.Title}
      meta={{ title: fallbackTitle }}
      counter={counter}
    >
      <ControlledTabs tabs={tabs} variant="container" />
    </PageLayout>
  );
};

export default Page;
