import _ from 'lodash';

import { ImpactRating } from './types';

enum ImpactPerformance {
  Within = 'within',
  Outside = 'outside',
  Unrated = 'unrated',
}

enum LikelihoodPerformance {
  Within = 'within',
  Outside = 'outside',
  Unrated = 'unrated',
}

export const getImpactPerformanceRating = (
  item: Pick<ImpactRating, 'Rating'>,
  impactAppetite?: number | null
): ImpactPerformance => {
  if (item.Rating && impactAppetite) {
    return item.Rating <= impactAppetite
      ? ImpactPerformance.Within
      : ImpactPerformance.Outside;
  }
  return ImpactPerformance.Unrated;
};

export const getLikelihoodPerformanceRating = (
  item: Pick<ImpactRating, 'Likelihood'>,
  likelihoodAppetite?: number | null
): LikelihoodPerformance => {
  if (item.Likelihood && likelihoodAppetite) {
    return item.Likelihood <= likelihoodAppetite
      ? LikelihoodPerformance.Within
      : LikelihoodPerformance.Outside;
  }
  return LikelihoodPerformance.Unrated;
};

export const getPerformanceRatingFromPerformanceScore = (
  performanceScore: number | null
) => {
  if (performanceScore == null) {
    return ImpactPerformance.Unrated;
  }
  return performanceScore >= 0
    ? ImpactPerformance.Within
    : ImpactPerformance.Outside;
};

export const getImpactPerformanceScore = (
  item: {
    Rating: number;
  },
  impactAppetite: number | null | undefined
): number | null => {
  if (_.isNil(impactAppetite)) {
    return null;
  }

  return impactAppetite - item.Rating;
};

export const getLikelihoodPerformanceScore = (
  item: {
    Likelihood?: number | null | undefined;
  },
  likelihoodAppetite: number | null | undefined
): number | null => {
  if (_.isNil(likelihoodAppetite) || _.isNil(item.Likelihood)) {
    return null;
  }

  return likelihoodAppetite - item.Likelihood;
};
