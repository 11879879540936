import { Controller, useFormContext } from 'react-hook-form';

import { FormField } from '@/components/Form/Form/FormField';

import { dataSources } from '../../UniversalWidget/dataSources';
import { WidgetPropertyFilter } from '../../UniversalWidget/form/WidgetPropertyFilter';
import { FilterSchema } from '../../UniversalWidget/settingsSchema';

export enum TestIds {
  Filtering = 'filtering',
}

export const WidgetSettingsFormFields = () => {
  const { control } = useFormContext<FilterSchema>();

  return (
    <div>
      <Controller
        control={control}
        render={({ field: { value, onChange } }) => (
          <FormField testId={TestIds.Filtering} label={'Filtering (optional)'}>
            <WidgetPropertyFilter
              value={value}
              onChange={onChange}
              dataSource={dataSources.risk}
            />
          </FormField>
        )}
        name={'filtering'}
      />
    </div>
  );
};
