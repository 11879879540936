import { TabsProps } from '@cloudscape-design/components-themed';
import { useTranslation } from 'react-i18next';
import { ObjectWithContributors, useHasPermission } from 'src/rbac/Permission';

import AssessmentCreateTab from '../create/tabs/AssessmentCreateTab';
import ActivityCreateTab from '../update/tabs/activities/create/ActivityCreateTab';
import ActivityUpdateTab from '../update/tabs/activities/update/ActivityUpdateTab';
import { AssessmentTypeEnum } from '../useAssessmentTypeConfig';
import ActivitiesTab from './tabs/activities/Tab';
import DetailsTab from './tabs/details/Tab';
import ResultsTab from './tabs/results/Tab';

export const useTabs = (
  parentPath: string | undefined,
  assessmentMode: AssessmentTypeEnum,
  assessment: ObjectWithContributors | undefined = undefined,
  activityMode: 'add' | 'update' | 'list' | undefined = undefined
) => {
  const { t } = useTranslation('common', { keyPrefix: 'assessmentResults' });
  const { t: taa } = useTranslation('common', {
    keyPrefix: 'assessmentActivities',
  });
  const canViewDocumentAssessmentResults = useHasPermission(
    'read:document_assessment_result',
    assessment
  );
  const canViewObligationAssessmentResults = useHasPermission(
    'read:obligation_assessment_result',
    assessment
  );
  const canViewAssessmentActivities = useHasPermission(
    'read:assessment_activity',
    assessment
  );
  const canViewRiskAssessmentResults = useHasPermission(
    'read:risk_assessment_result',
    assessment
  );
  const canViewAssessmentResults =
    canViewDocumentAssessmentResults ||
    canViewObligationAssessmentResults ||
    canViewRiskAssessmentResults;
  const detailsOnly = !assessment;
  const tabs: TabsProps.Tab[] = [
    {
      label: 'Details',
      id: 'details',
      content: detailsOnly ? (
        <AssessmentCreateTab assessmentMode={assessmentMode} />
      ) : (
        <DetailsTab assessmentMode={assessmentMode} />
      ),
      href: detailsOnly ? undefined : parentPath,
    },
  ];

  if (canViewAssessmentActivities) {
    tabs.push({
      label: taa('tab_title'),
      id: 'activities',
      content:
        activityMode === 'add' ? (
          <ActivityCreateTab assessmentMode={assessmentMode} />
        ) : activityMode === 'update' ? (
          <ActivityUpdateTab assessmentMode={assessmentMode} />
        ) : (
          assessment && (
            <ActivitiesTab
              parent={assessment}
              assessmentMode={assessmentMode}
            />
          )
        ),
      href: assessment ? `${parentPath}/activities` : '',
      disabled: detailsOnly,
    });
  }

  if (canViewAssessmentResults) {
    tabs.push({
      label: t('tab_title'),
      id: 'findings',
      content: assessment && (
        <ResultsTab parent={assessment} assessmentMode={assessmentMode} />
      ),
      href: assessment ? `${parentPath}/findings` : '',
      disabled: detailsOnly,
    });
  }

  return tabs;
};
