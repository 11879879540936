import { Alert, Textarea } from '@cloudscape-design/components-themed';
import Button from '@risksmart-app/components/Button';
import Modal from '@risksmart-app/components/Modal';
import { useNotifications } from '@risksmart-app/components/Notifications/notification-context';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { useOverrideChangeRequestByIdMutation } from '@/generated/graphql';
import { evictField } from '@/utils/graphqlUtils';

import styles from '../ChangeRequestLevels/style.module.scss';
import { FormField } from '../Form/Form/FormField';
interface Props {
  changeRequestId: string;
  onDismiss: () => void;
  visible: boolean;
}

const ChangeRequestOverrideModal: FC<Props> = ({
  changeRequestId,
  onDismiss,
  visible,
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'approvals' });

  const [rationale, setRationale] = useState<string | undefined>();
  const [errors, setErrors] = useState<{ rationale: string | undefined }>({
    rationale,
  });

  const { addNotification } = useNotifications();

  const [overrideChangeRequest, { loading: updating }] =
    useOverrideChangeRequestByIdMutation({
      update: (cache) => {
        evictField(cache, 'change_request_by_pk');
      },
      onError: (error) => {
        addNotification({
          type: 'error',
          content: error.message,
        });
      },
    });

  const schema = z.object({
    rationale: z
      .string({ required_error: 'You must provide a rationale' })
      .min(1, 'You must provide a rationale'),
    approved: z.boolean(),
  });

  const validate = () => {
    const result = schema.safeParse({ rationale, approved: true });

    if (
      !result.success &&
      !!result.error.issues.find((i) => i.path[0] === 'rationale')
    ) {
      setErrors({
        rationale: result.error.issues.find((i) => i.path[0] === 'rationale')
          ?.message,
      });
      return false;
    }

    setErrors({ rationale: undefined });

    return true;
  };

  const onSubmit = async (approved: boolean) => {
    if (validate()) {
      await overrideChangeRequest({
        variables: {
          Id: changeRequestId,
          Rationale: rationale ?? '-',
          Approved: approved,
        },
      });

      onDismiss();
    }
  };

  return (
    <Modal
      visible={visible}
      onDismiss={onDismiss}
      header="Change request override"
    >
      <div className="flex flex-col gap-6">
        <Alert type="warning">{t('override_change_request_modal_alert')}</Alert>
        <FormField label="Rationale" errorText={errors.rationale}>
          <Textarea
            name="rationale"
            value={rationale ?? ''}
            onChange={(e) => {
              setRationale(e.detail.value);
            }}
          />
        </FormField>
        <div className="flex flex-row gap-3">
          <Button
            variant="primary"
            onClick={async () => await onSubmit(true)}
            disabled={updating}
          >
            Approve
          </Button>
          <Button
            onClick={async () => await onSubmit(false)}
            {...{ className: styles.dangerButton }}
            disabled={updating}
          >
            Reject
          </Button>
          <Button onClick={onDismiss} disabled={updating}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ChangeRequestOverrideModal;
