
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_2qdw9_1ie2w_179",
  "root-with-toolbar": "awsui_root-with-toolbar_2qdw9_1ie2w_218",
  "refresh": "awsui_refresh_2qdw9_1ie2w_221",
  "root-no-actions": "awsui_root-no-actions_2qdw9_1ie2w_221",
  "root-variant-h2": "awsui_root-variant-h2_2qdw9_1ie2w_224",
  "root-variant-h3": "awsui_root-variant-h3_2qdw9_1ie2w_224",
  "root-has-description": "awsui_root-has-description_2qdw9_1ie2w_224",
  "root-variant-h1": "awsui_root-variant-h1_2qdw9_1ie2w_233",
  "main": "awsui_main_2qdw9_1ie2w_243",
  "no-wrap": "awsui_no-wrap_2qdw9_1ie2w_253",
  "main-variant-h1": "awsui_main-variant-h1_2qdw9_1ie2w_259",
  "actions": "awsui_actions_2qdw9_1ie2w_267",
  "actions-centered": "awsui_actions-centered_2qdw9_1ie2w_272",
  "actions-variant-h1": "awsui_actions-variant-h1_2qdw9_1ie2w_275",
  "actions-variant-h2": "awsui_actions-variant-h2_2qdw9_1ie2w_279",
  "actions-variant-h3": "awsui_actions-variant-h3_2qdw9_1ie2w_283",
  "title": "awsui_title_2qdw9_1ie2w_299",
  "title-variant-h1": "awsui_title-variant-h1_2qdw9_1ie2w_304",
  "title-variant-h2": "awsui_title-variant-h2_2qdw9_1ie2w_311",
  "title-variant-h3": "awsui_title-variant-h3_2qdw9_1ie2w_317",
  "virtual-space": "awsui_virtual-space_2qdw9_1ie2w_338",
  "info": "awsui_info_2qdw9_1ie2w_344",
  "description": "awsui_description_2qdw9_1ie2w_348",
  "description-variant-h1": "awsui_description-variant-h1_2qdw9_1ie2w_355",
  "description-variant-h2": "awsui_description-variant-h2_2qdw9_1ie2w_359",
  "description-variant-h3": "awsui_description-variant-h3_2qdw9_1ie2w_366",
  "heading": "awsui_heading_2qdw9_1ie2w_375",
  "heading-variant-h1": "awsui_heading-variant-h1_2qdw9_1ie2w_386",
  "heading-variant-h2": "awsui_heading-variant-h2_2qdw9_1ie2w_391",
  "heading-variant-h3": "awsui_heading-variant-h3_2qdw9_1ie2w_396",
  "heading-text": "awsui_heading-text_2qdw9_1ie2w_402",
  "heading-text-variant-h1": "awsui_heading-text-variant-h1_2qdw9_1ie2w_405",
  "heading-text-variant-h2": "awsui_heading-text-variant-h2_2qdw9_1ie2w_413",
  "heading-text-variant-h3": "awsui_heading-text-variant-h3_2qdw9_1ie2w_421",
  "counter": "awsui_counter_2qdw9_1ie2w_430"
};
  