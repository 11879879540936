import { useCollection } from '@cloudscape-design/collection-hooks';
import { SpaceBetween } from '@cloudscape-design/components-themed';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/notification-context';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import Table from '@risksmart-app/components/Table';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ObjectWithContributors,
  Permission,
  useHasPermission,
} from 'src/rbac/Permission';

import DeleteModal from '@/components/DeleteModal/DeleteModal';
import EmptyEntityCollection from '@/components/EmptyCollection/EmptyEntityCollection';
import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import TabHeader from '@/components/TabHeader';
import {
  useDeleteConsequencesMutation,
  useGetConsequencesByParentIssueIdQuery,
} from '@/generated/graphql';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { evictField } from '@/utils/graphqlUtils';

import { ConsequencesFields, useConsequencesColumnDefinitions } from './config';
import ConsequenceModal from './ConsequenceModal';
import ConsequenceTotalsRibbon from './ConsequenceTotalsRibbon';

interface Props {
  parent: ObjectWithContributors;
}

const Tab: FC<Props> = ({ parent }) => {
  useI18NSummaryHelpContent('consequences.tabHelp');
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'consequences',
  });
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const issueId = useGetGuidParam('issueId');

  const { addNotification } = useNotifications();
  const [selectedConsequences, setSelectedConsequences] = useState<
    ConsequencesFields[]
  >([]);
  const canDeleteConsequences = useHasPermission('delete:consequence', parent);
  const [deleteConsequences, deleteResult] = useDeleteConsequencesMutation({
    update: (cache) => {
      evictField(cache, 'consequence');
      evictField(cache, 'consequence_aggregate');
    },
  });

  const [consequenceId, setConsequenceId] = useState<string | undefined>();

  const { data, loading } = useGetConsequencesByParentIssueIdQuery({
    variables: {
      _eq: issueId,
    },
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });

  const handleConsequencesModalOpen = () => {
    setIsEditOpen(true);
  };

  const handleConsequencesModalClose = () => {
    setConsequenceId(undefined);
    setIsEditOpen(false);
  };

  const columnDefinitions = useConsequencesColumnDefinitions((consequence) => {
    setConsequenceId(consequence.Id);
    handleConsequencesModalOpen();
  });
  const onDelete = useDeleteResultNotification({
    entityName: st('entity_name'),
    asyncAction: async () => {
      await deleteConsequences({
        variables: { Ids: selectedConsequences.map((s) => s.Id) },
      });

      setSelectedConsequences([]);
      setIsDeleteModalVisible(false);
      return true;
    },
    failureAction: () => {
      setIsDeleteModalVisible(false);
    },
  });

  const { items, collectionProps } = useCollection(data?.consequence || [], {
    propertyFiltering: {
      filteringProperties: [],
      empty: (
        <EmptyEntityCollection
          entityLabel={st('entity_name')}
          action={
            <Permission permission="insert:consequence" parentObject={parent}>
              <Button formAction="none" onClick={handleConsequencesModalOpen}>
                {st('add_button')}
              </Button>
            </Permission>
          }
        />
      ),
    },
    sorting: {
      defaultState: {
        sortingColumn: columnDefinitions[0],
      },
    },
  });

  return (
    <>
      <Table
        {...collectionProps}
        selectionType={canDeleteConsequences ? 'multi' : undefined}
        selectedItems={selectedConsequences}
        trackBy="Id"
        onSelectionChange={({ detail }) => {
          setSelectedConsequences(detail.selectedItems);
        }}
        resizableColumns={true}
        header={
          <SpaceBetween size="m">
            <TabHeader
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Permission
                    permission="delete:consequence"
                    parentObject={parent}
                  >
                    <Button
                      formAction="none"
                      variant="normal"
                      disabled={!selectedConsequences.length}
                      onClick={() => setIsDeleteModalVisible(true)}
                    >
                      {t('delete')}
                    </Button>
                  </Permission>
                  <Permission
                    permission="insert:consequence"
                    parentObject={parent}
                  >
                    <Button
                      variant="primary"
                      formAction="none"
                      onClick={handleConsequencesModalOpen}
                    >
                      {st('add_button')}
                    </Button>
                  </Permission>
                </SpaceBetween>
              }
            >
              {st('tab_title')}
            </TabHeader>
            <ConsequenceTotalsRibbon consequences={data?.consequence ?? []} />
          </SpaceBetween>
        }
        variant="embedded"
        loading={loading}
        columnDefinitions={columnDefinitions}
        items={items}
        loadingText={st('loading_message') ?? ''}
        sortingDisabled={false}
      />
      {isEditOpen && (
        <ConsequenceModal
          consequenceId={consequenceId}
          onDismiss={handleConsequencesModalClose}
          issueId={issueId}
        />
      )}
      <DeleteModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        header={t('delete')}
        onDelete={onDelete}
        onDismiss={() => {
          setSelectedConsequences([]);
          setIsDeleteModalVisible(false);
        }}
      >
        {st('confirm_delete_message')}
      </DeleteModal>
    </>
  );
};

export default Tab;
