import i18n from 'src/i18n';

import { issueDetailsUrl } from '@/utils/urls';

import { GetItem } from './types';

export const getItem: GetItem = (item, lookupData) => {
  const issueId = item.data?.objectId ?? item.data?.issueId;
  const issue = lookupData.issues?.[issueId];
  return {
    message: i18n.t('notifications.messages.issueDue', {
      title: issue?.Title ?? i18n.t('notifications.unknown'),
    }),
    url: issue ? issueDetailsUrl(issue.Id) : null,
  };
};
