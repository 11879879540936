import { SpaceBetween } from '@cloudscape-design/components-themed';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/notification-context';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import Table from '@risksmart-app/components/Table';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAggregation } from 'src/providers/AggregationProvider';
import {
  ObjectWithContributors,
  Permission,
  useHasPermission,
} from 'src/rbac/Permission';

import DeleteModal from '@/components/DeleteModal/DeleteModal';
import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import TabHeader from '@/components/TabHeader';
import {
  Appetite_Model_Enum,
  useDeleteAppetitesMutation,
  useGetAppetitesByParentRiskIdQuery,
  useGetRiskByIdQuery,
} from '@/generated/graphql';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { evictField } from '@/utils/graphqlUtils';

import { useGetCollectionTableProps } from './config';
import { AppetiteFields } from './types';

interface Props {
  parent: ObjectWithContributors;
}

const Tab: FC<Props> = ({ parent }) => {
  useI18NSummaryHelpContent('appetites.help');
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], { keyPrefix: 'appetites' });
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const [selectedAppetites, setSelectedAppetites] = useState<AppetiteFields[]>(
    []
  );
  const canDeleteAppetites = useHasPermission('delete:appetite', parent);
  const riskId = useGetGuidParam('riskId');
  const { data: risk } = useGetRiskByIdQuery({ variables: { _eq: riskId } });
  const { addNotification } = useNotifications();
  const { appetiteAggregation, loading: aggregationLoading } = useAggregation();
  const { data, loading } = useGetAppetitesByParentRiskIdQuery({
    variables: {
      _eq: riskId,
    },
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });

  const [deleteAppetites, deleteResult] = useDeleteAppetitesMutation({
    update: (cache) => {
      evictField(cache, 'appetite');
      evictField(cache, 'appetite_aggregate');
      evictField(cache, 'risk_aggregate');
    },
  });

  const onDelete = useDeleteResultNotification({
    entityName: st('entity_name'),
    asyncAction: async () => {
      await deleteAppetites({
        variables: {
          Ids: selectedAppetites.map((s) => s.Id),
        },
      });
      setSelectedAppetites([]);
      setIsDeleteModalVisible(false);
      return true;
    },
    failureAction: () => {
      setIsDeleteModalVisible(false);
    },
  });

  const tableProps = useGetCollectionTableProps(data?.appetite, undefined, {
    sortingColumn: 'EffectiveDate',
    sortingDirection: 'desc',
  });

  const parentTier = risk?.risk?.[0]?.Tier;

  return (
    <>
      <Table
        {...tableProps}
        selectionType={
          canDeleteAppetites &&
          (appetiteAggregation === Appetite_Model_Enum.Default ||
            parentTier === 1)
            ? 'multi'
            : undefined
        }
        selectedItems={selectedAppetites}
        trackBy="Id"
        onSelectionChange={({ detail }) => {
          setSelectedAppetites(detail.selectedItems);
        }}
        resizableColumns={true}
        header={
          <SpaceBetween size="m">
            <TabHeader
              actions={
                (appetiteAggregation === Appetite_Model_Enum.Default ||
                  parentTier === 1) && (
                  <SpaceBetween direction="horizontal" size="xs">
                    <Permission
                      permission="delete:appetite"
                      parentObject={parent}
                    >
                      <Button
                        formAction="none"
                        variant="normal"
                        disabled={!selectedAppetites.length}
                        onClick={() => setIsDeleteModalVisible(true)}
                      >
                        {t('delete')}
                      </Button>
                    </Permission>
                    <Permission
                      permission="insert:appetite"
                      parentObject={parent}
                    >
                      <Button variant="primary" formAction="none" href="add">
                        {st('add_button')}
                      </Button>
                    </Permission>
                  </SpaceBetween>
                )
              }
            >
              {st('tab_title')}
            </TabHeader>
          </SpaceBetween>
        }
        variant="embedded"
        loading={loading || aggregationLoading}
        loadingText={st('loading_message')}
        sortingDisabled={false}
      />
      <DeleteModal
        isVisible={isDeleteModalVisible}
        loading={deleteResult.loading}
        header={t('delete')}
        onDelete={onDelete}
        onDismiss={() => setIsDeleteModalVisible(false)}
      >
        {st('confirm_delete_message')}
      </DeleteModal>
    </>
  );
};

export default Tab;
