import dayjs, { Dayjs } from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomAttributeFields } from '@/components/Form/CustomAttributes/CustomAttributeSchema';
import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import { useRating } from '@/hooks/use-rating';
import { useGetContributorsFieldConfig } from '@/utils/table/hooks/useGetContributorsFieldConfig';
import { useGetDepartmentFieldConfig } from '@/utils/table/hooks/useGetDepartmentFieldConfig';
import { useGetOwnersFieldConfig } from '@/utils/table/hooks/useGetOwnersFieldConfig';
import {
  StatefulTableOptions,
  useGetStatelessTableProps,
  UseGetTablePropsOptions,
} from '@/utils/table/hooks/useGetStatelessTableProps';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import { useGetTagFieldConfig } from '@/utils/table/hooks/useGetTagFieldConfig';
import { TableFields, TablePropsWithActions } from '@/utils/table/types';
import { dateColumn } from '@/utils/table/utils/dateColumn';

import { AssessmentFields, AssessmentRegisterFields } from './types';
import {
  AssessmentTypeEnum,
  useAssessmentTypeConfig,
} from './useAssessmentTypeConfig';
import { useLabelledFields } from './useLabelledFields';

const useGetFieldConfig = (
  assessmentMode: AssessmentTypeEnum
): TableFields<AssessmentRegisterFields> => {
  const { taxonomyKey, outcomeRatingKey } =
    useAssessmentTypeConfig(assessmentMode);
  const { t } = useTranslation('common', {
    keyPrefix: `${taxonomyKey}.columns`,
  });
  const allOwners = useGetOwnersFieldConfig<AssessmentRegisterFields>();
  const allContributors =
    useGetContributorsFieldConfig<AssessmentRegisterFields>();
  const { getByValue: statusGetByValue } = useRating('assessment_status');
  const { getByValue: outcomeGetByValue } = useRating(outcomeRatingKey);
  const tagField = useGetTagFieldConfig<AssessmentRegisterFields>();
  const departmentField =
    useGetDepartmentFieldConfig<AssessmentRegisterFields>();
  const { t: gt } = useTranslation(['common'], { keyPrefix: 'columns' });
  return useMemo(
    () => ({
      Title: {
        header: t('Title'),
        cell: (item) => {
          return (
            <Link isRelativeUrl={true} variant="secondary" href={item.Id}>
              {item.Title}
            </Link>
          );
        },
        isRowHeader: true,
      },
      StartDate: dateColumn(t('StartDate'), 'StartDate'),
      ActualCompletionDate: dateColumn(
        t('CompletionDate'),
        'ActualCompletionDate'
      ),
      StatusLabelled: {
        header: t('Status'),
        cell: (item) => (
          <SimpleRatingBadge rating={statusGetByValue(item.Status)} />
        ),
      },
      OutcomeLabelled: {
        header: t('Outcome'),
        cell: (item) => (
          <SimpleRatingBadge rating={outcomeGetByValue(item.Outcome)} />
        ),
      },
      AssessedItems: {
        header: t('AssessedItems'),
      },
      TargetCompletionDate: dateColumn(
        t('TargetCompletionDate'),
        'TargetCompletionDate'
      ),
      completedByUser: {
        header: t('CompletionBy'),
        cell: (item) => item.completedByUser?.FriendlyName,
      },
      CreatedAtTimestamp: dateColumn(t('created_on'), 'CreatedAtTimestamp'),
      ModifiedByUser: {
        header: t('updated_by_id'),
      },
      SequentialIdLabel: {
        header: t('id'),
        sortingField: 'SequentialId',
      },
      Id: {
        header: gt('guid'),
      },

      NextTestDate: dateColumn(t('NextTestDate'), 'NextTestDate'),
      ModifiedAtTimestamp: dateColumn(t('updated_on'), 'ModifiedAtTimestamp'),
      CreatedByUser: {
        header: t('created_by_id'),
      },
      allOwners,
      allContributors,
      tags: tagField,
      departments: departmentField,
    }),
    [
      allContributors,
      allOwners,
      departmentField,
      gt,
      outcomeGetByValue,
      statusGetByValue,
      t,
      tagField,
    ]
  );
};

export const enum AssessmentStatus {
  Complete = 'complete',
  NotStarted = 'notstarted',
  Inprogress = 'inprogress',
}

export const getStatusByDate = (
  now: Dayjs,
  startDate?: string | null,
  completionDate?: string | null
): AssessmentStatus => {
  if (dayjs(completionDate).isBefore(now)) {
    return AssessmentStatus.Complete;
  }
  if (!startDate) {
    return AssessmentStatus.NotStarted;
  }
  return AssessmentStatus.Inprogress;
};

const useGetProps = (
  assessmentMode: AssessmentTypeEnum,
  records: AssessmentFields[] | undefined,
  customAttributeSchema?: CustomAttributeFields | null
): UseGetTablePropsOptions<AssessmentRegisterFields> => {
  const { registerStorageKey, taxonomyKey } =
    useAssessmentTypeConfig(assessmentMode);
  const { t: at } = useTranslation('common', { keyPrefix: taxonomyKey });
  const fields = useGetFieldConfig(assessmentMode);
  const labelledFields = useLabelledFields(assessmentMode, records);
  return useMemo(
    () => ({
      data: labelledFields,
      customAttributeSchema: customAttributeSchema,
      entityLabel: at('entity'),
      emptyCollectionAction: <></>,
      storageKey: registerStorageKey,
      enableFiltering: true,
      initialColumns: [
        'Title',
        'StartDate',
        'ActualCompletionDate',
        'StatusLabelled',
        'allOwners',
      ],
      fields,
    }),
    [at, customAttributeSchema, fields, labelledFields, registerStorageKey]
  );
};

export const useGetCollectionTableProps = (
  assessmentMode: AssessmentTypeEnum,
  records: AssessmentFields[] | undefined,
  customAttributeSchema?: CustomAttributeFields | null
): TablePropsWithActions<AssessmentRegisterFields> => {
  const props = useGetProps(assessmentMode, records, customAttributeSchema);
  return useGetTableProps(props);
};

export const useGetAssessmentSmartWidgetTableProps = (
  assessmentMode: AssessmentTypeEnum,
  records: AssessmentFields[] | undefined,
  customAttributeSchema: CustomAttributeFields | null,
  statefulTableOptions: StatefulTableOptions<AssessmentRegisterFields>
): TablePropsWithActions<AssessmentRegisterFields> => {
  const props = useGetProps(assessmentMode, records, customAttributeSchema);
  return useGetStatelessTableProps<AssessmentRegisterFields>({
    ...props,
    ...statefulTableOptions,
    enableFiltering: false,
  });
};
