import useRisksmartUser from '@/hooks/useRisksmartUser';
import { loginUrl } from '@/utils/urls';

export default function Page() {
  const { isLoading, logout } = useRisksmartUser();

  // clear data from current session
  window.sessionStorage.clear();

  if (isLoading) {
    return <></>;
  }

  logout({ logoutParams: { returnTo: window.location.origin + loginUrl() } });

  return <></>;
}
