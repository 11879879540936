import { OptionDefinition } from '@cloudscape-design/components-themed/internal/components/option/interfaces';
import { PromptId } from '@risksmart-app/shared/ai/PromptId';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ControlFormFieldData } from 'src/pages/controls/update/forms/controlSchema';

import ControlledAutosuggest from '@/components/Form/ControlledAutosuggest';
import ControlledDatePicker from '@/components/Form/ControlledDatePicker';
import ControlledGroupAndUserContributorMultiSelect from '@/components/Form/ControlledGroupAndUserContributorMultiSelect';
import ControlledSelect from '@/components/Form/ControlledSelect';
import ControlledTextarea from '@/components/Form/ControlledTextarea';
import DepartmentSelector from '@/components/Form/DepartmentSelector';
import CustomisableForm from '@/components/Form/Form/CustomisableForm/CustomisableForm';
import TagSelector from '@/components/Form/TagSelector';
import {
  Contributor_Type_Enum,
  useGetControlsByUserQuery,
} from '@/generated/graphql';
import { useLibrary } from '@/hooks/use-library';
import useRisksmartUser from '@/hooks/useRisksmartUser';

type SuggestedTitle = {
  value: string;
  label: string;
  type: 'library' | 'existing';
};

type Props = {
  readOnly?: boolean;
};

const ControlFormFields: FC<Props> = ({ readOnly }) => {
  const { control, watch, setValue } = useFormContext<ControlFormFieldData>();

  const controlLibrary = useLibrary('controls');
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], { keyPrefix: 'controls' });

  const frequency = t('frequency');
  const frequencyOptions = Object.keys(frequency).map((key) => ({
    value: key,
    label: frequency[key as keyof typeof frequency],
  }));

  const { user } = useRisksmartUser();

  const { data: userControls } = useGetControlsByUserQuery({
    variables: {
      _eq: user?.userId,
    },
  });

  const autoPopulateDescription = (selectedTitleOption?: OptionDefinition) => {
    if (!selectedTitleOption) {
      return;
    }
    const selectedOption = selectedTitleOption as SuggestedTitle;
    if (selectedOption.type === 'library') {
      const description = controlLibrary.find(
        (control) => control.title === selectedOption?.value
      )?.description;
      description && setValue('Description', description);
    } else if (selectedOption?.type === 'existing') {
      const control = userControls?.control.find(
        (c) => c.Title === selectedOption?.value
      );
      control?.Description && setValue('Description', control.Description);
    }
  };

  const existingControlOptions: SuggestedTitle[] =
    userControls?.control.map((c) => ({
      value: c.Title || '-',
      label: c.Title || '-',
      type: 'existing',
    })) || [];

  const controlLibraryOptions: SuggestedTitle[] = controlLibrary.map((c) => ({
    value: c.title,
    label: c.title,
    type: 'library',
  }));
  const types = st('type');
  const typeOptions = Object.keys(types).map((value) => ({
    value,
    label: types[value as keyof typeof types],
  }));

  const title = watch('Title');

  return (
    <CustomisableForm readOnly={readOnly}>
      <ControlledSelect
        forceRequired={true}
        key="type"
        testId="type"
        filteringType="auto"
        label={st('fields.Type')}
        name="Type"
        description={st('fields.Type_help')}
        // todo: translation
        placeholder="Select"
        control={control}
        options={typeOptions}
        disabled={readOnly}
      />
      <ControlledAutosuggest
        key="title"
        forceRequired={true}
        name="Title"
        label={st('fields.Title')}
        description={st('fields.Title_help')}
        placeholder={st('fields.Title_placeholder')}
        control={control}
        onSelect={(e) => {
          autoPopulateDescription(e.detail.selectedOption);
        }}
        options={[
          {
            value: 'Created controls',
            label: st('title_groups.createdControls'),
            options: existingControlOptions,
          },
          {
            value: 'Library',
            label: st('title_groups.library'),
            options: controlLibraryOptions,
          },
        ]}
        disabled={readOnly}
      />

      <ControlledTextarea
        defaultRequired={true}
        key="description"
        name="Description"
        label={st('fields.Description')}
        description={st('fields.Description_help')}
        placeholder={st('fields.Description_placeholder')}
        control={control}
        disabled={readOnly}
        additionalPrompts={[
          {
            id: PromptId.GenerateAControlDescription,
            text: t('textInference.general.generateAControlDescription'),
            altPromptText: title,
          },
        ]}
      />

      <ControlledGroupAndUserContributorMultiSelect
        key="owners"
        forceRequired={true}
        control={control}
        includeGroups={true}
        label={t('fields.Owner')}
        description={st('fields.Owner_help')}
        inheritedContributorsName="ancestorContributors"
        contributorType={Contributor_Type_Enum.Owner}
        name="Owners"
        testId="owners"
        placeholder={t('fields.Owner_placeholder')}
        disabled={readOnly}
      />

      <ControlledGroupAndUserContributorMultiSelect
        key="contributors"
        control={control}
        includeGroups={true}
        inheritedContributorsName="ancestorContributors"
        contributorType={Contributor_Type_Enum.Contributor}
        label={t('fields.Contributor')}
        description={st('fields.Contributor_help')}
        name="Contributors"
        placeholder={t('fields.Contributor_placeholder')}
        disabled={readOnly}
      />

      <ControlledSelect
        key="testFrequency"
        filteringType="auto"
        label={st('fields.TestFrequency')}
        name="TestFrequency"
        description={st('fields.TestFrequency_help')}
        placeholder={st('fields.TestFrequency_placeholder')}
        control={control}
        addEmptyOption={true}
        options={frequencyOptions}
        disabled={readOnly}
      />

      <ControlledDatePicker
        key="nextTestDate"
        control={control}
        name="NextTestDate"
        label={st('fields.NextTestDate')}
        description={st('fields.NextTestDate_help')}
        disabled={readOnly}
      />

      <TagSelector
        name="tags"
        key="tags"
        control={control}
        disabled={readOnly}
      />
      <DepartmentSelector
        key="departments"
        name="departments"
        control={control}
        disabled={readOnly}
      />
    </CustomisableForm>
  );
};

export default ControlFormFields;
