import { useLayoutEffect } from 'react';

import { useHelpContent } from './HelpProvider';

export const usePopoverHelp = () => {
  const { setShowFieldHelpInPopover } = useHelpContent();
  useLayoutEffect(() => {
    setShowFieldHelpInPopover(true);
    return () => {
      setShowFieldHelpInPopover(false);
    };
  }, [setShowFieldHelpInPopover]);
};
