import { SpaceBetween } from '@cloudscape-design/components-themed';
import Button from '@risksmart-app/components/Button';
import { PageNotFound } from '@risksmart-app/components/errors/errors';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PageLayout } from 'src/layouts';
import { Permission } from 'src/rbac/Permission';
import {
  useGetDetailParentPath,
  useGetDetailPath,
} from 'src/routes/useGetDetailParentPath';

import ControlledTabs from '@/components/ControlledTabs';
import DeleteModal from '@/components/DeleteModal/DeleteModal';
import {
  namedOperations,
  Parent_Type_Enum,
  useDeleteIssuesMutation,
  useGetIssueByIdQuery,
} from '@/generated/graphql';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { getFriendlyId } from '@/utils/friendlyId';
import { evictField } from '@/utils/graphqlUtils';

import useExporter from './useExporter';
import { useTabs } from './useTabs';

type Props = {
  activeTabId:
    | 'details'
    | 'updates'
    | 'actions'
    | 'causes'
    | 'consequences'
    | 'assessment'
    | 'linkedItems';
  showDeleteButton?: boolean;
};

const Page: FC<Props> = ({ activeTabId, showDeleteButton }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], { keyPrefix: 'issues' });
  const defaultTitle = st('fallback_title');
  const issueId = useGetGuidParam('issueId');
  const [exportItem, { loading: exporting }] = useExporter(issueId);
  const { data, error } = useGetIssueByIdQuery({
    variables: {
      _eq: issueId,
    },
  });
  if (error) {
    throw error;
  }
  const detailsPath = useGetDetailPath(issueId);
  const parentPath = useGetDetailParentPath(issueId);

  const issue = data?.issue?.[0];
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteIssues, deleteResult] = useDeleteIssuesMutation({
    update: (cache) => {
      evictField(cache, 'issue');
      evictField(cache, 'issue_aggregate');
      evictField(cache, 'issue_assessment_aggregate');
      evictField(cache, 'issue_assessment_audit');
    },
    refetchQueries: [namedOperations.Query.getIssueById],
  });
  const tabs = useTabs(detailsPath, issue);

  const onDelete = useDeleteResultNotification({
    entityName: st('entity_name'),
    asyncAction: async () => {
      if (!issue) {
        throw new Error('No issue found');
      }
      await deleteIssues({
        variables: {
          Ids: [issue.Id],
        },
      });
      navigate(parentPath);
      return true;
    },
  });

  if (data?.issue.length === 0) {
    throw new PageNotFound(`Issue with id ${issueId} not found`);
  }
  const title = issue && issue.Title;
  const counter =
    issue && `(${getFriendlyId(Parent_Type_Enum.Issue, issue.SequentialId)})`;
  return (
    <PageLayout
      title={title}
      meta={{ title: defaultTitle }}
      counter={counter}
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button iconName="download" disabled={exporting} onClick={exportItem}>
            {t('export')}
          </Button>
          {showDeleteButton && (
            <Permission permission="delete:issue" parentObject={issue}>
              <Button
                variant="normal"
                formAction="none"
                onClick={() => {
                  setIsDeleteModalVisible(true);
                }}
              >
                {st('delete_button')}
              </Button>
            </Permission>
          )}
        </SpaceBetween>
      }
    >
      <ControlledTabs
        activeTabId={activeTabId}
        tabs={tabs}
        variant="container"
      />
      <DeleteModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        header={t('delete')}
        onDelete={onDelete}
        onDismiss={() => setIsDeleteModalVisible(false)}
      >
        {st('confirm_delete_message')}
      </DeleteModal>
    </PageLayout>
  );
};

export default Page;
