import Table from '@risksmart-app/components/Table';
import { FC } from 'react';
import { useGetCollectionTableProps } from 'src/pages/actions/config';
import { ActionFields } from 'src/pages/actions/types';

import { CustomAttributeFields } from '@/components/Form/CustomAttributes/CustomAttributeSchema';

interface Props {
  loading: boolean;
  records: ActionFields[] | undefined;
  customAttributeSchema?: CustomAttributeFields | null;
}

const AssessmentActionRegister: FC<Props> = ({
  loading,
  records,
  customAttributeSchema,
}) => {
  const tableProps = useGetCollectionTableProps(records, customAttributeSchema);
  return <Table variant="embedded" {...tableProps} loading={loading} />;
};

export default AssessmentActionRegister;
