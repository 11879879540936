
    import './styles.scoped.css';
    export default {
  "file-option-name": "awsui_file-option-name_ezgb4_136jz_9",
  "file-option-size": "awsui_file-option-size_ezgb4_136jz_10",
  "file-option-last-modified": "awsui_file-option-last-modified_ezgb4_136jz_11",
  "file-option-thumbnail": "awsui_file-option-thumbnail_ezgb4_136jz_12",
  "file-option": "awsui_file-option_ezgb4_136jz_9",
  "file-option-thumbnail-image": "awsui_file-option-thumbnail-image_ezgb4_136jz_28",
  "file-option-metadata": "awsui_file-option-metadata_ezgb4_136jz_33"
};
  