import { WidgetDataSource } from '../../Gigawidget/types';
import { getString } from '../util';

export const getStandardFieldOptions = <TDataSource extends WidgetDataSource>(
  categoryGetters: TDataSource['categoryGetters']
) => {
  return [
    {
      label: 'Standard Fields',
      options:
        categoryGetters
          .filter((cg) => !cg.date)
          .map((categoryGetter) => ({
            label: getString(categoryGetter.name),
            value: categoryGetter.id,
          })) ?? [],
    },
  ];
};

export const getDateFieldOptions = <TDataSource extends WidgetDataSource>(
  categoryGetters: TDataSource['categoryGetters'],
  showDateFields?: boolean
) => {
  return !showDateFields
    ? []
    : [
        {
          label: 'Date Fields',
          options:
            categoryGetters
              .filter((cg) => cg.date)
              .map((categoryGetter) => ({
                label: getString(categoryGetter.name),
                value: categoryGetter.id,
              })) ?? [],
        },
      ];
};

export const getCustomAttributeOptions = (
  customAttributeOptions?: { label: string; value: string }[]
) => {
  if (!customAttributeOptions || customAttributeOptions.length < 1) {
    return [];
  }

  return [
    {
      label: 'Custom Fields',
      options: customAttributeOptions,
    },
  ];
};
