import { FC, MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { Editor as TinyEditor } from 'tinymce';

import { FormContext } from '@/components/Form/Form/FormContext';
import { PageWrapper } from '@/components/Form/Form/PageWrapper';
import { FormContextProps } from '@/components/Form/Form/types';
import {
  Parent_Type_Enum,
  useGetGlobalApprovalsQuery,
  Version_Status_Enum,
} from '@/generated/graphql';

import {
  DocumentFileFormSchema,
  DocumentVersionFormFieldData,
} from './documentFileSchema';
import DocumentVersionFormFields from './DocumentVersionFormFields';

export type Props = Omit<
  FormContextProps<DocumentVersionFormFieldData>,
  | 'schema'
  | 'formId'
  | 'parentType'
  | 'i18n'
  | 'renderTemplate'
  | 'submitActions'
> & {
  readonly?: boolean;
  isEdit: boolean;
  savedStatus: Version_Status_Enum;
  editorRef: MutableRefObject<TinyEditor | null>;
  parentId: string;
  hasPendingChangeRequests: boolean;
  disableStatus: boolean;
};

const DocumentVersionForm: FC<Props> = (props) => {
  const { t: st } = useTranslation(['common']);
  const isDocumentFileDraft = props.savedStatus === Version_Status_Enum.Draft;
  const isPublished = props.savedStatus === Version_Status_Enum.Published;
  const allowStatusChange =
    !props.hasPendingChangeRequests && !props.readOnly && props.isEdit;
  const { data } = useGetGlobalApprovalsQuery({
    variables: {
      parentId: props.parentId,
    },
  });

  const requiresApproval = data?.approval.some(
    (a) => a.Workflow === 'publish-document-version'
  );

  return (
    <FormContext
      {...props}
      parentType={Parent_Type_Enum.DocumentFile}
      header={st('details')}
      i18n={st('documentFiles')}
      schema={DocumentFileFormSchema}
      formId={'document-file-form'}
      renderTemplate={(renderProps) => <PageWrapper {...renderProps} />}
      submitActions={[
        {
          label: st('save'),
          action: props.onSave,
        },
        ...(allowStatusChange && isDocumentFileDraft
          ? [
              {
                label: requiresApproval
                  ? st('submit_for_approval')
                  : st('publish'),
                action: (data: DocumentVersionFormFieldData) =>
                  props.onSave({
                    ...data,
                    Status: Version_Status_Enum.Published,
                  }),
              },
            ]
          : []),
        ...(allowStatusChange && isPublished
          ? [
              {
                label: st('archive'),
                action: (data: DocumentVersionFormFieldData) =>
                  props.onSave({
                    ...data,
                    Status: Version_Status_Enum.Archived,
                  }),
              },
            ]
          : []),
      ]}
    >
      <DocumentVersionFormFields
        disableStatus={props.disableStatus}
        isEdit={props.isEdit}
        editorRef={props.editorRef}
        savedStatus={props.savedStatus}
        parentId={props.parentId}
      />
    </FormContext>
  );
};

export default DocumentVersionForm;
