
    import './styles.scoped.css';
    export default {
  "tabs-header": "awsui_tabs-header_14rmt_6g5z4_286",
  "tabs-header-list": "awsui_tabs-header-list_14rmt_6g5z4_294",
  "pagination-button": "awsui_pagination-button_14rmt_6g5z4_311",
  "pagination-button-left": "awsui_pagination-button-left_14rmt_6g5z4_318",
  "pagination-button-left-scrollable": "awsui_pagination-button-left-scrollable_14rmt_6g5z4_321",
  "pagination-button-right": "awsui_pagination-button-right_14rmt_6g5z4_325",
  "pagination-button-right-scrollable": "awsui_pagination-button-right-scrollable_14rmt_6g5z4_328",
  "tabs-tab": "awsui_tabs-tab_14rmt_6g5z4_333",
  "tabs-tab-label": "awsui_tabs-tab-label_14rmt_6g5z4_345",
  "tabs-tab-link": "awsui_tabs-tab-link_14rmt_6g5z4_361",
  "refresh": "awsui_refresh_14rmt_6g5z4_380",
  "tabs-tab-disabled": "awsui_tabs-tab-disabled_14rmt_6g5z4_431",
  "tabs-tab-active": "awsui_tabs-tab-active_14rmt_6g5z4_466",
  "tabs-header-with-divider": "awsui_tabs-header-with-divider_14rmt_6g5z4_473",
  "root": "awsui_root_14rmt_6g5z4_477",
  "tabs": "awsui_tabs_14rmt_6g5z4_286",
  "tabs-content": "awsui_tabs-content_14rmt_6g5z4_518",
  "fit-height": "awsui_fit-height_14rmt_6g5z4_522",
  "tabs-content-active": "awsui_tabs-content-active_14rmt_6g5z4_528",
  "tabs-content-wrapper": "awsui_tabs-content-wrapper_14rmt_6g5z4_542",
  "with-paddings": "awsui_with-paddings_14rmt_6g5z4_542",
  "tabs-container-content-wrapper": "awsui_tabs-container-content-wrapper_14rmt_6g5z4_553"
};
  