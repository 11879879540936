import { FC, useCallback, useState } from 'react';

type Props = {
  logoKey: string | undefined;
  customLogoUrl?: () => Promise<string>;
};

const OrganisationLogo: FC<Props> = ({ logoKey, customLogoUrl }) => {
  const [src, setSrc] = useState(
    () =>
      window.sessionStorage.getItem('logo') ??
      `/organisation/${logoKey}/logo.jpg`
  );

  const getCustomLogo = useCallback(async () => {
    window.sessionStorage.removeItem('logo');
    // use this as fallback if customLogoUrl fails
    setSrc(`/organisation/default/logo.jpg`);
    try {
      if (!customLogoUrl) return;
      const cached = window.sessionStorage.getItem('logo');
      if (cached) {
        setSrc(cached);
      } else {
        const customSrc = await customLogoUrl();
        window.sessionStorage.setItem('logo', customSrc);
        setSrc(customSrc);
      }
    } catch (e) {
      console.error("Couldn't get custom logo", e);
    }
  }, [customLogoUrl]);

  if (!logoKey) {
    return <></>;
  }

  return (
    <div className="w-[44px] h-[44px] rounded-full mr-4 overflow-hidden">
      <img
        alt={'Organisation logo'}
        src={src}
        className="w-full h-full"
        onError={getCustomLogo}
      />
    </div>
  );
};

export default OrganisationLogo;
